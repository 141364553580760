
declare const JavascriptBridge: JavascriptBridgeInterface | undefined

interface JavascriptBridgeInterface {
   log(message: string): void
   pinLogin?(pin: string): void
}

function createBridge(): JavascriptBridgeInterface {

   // Android
   if (typeof JavascriptBridge !== 'undefined') {
      console.log("Found Android javascriptBridge")
      return JavascriptBridge
   }

   // iOS
   const webkit = (window as any).webkit
   console.log("webkit: ", webkit)
   if (webkit?.messageHandlers) {
      console.log("Found iOS messageHandlers")

      const pinLogin = webkit.messageHandlers.pinLogin
      const log = webkit.messageHandlers.log
      if (log) {
         return {
            log: (message: string) => {
               log.postMessage(message)
            },
            pinLogin: (pin: string) => {
               if (pinLogin) {
                  pinLogin.postMessage(pin)
               }
            }
         }
      } else {
         console.warn("No log handler found on webkit.messageHandlers")
      }
   }

   console.log("Using NOOP javascriptBridge")

   return {
      log: (message: string) => { },
      pinLogin: undefined
   }
}

const bridge = createBridge()

export default bridge
