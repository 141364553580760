import classNames from 'classnames';
import moment from 'moment';
import React, { useCallback, useEffect, useMemo, useRef } from 'react';
import { useDispatch } from 'react-redux';
import { AttendeeType, isRoom, Meeting } from '../model';
import { getCalendarDelegations, getSecondaryUser, getSelectedDate, isExternalUser, isSingleUser } from '../store/helpers';
import { makeMeetingsSelector } from '../store/reducers';
import { selectCalendarLoadedDays } from '../store/reducers/calendars';
import { actions, useSelector } from '../store/utils';
import { roundStartTime } from '../utils/dateUtils';
import { arraysEqual } from '../utils/misc';
import { ReactComponent as AddIcon } from './buttons/add.svg';
import { ReactComponent as LogoutIcon } from './buttons/logout.svg';
import { CalendarDelegationPicker } from './CalendarDelegationPicker';
import ContactPhoto from './ContactPhoto';
import { MeetingInformation } from './MeetingInformation';
import DefaultPage from './mobile/DefaultPage';
import css from './MyCalendar.module.scss';
import SheduleDatePicker from './ScheduleDatePicker';
import { TimeGrid, TimeGridColumn, TimeGridItem } from './TimeGrid';

const TotalDays = 7

export default function MyCalendar() {
   const singleUser = useSelector(isSingleUser)
   const date = moment(useSelector(getSelectedDate)).startOf('w')
   const dispatch = useDispatch()
   const myself = useSelector(getSecondaryUser)
   const teamsMode = useSelector(s => s.teams.teamsMode)
   const delegations = useSelector(getCalendarDelegations)
   const popup = useSelector(s => s.popup)
   const roomDelegated = isRoom(myself) && delegations && delegations.delegator.emailAddress !== myself.emailAddress
   const externalUser = useSelector(isExternalUser)

   useLoadCalendar(date.valueOf(), TotalDays)

   function onClick({ id }: Meeting) {
      dispatch(actions.openPopupWithParams({ type: 'meetingInformation', meetingId: id }))

      // if (roomDelegated) {
      //    dispatch(actions.openPopupWithParams({ type: 'meetingInformation', meetingId: id }))
      // } else {
      //    dispatch(actions.editBooking(id, undefined))
      // }
   }

   function onNew() {
      if (!myself) { return }
      dispatch(actions.selectDate())
      dispatch(actions.startBooking({ participants: [{ emailAddress: myself.emailAddress, type: AttendeeType.required }] }))
   }

   const showTime = date.isSameOrBefore() && date.add(1, 'w').isAfter()

   if (externalUser) {
      return <DefaultPage message="Thank you for using Book-It. To use Book-It again please follow a meeting link that you find in your Outlook meeting invite."/>
   }

   return <div className={classNames(css.myCalendar, teamsMode && css.teamsMode)}>
      <header>
         <SheduleDatePicker className={css.date} value={date.valueOf()} onChange={d => dispatch(actions.selectDate(d))} unit='w' />
         {!roomDelegated && <div className={css.button} tabIndex={0} onClick={onNew}>
            <AddIcon />
            New
         </div>}
         {delegations && <CalendarDelegationPicker delegations={delegations} />}
         {!singleUser && <div className={css.button} tabIndex={0} onClick={() => dispatch(actions.secondaryLogout())}>
            <LogoutIcon />
            Logout
         </div>}
      </header>
      <TimeGrid autoScroll headerClassName={css.columnHeader} corner={myself && <ContactPhoto className={css.photo} contact={myself} />} showCurrentTime={showTime}>
         {[...Array(TotalDays)].map((_, i) => <Column key={i} day={date.add(i, 'd').valueOf()} onClick={onClick} roomDelegated={roomDelegated} />)}
      </TimeGrid>
      {popup.type !== undefined && <MeetingInformation />}
   </div>
}



function Column({ day, onClick, roomDelegated }: { day: number, onClick(m: Meeting): void, roomDelegated: boolean }) {
   const myself = useSelector(getSecondaryUser)
   const selector = useMemo(() => makeMeetingsSelector(() => myself?.emailAddress, () => day), [myself, day])
   const meetings = useSelector(s => selector(s, null))
   const dispatch = useDispatch()

   const onStartBooking = useCallback((hr: number) => {
      if (roomDelegated) return
      dispatch(actions.selectDate(day))
      dispatch(actions.startBooking({ start: roundStartTime(hr, 'round'), 
                                      participants: [{ emailAddress: myself!.emailAddress, type: AttendeeType.required }] }))
   }, [roomDelegated, dispatch, day, myself])

   function onMeetingClick(e: React.MouseEvent, m: Meeting) {
      e.stopPropagation()
      onClick(m)
   }

   return <TimeGridColumn header={<>
      <div>{moment(day).format('dddd')}</div>
      <div>{moment(day).format('DD MMM')}</div>
   </>} loading={!meetings} headerClassName={css.columnHeader} onClick={onStartBooking}>
      {moment().isSame(day, 'd') && <div className={css.today} />}
      {meetings?.map(m => <TimeGridItem key={m.id}
         start={moment.max(moment(m.startTime), moment(day)).valueOf()}
         finish={moment.min(moment(m.endTime), moment(day).endOf('d')).valueOf()}
         className={classNames(css.meeting, isLong(m) && css.long)} onClick={e => onMeetingClick(e, m)}>
         {m.subject}
      </TimeGridItem>)}
   </TimeGridColumn>
}

function isLong(meeting: Meeting) {
   return moment(meeting.endTime).diff(meeting.startTime, 'h', true) >= 24
}

export function useLoadCalendar(date: number, totalDays: number) {
   const dispatch = useDispatch()
   const myself = useSelector(getSecondaryUser)
   const email = myself?.emailAddress
   const calDays = useSelector(s => email ? selectCalendarLoadedDays(s, email) : [])
   const oldDays = useRef<typeof calDays>()
   const externalUser = useSelector(isExternalUser)


   useEffect(() => {
      oldDays.current = undefined
   }, [date, email, totalDays])

   useEffect(() => {
      if (!email || externalUser) { return }

      if (oldDays.current && arraysEqual(oldDays.current, calDays)) {
         return
      }
      oldDays.current = calDays

      const set = new Set(calDays)
      let start = date.valueOf()
      let end = start

      for (let i = 0; i < totalDays; i++) {
         const day = moment(date).add(i, 'd').valueOf()
         const next = moment(day).add(1, 'd').valueOf()

         if (set.has(day)) {
            if (start !== end) {
               dispatch(actions.fetchCalendarsRequest(start, end, email))
            }
            start = next
         }

         end = next
      }

      if (start !== end) {
         dispatch(actions.fetchCalendarsRequest(start, end, email))
      }
   }, [calDays, date, dispatch, email, externalUser, totalDays])
}
