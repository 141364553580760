import React from 'react';
import { DropdownItem, DropdownMenu, DropdownToggle, Form, FormGroup, Modal, ModalBody, ModalHeader, UncontrolledDropdown } from 'reactstrap';
import { LocationPickerProps } from '../containers/LocationPicker';

export default class LocationPicker extends React.PureComponent<LocationPickerProps> {
   public render() {
      const { locations, setLocation } = this.props;

      return (
         <Modal isOpen>
            <ModalHeader>
               Please pick your location
            </ModalHeader>
            <ModalBody>
               <Form>
                  <FormGroup>
                     <UncontrolledDropdown>
                        <DropdownToggle caret disabled={locations === null}>
                           Please pick your location
                        </DropdownToggle>
                        <DropdownMenu>
                           {!locations ? [] : locations.map(loc => <DropdownItem key={loc} onClick={() => setLocation(loc)}>{loc}</DropdownItem>)}
                        </DropdownMenu>
                     </UncontrolledDropdown>
                  </FormGroup>
               </Form>
            </ModalBody>
         </Modal>
      );
   }
}
