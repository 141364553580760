import { bind } from 'bind-decorator';
import moment from 'moment';
import React from 'react';
import { TimelineProps } from '../containers/Timeline';
import TimelineItem from '../containers/TimelineItem';
import bg from './bg.jpg';
import Spinner from './Spinner';
import css from './Timeline.module.scss';

interface State {
   expandedIndex?: number;
}

export default class Timeline extends React.PureComponent<TimelineProps, State> {
   public readonly state: State = {};
   public readonly currentRef = React.createRef<HTMLDivElement>()
   private timer?: number

   public render() {
      const { meetings, time, loading } = this.props;
      if (loading) {
         return <div className={css.timeline}>
            <div className={css.spinner}>
               <Spinner className={css.svg} />
            </div>
         </div>;
      }

      const current = meetings.findIndex(m => m.endTime > time)

      const { expandedIndex } = this.state;
      return <div className={css.timeline} onClick={() => this.expandItem(undefined)} style={{ '--bg': `url("${this.props.backgroundUrl || bg}")` } as {}}>
         {meetings.map((meeting, index) =>
            <TimelineItem key={meeting.id} meeting={meeting} currentTime={time} onExpand={() => this.expandItem(index)}
               maxDuration={this.getMaxDuration(index)} isExpanded={index === expandedIndex} needsForceStart={this.needsForceStart(index)}
               innerRef={index === current ? this.currentRef : undefined} />)}
      </div>;
   }

   public componentDidMount() {
      this.props.onInit();
      this.props.onUpdated();
      this.timer = window.setInterval(this.scrollToCurrent, 1000)
   }

   public componentDidUpdate() {
      this.props.onUpdated();
   }

   public componentWillUnmount() {
      window.clearInterval(this.timer)
   }

   @bind
   private scrollToCurrent() {
      if (moment().diff(this.props.lastActivity, 's') < 10) { return }

      const item = this.currentRef.current
      if (item) {
         item.scrollIntoView({ behavior: 'smooth', block: 'start' })
      }
   }

   private getMaxDuration(index: number) {
      const { meetings } = this.props
      const meeting = meetings[index]

      const next = meetings.length - 1 === index ?
         moment(meeting.endTime).add(1, 'd').startOf('d') :
         moment(meetings[index + 1].startTime)

      return next.diff(meeting.endTime, 'h', true)
   }

   private needsForceStart(index: number) {
      if (index === 0) { return false }
      const { meetings } = this.props
      const prevMeeting = meetings[index - 1]
      return prevMeeting.ended === null && (prevMeeting.started !== null || prevMeeting.endTime > this.props.time)
   }

   private expandItem(index: number | undefined) {
      this.setState(({ expandedIndex }) => ({ expandedIndex: index !== expandedIndex ? index : undefined }));
   }
}
