import classNames from 'classnames';
import React from 'react';
import css from './FormField.module.scss';

type FormFieldProps = {
   icon?: React.ReactNode
   combine?: boolean
   className?: string
}

export default function FormField({ className, icon, combine, children }: React.PropsWithChildren<FormFieldProps>) {
   return <label className={classNames(className, css.formField, combine && css.combine)}>
      {icon !== undefined && <div className={css.icon}>{icon}</div>}
      <div className={css.body}>
         {children}
      </div>
   </label>
}
