import moment from 'moment';
import { AgendaItem, Attendee, AttendeeType, Contact, Domain, Domains, FreeBusyStatus, HomeType, isPerson, isRoom, MeetingType, Nearby, PermissionSet, Person, Recurrence, ResourceAvailabilityBlock, ResponseStatus, Room, RoomAttribute, RoomType, RoundtripBookingResourcesFilter, SchedulesDataItem, Suggestion, Team, TeamToJoin } from './model';
import { getExtraTime } from './store/reducers/booking';
import { StoreState } from './store/utils';
import { flatten } from './utils/misc';

async function fetchJson<T>(url: string, { headers, ...init }: RequestInit) {
   const response = await fetch(url, {
      credentials: 'include',
      ...init,
      headers: {
         ...headers,
         'Accept': 'application/json',
      }
   });
   if (response.status === 204) {
      return undefined! as T;
   }
   if (response.status >= 400) {
      let body = await response.text()
      let error: ApiError | null = null

      try {
         const json = await JSON.parse(body)
         if ('title' in json) {
            error = json
         }
      } catch (e) {
         ;
      }

      if (!error) {
         error = { title: body, detail: response.statusText, code: 'ErrorGeneral', instance: url, status: response.status }
      }
      throw new HttpError(response.status, response.statusText, error);
   }
   return await response.json() as T;
}

async function get<T>(url: string) {
   return await fetchJson<T>(url, {});
}

async function post<T>(url: string, body: any, headers: Record<string, string> = {}) {
   return await fetchJson<T>(url, {
      method: 'POST',
      headers: { 'Content-Type': 'application/json', ...headers },
      body: JSON.stringify(body)
   });
}

async function del<T>(url: string, body: any) {
   return await fetchJson<T>(url, {
      method: 'DELETE',
      headers: { 'Content-Type': 'application/json' },
      body: JSON.stringify(body)
   });
}

export interface ApiError {
   status: number
   instance: string
   code: ErrorCode

   title: string
   detail: string
}

type ErrorCode = 'ErrorGeneral' | 'ErrorTimeConflict' | 'ErrorMeetingNotFound' | 'ErrorMeetingLinkNotFound'

export class HttpError extends Error {
   public readonly status: number;
   public readonly statusText: string;
   public readonly apiError: ApiError;

   constructor(status: number, statusText: string, apiError: ApiError) {
      super(`${status} ${statusText}`);

      this.status = status;
      this.statusText = statusText;
      this.apiError = apiError;

      Object.setPrototypeOf(this, HttpError.prototype);
   }
}

export async function fetchRooms() {
   return await get<Room[]>(`/api/rooms`);
}

interface CalendarData {
   emailAddress: string
   meetings: MeetingData[]
}

export async function fetchCalendars(start: number, end: number, email: string): Promise<CalendarsData[string]> {
   const data = await post<CalendarData>(`api/calendar/calendars`, {
      emailAddress: email,
      start: new Date(start),
      end: new Date(end),
   })

   return populateEmailDayResult([email], start, end,
      email => {
         const item = data
         const meetings = item ? item.meetings : []
         return day => meetings.filter(m => moment(m.startTime).startOf('d').isSame(day))
      })[email]
}

interface ByEmailAndDay<T> {
   [email: string]: {
      [day: number]: T
   }
}

export type CalendarsData = ByEmailAndDay<MeetingData[]>
export type SchedulesData = ByEmailAndDay<SchedulesDataItem>

export async function fetchSchedules(days: number[], emails: string[]): Promise<SchedulesData> {
   return await post<SchedulesData>('api/calendar/schedules', {
      ranges: days.map(d => ({
         startUtc: moment(d).startOf('d').toDate(),
         endUtc: moment(d).endOf('d').toDate(),
      })),
      emailAddresses: emails,
   })
}

function populateEmailDayResult<T>(emails: string[], start: number, end: number, getData: (email: string) => (day: number) => T): ByEmailAndDay<T> {
   const result: ByEmailAndDay<T> = {}

   for (const email of emails) {
      const data: ByEmailAndDay<T>[''] = {}
      const getDayData = getData(email)

      for (let day = start; day < end; day = moment(day).add(1, 'd').valueOf()) {
         data[day] = getDayData(day)
      }

      result[email] = data
   }

   return result
}

export interface AvailabilitySearchItem {
   before: number
   after: number
   emails: string[]
   pinned: string[]
   count: number
}

export async function searchAvailable(
   organiser: string, pool: AvailabilitySearchItem[],
   participants: string[], start: number, duration: number, meetingId: string | undefined, allDay: boolean, recurrence: Recurrence | null, numberOfSuggestions: number): Promise<Suggestion[]> {

   const earliestDateTime = Math.max(moment(start).startOf('d').valueOf(), Date.now())

   const res = await post<AvailabilityResult[]>('api/calendar/availabilitySearch', {
      organiserEmail: organiser,
      anyAvailable: pool.map(p => ({
         mailboxes: p.emails,
         pinned: p.pinned,
         requiredCount: p.count,
         preBookMinutes: -p.before,
         postBookMinutes: p.after,
      })),
      allAvailable: participants,
      earliestDateTime: moment(earliestDateTime).format(),
      preferredDateTime: moment(start).format(),
      latestDateTime: moment(earliestDateTime).endOf('d').format(),
      honourWorkingHours: !allDay,
      duration: allDay ? duration / 24 : Math.round(duration * 60),
      durationUnit: allDay ? 2 : 0, // 0 = minutes, 2 = days
      meetingId,
      recurrence,
      allDay,
      numberOfSuggestions,
   })

   return res.map(x => ({
      start: new Date(x.startDateTime).valueOf(),
      participantsAvailable: x.allAvailable,
      resourcesAvailable: !x.incomplete,
      selected: x.anyAvailable.map(x => [...x.pinned, ...x.selected]),
      available: x.anyAvailable.map(x => x.available),
      incomplete: x.anyAvailable.map(x => x.incomplete),
   })).sort((a, b) => a.start - b.start)
}

interface AvailabilityResult {
   anyAvailable: Array<{
      available: string[]
      selected: string[]
      pinned: string[]
      incomplete: boolean
   }>
   incomplete: boolean
   allAvailable: boolean
   startDateTime: string
   endDateTime: string
}

export interface SecondaryLogin {
   contact: Contact
   delegations: DelegationData
   organisation: Organisation
   webExAuthUrl: string | null
}

export interface Login {
   myself: Contact
   delegations?: DelegationData
   teams: boolean
   singleUser: boolean
   needLogin: boolean
   secondary: SecondaryLogin | null
   staticData: {
      roomSizesFilter: number[]
      canBookAdhoc: boolean
      maxEarlyStartMinutes: number
      deviceBackgroundUrl: string | null
      allowSecondaryLoginAutoComplete: boolean
      allowAttendeeEditing: boolean
      allowExternalAttendeeEditing: boolean
   },
   organisation: null | Organisation,
   domain: string
   device: null | {
      home: HomeType
      name: string
      rooms: Array<{
         emailAddress: string
         directionIcon: string | null
      }>
      sharedOrganisations: SharedOrganisationData[]
      floorPlans: {
         floor: string | null
         plan: string
      }[]
      loginData: Domain[]
   }
   isExternal: boolean
}
export interface SharedOrganisationData {
   organisationId: string
   name: string
   iconUrl: string | null
}
export interface DelegationData {
   delegator: Person
   room: {all: boolean, rooms: string[]}
   people: {all: boolean, people: Person[]}
}
export interface Organisation {
   organisationId: string,
   rooms: Room[]
   attributes: RoomAttribute[]
   domains: Domains
   permissions: PermissionSet[]
   onlineMeetingTypes: MeetingType[]
}

export interface MeetingLink {
   meetingData: MeetingData
   externalData: Login
}

export type LoginArgs = { pin: string } | { userName: string, password: string } | { token: string, teams: boolean };

export async function login(payload: LoginArgs) {
   try {
      if ('pin' in payload) {
         return await post<Login>('api/login/pin', payload.pin);
      } else if ('token' in payload) {
         return await post<Login>('api/login/ad_token', payload);
      } else {
         return await post<Login>('api/login', payload);
      }
   } catch (e) {
      if (e instanceof HttpError && e.status === 401) {
         return false;
      }
      throw e;
   }
}

export type DelegeatedLoginArgs = { delegatee: string, type: "Room" | "Person" }

export async function delegatedLogin(payload: DelegeatedLoginArgs) {
   try {
      return await post<Login>('api/login/delegated', { email: payload.delegatee, type: payload.type } );
   } catch (e) {
      if (e instanceof HttpError && e.status === 401) {
         return false;
      }
      throw e;
   }
}

export async function bearerLogin(token: string) {
   try {
      return await post<Login>('api/login/bearer', null, { Authorization: `Bearer ${token}` });
   } catch (e) {
      if (e instanceof HttpError && e.status === 401) {
         return false;
      }
      throw e;
   }
}

export async function whoAmI() {
   try {
      return await get<Login>('/api/whoAmI');
   } catch (e) {
      if (e instanceof HttpError && e.status === 401) {
         return undefined;
      }
      throw e;
   }
}

export async function fetchMeetingByMeetingLinkId(meetingLinkId: string) {
   try {
      return await get<MeetingLink>(`api/calendar/meeting_link/${meetingLinkId}`)
   } catch (e) {
      if (e instanceof HttpError && e.status === 400) {
         return null;
      }
      throw e;
   }
}

export async function searchContacts(name: string, domainKey?: string) {
   name = name.trim();

   if (name.length < 1) {
      return [];
   }

   try {
      return await post<Contact[]>('api/search/contacts', { name, domainKey });
   } catch (e) {
      return []
   }
}

export type Recipient = {
   emailAddress: string
   name: string
}

export interface AttendeeResource extends Recipient {
   attendeeType: 'Required' | 'Optional' | 'Resource'
   responseStatus: ResponseStatus
}

export interface MeetingData {
   id: string
   calendarContext: string
   startTime: number
   endTime: number
   subject: string
   bodyText: string | null
   organiser?: Recipient
   participants: Array<AttendeeResource>
   meetingRooms: Array<AttendeeResource>
   onlineMeetingTypes: MeetingType[]
   isPrivate: boolean
   isAdHocMeeting: boolean
   adhocOrganisationId: string | null
   isExternalOrganisation: boolean

   resourceMeetings: null | Array<{
      startOffsetMins: number
      endOffsetMins: number
      emailAddress: string[]
   }>

   meetingExceptions: MeetingExceptions

   attachments?: Array<{
      uniqueId: string
      name: string
      contentType: string
      size: number
   }>

   agendaItems: AgendaItem[]
   agendaNotes: string

   permissions: PermissionSet | null
   filter: RoundtripBookingResourcesFilter[]
   pending: boolean
   includeMeetingLink: boolean
   showAs: FreeBusyStatus

   iCalUid: string
   isRecurring: boolean
   recurrenceId: string
   recurrence: Recurrence | null

   isAllDayEvent: boolean
   teamsToJoin: TeamToJoin[]
}

export type MeetingExceptions = Record<string, { startTime: number, endTime: number }>

export interface MeetingException {
   startTime: number
   endTime: number
}

export async function fetchMeeting(id: string) {
   return await get<MeetingData>(`api/calendar/meeting/${encodeURIComponent(id)}?organiser=false`)
}

export async function fetchFullMeeting(id: string) {
   return await get<MeetingData>(`api/calendar/meeting/${encodeURIComponent(id)}`)
}

interface CreateMeetingArgs {
   booking: NonNullable<StoreState['booking']>
   time: number
   participants: Attendee[]
   addFiles: File[]
   removeFiles: string[]
   recurrence: Recurrence | null
   autoStart: boolean
   includeMeetingLink: boolean
   webExToken: string | undefined
}

interface ResourceMeeting {
   startOffsetMins: number
   endOffsetMins: number
   emailAddresses: string[]
}

export async function createMeeting({ booking, time, participants, addFiles, removeFiles, recurrence, autoStart, includeMeetingLink, webExToken }: CreateMeetingArgs) {
   if (!booking.suggested) {
      throw new Error('No accepted suggestion')
   }

   console.log("createMeeting includeMeetingLink", includeMeetingLink)


   let startTime = moment(time).toDate();
   let endTime = moment(time).add(booking.duration, 'h').toDate();

   if (booking.allDay) {
      startTime = new Date(trimAllDayUtc(startTime.valueOf()))
      endTime = new Date(trimAllDayUtc(endTime.valueOf()))
   }

   const resources: RoundtripBookingResourcesFilter[] = booking.resources.map((resource, i) => ({
      ...resource,
      selected: booking.suggested!.selected[i],
   }))

   if (booking.nearby) {
      resources.push({
         selected: booking.suggested.selected[0],
         type: booking.nearby.roomType,
         quantity: 1,
         location: [],
         attributes: [],
         sizeIndex: -1,
         extraBefore: 0,
         extraAfter: 0,
         pinned: [],
      })
   }

   const resourceMeetings: ResourceMeeting[] = resources
      .filter(r => r.type !== 'MeetingRoom')
      .map<ResourceMeeting>(r => ({
         emailAddresses: r.selected,
         startOffsetMins: getExtraTime(r.extraBefore, booking, r.type),
         endOffsetMins: getExtraTime(r.extraAfter, booking, r.type),
      })).concat(participants.filter(p => isRoom(p.contact) && p.contact.roomType !== 'MeetingRoom').map<ResourceMeeting>(p => ({
         emailAddresses: [p.contact.emailAddress],
         // user can't enter those right now for manually added resources, so we default them to zeroes
         startOffsetMins: 0,
         endOffsetMins: 0,
      })))

   const { isPrivate, createAppointment, subject, agenda, permissions, types, teamsToJoin } = booking

   const args = {
      startTime,
      endTime,
      isAllDayEvent: booking.allDay,
      subject: subject || 'Untitled Meeting',
      agendaItems: agenda.items,
      agendaNotes: agenda.notes,

      onlineMeetingTypes: booking.createAppointment ? types : [],
      isPrivate,
      createAppointment,

      attendees: participants.filter(p => isPerson(p.contact)).map(toAttendee),
      meetingRooms: [
         ...participants.filter(p => isRoom(p.contact) && p.contact.roomType === 'MeetingRoom').map(p => p.contact.emailAddress),
         ...flatten(resources.filter(r => r.type === 'MeetingRoom').map(r => r.selected))
      ],

      resourceMeetings,
      permissions,

      filesToRemove: removeFiles,
      recurrence,
      autoStart,
      includeMeetingLink,
      webExToken,

      filter: resources,
      teamsToJoin,
      adhocOrganisationId: null
   }

   const data = new FormData()
   data.append('args', JSON.stringify(args))
   addFiles.forEach(f => data.append('filesToAdd', f, f.name))

   console.log("createMeeting", args)

   const id = booking.meetingId
   return await fetchJson<MeetingData>(`api/calendar/meeting-with-attachments${id ? '/' + encodeURIComponent(id) : ''}`, {
      method: 'POST',
      body: data,
   });

   function trimAllDayUtc(time: number) {
      const [y, m, d] = moment(time).toArray()
      return moment.utc([y, m, d]).valueOf()
   }

   function toAttendee(p: Attendee) {
      return { emailAddress: p.contact.emailAddress, isRequired: p.type === AttendeeType.required }
   }
}

export async function createAdhocMeeting(time: number, duration: number, subject: string, room: string, adhocOrganisationId: string|null) {
   return await post<MeetingData>('api/calendar/meeting', {
      startTime: moment(time).toDate(),
      endTime: moment(time).add(duration, 'h').toDate(),
      subject,
      bodyText: '',

      isPrivate: false,
      isAdHocMeeting: true,

      attendees: [],
      meetingRooms: [room],

      resourceMeetings: [],
      agendaItems: [],
      notes: '',
      adhocOrganisationId: adhocOrganisationId
   });
}

export async function cancelMeeting(id: string, args?: SecondaryLoginArgs) {
   await del(`api/calendar/meeting/${encodeURIComponent(id)}`, args);
   return { id }
}


export async function changeMeetingResponseStatus(id: string, comment: string|undefined, sendResponse: boolean, responseStatus: "Accepted" | "Tentative" | "Declined" ) {
   await post(`api/calendar/meeting/${encodeURIComponent(id)}/responseStatus`, {
      comment: sendResponse ? comment : null,
      sendResponse,
      responseStatus
   });
   return { id }
}

export async function extendMeeting(id: string, hr: number) {
   return await post<MeetingData>(`api/calendar/meeting/extend/${encodeURIComponent(id)}`, {
      minutes: hr * 60,
   });
}

export function getErrorMessage(err: any) {
   return err instanceof HttpError
      ? (err.status === 401 ? 'Invalid username or password' : err.apiError.title ? (err.apiError.title + "\r\n" + err.apiError.detail) : err.statusText)
      : (err as Error).message || 'Error';
}

export async function findPerson(email: string) {
   try {
      return await get<Person>(`/api/people/${encodeURIComponent(email)}`);
   } catch (e) {
      if (e instanceof HttpError && e.status === 404) {
         return null;
      }
      throw e;
   }
}

export interface AzureAdConfig {
   appId: string;
   tenantId: string;
}

export async function fetchContacts(emails: string[]) {
   if (emails.length === 0) { return [] }
   return await post<Contact[]>('/api/people/people', emails);
}

export async function fetchAvailableSlot(mode: 'next' | 'prev', time: number, duration: number, participants: Contact[]) {
   const resp = await post<MeetingData>(`/api/calendar/${mode}Slot`, {
      startTime: moment(time).toDate(),
      endTime: moment(time).add(duration, 'h').toDate(),

      attendees: participants
         .filter(p => isPerson(p))
         .map(p => ({ emailAddress: p.emailAddress })),
      meetingRooms: participants
         .filter(isRoom).map(r => r.emailAddress),
   })

   return resp.startTime === time ? null : resp.startTime
}

export type SecondaryLoginArgs = { userName: string, password: string } | { token: string }

export async function secondaryLogin(args: SecondaryLoginArgs | { userName: string, token: string }) {
   const res = 'token' in args && !('userName' in args)
      ? await post<SecondaryLogin | false | string>('/api/secondaryLogin/ad_token', args.token)
      : await post<SecondaryLogin | false | string>('/api/secondaryLogin', args)
   return res === false ? 'Please check your credentials and try again' : res
}

export async function secondaryLogout() {
   await post('/api/secondaryLogout', {})
}

export async function logout() {
   await post('/api/logout', {})
}

export async function startMeeting(id: string) {
   await post(`/api/calendar/meeting/${encodeURIComponent(id)}/actualStart`, {})
}

export async function leaveMeeting(id: string) {
   await post(`/api/calendar/meeting/${encodeURIComponent(id)}/actualEnd`, {})
}

export async function fetchVersion() {
   return await get<string | undefined>('/api/version')
}

export async function fetchRecurrenceInstances(email: string, recId: string, start: number, end: number) {
   return (await post<MeetingData[]>('/api/calendar/instances', {
      emailAddress: email,
      recurrenceId: recId,
      start: new Date(start),
      end: new Date(end),
   }))
}

export async function organisationLogin(email: string) {
   try {
      return await get<Domain>(`/api/organisation/login?emailAddress=${encodeURIComponent(email)}`)
   } catch (e) {
      if (e instanceof HttpError) {
         if (e.status === 400) {
            return 'Not a valid email address'
         }

         if (e.status === 404) {
            return 'Organisation not found'
         }

         return e.apiError.title || e.message
      }

      if (e instanceof Error) {
         return e.message
      }

      return String(e)
   }
}

export function getPhotoUrl(email: string) {
   return `/api/people/${encodeURIComponent(email)}/photo`
}

export type QuickBookBooking = {
   roomEmail: string
   roomName: string
   start: number
   end: number
   isBookable: boolean
} & ({
   isMine: true
   id: string
} | {
   isMine: false
   id?: undefined
})

export async function findNearby(start: number, end: number, email: string, resourceType: RoomType, isAllDay: boolean) {
   return await post<Record<string, ResourceAvailabilityBlock>>(`api/calendar/nearbySearch`, {
      start,
      durationInMinutes: moment(end).diff(start, 'm', true),
      end: moment(end).endOf('d').valueOf(),
      resourceEmail: email,
      resourceType,
      honourWorkingHours: !isAllDay,
   })
}

export async function fetchMyTeams() {
   return await get<Team[]>('api/teams/myTeams')
}

export async function addResourcesToMeeting(booking: NonNullable<StoreState['booking']>) {
   if (!booking.suggested) {
      throw new Error('No accepted suggestion')
   }

   if (!booking.meetingId) {
      throw new Error('Only existing meeting can be ')
   }

   const resources: RoundtripBookingResourcesFilter[] = booking.resources.map((resource, i) => ({
      ...resource,
      selected: booking.suggested!.selected[i],
   }))

   const resourceMeetings: ResourceMeeting[] = resources
      .filter(r => r.type !== 'MeetingRoom')
      .map<ResourceMeeting>(r => ({
         emailAddresses: r.selected,
         startOffsetMins: getExtraTime(r.extraBefore, booking, r.type),
         endOffsetMins: getExtraTime(r.extraAfter, booking, r.type),
      }))

   return await post<MeetingData>(`api/calendar/meeting/${encodeURIComponent(booking.meetingId)}/resources`, {
      meetingRooms: resources.filter(r => r.type === 'MeetingRoom').flatMap(r => r.selected),
      resourceMeetings,
      filter: resources,
   })
}

export interface StaticConfig {
   mrsUrl: string
}

export async function fetchStaticConfig() {
   return await get<StaticConfig>('api/config/static')
}

export async function generateOtp(mrsRoot: string, email: string, nonce: string, tag?: string, meetingId?: string, messageId?: string, messageParameters?: Record<string, number|string|boolean>) {
   const query = new URLSearchParams()
   if (meetingId !== undefined) {
      query.append('meetingId', meetingId)
   }
   if (tag !== undefined) {
      query.append('tag', tag)
   }
   query.append('otp', '')

   return await post(`${mrsRoot}/otp/generate-nonce`, { email, nonce, url: `${window.location.origin}/api/login/email?${query}`, message_id: messageId, messageParameters })
}

export type VisitorType = 'SameOrg' | 'OtherOrg' | 'External'

export type VisitorTapResult = {
   meetings: MeetingData[]
   visitorType: VisitorType
}

export async function tapVisitorTag(id: string) {
   try {
      return await post<VisitorTapResult>('api/NfcTag/scanned', id)
   } catch (e) {
      if (e instanceof HttpError && e.status === 401) {
         return null
      }

      throw e
   }
}

export type DisplayBoardWelcome = {
   person: Person
   meetings: MeetingData[]
}

export async function notifyOrganiser(tagId: string, meetingId: string) {
   return await post<VisitorTapResult>('api/NfcTag/notify-organiser', { tagId, meetingId })
}
