import classNames from 'classnames';
import React, { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { Dispatch } from 'redux';
import { DelegationData } from '../api';
import { Contact, isPerson, isRoom, Room } from '../model';
import { getSecondaryUser, selectRooms } from '../store/helpers';
import { actions } from '../store/utils';
import Autocomplete from './Autocomplete';
import { ReactComponent as ArrowLeft } from './buttons/arrow-left.svg';
import css from './CalendarDelegationPicker.module.scss';
import ContactPhoto from './ContactPhoto';
import { performContactSearch } from './ParticipantInput';

type DelegateItem = {
   item: Contact;
   type: "Room" | "Person";
}

export function CalendarDelegationPicker({ delegations }: { delegations: DelegationData }) {
   const dispatch = useDispatch()

   const [value, setValue] = useState("")

   const allRooms = useSelector(selectRooms)
   const myself = useSelector(getSecondaryUser)

   const [isOpen, setIsOpen] = useState(false)


   useEffect(() => {
      if (!isOpen) {
         setValue("")
      }
   }, [isOpen])


   const handleSelect = (val: string, item: DelegateItem) => {
      setIsOpen(false)
      dispatch(actions.delegatedLogin({ delegatee: val, type: item.type }))
   }
   
   async function getItems(name: string): Promise<DelegateItem[]> {
      if (myself === undefined ) return []
      const people = await getPeopleItems(name, myself, delegations, dispatch)
      const rooms = await getRoomItems(delegations, allRooms)
      return [...people, ...rooms].filter(c => !name || c.item.name.toLowerCase().includes(name.toLowerCase()) || c.item.emailAddress.toLowerCase().includes(name.toLowerCase()))
   }


   return <div className={classNames(css.delegationPicker, isOpen && css.isOpen)}>
      <button onClick={() => setIsOpen(!isOpen)}>
         {myself && renderPersonItem({item: myself, type: "Person"}, false)}
         <ArrowLeft className={css.cheveron}/>
      </button>
      {isOpen && <Autocomplete
         className={css.autoComplete}
         getItems={getItems}
         value={value}
         onChange={(e, v) => setValue(v)}
         getItemValue={getItemValue}
         renderItem={renderPersonItem}
         onSelect={handleSelect}
         onBlur={() => setTimeout(() => setIsOpen(false), 200)}
         renderInput={props =><div className={css.inputWrapper}><input {...props} placeholder="Type to search..." autoFocus /></div>}
         menuClassName={css.menu}
      />}
   </div>
}


async function getPeopleItems(name: string, myself: Contact, delegations: DelegationData, dispatch: Dispatch<any>): Promise<DelegateItem[]> {
   if (!delegations.people) return []

   const isDelegated = myself.emailAddress !== delegations.delegator.emailAddress
   

   let base: Contact[]
   if (delegations.people.all) {
      const searchResults = await performContactSearch(name, dispatch)
      if (!isDelegated) {
         // if we aren't delegated make sure we don't return the delegator
         base = searchResults.filter(c => c.emailAddress !== delegations.delegator.emailAddress)
      } else {
         base = searchResults.filter(c => c.emailAddress !== myself.emailAddress)
      }
   } else {
      base = isDelegated 
         ? [delegations.delegator, ...delegations.people.people].filter(c => c.emailAddress !== myself.emailAddress)
         : delegations.people.people
      }

   return base.map(r => ({item: r, type: "Person"}))
}

async function getRoomItems(delegations: DelegationData, allRooms: Room[]): Promise<DelegateItem[]> {
   if (!delegations.room) return []

   return (delegations.room.all ? allRooms : allRooms.filter(r => delegations.room.rooms.includes(r.emailAddress)))
      .map(r => ({item: r, type: "Room"}))
}


function renderPersonItem(item: DelegateItem, isHighlighted: boolean) {
   return <div className={classNames(css.item, isHighlighted && css.highlighted)} key={item.item.emailAddress}>
      <ContactPhoto contact={item.item} />
      <div className={css.details}>
         <div className={css.name}>{item.item.name}</div>
         <div className={css.desc}>{getDesc(item.item)}</div>
      </div>
   </div>;
}

function getDesc(item: Contact) {
   if (isPerson(item)) {
      return [item.jobTitle, item.department].filter(x => x).join(', ')
   }
   if (isRoom(item)) {
      switch (item.roomType) {
         case 'MeetingRoom': return "Meeting Room"
         case 'ParkingSpace': return "Carppark"
         case 'HotDesk': return "Hot Desk"
         case 'Locker': return "Locker"
      }
   }
   return ""
}


function getItemValue({item}: DelegateItem) {
   return item.emailAddress;
}
