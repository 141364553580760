import classNames from 'classnames';
import { isMobile } from 'is-mobile';
import moment from 'moment';
import { useRef } from 'react';
import ReactDatePicker from 'react-datepicker';
import css from './DatePicker.module.scss';
import { NativeDatePicker } from './mobile/NativePickers';
import Popover, { usePopover } from './Popover';

type Props = {
   className?: string
   minDate?: number
   disabled?: boolean
} & ({
   allowEmpty?: false
   value: number
   onChange(value: number): void
} | {
   allowEmpty: true
   value: number | null
   onChange(value: number | null): void
   emptyStr?: string
})

export default function DatePicker({ className, value, onChange, minDate, disabled, ...props }: Props) {
   const popupRef = useRef<HTMLDivElement>(null)
   const inputRef = useRef<HTMLInputElement>(null)
   const [isOpen, setOpen] = usePopover(inputRef, popupRef)

   function onDatePickerChange(d: moment.Moment | null) {
      if (!d) { return }
      setOpen(false)
      onChange(d.valueOf())
   }

   function open() {
      if (disabled) return
      setOpen(true)
   }

   function onClear() {
      onChange(null!)
      setOpen(false)
   }

   const emptyStr = props.allowEmpty ? props.emptyStr : undefined
   const formatted = value === null ? emptyStr : moment(value).format('LL')

   if (isMobile({ tablet: true })) {
      return <NativeDatePicker className={classNames(css.nativePicker, className)} value={value} onChange={v => onChange(v ?? minDate ?? (props.allowEmpty ? null! : moment().startOf('d').valueOf()))} minValue={minDate}>
         <div className={classNames(css.input)}>{formatted}</div>
      </NativeDatePicker>
   }

   return <>
      <input ref={inputRef} className={classNames(css.input, className)} readOnly value={formatted} onClick={open} onFocus={open} disabled={disabled} />
      {isOpen && <Popover ref={popupRef} referenceElement={inputRef.current}>
         <ReactDatePicker inline selected={value === null ? null : moment(value)} dateFormat={value !== null ? undefined : `'${(emptyStr ?? '').replace("'", "''")}'`}
            onChange={onDatePickerChange} minDate={moment(minDate)} disabled={disabled} />
         {props.allowEmpty && <button className={css.clear} onClick={onClear} disabled={disabled}>Clear</button>}
      </Popover>}
   </>
}
