import { all, put } from 'redux-saga/effects';
import * as Api from '../api';
import { actions, StoreState } from '../store/utils';
import { processEvery, takeEvery } from '../utils/apiActions';
import { flatten } from '../utils/misc';

function getContactsFromMeetings(data: Api.MeetingData[]) {
   const emails = [];
   for (const m of data) {
      emails.push(...m.participants.map(c => c.emailAddress));
      if (m.organiser) {
         emails.push(m.organiser.emailAddress)
      }
   }
   return emails
}

function* fetchContacts(state: StoreState, emails: string[]) {
   emails = [ ...new Set(emails) ];
   emails = emails.filter(email => state.contacts[email] === undefined && !contactsBeingFetched.has(email));

   if (emails.length) {
      for (const email of emails) {
         contactsBeingFetched.add(email);
      }
      yield put(actions.fetchContacts.request(emails));
   }

}

const contactsBeingFetched = new Set<string>()

const onFetchCalendarsResponse = takeEvery(actions.fetchCalendarsResponse, function* (state, data) {
   const emails = getContactsFromMeetings(flatten(Object.values(data.schedules)))
   yield fetchContacts(state, emails)
})

const onContactsFeched = takeEvery(actions.fetchContacts.response, function* (state, contacts): IterableIterator<any> {
   for (const c of contacts) {
      contactsBeingFetched.delete(c.emailAddress);
   }
})

export default all([
   onFetchCalendarsResponse,
   onContactsFeched,
   processEvery(actions.fetchContacts, Api.fetchContacts),
])
