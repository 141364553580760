import '@fortawesome/fontawesome-free/css/all.css';
import 'bootstrap/dist/css/bootstrap.min.css';
import { ConnectedRouter } from 'connected-react-router';
import { createBrowserHistory } from 'history';
import { isMobile } from 'is-mobile';
import 'moment';
import 'moment-immutable';
import 'moment/locale/en-nz';
import { parse } from 'query-string';
import React from 'react';
import 'react-datepicker/dist/react-datepicker.min.css';
import ReactDOM from 'react-dom';
import { Provider } from 'react-redux';
import { matchPath, Route, Switch } from 'react-router-dom';
import App from './App';
import { isAzureAdCallback } from './azure-ad';
import MeetingLink from './components/MeetingLink';
import FastTrack from './components/mobile/FastTrack';
import Visit from './components/mobile/Visit';
import { PopperProvider } from './components/Popper';
import WebexLoginCallback, { isWebExCallback } from './components/WebexLoginCallback';
import TeamsConfig from './containers/TeamsConfig';
import TeamsLoginPopup from './containers/TeamsLoginPopup';
import { AfterLoginAction, RoomType } from './model';
import { configureStore } from './store/configureStore';
import { actions } from './store/utils';
import { isSafari } from './utils/misc';
import { RemProvider } from './utils/remUtil';
import bridge from './javascriptBridge';

let appUnloaded = false
let teamsMode = !!window.sessionStorage.getItem('teams')
let afterLoginAction: AfterLoginAction = null

const baseUrl = document.getElementsByTagName('base')[0].getAttribute('href')!;
const history = createBrowserHistory({
   basename: baseUrl
});

(window as any).oldLocation = window.location.hash
if (window.location.hash && !isAzureAdCallback() && !isWebExCallback()) {
   const query = parse(window.location.hash);

   if (query.teams !== undefined) {
      teamsMode = true;
      window.sessionStorage.setItem('teams', 'true')
   }

   if (query.new !== undefined) {
      afterLoginAction = { type: 'new' }
   }

   if (typeof query.nearby === 'string') {
      const nearby: { email?: string, type?: RoomType, start?: number } = JSON.parse(query.nearby)

      if (nearby?.type && nearby.email) {
         afterLoginAction = {
            type: 'nearby',
            email: nearby.email,
            roomType: nearby.type,
            start: nearby.start,
         }
      }
   }

   if (typeof query.addResources === 'string') {
      afterLoginAction = {
         type: 'addResources',
         meetingId: query.addResources,
      }
   }

   // remove the hash
   const { pathname, search } = window.location;
   history.replace(pathname + search);
}


const store = configureStore({ teams: { teamsMode }, mobile: isMobile(), afterLoginAction }, history, !noSagas());
function logError(message: string, e: any) {
   bridge.log(JSON.stringify({
      time: Date.now(),
      message,
      error: e,
   }))
}
window.addEventListener('error', e => {
   console.error('Unhandled error', e.error)
   logError('Unhandled error', e.error)
   reloadOnFatalError()
}, false)

window.addEventListener('unhandledrejection', e => {
   console.error('Unhandled promise rejection', e.promise, e.reason)
   logError('Unhandled promise rejection', e.reason)
   reloadOnFatalError()
}, false)

window.addEventListener('beforeunload', () => {
   console.log("beforeunload event recvd")
   appUnloaded = true
})
window.addEventListener('pageshow', () => {
   if (!appUnloaded) { return }
   appUnloaded = false
   reloadOnFatalError()
})

export function isAppUnloaded() { return appUnloaded }

if (isSafari()) {
   const link = document.createElement('link')
   link.setAttribute('rel', 'stylesheet')
   link.setAttribute('href', `${process.env.PUBLIC_URL}safari.css`)

   document.head.prepend(link)

   if (isMobile()) {
      const viewport = document.querySelector('meta[name=viewport]')
      if (viewport) {
         viewport.setAttribute('content', (viewport.getAttribute('content') || '') + ', maximum-scale=1')
      }
   }
}

ReactDOM.render(
   applyProviders(
      <Provider store={store} children={<div />} />,
      <ConnectedRouter history={history} />,
      <RemProvider />,
      <PopperProvider />,

      <Switch>
         <Route path='/webex-login-callback' component={WebexLoginCallback} />
         <Route path='/teams-auth-popup' component={TeamsLoginPopup} />
         <Route path='/teams-auth-popup-callback' render={() => <TeamsLoginPopup callback />} />
         <Route path='/teams' component={TeamsConfig} />
         <Route path='/visit/:id?' component={Visit} />
         <Route path='/meeting/link/:id' component={MeetingLink} />
         <Route path='/fast-track' component={FastTrack} />
         <Route path='/' component={App} />
      </Switch>
   ),
   document.getElementById('root'));

function applyProviders(...providers: React.ReactElement[]) {
   return providers.reduceRight((child, parent) => React.cloneElement(parent, { children: child }));
}

export function reloadOnFatalError() {
   console.warn('reloadOnFatalError: ', appUnloaded)
   if (appUnloaded) {
      console.warn('not reloading since app is being unloaded')
      return
   }
   if (process.env.NODE_ENV === 'development') {
      alert('fatal error')
   } else {
      window.location.reload()
      store.dispatch(actions.reconnecting())
   }
}

function isPopupWindow() {
   return matchAnyUrl([
      '/webex-login-callback',
      '/teams-auth-popup',
      '/teams-auth-popup-callback',
      '/teams',
   ]) !== null
}

function noSagas() {
   return matchAnyUrl([
      '/visit',
      '/fast-track',
   ]) !== null || isPopupWindow()
}

export function matchAnyUrl<T = {}>(urls: string[]) {
   return matchPath<T>(window.location.pathname, urls.map(url => history.createHref({ pathname: url })))
}
