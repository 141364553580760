import { getType } from 'typesafe-actions';
import { actions, Actions } from '../utils';

interface Config {
   canBookAdhoc: boolean
   maxEarlyStartMinutes: number
   deviceBackgroundUrl: string | null
   allowSecondaryLoginAutoComplete: boolean
   allowAttendeeEditing: boolean
   allowExternalAttendeeEditing: boolean
}

const defaultConfig: Config = {
   canBookAdhoc: false,
   maxEarlyStartMinutes: 15,
   deviceBackgroundUrl: null,
   allowSecondaryLoginAutoComplete: false,
   allowAttendeeEditing: false,
   allowExternalAttendeeEditing: false,
}

export default function config(state: Config = defaultConfig, action: Actions): Config {
   switch (action.type) {
      case getType(actions.whoAmI.response):
      case getType(actions.login.response):
         if (action.payload) {
            const { canBookAdhoc, maxEarlyStartMinutes, deviceBackgroundUrl, allowSecondaryLoginAutoComplete, allowAttendeeEditing, allowExternalAttendeeEditing } = action.payload.staticData
            return {
               canBookAdhoc,
               maxEarlyStartMinutes,
               deviceBackgroundUrl,
               allowSecondaryLoginAutoComplete,
               allowAttendeeEditing,
               allowExternalAttendeeEditing
            }
         }
   }

   return state
}
