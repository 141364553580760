import classNames from 'classnames';
import React from 'react';
import BottomSheet from './BottomSheet';
import { usePageNav } from './navigation';
import css from './Selectors.module.scss';

type SelectorProps = {
   className?: string
   popupChildren?: React.ReactNode
   setOpen: (open: boolean) => void
   open: boolean
}

function Selector({ className, popupChildren, setOpen, open, children }: React.PropsWithChildren<SelectorProps>) {
   return <>
      <button className={classNames(css.selector, className)} onClick={() => setOpen(true)}>{children}</button>
      <BottomSheet open={open} onClose={() => setOpen(false)}>
         {popupChildren}
      </BottomSheet>
   </>
}

type MultiSelectProps<T> = {
   label?: React.ReactNode
   items: readonly T[]
   values: readonly T[]
   onChange: (items: T[]) => void
   renderItem: (item: T) => string
   renderSelected?: (items: readonly T[]) => React.ReactNode
}

export function MultiSelect<T>({ label, items, values, onChange, renderItem, renderSelected }: MultiSelectProps<T>) {
   const { isOpen, open, close } = usePageNav()

   function toggle(item: T) {
      if (values.includes(item)) {
         onChange(values.filter(x => x !== item))
      } else {
         onChange([...values, item])
      }
   }

   function mapItem(item: T) {
      const checked = values.includes(item)
      return <button key={JSON.stringify(item)} className={css.multiSelectItem} onClick={() => toggle(item)}>
         {renderItem(item)}
         {checked && <svg className={css.check} height="24" viewBox="0 0 24 24" width="24"><path d="M0 0h24v24H0V0z" fill="none" /><path d="M19 3H5c-1.1 0-2 .9-2 2v14c0 1.1.9 2 2 2h14c1.1 0 2-.9 2-2V5c0-1.1-.9-2-2-2zm0 16H5V5h14v14zM17.99 9l-1.41-1.42-6.59 6.59-2.58-2.57-1.42 1.41 4 3.99z" /></svg>}
         {!checked && <svg className={css.check} height="24" viewBox="0 0 24 24" width="24"><path d="M0 0h24v24H0V0z" fill="none" /><path d="M19 5v14H5V5h14m0-2H5c-1.1 0-2 .9-2 2v14c0 1.1.9 2 2 2h14c1.1 0 2-.9 2-2V5c0-1.1-.9-2-2-2z" /></svg>}
      </button>
   }

   function onRenderSelected() {
      if (renderSelected) {
         return renderSelected(values)
      }

      return values.length === 0 ? 'None' : values.map(renderItem).join(', ')
   }

   return <Selector className={css.multiSelect} popupChildren={items.map(mapItem)} open={isOpen} setOpen={x => x ? open() : close()}>
      {label !== undefined && <div className={css.label}>{label}</div>}
      <div className={css.items}>{onRenderSelected()}</div>
   </Selector>
}

type SingleSelectProps<T extends number | string> = {
   label?: React.ReactNode
   items: readonly T[]
   value: T
   onChange: (items: T) => void
   renderItem: (item: T) => React.ReactNode
}

export function SingleSelect<T extends number | string>({ label, items, value, onChange, renderItem }: SingleSelectProps<T>) {
   const { isOpen, open, close } = usePageNav()

   function mapItem(item: T) {
      return <button key={item} className={css.singleSelectItem} onClick={(e) => { close(); onChange(item); e.preventDefault() }}>
         {renderItem(item)}
         {value === item && <svg className={css.radio} height="24" viewBox="0 0 24 24" width="24"><path d="M0 0h24v24H0V0z" fill="none" /><path d="M12 2C6.48 2 2 6.48 2 12s4.48 10 10 10 10-4.48 10-10S17.52 2 12 2zm0 18c-4.42 0-8-3.58-8-8s3.58-8 8-8 8 3.58 8 8-3.58 8-8 8z" /><circle cx="12" cy="12" r="5" /></svg>}
         {value !== item && <svg className={css.radio} height="24" viewBox="0 0 24 24" width="24"><path d="M0 0h24v24H0V0z" fill="none" /><path d="M12 2C6.48 2 2 6.48 2 12s4.48 10 10 10 10-4.48 10-10S17.52 2 12 2zm0 18c-4.42 0-8-3.58-8-8s3.58-8 8-8 8 3.58 8 8-3.58 8-8 8z" /></svg>}
      </button>
   }

   return <Selector className={css.singleSelect} popupChildren={items.map(mapItem)} open={isOpen} setOpen={x => x ? open() : close()}>
      {label !== undefined && <div className={css.label}>{label}</div>}
      <div className={css.items}>{renderItem(value)}</div>
   </Selector>
}
