import moment from 'moment';
import { all, call, delay, fork, put } from 'redux-saga/effects';
import * as Api from '../api';
import { shouldLoadSchedule } from '../store/reducers/schedules';
import { actions } from '../store/utils';
import { invokeApiRetry, takeEvery } from '../utils/apiActions';
import { ApiResult } from './api';

const onFetchSchedule = takeEvery(actions.fetchScheduleRequest, function* (state, { force, day, email }) {
   day = moment(day).startOf('d').valueOf()

   if (!force && !shouldLoadSchedule(state, email, day)) {
      return;
   }

   yield put(actions.fetchScheduleRequested(day, email))
   yield* enqueueFetchSchedule(day, email)
})


interface ScheduleBatch {
   days: number[]
   emails: string[]
}

const fetchScheduleDelay = 10
let fetchScheduleBatch: ScheduleBatch | null = null
let fetchScheduleBatchInFlight: ScheduleBatch | null = null

function* enqueueFetchSchedule(day: number, email: string) {
   const date = moment(day).startOf('d').valueOf()

   if (fetchScheduleBatch === null) {
      fetchScheduleBatch = { days: [ date ], emails: [ email ] }
      yield fork(batch)
   } else {
      if (!fetchScheduleBatch.days.includes(date)) {
         fetchScheduleBatch.days.push(date)
      }

      if (!fetchScheduleBatch.emails.includes(email)) {
         fetchScheduleBatch.emails.push(email)
      }
   }

   function* batch() {
      yield delay(fetchScheduleDelay)

      fetchScheduleBatchInFlight = fetchScheduleBatch
      fetchScheduleBatch = null

      if (fetchScheduleBatchInFlight === null) {
         throw new Error('Something went horribly worng in enqueueFetchSchedule')
      }

      const { days, emails } = fetchScheduleBatchInFlight

      yield invokeApiRetry(function* () {
         const data: ApiResult<typeof Api.fetchSchedules> = yield call(Api.fetchSchedules, days, emails)
         yield put(actions.fetchSchedulesResponse(data))
      })
   }
}

export default all([
   onFetchSchedule,
])
