import { Extend, newGuid } from "./utils/misc";

export type MeetingType = 'SkypeForBusiness' | 'Teams'| 'Jitsi' | 'Zoom' | 'WebEx' | 'GoogleMeet'

export type LocationFilter = readonly (string | null)[]

export interface BookingResourcesFilter {
   type: RoomType
   quantity: number
   location: LocationFilter
   sizeIndex: number
   attributes: number[]
   extraBefore: number
   extraAfter: number
   pinned: string[]
}

export interface RoundtripBookingResourcesFilter extends BookingResourcesFilter {
   selected: string[]
}

export function createBookingResourceFilter(): BookingResourcesFilter {
   return {
      type: 'MeetingRoom',
      quantity: 1,
      location: [],
      sizeIndex: -1,
      attributes: [],
      extraBefore: -15,
      extraAfter: 15,
      pinned: [],
   }
}

export interface Meeting {
   id: string
   startTime: number
   endTime: number
   subject: string
   organiser?: Contact
   participants: Contact[]
   types: MeetingType[]
   isPrivate: boolean
   isAdhoc: boolean
   adhocOrganisationId: string | null
   isExternal: boolean
   isRecurring: boolean
   permissions: PermissionSet | null
   pending: boolean
   showAs: FreeBusyStatus
   allDay: boolean
}

export interface MeetingExtras {
   started: number | null
   ended: number | null
}

interface ContactBase {
   name: string
   emailAddress: string
   organisation: string | null
}

export const PersonType = 'Person'
export interface Person extends ContactBase {
   type: typeof PersonType
   jobTitle: string | null
   department: string | null
   isGuest: boolean
}

export const RoomType = 'Room'
export interface Room extends ContactBase {
   type: typeof RoomType
   seats: number
   attributes: number[]
   location: string
   hasFloorPlan: boolean
   roomType: RoomType
   floor: string | null
}

export const AllRoomTypes = ['MeetingRoom', 'HotDesk', 'Locker', 'ParkingSpace'] as const
export type RoomType = typeof AllRoomTypes[number]

export function getRoomTypeName(type: RoomType, quantity = 1) {
   switch (type) {
      case 'MeetingRoom': return quantity === 1 ? 'Room' : 'Rooms'
      case 'HotDesk': return quantity === 1 ? 'Hot Desk' : 'Hot Desks'
      case 'Locker': return quantity === 1 ? 'Locker' : 'Lockers'
      case 'ParkingSpace': return quantity === 1 ? 'Carpark' : 'Carparks'
   }

   const _exhaustive: never = type
}

export type Contact = Person | Room

export function isRoom(p?: Contact): p is Room {
   return p ? p.type === RoomType : false
}

export function isPerson(p?: Contact): p is Person {
   return p ? p.type === PersonType : false
}

export interface BookingTime {
   start: number
   duration: number
}

export interface RoomAttribute {
   id: number
   name: string
   url: string
}

export type PopupTypeNoParams = 'secondaryLogin' | 'myCalendar' | 'newBooking' | 'autoLogout'

export type PopupParams = {
   type: PopupTypeNoParams
} | {
   type: 'cancelMeeting' | 'startMeeting' | 'meetingInformation' | 'addResource'
   meetingId: string
} | {
   type: 'progress'
   message: string
   canCancel?: boolean
   noDelay?: boolean
} | {
   type: 'editMeeting'
   organiser: string | undefined
}

export interface Team {
   id: string
   displayName: string
   description: string
   members: readonly {
      displayName: string
      email: string
   }[]
}

export type TeamWithMembers = Extend<Team, {
   members: Contact[]
}>

export interface TeamToJoin {
   teamId: string
   teamName: string
}

export type HomeType = 'RoomList' | 'Timeline' | 'DisplayBoard'

export type FreeBusyStatus = 'Unknown' | 'Free' | 'Tentative' | 'Busy' | 'Oof' | 'WorkingElsewhere'

export type Suggestion = {
   start: number
   participantsAvailable: boolean
   selected: string[][]
   available: string[][]
   incomplete: boolean[]
   resourcesAvailable: boolean
}

export enum Page {
   bookingFilter,
   calendar,
   timeline,
   roomList,
   searchMore,
}

export type Domain = {
   domain: string
} & (
   {
      loginType: LoginType.azure,
      clientId: string
      tenantId: string
   }
   |
   {
      loginType: LoginType.bookIt | LoginType.google | LoginType.none | LoginType.email
   }
)

export enum LoginType {
   bookIt,
   azure,
   google,
   none,
   email
}

export type Domains = Record<string, Domain>

export interface AgendaItem {
   uniqueId: string
   subject: string
   isRunning: boolean
   isCompleted: boolean
   scheduledTime: number // duration
   elapsedTime: number // duration
   webLink: string
   attachmentFilename: string | null
}

export interface Agenda {
   notes: string
   items: AgendaItem[]
}

export type Attachment = File | {
   id: string
   name: string
   type: string
   size: number
}

export interface SchedulesDataItem {
   periods: Period[]
   workingHours: Period[]
}

export interface Period {
   start: number
   end: number
}

export type AttendeeData = {
   emailAddress: string
   type: AttendeeType
   responseStatus?: ResponseStatus
}

export enum AttendeeType {
   required,
   optional,
}

export type ResponseStatus = "Unknown" | "NoResponse" | "NoResponseRequired" | "ResponseRequired" | "Accepted" | "Rejected" | "Tentative" | "Organiser" | "None"

export type Attendee = {
   contact: Contact
   type: AttendeeType
   responseStatus?: ResponseStatus
}

export function getGuest(emailAddress: string, name?: string): Person {
   return {
      type: 'Person',
      emailAddress,
      name: name ?? emailAddress,
      jobTitle: null,
      department: null,
      organisation: null,
      isGuest: true
   }
}

type RecurrenceRange = {
   startDate: string
} & ({
   type: 'endDate'
   endDate: string
} | {
   type: 'numbered'
   numberOfOccurrences: number
} | {
   type: 'noEnd'
})

export type RecurrenceDayOfWeek = 'sunday' | 'monday' | 'tuesday' | 'wednesday' | 'thursday' | 'friday' | 'saturday'
export const AllRecurrenceDaysOfWeek: readonly RecurrenceDayOfWeek[] = ['sunday', 'monday', 'tuesday', 'wednesday', 'thursday', 'friday', 'saturday']

type RecurrencePattern = {
   type: 'daily'
   interval: number
} | {
   type: 'weekly'
   interval: number
   daysOfWeek: RecurrenceDayOfWeek[]
   firstDayOfWeek?: RecurrenceDayOfWeek
} | {
   type: 'absoluteMonthly'
   interval: number
   dayOfMonth: number
} | {
   type: 'relativeMonthly'
   interval: number
   daysOfWeek: RecurrenceDayOfWeek[]
} | {
   type: 'absoluteYearly'
   interval: number
   dayOfMonth: number
   month: number
} | {
   type: 'relativeYearly'
   interval: number
   daysOfWeek: RecurrenceDayOfWeek[]
   month: number
}

export type Recurrence = {
   pattern: RecurrencePattern
   range: RecurrenceRange
}

export type AfterLoginAction =
   { type: 'nearby', start?: number } & Nearby |
   { type: 'new' } |
   { type: 'addResources', meetingId: string } |
   null

export type ResourceAvailabilityBlock = {
   start: number
   end: number
   order: number
   name: string
}

export interface Nearby {
   email: string
   roomType: RoomType
}

export type PermissionSet = {
   permissionSetId: string
   name: string
   permissions: UserPermission[]
}

export type UserPermission = {
   user: UserType
   properties: PropertyPermission[]
}

export type PropertyPermission = {
   property: PropertyType,
   access: AccessType
}

export type AccessType = 'Update'

export type UserType =
   { type: 'All' } |
   { type: 'Organisation' } |
   { type: 'External' } |
   { type: 'Specific', attendees: string[] }


   export type PropertyType = 'ConferenceType' | 'Date' | 'Time' | 'AllDay' | 'Recurrence' |
                     'Private'| 'Title' | 'Attendees' | 'IncludeTeamInVideoFX' | 
                     'Resources' | 'Agenda' | 'All'


export function createDefaultPermissionSet(): PermissionSet {
   return {
      permissionSetId: newGuid(),
      name: "None",
      permissions: []
   }
}
