import moment from 'moment';
import FontAwesome from 'react-fontawesome';
import { useDispatch } from 'react-redux';
import { Prompt } from 'react-router';
import { AttendeeType, TeamWithMembers } from '../../model';
import { getSecondaryUsername, isExternalUser } from '../../store/helpers';
import { mustCreateAppointment } from '../../store/reducers/booking';
import { actions, useSelector } from '../../store/utils';
import { AttendeesIcon, EditIcon, OnlineMeetingTypeIcon } from '../icons';
import { RecurrencePattern, useRecurrence } from '../RecurrenceEditor';
import Suggestions from '../Suggestions';
import { AttendeeSelector } from './Attendees';
import DateTimeFields, { SimpleDateTimeFields } from './DateTimeFields';
import DescriptionEditor from './DescriptionEditor';
import FormField from './FormField';
import { Header } from './Header';
import css from './Meeting.module.scss';
import { MeetingTypeSingleSelector, MeetingTypeToggleSelector } from './MeetingTypeSelector';
import { NativeDatePicker } from './NativePickers';
import NearbyFilterEditor from './NearbyFilterEditor';
import NearbySearch from './NearbySearch';
import Page from './Page';
import ResourceFilterEditor from './ResourceFilterEditor';
import { SingleSelect } from './Selectors';
import TeamSelector from './TeamSelector';
import TextInput from './TextInput';
import { ToggleWithLabel } from './Toggle';
import VideoFxTeams from './VideoFxTeams';
//import QRCode from './QRCode';

export default function Meeting() {
   const dispatch = useDispatch()
   const booking = useSelector(s => s.booking)
   const myself = useSelector(getSecondaryUsername)
   const popupOpen = useSelector(s => s.popup.type !== undefined)
   const externalUser = useSelector(isExternalUser)
   const meetingOrganiser = useSelector(s => s.booking?.meetingId && s.meetings[s.booking.meetingId]?.organiser)
   const allowAttendeeEditing = useSelector(s => s.config.allowAttendeeEditing 
      && s.login.data && (s.login.data.myself.toLowerCase() === meetingOrganiser?.toLowerCase() || s.booking?.meetingId === undefined)
      && booking)

   function onDelete() {
      if (!booking || !booking.meetingId || !myself) { return }
      dispatch(actions.openPopupWithParams({ type: 'progress', message: 'Cancelling meeting', noDelay: true }, true))
      dispatch(actions.cancelMeeting(booking.meetingId, { userName: myself, password: '' }))
   }

   function onAddTeam(team: TeamWithMembers) {
      for (const member of team.members) {
         dispatch(actions.addParticipant(member.emailAddress, AttendeeType.required))
      }
   }

   if (!booking) { return null }

   const createMeeting = () => {
      dispatch(actions.createMeeting())
   }

   function getTitle() {
      if (!booking) { return null }

      if (booking.meetingId) {
         return 'Edit meeting'
      }

      if (booking.full) {
         return 'New meeting'
      }

      if (booking.nearby) {
         return 'Book nearby'
      }

      return 'Meet now'
   }

   return <Page>
      <Prompt message={(location, _action) => popupOpen ? false : booking && location.pathname !== '/book/meeting' ? 'Discard changes?' : true} />
      <Header close={true} save={booking.suggested ? createMeeting : undefined}>
         <span>{getTitle()}</span>
      </Header>
      <Page.Body>
         {!mustCreateAppointment(booking) && booking.full && !booking.nearby && <FormField icon={<span aria-hidden className='far fa-calendar' />}>
            <ToggleWithLabel label='Create calendar appointment' value={booking.createAppointment} onChange={v => dispatch(actions.setCreateAppointment(v))} />
         </FormField>}
         {!booking.nearby && booking.createAppointment && <FormField icon={<EditIcon />}>
            <TextInput placeholder='Title' value={booking.subject} onChange={e => dispatch(actions.setBookingSubject(e.currentTarget.value))} />
         </FormField>}
         {!booking.full && <>
            {!booking.nearby && booking.createAppointment && <FormField icon={<OnlineMeetingTypeIcon />}>
               <MeetingTypeToggleSelector />
            </FormField>}
            <SimpleDateTimeFields />
         </>}
         {booking.full && <>
            {booking.createAppointment && <FormField icon={<OnlineMeetingTypeIcon />}>
               <MeetingTypeSingleSelector />
            </FormField>}
            {!booking.nearby && <>
               <FormField icon={<AttendeesIcon />}>
                  <AttendeeSelector />
               </FormField>
               <TeamSelector title='Add a team' onAdd={onAddTeam}>
                  + Add a team...
               </TeamSelector>
            </>}
            <DateTimeFields />
         </>}
         {booking.nearby ? <NearbyFilterEditor /> : <ResourceFilterEditor />}
         <NearbySearch />
         {!booking.allDay && booking.full && !booking.nearby &&<FormField>
            <div className={css.suggestionsContainer}>
               <span>Suggested times:</span>
               <Suggestions className={css.suggestions} />
            </div>
         </FormField>}
         {booking.full && booking.createAppointment && <>
            <FormField icon={<svg viewBox='0 0 24 24'><path d='M14 17H4v2h10v-2zm6-8H4v2h16V9zM4 15h16v-2H4v2zM4 5v2h16V5H4z' /></svg>}>
               <DescriptionEditor />
            </FormField>
            <FormField icon={<FontAwesome name='lock' />}>
               <ToggleWithLabel label='Private' value={booking.isPrivate} onChange={p => dispatch(actions.setMeetingPrivate(p))} />
            </FormField>
            {allowAttendeeEditing &&  <FormField icon={<FontAwesome name='user-edit' />}>
               {/* <ToggleWithLabel label='Allow attendee editing' value={booking.editScope === EditScope.All} onChange={p => dispatch(actions.setEditScope(p ? EditScope.All : EditScope.None))} /> */}
            </FormField>}
            <RecurrenceEditor />
            {!externalUser && <VideoFxTeams />}
         </>}
         {!booking.full && <>
            <FormField>
               <button onClick={e => dispatch(actions.setMeetingFull(true))} className={css.moreButton}>More options</button>
            </FormField>
         </>}

         {/* {!booking.full && <>
            <FormField>
               <QRCode />
            </FormField>
         </>} */}

      </Page.Body>
      {!externalUser && booking.meetingId && <button className={css.delete} onClick={onDelete}>
         Delete event
      </button>}
   </Page>
}

function RecurrenceEditor() {
   const { pattern, patterns, endDate, setEndDate, setPattern } = useRecurrence()

   function displayPattern(pattern: RecurrencePattern) {
      return pattern === RecurrencePattern.none ? 'Never' : pattern
   }

   return <>
      <FormField icon={<FontAwesome name='sync-alt' />}>
         <SingleSelect label='Repeat'
            items={patterns}
            value={pattern}
            onChange={pattern => setPattern(pattern)}
            renderItem={displayPattern} />
      </FormField>
      {pattern !== RecurrencePattern.none && <>
         <FormField icon={null} combine>
            <div>
               <NativeDatePicker className={css.recurrenceUntil} value={endDate} onChange={setEndDate}>
                  <div>Until</div>
                  {endDate !== null ? <div>{moment(endDate).format('LL')}</div> : <div>forever</div>}
               </NativeDatePicker>
            </div>
         </FormField>
      </>}
   </>
}

