export function createPromiseSource<T = void>(): PromiseSource<T> {
   let resolve: PromiseSource<T>['resolve']
   let reject: PromiseSource<T>['reject']

   const promise = new Promise<T>((res, rej) => {
      resolve = res
      reject = rej
   })

   return { promise, resolve: resolve!, reject: reject! }
}

export type PromiseSource<T> = {
   readonly promise: Promise<T>
   readonly resolve: (value: T | PromiseLike<T>) => void
   readonly reject: (reason: any) => void
}
