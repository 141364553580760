import React from 'react';
import FontAwesome from 'react-fontawesome';
import { useHistory } from 'react-router-dom';
import css from './Header.module.scss';

type HeaderProps = {
   close?: boolean | (() => void)
   back?: boolean | (() => void)
   save?: () => void
   next?: () => void
}

export function Header({ back, close, save, next, children }: React.PropsWithChildren<HeaderProps>) {
   const { goBack } = useHistory()

   function onBack(act: boolean | (() => void)) {
      if (typeof act === 'function') {
         act()
      } else {
         goBack()
      }
   }

   return <div className={css.header}>
      {back && <button type='button' onClick={() => onBack(back)}><FontAwesome name='chevron-left' /></button>}
      {close && <button type='button' onClick={() => onBack(close)}><FontAwesome name='times' /></button>}
      <div className={css.content}>
         {children}
      </div>
      {save && <button type='button' onClick={save}><FontAwesome name='check' /></button>}
      {next && <button type='button' onClick={next}><FontAwesome name='chevron-right' /></button>}
   </div>
}
