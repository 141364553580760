import { connect } from "react-redux";
import { Dispatch } from "redux";
import BookingCursor from "../components/BookingCursor";
import { selectBookingTime } from "../store/helpers";
import { actions, ReduxPropsType, StoreState } from "../store/utils";

function mapStateToProps(state: StoreState) {
   return {
      booking: selectBookingTime(state),
   }
}

function mapDispatchToProps(dispatch: Dispatch) {
   return {
      setStart(hr: number) {
         dispatch(actions.setBookingStart(hr));
      },
      setDuration(hr: number) {
         dispatch(actions.setBookingDuration(hr));
      },
   }
}

const withRedux = connect(mapStateToProps, mapDispatchToProps);
export type BookingCursorProps = ReduxPropsType<typeof withRedux>;
export default withRedux(BookingCursor);
