import { bind } from 'bind-decorator';
import debounce from 'debounce-promise';
import React from 'react';
import { connect } from 'react-redux';
import { Route, RouteComponentProps, Switch } from 'react-router-dom';
import { Dispatch } from 'redux';
import css from './App.module.scss';
import AddResourcePopup from './components/AddResourcePopup';
import AutoLogoutPopup from './components/AutoLogoutPopup';
import BookingFilter from './components/BookingFilter';
import BookingProgressPopup from './components/BookingProgressPopup';
import DisplayBoard from './components/display-board';
import Footer from './components/Footer';
import Login from './components/Login';
import LoginPopup from './components/LoginPopup';
import AddResources from './components/mobile/AddResources';
import BookingSuccess from './components/mobile/BookingSuccess';
import LoginMobile from './components/mobile/Login';
import Meeting from './components/mobile/Meeting';
import MyCalendarMobile from './components/mobile/MyCalendarMobile';
import MyCalendar from './components/MyCalendar';
import MeetingLink from './components/MeetingLink';
import { PopperHost } from './components/Popper';
import ReconnectPopup from './components/ReconnectPopup';
import Spinner from './components/Spinner';
import Splash from './components/Splash';
import StartConfirmationPopup from './components/StartConfirmationPopup';
import { ToastHost } from './components/Toast';
import Version from './components/Version';
import Booking from './containers/Booking';
import Header from './containers/Header';
import LocationPicker from './containers/LocationPicker';
import TeamsLogin from './containers/TeamsLogin';
import Timeline from './containers/Timeline';
import { actions, ReduxPropsType, StoreState } from './store/utils';
import { matchAnyUrl } from '.';

class App extends React.PureComponent<RouteComponentProps<void> & AppProps> {
   public render() {
      return <>
         {this.renderApp()}
         <Version />
         <ReconnectPopup />
      </>
   }

   private renderApp() {
      const { login, teamsMode, hasLocation, onLastActivity, appInited, mobile, popup } = this.props;

      if (!login.initialized) {
         return <Spinner className={css.spinner} />
      }

      if (!login.data) {
         if (teamsMode) {
            return <div className={css.app}>
               <TeamsLogin />
            </div>;
         } else if (mobile) {
            return <LoginMobile />;
         } else {
            return <Login />;
         }
      }

      const noRooms = !login.data.organisation || login.data.organisation.rooms.length === 0

      if (!hasLocation && !login.data.isExternal && !noRooms) {
         return <LocationPicker />;
      }

      if (!appInited) {
         return <Spinner className={css.spinner} />
      }

      const device = login.data.device
      if (device?.home === 'DisplayBoard') {
         return <DisplayBoard />
      }
      
      return (
         <div className={css.app} onMouseMove={onLastActivity} onMouseDown={onLastActivity} onKeyDown={onLastActivity} onScroll={onLastActivity}>
            <Route exact path='/' component={Header} />
            <main>
               <Switch>
                  <Route exact path='/' component={Timeline} />
                  <Route path='/mobile/calendar' component={MyCalendarMobile} />
                  <Route path='/calendar' component={MyCalendar} />
                  <Route path='/rooms' render={this.renderBooking} />
                  <Route path='/book/filter' component={BookingFilter} />
                  <Route path='/book/calendar' render={this.renderBooking} />
                  <Route path='/book/meeting' component={Meeting} />
                  <Route path='/meeting/link/:id' component={MeetingLink} />
                  <Route path='/meeting/:id/add-resources' component={AddResources} />
                  <Route path='/success' component={BookingSuccess} />
               </Switch>
               <AutoLogoutPopup />
               {popup.type && <LoginPopup />}
               <BookingProgressPopup />
               <AddResourcePopup />
            </main>
            <Route exact path='/' component={Footer} />
            <ToastHost />
            <PopperHost className={css.popperHost} />
            <Splash />
            <StartConfirmationPopup />
         </div>
      );
   }

   public componentDidMount() {
      this.props.onInit();
   }

   @bind
   private renderBooking({ match }: RouteComponentProps<any>) {
      return <Booking roomListMode={match.path === '/rooms'} />
   }
}

function mapStateToProps({ login, booking, teams: { teamsMode }, location, localRooms, mobile, appInited, popup }: StoreState) {
   return { login, booking, teamsMode, mobile, hasLocation: location !== null || localRooms.length > 0, appInited, popup };
}

function mapDispatchToProps(dispatch: Dispatch) {
   return {
      onInit() { dispatch(actions.initApp()) },
      onLastActivity: debounce(() => dispatch(actions.lastActivity()), 1000, { leading: true }),
   };
}

const withRedux = connect(mapStateToProps, mapDispatchToProps);
type AppProps = ReduxPropsType<typeof withRedux>
export default withRedux(App);
