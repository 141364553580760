import React from 'react';
import { useDispatch } from 'react-redux';
import { actions, useSelector } from '../store/utils';
import useInterval from '../utils/useInterval';
import css from './Splash.module.scss';

export default function Splash() {
   const splash = useSelector(s => s.splash)
   const dispatch = useDispatch()

   useInterval(() => {
      dispatch(actions.setSplash())
   }, splash === null ? null : 2000, [ splash ])

   if (splash === null) { return null }
   return <div className={css.Splash} key={splash}>
      {splash}
   </div>
}
