import moment from 'moment';
import { connect } from 'react-redux';
import { Dispatch } from 'redux';
import { createSelector } from 'reselect';
import Header from '../components/Header';
import { AttendeeType, Page } from '../model';
import { getCurrentRoom, getSecondaryUser, isLoginDisabled, selectCurrentRoomTimeline } from '../store/helpers';
import { actions, ReduxPropsType, StoreState } from '../store/utils';

const getPeriods = (store: StoreState) => ({
   time: store.time,
   periods: selectCurrentRoomTimeline(store),
});

const getIsBusy = createSelector(
   getPeriods,
   ({ time, periods }) => {
      const now = moment(time);
      return (periods || []).some(p => now.isSameOrAfter(p.startTime) && now.isBefore(p.endTime))
   }
)

const withRedux = connect((state: StoreState) => ({
   currentRoom: getCurrentRoom(state),
   time: state.time,
   isBusy: getIsBusy(state),
   myself: getSecondaryUser(state),
   loginDisabled: isLoginDisabled(state),
   canBookAdhoc: state.config.canBookAdhoc,
}), (dispatch: Dispatch) => ({
   dispatch,
   onLogin() {
      dispatch(actions.openPopup('secondaryLogin'))
   },
   onLogout() {
      dispatch(actions.secondaryLogout())
   },
}), ({ myself, ...props }, { dispatch, ...callbacks }, ownProps) => ({
   myself,
   ...props,
   ...callbacks,
   ...ownProps,
   onNew: () => {
      dispatch(actions.startBooking({ participants: myself ? [{ emailAddress: myself.emailAddress, type: AttendeeType.required }] : undefined }))
   },
   onMyCalendar: () => {
      if (myself) {
         dispatch(actions.goToPage(Page.calendar))
      } else {
         dispatch(actions.openPopup('myCalendar'))
      }
   },
}));

export type HeaderProps = ReduxPropsType<typeof withRedux>;
export default withRedux(Header);
