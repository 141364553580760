import { InferableComponentEnhancerWithProps, useSelector as useUntypedSelector } from 'react-redux';
import { ActionType, StateType } from 'typesafe-actions';
import * as actions from './actions';
import createRootReducer from './reducers';

export { actions };
export type Actions = ActionType<typeof actions>;

export type StoreState = StateType<ReturnType<typeof createRootReducer>>;

export type ReduxPropsType<T extends InferableComponentEnhancerWithProps<any, any>> = T extends InferableComponentEnhancerWithProps<infer P, any> ? P : any;

export function useSelector<TSelected>(selector: (state: StoreState) => TSelected, equalityFn?: (a: TSelected, b: TSelected) => boolean): TSelected {
   return useUntypedSelector<StoreState, TSelected>(selector, equalityFn)
}
