type PropertyGetter<T> = ((item: T) => any) & {
   desc?: boolean;
};

function compare<T>(a: T, b: T, fn: PropertyGetter<T>) {
   const pa = fn(a);
   const pb = fn(b);
   const f = fn.desc ? -1 : 1;

   if (pa < pb) {
      return -f;
   } else if (pa > pb) {
      return f;
   } else {
      return 0;
   }
}

export function desc<T>(fn: PropertyGetter<T>) {
   fn.desc = true;
   return fn;
}

export default function sortBy<T>(...args: Array<PropertyGetter<T>>) {
   return (a: T, b: T) => {
      for (const fn of args) {
         const res = compare(a, b, fn);
         if (res !== 0) { return res; }
      }
      return 0;
   }
}

