import moment from 'moment';
import { all, call, delay, put, select, take } from 'redux-saga/effects';
import { getType } from 'typesafe-actions';
import { getServerTimestamp } from '../pushNotifications';
import { getCurrentRoomEmail, isSingleUser } from '../store/helpers';
import { actions, StoreState } from '../store/utils';
import { takeEvery } from '../utils/apiActions';
import { delay as promiseDelay } from '../utils/misc';

function* updateTime() {
   yield take(getType(actions.initApp));
   for (; ;) {
      const state: StoreState = yield select()
      yield put(actions.updateTime(Date.now() + state.serverTimeOffset));
      yield delay(1000);
   }
}

const autoResetDatePeriod = 1 * 60 * 1000; // 1 min

let lastSelectDate = 0;
const onSelectDate = takeEvery(actions.selectDate, function* (): IterableIterator<never> {
   lastSelectDate = Date.now();
})

const onUpdateTime = takeEvery(actions.updateTime, function* (state) {
   if (isSingleUser(state)) { return }

   const { time, booking, selectedDate } = state
   if (!booking && Date.now() - lastSelectDate > autoResetDatePeriod && moment(time).startOf('d').valueOf() !== selectedDate) {
      yield put(actions.selectDate(time));
   }
});

// remove room's previous calendar at midnight
const removeRoomCal = takeEvery(actions.selectDate, function* (state) {
   const currentRoomEmail = getCurrentRoomEmail(state)
   if (!currentRoomEmail || state.booking) { return }
   yield put(actions.removeCalendarDaysBut(currentRoomEmail, state.selectedDate))
})

function* syncTime() {
   for (; ;) {
      const offset: number = yield call(getServerOffset);
      yield put(actions.setServerTimeOffset(offset))

      yield delay(1000 * 60 * 60); // 1 hr
   }
}

async function getServerOffset() {
   const stats = []
   const N = 11
   for (let retry = 0; retry < N;)
   {
      await promiseDelay(1000)

      const t0 = Date.now()
      const t12 = await getServerTimestamp()
      if (t12 === undefined) { continue }
      const t3 = Date.now()

      stats.push(t12 - (t3 + t0) / 2)

      retry++
   }

   stats.sort((a, b) => a - b)
   return stats[Math.trunc(N / 2)]
}

export default all([
   updateTime(),
   onUpdateTime,
   onSelectDate,
   removeRoomCal,
   syncTime(),
]);
