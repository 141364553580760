import { useContext } from 'react';
import { v4 as uuidv4 } from 'uuid';

export type PromiseReturnType<T> = T extends Promise<infer R> ? R : never;

export interface HasChildren {
   children?: React.ReactNode;
}

export type Except<T, U extends keyof any> = Pick<T, Exclude<keyof T, U>>
export type Extend<T, U> = U & Except<T, keyof U>

export type Mutable<T> = {
   -readonly [P in keyof T]: T[P]
}

// see https://github.com/Microsoft/TypeScript/issues/16069
export function isDefined<T>(arg: T): arg is T extends undefined ? never : T {
   return arg !== undefined;
}

export function isTruthy<T>(arg: T): arg is T extends false ? never : NonNullable<T> {
   return Boolean(arg);
}

export const keysOf = Object.keys as <T>(o: T) => (Extract<keyof T, string>)[]

export function distinct<T>(array: T[]) : T[];
export function distinct<T, V>(array: T[], getKey: (arg: T) => V) : T[];
export function distinct<T, V>(array: T[], getKey?: (arg: T) => V) {
   if (!getKey) { return [ ...new Set(array) ] };

   const result = [];
   const set = new Set<V>();

   for (const item of array) {
      const key = getKey(item);
      if (!set.has(key)) {
         set.add(key);
         result.push(item);
      }
   }

   return result;
}

export function firstOf<T>(array: T[], predicate?: (item: T) => boolean) {
   if (!predicate) { return array[0] }

   for (const item of array) {
      if (predicate(item)) {
         return item;
      }
   }
   return undefined;
}

export function arraysEqual<T>(arr1: T[], arr2: T[]) {
   if (arr1.length !== arr2.length) { return false }
   const set1 = new Set(arr1)
   const set2 = new Set(arr2)
   return Array.of(...set1).every(s1 => set2.has(s1))
      && Array.of(...set2).every(s2 => set1.has(s2))
}

export function flatten<T>(arrays: T[][]) {
   return new Array<T>().concat(...arrays)
}

export function toObject<T, K extends keyof any, V>(array: Iterable<T>, keySelector: (item: T) => K, selector: (item: T) => V): Record<K, V> {
   const obj: Record<any, V> = {}

   for (const item of array) {
      const key = keySelector(item)
      obj[key as any] = selector(item)
   }

   return obj
}

export function toggleArrayItem<T>(items: readonly T[], item: T) {
   if (items.includes(item)) {
      return items.filter(x => x !== item)
   } else {
      return [ ...items, item ]
   }
}

export function backoff(retries: number) {
   const s = 1000
   const base = 1 * s
   const exp = 1.5
   const max = 30 * s
   const min = 1 * s
   const jitter = 5 * s

   const result = Math.min(max, base * (Math.pow(exp, retries) - 1) + min)
   return result + Math.random() * jitter
}

export function delay(ms: number) {
   return new Promise<void>(res => {
      window.setTimeout(() => res(), ms)
   })
}

export function updateRef<T>(ref: React.Ref<T> | undefined, value: T | null) {
   if (typeof ref === 'function') {
      ref(value)
   } else if (ref) {
      (ref as Mutable<typeof ref>).current = value
   }
}

export function useRequiredContext<T>(ctx: React.Context<T | null>): T {
   const value = useContext(ctx)
   if (!value) { throw new Error('Missing provider for ' + ctx.displayName) }
   return value
}

export function isSafari() {
   return /(safari|AppleWebKit)/i.test(window.navigator.userAgent)
      && !/(chrome|android)/i.test(window.navigator.userAgent)
}

export function newGuid() {
  return uuidv4()
}

export function pluralize(value: number, one: string, many: (v: number) => string, zero?: string) {
   if (value === 0) {
      return zero !== undefined ? zero : many(value)
   } else if (value === 1) {
      return one
   } else {
      return many(value)
   }
}
