import classNames from 'classnames';
import React, { useState, useEffect } from 'react';
import { CSSTransition, TransitionGroup } from 'react-transition-group';
import useInterval from '../../utils/useInterval';
import css from './Pager.module.scss';

interface Props {
   className?: string
   pages: number
   delayMs: number
   children(page: number): React.ReactNode
   measurer?: {
      ref: React.Ref<HTMLDivElement>
      children: React.ReactNode
   }
}

export default function Pager({ className, pages, delayMs, children, measurer }: Props) {
   const [ page, setPage ] = useState(0)

   useInterval(() => {
      setPage((page + 1) % pages)
   }, delayMs, [ pages ])

   useEffect(() => setPage(0), [ pages ])

   return <div className={classNames(css.Pager, className)}>
      <div className={css.container}>
         {measurer && <div className={classNames(css.child, css.measurer)} ref={measurer.ref}>
            {measurer.children}
         </div>}
         <TransitionGroup component={null}>
            <CSSTransition key={page} timeout={200} classNames={{ enter: css.enter, enterActive: css.active, exit: css.exit }}>
               <div key={page} className={classNames(css.child)}>
                  {children(page)}
               </div>
            </CSSTransition>
         </TransitionGroup>
      </div>

      <div className={css.pages}>
         {Array.from(new Array(pages)).map((_, i) => <div key={i} className={classNames(css.page, i === page && css.active)} />)}
      </div>
   </div>
}
