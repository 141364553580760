import { ApiError, HttpError } from '../../api';
import { Actions, actions } from '../utils';
import { getType } from 'typesafe-actions';

type Toasts = Record<string, ApiError | string>

let lastId = 0;

export default function toasts(state: Toasts = {}, action: Actions): Toasts {
   if (action.type === getType(actions.apiError)) {
      const err = action.payload
      return {
         ...state,
         [(lastId++).toString()]: err instanceof HttpError ? err.apiError : action.payload.message,
      }
   } else if (action.type === getType(actions.closeErrorToast)) {
      const { [action.payload]: _, ...rest } = state
      return rest
   }

   return state
}
