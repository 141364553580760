import classNames from 'classnames'
import React from 'react'
import css from './Button.module.scss'

interface Props extends React.ButtonHTMLAttributes<HTMLButtonElement> {
   color?: 'accent' | 'red' | 'green' | 'none'
}

export default React.forwardRef<HTMLButtonElement, Props>(function Button({ className, color, children, ...props }, ref) {
   return <button ref={ref} className={classNames(css.button, color && css[color], className)} {...props}>
      {children}
   </button>
})
