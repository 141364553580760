import classNames from 'classnames';
import React from 'react';
import css from './Toggle.module.scss';

type Props = {
   className?: string
   disabled?: boolean
   value: boolean
   onChange(value: boolean): void
}

export default function Toggle({ className, value, disabled, onChange }: Props) {
   return <div className={classNames(css.toggle, className, value && css.checked, disabled && css.disabled)}>
      <input type='checkbox' checked={value} disabled={disabled} onChange={e => onChange(e.currentTarget.checked)} />
      <div className={classNames(css.track)}>
         <div className={classNames(css.thumb)} />
      </div>
   </div>
}

export function ToggleWithLabel({ label, ...props }: Props & { label: React.ReactNode }) {
   return <div className={css.toggleWithLabel}>
      <span>{label}</span>
      <Toggle {...props} />
   </div>
}
