import moment from 'moment';
import { connect } from 'react-redux';
import { Dispatch } from 'redux';
import Timeline from '../components/Timeline';
import { getCurrentRoomEmail, selectCurrentRoomTimeline } from '../store/helpers';
import { shouldLoadCalendar } from '../store/reducers/calendars';
import { actions, ReduxPropsType, StoreState } from '../store/utils';
import { getDayStart } from '../utils/dateUtils';

const EmptyArray: never[] = []

function mapStateToProps(state: StoreState) {
   const { time, lastActivity } = state
   const currentRoomEmail = getCurrentRoomEmail(state)
   const meetings = selectCurrentRoomTimeline(state)

   return {
      time,
      meetings: meetings || EmptyArray,
      loading: meetings === undefined,
      shouldLoad: currentRoomEmail !== null && shouldLoadCalendar(state, currentRoomEmail, getDayStart(state.time)),
      currentRoomEmail,
      lastActivity,
      key: currentRoomEmail, // reset the state if the room changes
      backgroundUrl: state.config.deviceBackgroundUrl,
   };
}

function mapDispatchToProps(dispatch: Dispatch) {
   return {
      dispatch,
      onInit() {
         dispatch(actions.selectDate())
      }
   }
}


const withRedux = connect(mapStateToProps, mapDispatchToProps, ({ shouldLoad, currentRoomEmail, ...props1 }, { dispatch, ...props2 }, props3) => ({
   ...props1,
   ...props2,
   ...props3,
   onUpdated: () => {
      if (shouldLoad && currentRoomEmail) {
         dispatch(actions.fetchCalendarsRequest(getDayStart(props1.time), moment(props1.time).endOf('d').valueOf(), currentRoomEmail))
      }
   }
}));

export type TimelineProps = ReduxPropsType<typeof withRedux>;
export default withRedux(Timeline);
