import moment from 'moment';
import { createSelector } from 'reselect';
import { SharedOrganisationData } from '../api';
import { AllRecurrenceDaysOfWeek, AllRoomTypes, Attendee, BookingResourcesFilter, BookingTime, Contact, createBookingResourceFilter, createDefaultPermissionSet, Domain, getGuest, isRoom, LocationFilter, LoginType, Meeting, Recurrence, Room, Suggestion, TeamWithMembers } from '../model';
import { flatten, isDefined } from '../utils/misc';
import { getBooking, getBookingParticipants, getMeetingsByEmail, makeMeetingsSelector } from './reducers';
import { getDraftResources } from './reducers/booking';
import { selectCurrentDraftSuggestion } from './reducers/suggestions';
import { StoreState } from './utils';

export function getCurrentRoomEmail(state: StoreState) {
   const { login: { data } } = state
   if (!data) { return null }
   return data.singleUser ? null : data.myself
}

export function getCurrentRoom(state: StoreState) {
   const currentRoomEmail = getCurrentRoomEmail(state)
   return !currentRoomEmail ? undefined : state.contacts[currentRoomEmail];
}

export function getAdhocRoom(state: StoreState) {
   if (state.booking?.resources && state.booking.resources.length === 1 && state.booking.resources[0].pinned.length === 1) {
      return state.contacts[state.booking.resources[0].pinned[0]]
   }
   return getCurrentRoom(state)
}

const currentRoomTimelineSelector = makeMeetingsSelector<null>(
   (state: StoreState) => getCurrentRoomEmail(state) ?? undefined,
   getSelectedDate,
);

export function selectCurrentRoomTimeline(state: StoreState) {
   return currentRoomTimelineSelector(state, null);
}

export function isCalendarLoaded(state: StoreState, email: string, day: moment.MomentInput) {
   return getMeetingsByEmail(state, email, day) !== undefined;
}

export const selectContacts = createSelector(
   (s: StoreState) => s.contacts,
   (contacts): Contact[] => Object.values(contacts).filter(isDefined),
)

export const selectRooms = createSelector(
   selectContacts,
   contacts => contacts.filter(isRoom)
)

export const selectLocationRoomFilter = createSelector(
   (s: StoreState) => s.localRooms,
   s => s.secondaryLogin,
   (localRooms, secondaryLogin) => {
      const filteredLocalRooms = localRooms.filter(room => !secondaryLogin || secondaryLogin.organisation.rooms.some(r => r.emailAddress === room))
      return function filterRoom(room: Room, location: LocationFilter) {
         return location.length === 0 || (location.some(loc => loc === null ? filteredLocalRooms.includes(room.emailAddress) : room.location === loc))
      }
   }
)

export const selectRoomSizeFilter = createSelector(
   (s: StoreState)=> s.roomSizesFilter,
   (roomSizesFilter) => {
      return function getRoomSizeFilter(room: Room, sizeIndex: number) {
         if (sizeIndex === -1) {
            return true
         }

         if (sizeIndex === 0) {
            return room.seats < roomSizesFilter[0]
         } else if (sizeIndex === roomSizesFilter.length) {
            return roomSizesFilter[roomSizesFilter.length - 1] <= room.seats;
         }

         return roomSizesFilter[sizeIndex - 1] <= room.seats && room.seats < roomSizesFilter[sizeIndex]
      }
   }
)

export const selectRoomFilter = createSelector(
   selectLocationRoomFilter,
   selectRoomSizeFilter,
   (locationFilter, sizeFilter) => {
      return function filterRoom(room: Room, filter: BookingResourcesFilter) {

         return (room.roomType === filter.type)
            && locationFilter(room, filter.location)
            && (room.roomType !== 'MeetingRoom' ||
               sizeFilter(room, filter.sizeIndex) && filter.attributes.every(resId => room.attributes.includes(resId))
            )
      }
   },
)

export const selectNearbyFilterRoom = createSelector(
   (s: StoreState) => s.booking?.nearby,
   s => s.contacts,
   (nearby, contacts) => {
      return nearby && contacts[nearby.email]
   }
)

export const selectBookingTime = createSelector(
   getBooking,
   (booking): BookingTime | undefined => {
      if (!booking) { return }
      const { start, duration } = booking;
      return { start, duration };
   }
)

export const selectBookingParticipants = createSelector(
   getBookingParticipants,
   s => s.contacts,
   (parts, contacts) => parts.map(p => contacts[p.emailAddress]).filter(isDefined)
)

export const selectBookingAttendees = createSelector(
   getBookingParticipants,
   s => s.contacts,
   (parts, contacts) => parts.map(p => {
      const contact = contacts[p.emailAddress]
      const attendee: Attendee = { contact: contact || getGuest(p.emailAddress), type: p.type, responseStatus: p.responseStatus ? p.responseStatus : "Unknown" }
      return attendee
   })
)

// TODO
export const selectSuggestedBookingParticipants = createSelector(
   s => s.booking?.suggested?.selected,
   (s: StoreState) => s.contacts,
   (emails, contacts) => flatten(emails || []).map(email => contacts[email]).filter(isRoom)
)

export const selectSuggested = createSelector(
   s => s.booking?.suggested?.selected,
   (s: StoreState) => s.contacts,
   (suggested, contacts) => (suggested || []).map(emails => emails.map(email => contacts[email]).filter(isRoom))
)

export function getIdOfMeetingBeindEdited(state: StoreState) {
   return state.booking?.meetingId || undefined;
}

export function getIsEditing(state: StoreState) {
   return Boolean(getIdOfMeetingBeindEdited((state)));
}

export function getContact(state: StoreState, email: string) {
   return state.contacts[email];
}

export function getSelectedDate(state: StoreState) {
   return state.selectedDate;
}

export const selectTeams = createSelector(
   (s: StoreState) => s.teams.teams,
   (s: StoreState) => s.contacts,
   (teams, contacts): TeamWithMembers[] | null => teams?.map(team => ({ ...team, members: team.members.map(m => contacts[m.email]).filter(isDefined) })) ?? null
);

export function isSingleUser({ login: { data } }: StoreState) {
   return data && data.singleUser;
}

export function isExternalUser({ login: { data } }: StoreState) {
   return Boolean(data && data.isExternal)
}

export function getNeedLogin({ login: { data } }: StoreState) {
   return !data || data.needLogin;
}

export function isMobile({ mobile }: StoreState) {
   return mobile;
}

export function getMyself(state: StoreState) {
   if (!state.login.data) { return }
   return state.contacts[state.login.data.myself];
}

export function getCalendarDelegations({ login: { data } }: StoreState) {
   if (!data || !data.delegations) return false
   return data.delegations
}

export function getSecondaryUsername(state: StoreState) {
   if (!state.login.data) { return }
   return isSingleUser(state) ? state.login.data.myself : state.secondaryLogin ? state.secondaryLogin.emailAddress : false
}

export function getSecondaryUserWebExAuthUrl(state: StoreState) {
   if (!state.login.data) { return }
   return state.secondaryLogin ? state.secondaryLogin.webExAuthUrl : null
}

export function getSecondaryUser(state: StoreState) {
   const user = getSecondaryUsername(state);
   return user ? state.contacts[user] : undefined
}

export function isMyselfOrganiser(state: StoreState) {
   const myself = getSecondaryUsername(state)
   const meetingOrganiser = state.booking?.meetingId && state.meetings[state.booking.meetingId]?.organiser
   return myself && (myself.toLowerCase() === meetingOrganiser?.toLowerCase() || !getIsEditing(state))
}

export function getOrganisationDefaultPermissions(state: StoreState) {
   const permissions = state.login.data &&  state.login.data?.organisation?.permissions
   const permission = (permissions && permissions.length > 0)  ? permissions[0] : createDefaultPermissionSet()
   return permission
}

export const getLoginData = createSelector(
   (s: StoreState) => s.login?.data && s.login.data.device?.loginData,
   (loginData): Map<string, Domain> => {
      if (!loginData) return new Map()
      return new Map(loginData.map(dom => [dom.domain, dom] as const))
   }
);

export function getMeetingTypes(state: StoreState) {
   return state.meetingTypes
}

interface SlotParams {
   email?: string
   time: number
}

const slotMeetingSelector = makeMeetingsSelector<SlotParams>((s, x) => x.email, (s, x) => x.time)

export const selectSlots = createSelector(
   (s: StoreState, { time }: SlotParams) => time,
   (s: StoreState, { email }: SlotParams) => email,
   slotMeetingSelector,
   getSecondaryUser,
   (s: StoreState) => s.contacts,
   (time, email, periods, user, contacts) => !periods ? [] : [...getSlots(
      periods, time,
      email ? isRoom(contacts[email]) : false,
      user ? user.emailAddress === email : false,
   )]
)

function* getSlots(timeline: Meeting[], time: number, room: boolean, myself: boolean): IterableIterator<TimelineSlot> {
   const now = moment(time);

   let lastBusy = now.startOf('day');

   for (const period of timeline!) {
      const start = moment(period.startTime);
      const finish = moment(period.endTime);

      if (lastBusy.isBefore(start)) {
         yield { start: lastBusy, finish: start, isFree: true };
      }

      const organiser = period.organiser;
      const showTitle = myself || room && !period.isPrivate

      yield {
         id: period.id,
         start,
         finish,
         isFree: false,
         title: showTitle ? period.subject : 'Busy',
         organiser: organiser?.name,
      };

      if (lastBusy.isBefore(finish)) {
         lastBusy = finish;
      }
   }

   if (lastBusy.isBefore(now.endOf('day'))) {
      yield { start: lastBusy, finish: now.add(1, 'day').startOf('day'), isFree: true };
   }
}

export type TimelineSlot = {
   start: moment.Moment;
   finish: moment.Moment;
} & ({
   isFree: true;
} | {
   isFree: false;
   id: string;
   title: string;
   organiser?: string;
})

export const makeRoomAttributesSelector = <P>(getEmail: (s: StoreState, props: P) => string | undefined) => createSelector(
   (s: StoreState) => s.contacts,
   (s: StoreState) => s.roomAttributes,
   getEmail,
   (contacts, attributes, email) => {
      if (!email || !attributes) { return [] }
      const room = contacts[email]
      if (!isRoom(room)) { return [] }
      return attributes.filter(a => room.attributes.includes(a.id))
   }
)

/**
 * @returns `null` if loading, `false` if nothing is available, `'rooms'` if only rooms are available and attendees are not, otherwise `true`
 */
export function getHaveSuggestions(state: StoreState) {
   // TODO
   const suggestions = state.suggestions.final.suggestions
   return !suggestions ? null
      : suggestions.length === 0 ? false
      : suggestions.some(x => x.participantsAvailable) ? true
      : 'rooms'
}

export function isLoginDisabled(state: StoreState) {
   return Object.values(state.domains).every(d => d.loginType === LoginType.none)
}

export function getSelectedDateTime({ booking, selectedDate }: StoreState) {
   if (!booking) { return null }
   const { start, allDay } = booking;
   return allDay ? selectedDate : moment(selectedDate).add(start, 'h').valueOf();
}

export const getSharedOrganisations = createSelector(
   (s: StoreState) => s.login.data && s.login.data?.device?.sharedOrganisations,
   (sharedOrgs): SharedOrganisationData[] => {
      if (!sharedOrgs) return []
      return sharedOrgs.sort()
   }
)

export function getOrganisationName(state: StoreState, organisationId: string|null) {
   if (organisationId === null) return null;

   const orgs = getSharedOrganisations(state) 

   return orgs.find(o => o.organisationId === organisationId)?.name ?? null
}

export const selectRecurrence = createSelector(
   getSelectedDateTime,
   s => s.booking?.recurrence,
   (time, recurrence): Recurrence | null => {
      if (!recurrence || time === null) { return null }

      return {
         range: {
            ...recurrence.range,
            startDate: new Date(time).toISOString(),
         },
         pattern: {
            ...recurrence.pattern,
            ...(recurrence.pattern.type === 'absoluteMonthly' ? {
               dayOfMonth: moment(time).date()
            } : {}),
            ...(recurrence.pattern.type === 'weekly' && recurrence.pattern.daysOfWeek.length === 1 ? {
               daysOfWeek: [AllRecurrenceDaysOfWeek[moment(time).day()]],
            } : {}),
         }
      }
   }
)

export const selectDefaultResourceFactory = createSelector(
   selectRooms,
   getMyself,
   s => s.location,
   (rooms, myself, location) => (): BookingResourcesFilter | null => {
      // default to the first available type
      const type = AllRoomTypes.find(t => rooms.some(r => r.roomType === t))
      console.log("found type ", type)
      if (!type) return null

      return {
         ...createBookingResourceFilter(),
         location: [isRoom(myself) ? myself.location : location],
         type,
      }
   }
)

export function getDraftResourceSuggestion(state: StoreState, type: 'selected' | 'available') {
   if (!state.booking?.newResource) { return null }
   const draft = selectCurrentDraftSuggestion(state)
   if (!draft) { return null }

   const resources = getDraftResources(state.booking)
   const index = resources.indexOf(state.booking.newResource)

   return draft[type][index].map(email => state.contacts[email]).filter(isRoom)
}

export function getAcceptedSuggestion(state: StoreState) {
   if (!state.booking) { return null }
   const { booking: { suggested } } = state
   if (!suggested) { return null }

   return  {
      ...suggested,
      selected: suggested.selected.map((sug) => sug.map(email => state.contacts[email]).filter(isRoom)),
   }
}

export function selectDomain({ login, domains }: StoreState) {
   if (!login.data) { return null }
   return domains[login.data.domain] ?? null
}
