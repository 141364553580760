import classNames from 'classnames';
import React, { useCallback, useEffect, useMemo } from 'react';
import FontAwesome from 'react-fontawesome';
import { useDispatch } from 'react-redux';
import { AllRoomTypes, BookingResourcesFilter, getRoomTypeName, RoomAttribute } from '../../model';
import { selectDefaultResourceFactory } from '../../store/helpers';
import { actions, useSelector } from '../../store/utils';
import { toggleArrayItem } from '../../utils/misc';
import { orList } from '../../utils/react';
import { useResourceList } from '../BookingFilter';
import { ClockIcon, LocationIcon, RoomSizeIcon } from '../icons';
import IntInput from '../IntInput';
import { SuggestedResources } from '../ResourceFilterDialog';
import { ResourceTypeIcon } from '../ResourceIcon';
import { getLocationName, getLocationNames, getResourceFilterSummary, getSizeFilters } from '../RoomFilter';
import FormField from './FormField';
import { Header } from './Header';
import { usePageNav } from './navigation';
import css from './ResourceFilterEditor.module.scss';
import { MultiSelect, SingleSelect } from './Selectors';
import SideSheet from './SideSheet';

export default function ResourceFilterEditor() {
   const dispatch = useDispatch()
   const booking = useSelector(s => s.booking)
   const factory = useSelector(selectDefaultResourceFactory)
   const roomSizesFilter = useSelector(s => s.roomSizesFilter)
   const roomAttributes = useSelector(s => s.roomAttributes)
   const { isOpen, open, close } = usePageNav()
   const suggestions = useResourceList()

   const shouldOpen = Boolean(booking?.newResource)

   useEffect(() => {
      if (!isOpen) {
         dispatch(actions.rollbackResource())
      }
   }, [dispatch, isOpen])

   useEffect(() => {
      if (shouldOpen) {
         open()
      } else {
         close()
      }
   }, [close, open, shouldOpen])

   if (!booking || !suggestions) { return null }
   const { loading, current } = suggestions

   return <>
      {current.map((res, i) => <FormField key={i} icon={<ResourceTypeIcon type={res.filter.type} />}>
         <button className={css.button} onClick={() => dispatch(actions.editResource(res.filter, res.filter))}>
            <div>
               {orList(getLocationNames(res.filter.location))}, {getResourceFilterSummary(res.filter, roomSizesFilter, roomAttributes)}
            </div>
            {loading ? <div className={css.loading}>Loading...</div>
            : res.incomplete && res.resources.length === 0 ? <div className={css.unavailable}>Nothing is available</div>
            : <ul>
               {res.resources.map(({ room, unavailable }) => <li key={room.emailAddress} className={classNames({
                  [css.invalid]: unavailable,
               })}>
                  {room.name || room.emailAddress} {room.roomType === 'MeetingRoom' && `(${room.seats} seat${room.seats === 1 ? '' : 's'})`}
               </li>)}
               {res.incomplete && <li className={css.unavailable}>Some resources are not available</li>}
            </ul>}
         </button>
      </FormField>)}

      {booking.full && (factory() !== null) && <FormField icon={booking.resources.length === 0 ? <ResourceTypeIcon type='MeetingRoom' /> : null}>
         <button className={css.button} onClick={() => dispatch(actions.editResource(null, factory()))}>
            + Add
         </button>
      </FormField>}

      <SideSheet open={isOpen}>
         <Header close save={() => dispatch(actions.commitResource())}>Requested resource</Header>
         <SideSheet.Body>
            {booking.newResource && <ResourceFilter value={booking.newResource} canRemove={booking.oldResource !== null && booking.full} />}
         </SideSheet.Body>
      </SideSheet>
   </>
}

type Props = {
   value: BookingResourcesFilter
   canRemove: boolean
}

type UpdateFilter = <T extends keyof BookingResourcesFilter>(value: Pick<BookingResourcesFilter, T>) => void

function ResourceFilter({ value, canRemove }: Props) {
   const dispatch = useDispatch()
   const locations = useSelector(s => s.locations) || []

   const roomSizesFilter = useSelector(s => s.roomSizesFilter)
   const roomSizes = useMemo(() => getSizeFilters(roomSizesFilter), [roomSizesFilter])
   const roomAttributes = useSelector(s => s.roomAttributes)

   const update = useCallback<UpdateFilter>((value) => {
      dispatch(actions.updateResource(value))
   }, [dispatch])

   function toggleAttribute(attr: RoomAttribute) {
      value && update({ attributes: toggleArrayItem(value.attributes, attr.id) })
   }

   return <>
      <FormField icon={<LocationIcon />}>
         <MultiSelect values={value.location} onChange={location => update({ location })}
            items={locations} renderItem={getLocationName}
            renderSelected={val => orList(getLocationNames(val))} />
      </FormField>

      <FormField icon={<ResourceTypeIcon type={value.type} />}>
         <SingleSelect items={AllRoomTypes} value={value.type} onChange={type => update({ type })} renderItem={getRoomTypeName} />
      </FormField>

      <FormField icon={<FontAwesome name='hashtag' />}>
         <div className={css.quantity}>
            <span>Quantity</span>
            <IntInput className={css.input} value={value.quantity} onChange={quantity => update({ quantity: Math.max(1, quantity) })} />
         </div>
      </FormField>

      {value.type === 'MeetingRoom' && <>
         <FormField icon={<RoomSizeIcon />}>
            <SingleSelect label='Room size' value={value.sizeIndex} onChange={sizeIndex => update({ sizeIndex })}
               items={[-1, ...roomSizes.map((_, i) => i)]} renderItem={i => i === -1 ? 'Any' : roomSizes[i]} />
         </FormField>

         {roomAttributes && roomAttributes.length > 0 && <FormField>
            <div className={css.attributes}>
               <button className={css.fake} />
               <label>Room attributes</label>
               {roomAttributes.map(attr => <button key={attr.id} className={classNames(value.attributes.includes(attr.id) && css.active)} onClick={() => toggleAttribute(attr)}>
                  <img src={attr.url} alt={attr.name} title={attr.name} />
               </button>)}
            </div>
         </FormField>}
      </>}

      {(value.type === 'Locker' || value.type === 'ParkingSpace') && <FormField icon={<ClockIcon />}>
         <ExtraTimeEditor value={value} onChange={update} />
      </FormField>}

      {canRemove && <button className={css.delete} onClick={() => dispatch(actions.removeResource())}>
         Remove this resource
      </button>}

      <SuggestedResources className={css.resources} resource={value} />
   </>
}

type ExtraTimeProps = {
   value: BookingResourcesFilter
   onChange: UpdateFilter
}

export function ExtraTimeEditor({ value, onChange }: ExtraTimeProps) {
   if (!(value.type === 'Locker' || value.type === 'ParkingSpace')) {
      return null
   }

   return <>
      <label className={css.extra} onClick={e => e.stopPropagation()}>
         Before (minutes)
         <IntInput className={css.input} value={-value.extraBefore} onChange={v => onChange({ extraBefore: -v })} />
      </label>
      <div className={css.divider} />
      <label className={css.extra} onClick={e => e.stopPropagation()}>
         After (minutes)
         <IntInput className={css.input} value={value.extraAfter} onChange={v => onChange({ extraAfter: v })} />
      </label>
   </>
}
