import classNames from 'classnames';
import React from 'react';
import { Link } from 'react-router-dom';
import { HeaderProps } from '../containers/Header';
import { ReactComponent as AddIcon } from './buttons/add.svg';
import { ReactComponent as EditIcon } from './buttons/edit.svg';
import { ReactComponent as LoginIcon } from './buttons/login.svg';
import { ReactComponent as LogoutIcon } from './buttons/logout.svg';
import css from './Header.module.scss';
import TimeAndDate from './TimeAndDate';

export default function Header({ currentRoom, time, isBusy, myself, onNew, onMyCalendar, onLogin, onLogout, loginDisabled, canBookAdhoc }: HeaderProps) {
   return <header className={classNames(css.header, isBusy && css.busy)} data-testid={isBusy ? 'header-busy' : 'header-free'}>
      <div className={css.time}>
         <TimeAndDate time={time} />
      </div>
      <div className={css.title}>
         {currentRoom?.name}
      </div>
      <div className={css.buttons}>
         {(!loginDisabled || canBookAdhoc) && <div tabIndex={0} onClick={onNew} data-testid='header-new'>
            <AddIcon />
            New
         </div>}
         {myself && <div tabIndex={0} onClick={onMyCalendar}>
            <EditIcon />
            My calendar
         </div>}
         <Link to='/rooms'>
            <svg viewBox="0 0 1792 1792"><path d="M256 1312v192q0 13-9.5 22.5t-22.5 9.5h-192q-13 0-22.5-9.5t-9.5-22.5v-192q0-13 9.5-22.5t22.5-9.5h192q13 0 22.5 9.5t9.5 22.5zm0-384v192q0 13-9.5 22.5t-22.5 9.5h-192q-13 0-22.5-9.5t-9.5-22.5v-192q0-13 9.5-22.5t22.5-9.5h192q13 0 22.5 9.5t9.5 22.5zm0-384v192q0 13-9.5 22.5t-22.5 9.5h-192q-13 0-22.5-9.5t-9.5-22.5v-192q0-13 9.5-22.5t22.5-9.5h192q13 0 22.5 9.5t9.5 22.5zm1536 768v192q0 13-9.5 22.5t-22.5 9.5h-1344q-13 0-22.5-9.5t-9.5-22.5v-192q0-13 9.5-22.5t22.5-9.5h1344q13 0 22.5 9.5t9.5 22.5zm-1536-1152v192q0 13-9.5 22.5t-22.5 9.5h-192q-13 0-22.5-9.5t-9.5-22.5v-192q0-13 9.5-22.5t22.5-9.5h192q13 0 22.5 9.5t9.5 22.5zm1536 768v192q0 13-9.5 22.5t-22.5 9.5h-1344q-13 0-22.5-9.5t-9.5-22.5v-192q0-13 9.5-22.5t22.5-9.5h1344q13 0 22.5 9.5t9.5 22.5zm0-384v192q0 13-9.5 22.5t-22.5 9.5h-1344q-13 0-22.5-9.5t-9.5-22.5v-192q0-13 9.5-22.5t22.5-9.5h1344q13 0 22.5 9.5t9.5 22.5zm0-384v192q0 13-9.5 22.5t-22.5 9.5h-1344q-13 0-22.5-9.5t-9.5-22.5v-192q0-13 9.5-22.5t22.5-9.5h1344q13 0 22.5 9.5t9.5 22.5z" /></svg>
            More rooms
         </Link>
         {!loginDisabled && (myself ? <div tabIndex={0} onClick={onLogout}>
            <LogoutIcon />
            Logout
         </div> : <div tabIndex={0} onClick={onLogin}>
            <LoginIcon />
            Login
         </div>)}
      </div>
   </header>;
}
