import classNames from 'classnames';
import React from 'react';
import css from './Page.module.scss';

export default function Page({ className, ...props }: React.HTMLAttributes<HTMLDivElement>) {
   return <div className={classNames(css.page, className)} {...props} />
}

Page.Body = PageBody

export function PageBody({ className, ...props }: React.HTMLAttributes<HTMLDivElement>) {
   return <div className={classNames(css.body, className)} {...props} />
}
