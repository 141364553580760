import classNames from 'classnames';
import { isMobile } from 'is-mobile';
import moment from 'moment';
import React, { useRef } from 'react';
import DatePicker from 'react-datepicker';
import { ReactComponent as ArrowLeft } from './buttons/arrow-left.svg';
import { ReactComponent as ArrowRight } from './buttons/arrow-right.svg';
import { NativeDatePicker } from './mobile/NativePickers';
import Popover, { usePopover } from './Popover';
import css from './ScheduleDatePicker.module.scss';

interface Props {
   className?: string
   value: number
   unit?: 'd' | 'w'
   onChange: (date: number) => void
}

export default function SheduleDatePicker({ className, value, unit = 'd', onChange }: Props) {
   const buttonRef = useRef<HTMLButtonElement>(null)
   const popupRef = useRef<HTMLDivElement>(null)
   const [isOpen, setOpen] = usePopover(buttonRef, popupRef)

   return <div className={classNames(css.datePicker, className)}>
      <button className={css.button} onClick={() => change(-1)}>
         <ArrowLeft />
      </button>
      {renderButton()}
      <button className={css.button} onClick={() => change(+1)}>
         <ArrowRight />
      </button>
      {isOpen && <Popover referenceElement={buttonRef.current} ref={popupRef} placement='bottom' gap={5}>
         <DatePicker inline selected={moment(value)} onChange={onDatePicked} />
      </Popover>}
   </div>

   function renderButton() {
      if (isMobile({ tablet: true })) {
         return <NativeDatePicker className={css.nativePicker} value={value} onChange={v => onChange(v || moment().startOf('d').valueOf())}>
            <div className={css.button}>{renderDate()}</div>
         </NativeDatePicker>
      }

      return <button className={css.button} ref={buttonRef} onClick={() => setOpen(!isOpen)}>
         {renderDate()}
      </button>
   }

   function renderDate() {
      const date = moment(value)

      if (unit === 'd') {
         return date.format('dddd, D MMMM YYYY')
      } else {
         return <>
            {date.format('D MMM YYYY')}
            {' '}&ndash;{' '}
            {date.endOf('w').format('D MMM YYYY')}
         </>
      }
   }

   function change(amount: number) {
      onChange(moment(value).add(amount, unit).startOf(unit).valueOf())
   }

   function onDatePicked(date: moment.Moment) {
      setOpen(false)
      onChange(date.startOf(unit).valueOf())
   }
}
