import { AuthenticationResult, AuthError, BrowserCacheLocation, InteractionRequiredAuthError, PublicClientApplication, RedirectRequest } from '@azure/msal-browser'

interface AzureAdClientOptions {
   clientId: string
   scopes: string[]
   redirectUrl: string
   authority?: string
   onRedirected?(app: ReturnType<typeof createAzureAdClient>, response: AuthenticationResult | null, authErr: AuthError | undefined): void
   forceLogin?: boolean
   loginHint?: string
   cacheLocation?: BrowserCacheLocation
}

export default function createAzureAdClient({ clientId, scopes, authority, redirectUrl, onRedirected, forceLogin, loginHint, cacheLocation }: AzureAdClientOptions) {
   const authRequestNoForce: RedirectRequest = {
      scopes,
      authority,
      loginHint,
   }

   const authRequest: RedirectRequest = {
      ...authRequestNoForce,
      extraQueryParameters: forceLogin ? { max_age: '0', prompt: 'login' } : undefined
   }

   const app = new PublicClientApplication({
      auth: {
         clientId,
         authority,
         redirectUri: redirectUrl.startsWith('http') ? redirectUrl : window.location.origin + redirectUrl,
         navigateToLoginRequestUrl: false,
      },
      // system: { logger: new Logger((level, message) => console.log(level, message)) },
      cache: { cacheLocation: cacheLocation || 'localStorage' },
   })

   const me = {
      acquirePopup,
      acquireRedirect,
      acquireSilent,
   }

   handleRedirect()

   async function acquireSilent() {
      try {
         const accs = app.getAllAccounts()
         let request = authRequestNoForce
         if (accs.length === 1) {
            request = { ...request, account: accs[0] }
         }
         return await app.acquireTokenSilent(request)
      } catch (error) {
         console.error(error)
         if (error instanceof InteractionRequiredAuthError) {
            return null
         }
         return null
      }
   }

   async function acquireTokenPopup() {
      const silent = await acquireSilent()
      if (silent) { return silent }
      return await app.acquireTokenPopup(authRequestNoForce)
   }

   async function acquirePopup() {
      if (forceLogin) {
         const res = await app.loginPopup(authRequest)
         app.setActiveAccount(res.account)
      }

      return await acquireTokenPopup()
   }

   function acquireRedirect() {
      if (forceLogin || app.getAllAccounts().length !== 1) {
         app.loginRedirect(authRequest)
      } else {
         app.acquireTokenRedirect(authRequest)
      }
   }

   async function handleRedirect() {
      try {
         const res = await app.handleRedirectPromise()
         if (res) {
            app.setActiveAccount(res.account)
         }
         onRedirected?.(me, res, undefined)
      } catch (err) {
         onRedirected?.(me, null, err)
      }
   }

   return me
}

export function isAzureAdCallback(hash: string = window.location.hash) {
   try {
      if (hash.startsWith('#')) {
         hash = hash.substr(1)
      }
      return new URLSearchParams(hash).has('state')
   } catch (e) {
      return false
   }
}
