import React from 'react';
import { useHistory } from 'react-router';
import { SuccessIcon } from '../icons';
import css from './BookingSuccess.module.scss';
import { Header } from './Header';
import Page from './Page';

export default function BookingSuccess() {
   const { goBack } = useHistory()

   return <Page>
      <Header save={goBack}>Booking succeded</Header>
      <Page.Body className={css.bookingSuccess}>
         <h1>Thank you. Meeting booked.</h1>
         <SuccessIcon />
         <h2>Tap NFC Tag in room to start your meeting</h2>
      </Page.Body>
   </Page>
}
