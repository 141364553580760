import { all, put, take } from 'redux-saga/effects';
import { getLocation, resetSettings, setLocation } from '../settings';
import { actions } from '../store/utils';
import { takeEvery } from '../utils/apiActions';
import { getType } from 'typesafe-actions';

const onPickLocation = takeEvery(actions.pickLocation, function* (state, location): IterableIterator<never> {
   setLocation(location);
});

const onWhoAmI = takeEvery(actions.whoAmI.response, function* (state, login): IterableIterator<never> {
   if (!login) { resetSettings() }
});

function* getLocationFromSettings() {
   yield take(getType(actions.loggedIn))
   const loc = getLocation();
   if (loc) {
      yield put(actions.pickLocation(loc));
   }
};

export default all([
   onPickLocation,
   onWhoAmI,
   getLocationFromSettings(),
]);
