import moment from 'moment';
import React, { useEffect, useState } from 'react';
import { useDispatch } from 'react-redux';
import { findNearby } from '../../api';
import { ResourceAvailabilityBlock, Suggestion } from '../../model';
import { getSelectedDateTime } from '../../store/helpers';
import { actions, useSelector } from '../../store/utils';
import { keysOf } from '../../utils/misc';
import Spinner from '../Spinner';
import { Header } from './Header';
import { usePageNav } from './navigation';
import css from './NearbySearch.module.scss';
import SideSheet from './SideSheet';

type Block = ResourceAvailabilityBlock & {
   email: string
   duration: number
   suggestion: Suggestion
}

export default function NearbySearch() {
   const { isOpen, open, close } = usePageNav()

   const dispatch = useDispatch()
   const booking = useSelector(s => s.booking)
   const start = useSelector(getSelectedDateTime)
   const [blocks, setBlocks] = useState<Block[]>()
   const end = start !== null && booking ? moment(start).add(booking.duration, 'h').valueOf() : null
   const isAllDay = booking ? booking.allDay : false
   const nearby = booking ? booking.nearby : undefined

   useEffect(() => {
      if (!nearby || start === null || end === null) { return }

      const findSuggestions = async () => {
         setBlocks(undefined)
         const result = await findNearby(start, end, nearby.email, nearby.roomType, isAllDay)
         if (!active) { return }

         const blocks = keysOf(result)
            .sort((a, b) => result[a].order - result[b].order)
            .map<Block>(email => {
               const block = result[email]
               return {
                  ...block,
                  email,
                  duration: moment(block.end).diff(block.start, 'h', true),
                  suggestion: {
                     start: block.start,
                     selected: [[ email ]],
                     available: [[ email ]],
                     incomplete: [false],
                     participantsAvailable: true,
                     resourcesAvailable: true,
                  }
               }
            })

         setBlocks(blocks)
         const first = blocks[0]

         dispatch(actions.acceptSuggestion(
            first?.suggestion ?? null,
            first?.email === nearby.email ? undefined : 'Tapped resource isn\'t available now',
         ))
      }

      let active = true
      findSuggestions()
      return () => { active = false }
   }, [nearby, start, dispatch, end, isAllDay])

   if (!booking?.nearby) { return null }

   const onClick = (block: Block) => {
      dispatch(actions.acceptSuggestion(block.suggestion))

      if (block.duration < booking.duration) {
         dispatch(actions.setBookingDuration(block.duration))
      }

      close()
   }

   return <>
      <button className={css.showMore} onClick={open}>Show more</button>
      <SideSheet open={isOpen}>
         <Header back>More nearby</Header>
         <SideSheet.Body>
            <div className={css.wrapper}>
               {!blocks ? <Spinner className={css.spinner} />
               : blocks.length === 0 ? <div className={css.error}>No suggestions</div>
               : blocks.map(block => <button className={css.block} key={block.email} onClick={() => onClick(block)}>
                  <div className={css.header}>{block.name}</div>
                  <div>{availableFor(block)}</div>
               </button>)}
            </div>
         </SideSheet.Body>
      </SideSheet>
   </>
}

function availableFor(block: Block): string {

   const start = moment(block.start)
   const end = moment(block.end)

   // Is the next block today?
   if (start.dayOfYear() !== moment().dayOfYear()) {
      return 'Unavailable today'
   }

   let from = ''
   if (moment(start).diff(moment(), 'm') < 5) {
      from = 'now'
   } else {
      from = `${start.format('hh:mm a')}`
   }

   let until = ''
   if (isMidnight(end.valueOf())) {
      until = 'midnight'
   } else {
      until = `${end.format('hh:mm a')}`
   }

   if (from === 'now' && until === 'midnight') {
      return 'Available for the rest of the day'
   }

   return `Available from ${from} until ${until}`
}

function isMidnight(value: number) {
   return moment(value).isSame(moment(value).startOf('d')) || moment(value).isSame(moment(value).endOf('d'))
}
