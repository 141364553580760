import bind from 'bind-decorator';
import classNames from 'classnames';
import debounce from 'debounce-promise';
import React, { CSSProperties } from 'react';
import ReactAutocomplete from 'react-autocomplete';
import ReactDOM from 'react-dom';
import scrollParent from 'scrollparent';
import css from './Autocomplete.module.scss';

export interface AutocompleteProps<T> {
   renderInput?: (props: React.InputHTMLAttributes<HTMLInputElement> & React.RefAttributes<HTMLInputElement>) => React.ReactNode;
   getItems: (input: string) => Promise<T[]> | T[];
   renderItem: (item: T, isHighlighted: boolean, styles?: React.CSSProperties) => React.ReactElement;
   getItemValue: (item: T) => string;
   onSelect?: (value: string, item: T) => void;
   value: string;
   onChange?: (e: React.ChangeEvent<HTMLInputElement>, value: string) => void
   className?: string;
   menuClassName?: string;
   onFocus?: (e: React.FocusEvent<HTMLInputElement>) => void;
   onBlur?: (e: React.FocusEvent<HTMLInputElement>) => void;
   shouldHighlight?(item: T, itemValue: string, value: string): boolean
   disabled?: boolean;
}

function getDefaultState() {
   return {
      items: [],
      valid: true,
   }
}

type State<T> = {
   items: T[]
   valid: boolean
}

export default class Autocomplete<T> extends React.PureComponent<AutocompleteProps<T>, State<T>> {
   public readonly state: State<T> = getDefaultState();
   private readonly ref = React.createRef<ReactAutocomplete>();
   private previousValue = '';
   private mounted = true;

   constructor(props: AutocompleteProps<T>) {
      super(props);
      this.getItems = debounce(this.getItems, 300);
   }

   public render() {
      const { items } = this.state;
      const { value, renderInput, renderItem, getItemValue, onSelect, className, onBlur, disabled } = this.props;
      return <ReactAutocomplete items={items} value={value} onChange={this.onChange}
         ref={this.ref} renderInput={renderInput as any} getItemValue={getItemValue} autoHighlight
         renderItem={renderItem} onSelect={onSelect} renderMenu={this.renderMenu} inputProps={{ onFocus: this.onFocus, onBlur, disabled: disabled }}
         onMenuVisibilityChange={this.scrollToHighlighted}
         wrapperStyle={{}} wrapperProps={{ className }} />;
   }

   public async componentDidMount() {
      const { value, getItems } = this.props;
      this.updateItems(value, getItems);

      patchHighlighting(this.ref.current, this.shouldHighlight);
   }

   public componentDidUpdate() {
      const { value } = this.props;

      if (this.previousValue !== value) {
         this.previousValue = value;
         this.updateItems(value);
      }

      this.resizeMenu();
   }

   public componentWillUnmount() {
      this.mounted = false;
   }

   @bind
   private async scrollToHighlighted() {
      const { current: autocomplete } = this.ref
      if (!autocomplete || !autocomplete.state.isOpen) { return }

      await new Promise<void>((resolve) => {
         const index = autocomplete.state.highlightedIndex
         if (index !== null) {
            resolve()
            return
         }

         // fix losing selected item on close/open
         autocomplete.setState((autocomplete as any).maybeAutoCompleteText, () => resolve())
      })

      const index = autocomplete.state.highlightedIndex
      if (index === null) { return }

      const item = autocomplete.refs[`item-${index}`]
      if (item) {
         const node = ReactDOM.findDOMNode(item)
         if (node && node instanceof HTMLElement) {
            setTimeout(() => node.scrollIntoView(), 0)
         }
      }
   }

   @bind
   private onChange(e: React.ChangeEvent<HTMLInputElement>, value: string) {
      this.setState({ valid: false });

      const { onChange } = this.props;
      if (onChange) {
         onChange(e, value);
      }
   }

   @bind
   private resizeMenu() {
      const { current: ref } = this.ref;
      if (ref === null) { return }
      const node = ReactDOM.findDOMNode(ref);
      if (!(node instanceof Element)) { return }
      const menu = node.querySelector('.' + css.itemContainer) as HTMLElement;

      if (menu !== null) {
         const parent = scrollParent(menu);
         const bounds = menu.getBoundingClientRect();
         const parentBounds = parent.getBoundingClientRect();
         const height = parentBounds.top + parentBounds.height - bounds.top
         // const height = parent.scrollHeight - (bounds.top + parent.scrollTop - parentBounds.top);
         menu.style.maxHeight = `${Math.min(Math.floor(height - 1), 400)}px`

         const pos = fixMenuPosition(ref, {})
         Object.assign(menu.style, pos)
      }
   }

   @bind
   private renderMenu(items: any, value: string, style: React.CSSProperties) {
      return <div className={classNames(css.menu, this.props.menuClassName)} style={fixMenuPosition(this.ref.current, style)}>
         <div className={css.itemContainer}>{items}</div>
         {!this.state.valid && <div className={css.curtain} />}
      </div>;
   }

   private async updateItems(value: string, getItems: (value: string) => any[] | Promise<any[]> = this.getItems) {
      const items = await Promise.resolve(getItems(value));
      if (this.props.value === value && this.mounted) {
         this.setState({ items, valid: true });
      }
   }

   @bind
   private getItems(str: string) {
      return this.props.getItems(str);
   }

   @bind
   private onFocus(e: React.FocusEvent<HTMLInputElement>) {
      setTimeout(this.resizeMenu);
      if (this.props.onFocus) {
         this.props.onFocus(e)
      }
   }

   @bind
   private shouldHighlight(item: T, itemValue: string) {
      const { shouldHighlight, value } = this.props

      if (shouldHighlight) {
         return shouldHighlight(item, itemValue, value)
      } else {
         return itemValue.toLowerCase().startsWith(value.toLowerCase())
      }
   }
}

function patchHighlighting(autocomplete: any, shouldHighlight: (item: any, itemValue: string) => boolean) {
   autocomplete.maybeAutoCompleteText = function newAutoCompleteText(state: ReactAutocomplete.State, props: ReactAutocomplete.Props): Partial<ReactAutocomplete.State> {
      const { highlightedIndex } = state
      const { getItemValue } = props
      let index = highlightedIndex ?? 0
      let items = autocomplete.getFilteredItems(props)

      function isItemSelectable(item: any) {
         return props.isItemSelectable!(item) && shouldHighlight(item, getItemValue(item));
      }

      let found = false
      for (let i = 0; i < items.length; i++) {
         if (isItemSelectable(items[index])) {
            found = true
            break
         }
         index = (index + 1) % items.length
      }

      return {
         highlightedIndex: found ? index : null
      };

      // eslint-disable-next-line no-extra-bind
   }.bind(autocomplete);
}

function fixMenuPosition(autocomplete: ReactAutocomplete | null, style: CSSProperties) {
   if (!autocomplete) { return style }

   const input = autocomplete.refs.input as HTMLElement
   let node = input;
   const menu = autocomplete.refs.menu as HTMLElement;
   if (!node || !menu) { return style }

   const computedStyle = window.getComputedStyle(node)
   let top = parseInt(computedStyle.marginBottom || '0', 10) || 0
   let left = parseInt(computedStyle.marginLeft || '0', 10) || 0
   top += input.offsetHeight

   // go up the child tree until both children belong to the same offset parent
   for (; ;) {
      left += node.offsetLeft;
      top += node.offsetTop;

      const parent = node.offsetParent;
      if (parent === menu.offsetParent) {
         break;
      }

      if (!(parent instanceof HTMLElement)) { return style }
      node = parent;
   }

   const viewHeight = window.innerHeight || document.documentElement.clientHeight

   // position on top?
   const menuBounds = menu.getBoundingClientRect()
   const inputBounds = input.getBoundingClientRect()
   const moreSpaceAbove = viewHeight - inputBounds.bottom < inputBounds.top

   if (moreSpaceAbove && inputBounds.bottom + menuBounds.height > viewHeight) {
      top -= menuBounds.height + input.offsetHeight
   }

   return { ...style, left, top };
}
