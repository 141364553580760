declare global {
   interface Window {
      ApplicationHosting?: ApplicationHostingInterface
   }
}

export interface HostingContext {
   getToken(): Promise<string>
   action: {
      type: "calendar"
   } | {
      type: "editMeeting"
      meetingId: string
   } | null
   goHome(): void
}
interface ApplicationHostingContext {
   accessToken: string
   launchAction?: LaunchActionArgs
}

interface ApplicationHostingInterface {
   context(): string
   sendAction(type: 'Navigate', app: 'MobileApp', path: string[]): void
}

function getAndroidContext(): HostingContext | undefined {
   const hosting = window.ApplicationHosting
   if (!hosting) { return }

   if (!window.ApplicationHosting) { return }
   const context = JSON.parse(window.ApplicationHosting.context()) as ApplicationHostingContext
   if (!context) { return }

   return {
      async getToken() { return context.accessToken },
      action: context.launchAction ? readAction(context.launchAction) : null,
      goHome() {
         hosting.sendAction('Navigate', 'MobileApp', ['/'])
      }
   }
}

async function getiOSContext(): Promise<HostingContext | undefined> {
   const webkit = (window as any).webkit
   if (webkit === undefined || webkit.messageHandlers === undefined) { return }
   const applicationHosting = webkit.messageHandlers.applicationHosting
   const accessToken = webkit.messageHandlers.accessToken
   const sendAction  = webkit.messageHandlers.sendAction

   if (applicationHosting === undefined || accessToken === undefined || sendAction === undefined) { return  }
   const context = JSON.parse(await applicationHosting.postMessage({})) as ApplicationHostingContext

   return {
      getToken() { return accessToken.postMessage({}) },
      action:  null, // context.launchAction ? readAction(context.launchAction) : null,
      goHome() {
         sendAction.postMessage(JSON.stringify({
            $type: 'Navigate', 
            app: 'MobileApp', 
            args: ['/']
         }))
      }
   }
}

export async function getHostingContext() {
   return getAndroidContext() || await getiOSContext()
}

export function onLaunchAction(callback: (action: LaunchAction) => void) {
   window.addEventListener('message', onMessage)
   return () => window.removeEventListener('message', onMessage)

   function onMessage(ev: MessageEvent) {
      if (typeof ev.data !== 'string' || !ev.data.includes('$type')) { return }

      try {
         const message = JSON.parse(ev.data) as LaunchMessage
         if (message['$type'] !== 'IpfxAction') { return }

         callback(readAction(message.args));
      } catch (e) {
      }
   }
}

function readAction([type, ...args]: LaunchActionArgs): LaunchAction {
   switch (type) {
      case 'calendar':
         return { type }

      case 'editMeeting':
         const [meetingId] = args
         return { type, meetingId }
   }

   throw new Error('Unknown action type: ' + type)
}


interface LaunchMessage {
   $type: 'IpfxAction',
   args: LaunchActionArgs
}

export type LaunchAction = {
   type: 'calendar'
} | {
   type: 'editMeeting'
   meetingId: string
}

type LaunchActionArgs = [LaunchActionType, ...string[]]

type LaunchActionType = LaunchAction['type']
