import { getType } from 'typesafe-actions';
import { DelegationData, Organisation } from '../../api';
import { Actions, actions } from '../utils';

type SecondaryLogin = false | {
   emailAddress: string
   delegations?: DelegationData
   organisation: Organisation
   webExAuthUrl: string | null
}

export default function secondaryLogin(state: SecondaryLogin = false, action: Actions): SecondaryLogin {
   switch (action.type) {
      case getType(actions.secondaryLogin.response):
         return { emailAddress: action.payload.contact.emailAddress, organisation: action.payload.organisation, webExAuthUrl: action.payload.webExAuthUrl }

      case getType(actions.secondaryLogout):
         return false

      case getType(actions.whoAmI.response):
      case getType(actions.login.response):
         if (!action.payload) { return false }
         return action.payload.secondary ? { 
            emailAddress: action.payload.secondary.contact.emailAddress, 
            delegations: action.payload.secondary.delegations,
            organisation: action.payload.secondary.organisation,
            webExAuthUrl: action.payload.secondary.webExAuthUrl } : false
   }

   return state
}
