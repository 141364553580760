import { bind } from 'bind-decorator';
import classNames from 'classnames';
import React from 'react';
import { BookingCursorProps } from '../containers/BookingCursor';
import { BookingTime } from '../model';
import { TimeRounding } from '../utils/dateUtils';
import DragDrop from '../utils/DragDrop';
import css from './BookingCursor.module.scss';
import { ReactComponent as Selection } from './buttons/selection.svg';
import { hoursToPx, pxToHours } from './Schedule';

type Props = {
   className?: string;
} & BookingCursorProps;

interface State {
   offsetX: number;
   deltaW: number;
   prevBooking?: BookingTime;
   mode?: 'move' | 'size';
}

function getInitialState(): State {
   return {
      offsetX: 0,
      deltaW: 0,
   };
}

export default class BookingCursor extends React.PureComponent<Props, State> {
   public static getDerivedStateFromProps({ booking }: Props, { prevBooking }: State): Partial<State> | null {
      if (booking === prevBooking) { return null; }
      return {
         ...getInitialState(),
         mode: undefined,
         prevBooking: booking,
      }
   }

   public readonly state = getInitialState();

   private readonly drag = new DragDrop(this.onMove, this.onDone);
   private prevX: number = NaN;

   public render() {
      const { className, booking } = this.props;
      if (!booking) { return null }
      const { start, duration } = booking;

      const end = Math.min(start + duration, 24);

      return <div className={classNames(css.bookingCursor, this.state.mode && css.dragging, className)} style={{
         left: hoursToPx(start) + this.state.offsetX,
         minWidth: hoursToPx(end - start) + this.state.deltaW,
      }} onMouseDown={e => this.onStartMove(e.pageX)} onTouchStart={e => this.onStartMove(e.touches[0].pageX)}>
         <Knob className={css.leftSelection} onDown={this.onStartMove} />
         <Knob className={css.rightSelection} onDown={this.onStartSize} />
      </div>;
   }

   public componentWillUnmount() {
      // just in case
      this.drag.stop();
   }

   @bind
   private onStartMove(x: number) {
      this.setState({ mode : 'move' });
      this.prevX = x;
      this.drag.start();
   }

   @bind
   private onStartSize(x: number) {
      this.setState({ mode : 'size' });
      this.prevX = x;
      this.drag.start();
   }

   @bind
   private onMove(x: number) {
      const delta = x - this.prevX;
      const name = this.state.mode === 'move' ? 'offsetX' : 'deltaW';

      this.setState({ [name]: delta } as any);
   }

   @bind
   private onDone(x: number) {
      const { booking, setStart, setDuration } = this.props;
      if (!booking) { return }

      const delta = x - this.prevX;
      const snapTo = TimeRounding.desktop;

      if (this.state.mode === 'move') {
         const time = roundTo(booking.start + pxToHours(delta), snapTo);
         setStart(Math.min(24 - snapTo, Math.max(0, time)));
      } else {
         const dur = roundTo(booking.duration + pxToHours(delta), snapTo);
         setDuration(Math.min(24 - booking.start, dur));
      }

      this.setState(getInitialState());
   }
}

function roundTo(x: number, to: number) {
   return Math.round(x / to) * to;
}

interface KnobProps {
   className: string;
   onDown(x: number): void;
}

class Knob extends React.PureComponent<KnobProps> {
   public render() {
      const { className } = this.props;
      return <Selection className={className} onMouseDown={this.onMouseDown} onTouchStart={this.onTouchStart} />
   }

   @bind
   private onMouseDown(e: React.MouseEvent) {
      e.stopPropagation()
      this.props.onDown(e.pageX);
   }

   @bind
   private onTouchStart(e: React.TouchEvent) {
      e.stopPropagation()
      this.props.onDown(e.touches[0].pageX);
   }
}

