import { bind } from 'bind-decorator';
import React from 'react';
import css from './PinInput.module.scss';

interface Props {
   onComplete?: (pin: string) => void;
}

interface State {
   pin: string;
}

const pinLength = 6;

export default class PinInput extends React.PureComponent<Props, State> {
   public readonly state: State = { pin: '' };
   private readonly inputRef: React.RefObject<HTMLInputElement> = React.createRef();

   public componentDidMount() {
      document.addEventListener('keydown', this.onKeyDown, true);
      document.addEventListener('click', this.onClick, true);
   }

   public componentWillUnmount() {
      document.removeEventListener('keydown', this.onKeyDown, true);
      document.removeEventListener('click', this.onClick, true);
   }

   public render() {
      const { pin } = this.state;
      return <div className={css.pinInput}>
         {new Array(pinLength).fill(0).map((s, i) => <div key={i} className={css.item}>
            <span>{pin[i] || (i === pin.length && '_') || ''}</span>
         </div>)}
         <input ref={this.inputRef} autoFocus autoComplete='off' className={css.hiddenInput} value='' onChange={this.onChange} data-testid='pin-input' />
      </div>;
   }

   @bind
   private onKeyDown(e: KeyboardEvent) {
      let { pin } = this.state;
      if (e.key.length === 1) {
         pin += e.key.toUpperCase();
      } else if (e.key === 'Backspace') {
         pin = pin.substring(0, pin.length - 1);
      } else if (e.key === 'Enter') {
         ;
      } else {
         return;
      }

      e.preventDefault();
      e.stopPropagation();

      this.updatePin(pin);
   }

   @bind
   private onChange(e: React.ChangeEvent<HTMLInputElement>) {
      // this is a workaround for Android where keydown doesn't return alphanum keys
      const { pin } = this.state;
      this.updatePin(pin + e.currentTarget.value.toUpperCase());
   }

   @bind
   private onClick() {
      const ref = this.inputRef.current;
      if (ref) { ref.focus(); }
   }

   private updatePin(pin: string) {
      if (pin.length > pinLength) {
         pin = pin.substring(0, pinLength);
      }
      this.setState({ pin });

      const { onComplete } = this.props;
      if (onComplete && pin.length === pinLength) {
         onComplete(pin);
         this.setState({ pin: '' });
      }
   }
}
