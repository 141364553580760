import React from 'react';
import css from './Footer.module.scss';
// import Logo from './Logo';

export default class Footer extends React.Component {
   public render() {
      return <footer className={css.footer}>
         {/* <Logo mono className={css.logo} /> */}
      </footer>;
   }
}
