import { connect } from 'react-redux';
import { Dispatch } from 'redux';
import TeamsLogin from '../components/TeamsLogin';
import { actions, ReduxPropsType, StoreState } from '../store/utils';
import * as teams from '../teams';

function mapStateToProps(state: StoreState) {
   const { login } = state;
   return { login };
}

function mapDispatchToProps(dispatch: Dispatch) {
   return {
      async onInit() {
         const domain = await teams.getTeamsDomain()
         if (!domain) { return }

         const token = await teams.acquireTeamsTokenSilent(domain)
         if (token) {
            tokenLogin(dispatch, token);
         }
      },
      async onLogin() {
         try {
            tokenLogin(dispatch, await teams.showAuthPopup());
            return;
         } catch (e) {
            return String(e);
         }
      },
   }
}

function tokenLogin(dispatch: Dispatch, token: string) {
   dispatch(actions.login.request({ token, teams: true }));
}

const withRedux = connect(mapStateToProps, mapDispatchToProps);
export type TeamsLoginProps = ReduxPropsType<typeof withRedux>;
export default withRedux(TeamsLogin);
