import { LOCATION_CHANGE, replace } from 'connected-react-router';
import moment from 'moment';
import { all, call, put, race, select, take, takeEvery as takeEveryUntyped } from 'redux-saga/effects';
import { getType } from 'typesafe-actions';
import * as Api from '../api';
import { webexLogin } from '../components/WebexLoginCallback';
import { AttendeeType, BookingResourcesFilter, isRoom, Page } from '../model';
import { getOrganisationDefaultPermissions, getCurrentRoomEmail, getIsEditing, getSecondaryUser, getSecondaryUsername, getSecondaryUserWebExAuthUrl, getSelectedDateTime, isMobile, isSingleUser, selectBookingAttendees, selectContacts, selectDefaultResourceFactory, selectRecurrence, getMeetingTypes } from '../store/helpers';
import { getHaveSecondaryLogin } from '../store/reducers';
import { Meeting } from '../store/reducers/meetings';
import { actions, StoreState } from '../store/utils';
import { reportApiError, takeEvery } from '../utils/apiActions';
import { getDayStart, timeOfDayInHours } from '../utils/dateUtils';
import { isDefined, PromiseReturnType } from '../utils/misc';
import { ApiResult } from './api';
import { runAvailabilitySearch } from './suggestions';
import { goHome } from './utils';

const startBooking = takeEvery(actions.startBooking, function* (state, { start: hr, participants, nearby }) {
   if (!(yield requireLogin(state, 'newBooking'))) {
      yield put(actions.endBooking())
      return
   }

   state = yield select()

   if (!state.mobile && getSecondaryUser(state) && !state.booking!.nearby) {
      yield put(actions.setMeetingFull(true))
   }

   yield addCurrentTeam(state);

   if (!getIsEditing(state)) {

      const allTypes = getMeetingTypes(state)
      if (state.booking && !allTypes.includes(state.booking.types[0])) {
         yield put(actions.setMeetingTypes([allTypes[allTypes.length - 1]]))
      }

      let resource: BookingResourcesFilter | null = selectDefaultResourceFactory(state)()

      if (participants?.map(p => state.contacts[p.emailAddress]).some(isRoom)) {
         resource = null
      }

      if (nearby) {
         resource = null
      }

      if (resource && (state.booking && state.booking?.resources.length === 0)) {
         yield put(actions.editResource(null, resource))
         yield put(actions.commitResource())
      }
   }

   const meetingData = state.booking?.meetingId ? state.meetings[state.booking.meetingId] : undefined

   yield put(actions.setPermissions(meetingData?.permissions ?? getOrganisationDefaultPermissions(state)))

   yield put(actions.goToPage(Page.bookingFilter))
});

function requireLogin(state: StoreState, type: 'newBooking'): Iterator<any, any, any>
function requireLogin(state: StoreState, type: 'editMeeting', organiser: string | undefined): Iterator<any, any, any>
function* requireLogin(state: StoreState, type: 'newBooking' | 'editMeeting', user?: string) {
   if (hasLogin(state, user)) { return true }

   if (state.config.canBookAdhoc && type === 'newBooking' && !state.booking?.full) { return true }

   yield put(actions.openPopupWithParams(type === 'newBooking' ? { type } : { type, organiser: user }));
   yield take([getType(actions.closePopup), getType(actions.secondaryLogin.response)])
   return hasLogin(yield select(), user)
}

function hasLogin(state: StoreState, user?: string) {
   if (isSingleUser(state) || getHaveSecondaryLogin(state)) {
      return user === undefined || user === getSecondaryUsername(state)
   }
   return false
}

function* setMeetingTitle() {
   for (; ;) {
      yield take([getType(actions.startBooking), getType(actions.secondaryLogin.response)])
      const state: StoreState = yield select()

      if (state.booking && !state.booking.meetingId && !isSingleUser(state)) {
         const user = getSecondaryUser(state)
         if (user) {
            yield put(actions.setBookingSubject(`${user.name}'s meeting`))
         }
      }
   }
}

function* addCurrentTeam(state: StoreState) {
   // auto-add current team's members
   for (const email of state.teams.currentTeam) {
      yield put(actions.addParticipant(email, AttendeeType.required))
   }
}

const createMeeting = takeEvery(actions.createMeeting, function* (state) {
   const { booking } = state;
   if (!booking) { return }

   const oldMeeting = booking.meetingId ? state.meetings[booking.meetingId] : undefined
   const participants = selectBookingAttendees(state)
   const time = getSelectedDateTime(state)!
   const recurrence = selectRecurrence(state)

   const addFiles = booking.attachments.filter((a): a is File => a instanceof File)
   const removeFiles = oldMeeting ? oldMeeting.attachments.filter(a => !booking.attachments.includes(a)).map(a => a.name) : []
   const autoStart = Boolean(booking.nearby)
      && booking.suggested?.selected[0]?.[0] === booking.nearby?.email
      && moment(time).diff(undefined, 'm') <= 5

   let meeting: PromiseReturnType<ReturnType<typeof Api.createMeeting>>;
   try {
      yield put(actions.openPopupWithParams({ type: 'progress', message: 'Booking the meeting...', noDelay: true }, true));

      let webExToken: ApiResult<typeof webexLogin> | undefined

      if (booking.types.includes('WebEx')) {
         const webExAuthurl = getSecondaryUserWebExAuthUrl(state)
         console.log('WebEx: getSecondaryUserWebExAuthUrl', webExAuthurl);
         if (webExAuthurl) {
            webExToken = yield call(webexLogin, webExAuthurl)
         } else {
            throw new Error('Missing webex Auth url for login')
         }
      }

      meeting = yield call(Api.createMeeting, {
         booking,
         time,
         participants,
         addFiles,
         removeFiles,
         recurrence,
         autoStart,
         includeMeetingLink: Boolean(booking.permissions && booking.permissions.permissions.length > 0 && booking.permissions.permissions.some(p => p.properties.length > 0)),
         webExToken,
      });
   } catch (e) {
      console.log('Error: ', e);

      yield put(actions.setPopupError(Api.getErrorMessage(e)));
      if (e instanceof Api.HttpError && e.apiError.code === 'ErrorTimeConflict') {
         yield call(runAvailabilitySearch, true)
      }
      return;
   }

   state = yield select()
   const existing = Object.values(state.meetings)
      .filter(isDefined)
      .filter(m => oldMeeting && m.iCalUid === oldMeeting.iCalUid || m.iCalUid === meeting.iCalUid)

   for (const m of existing) {
      yield put(actions.removeMeeting(m.id))
   }

   for (const p of participants) {
      yield put(actions.addMeetingData(p.contact.emailAddress, { ...meeting, pending: true }));
   }

   yield put(actions.endBooking());

   if (isMobile(state)) {
      yield put(replace('/success'))
   } else {
      yield goHome()
      yield put(actions.setSplash('Booking succeeded'))
   }
});

const createAdhocMeeting = takeEvery(actions.createAdhocMeeting, function* (state, { room, duration, adhocOrganisationId }) {
   yield put(actions.openPopupWithParams({ type: 'progress', message: 'Booking the meeting...', noDelay: true }, true))

   let meeting: PromiseReturnType<ReturnType<typeof Api.createAdhocMeeting>>
   try {
      meeting = yield call(Api.createAdhocMeeting, state.time, duration, 'Adhoc meeting', room, adhocOrganisationId)
   } catch (e) {
      yield put(actions.setPopupError(Api.getErrorMessage(e)))
      return
   }

   yield put(actions.addMeetingData(room, { ...meeting, pending: true }));
   yield put(actions.endBooking());
   yield goHome()
   yield put(actions.setSplash('Booking succeeded'))
});

const endBooking = takeEvery(actions.endBooking, function* (state: StoreState, onIdle?: boolean) {
   const { localRooms, location, contacts, teams: { teams }, login: { data: login } } = state
   const secondaryUser = getSecondaryUser(state)
   const secondaryLogin = secondaryUser?.emailAddress

   const people = new Set<string>(...(teams || []).map(t => t.members.map(m => m.email)))
   if (login) { people.add(login.myself) }
   if (secondaryLogin) { people.add(secondaryLogin) }

   const rooms = location === null
      ? localRooms
      : Object.keys(contacts)
         .map(e => contacts[e]!)
         .filter(c => isRoom(c) && c.location === location)
         .map(r => r.emailAddress)

   const emails = [...people, ...rooms]

   yield put(actions.closePopup())

   // remove calendars first
   const cals = getAllCalendars(state).filter(email => !emails.includes(email))
   yield put(actions.removeCalendars(cals))

   // then get rid of meetings that are no longer referenced from anywhere
   const remainingMeetingIds: string[] = []
   yield removeOrphanMeetings(remainingMeetingIds)

   // don't delete participants of remaining meetings
   const meetings: StoreState['meetings'] = yield select((s: StoreState) => s.meetings)
   for (const id of remainingMeetingIds) {
      const m = meetings[id]
      if (m) {
         m.participants.forEach(p => people.add(p.emailAddress))
         if (m.organiser) {
            people.add(m.organiser)
         }
      }
   }

   // load fresh teams just in case they've changed since we looked
   state = yield select()
   if (state.teams.teams) {
      state.teams.teams.forEach(t => t.members.forEach(m => people.add(m.email)))
   }

   yield put(actions.cleanupPersons([...people]))

   const currentRoomEmail = getCurrentRoomEmail(state)
   if (onIdle && currentRoomEmail) {
      yield put(actions.fetchCalendarsRequest(getDayStart(state.time), moment(state.time).endOf('d').valueOf(), currentRoomEmail))
   }
})

export function getAllCalendars(state: StoreState) {
   return Object.keys(state.calendars)
}

const editBooking = takeEvery(actions.editBooking, function* (state, { meetingId, meetingData }) {

   let meeting: Meeting | undefined
   if (meetingId) {
      meeting = state.meetings[meetingId]
      if (!meeting) { return }
      if (!(yield requireLogin(state, 'editMeeting', meeting.organiser))) { return }


      // for now always request the meeting from the server to get the (unextended) organiser's version
      yield put(actions.openPopupWithParams({ type: 'progress', message: 'Loading...', canCancel: true }, true))
      try {
         const { meetingData, cancel }: { meetingData: ApiResult<typeof Api.fetchFullMeeting>, cancel: true | undefined } = yield race({
            meetingData: call(Api.fetchFullMeeting, meetingId),
            cancel: call(function* () {
               for (; ;) {
                  const state: StoreState = yield select()
                  if (state.popup.type !== 'progress') {
                     return true
                  }

                  yield take([getType(actions.openPopupWithParams), getType(actions.closePopup)])
               }
            })
         })

         if (cancel) {
            return
         }

         yield put(actions.replaceMeetingData(meetingData.id, meetingData))

         state = yield select()
         meeting = state.meetings[meetingData.id]!
         yield put(actions.closePopup())
      } catch (e) {
         yield put(actions.closePopup())
         yield reportApiError(e)
         return
      }

   } else if (meetingData) {
      yield put(actions.replaceMeetingData(meetingData.id, meetingData))

      state = yield select()
      meeting = state.meetings[meetingData.id]!
   } else {
      throw new Error('Missing parameter')
   }



   yield put(actions.goToPage(Page.bookingFilter, meetingId !== undefined ? undefined : false))
   const allRooms = selectContacts(state).filter(isRoom).map(r => r.emailAddress);

   const { startTime, endTime, filter } = meeting
   const rooms = meeting.participants.filter(p => allRooms.includes(p.emailAddress) && filter.every(f => !f.selected.includes(p.emailAddress)));
   const people = meeting.participants.filter(p => !allRooms.includes(p.emailAddress))

   const day = getDayStart(startTime);
   yield put(actions.selectDate(day))

   yield put(actions.startBooking({
      start: timeOfDayInHours(startTime),
      participants: [...people, ...rooms],
      meeting: {
         ...meeting,
         // treat selected as pinned on edit
         filter: filter.map(({ selected, ...filter }) => ({ ...filter, pinned: selected })),
      },
   }));
   yield put(actions.setBookingDuration(moment(endTime).diff(startTime, 'h', true)));

   yield put(actions.fetchContacts.request(people.map(p => p.emailAddress)))
});

function* removeOrphanMeetings(outRemainingMeetingIds: string[]) {
   const state: StoreState = yield select();

   const meetings = new Set(Object.keys(state.meetings));
   for (const email of getAllCalendars(state)) {
      const cal = state.calendars[email]
      if (!cal) { continue }
      for (const day in cal) {
         const ids = cal[day]
         if (!ids) { continue }
         for (const id of ids) {
            meetings.delete(id);
            outRemainingMeetingIds.push(id);
         }
      }
   }

   for (const id of meetings) {
      yield put(actions.removeMeeting(id));
   }
}

const addResource = takeEvery(actions.openPopupWithParams, function* (state, { params }) {
   if (params.type !== 'addResource') { return }
   const meeting = state.meetings[params.meetingId]
   if (!meeting) { return }
   yield put(actions.startAddResource(meeting))
})

const endBookingOnNavigation = takeEveryUntyped(LOCATION_CHANGE, function* () {
   const state: StoreState = yield select()
   if (state.mobile && state.router.location.pathname === '/mobile/calendar') {
      yield put(actions.endBooking())
   }
})

export default all([
   startBooking,
   createMeeting,
   createAdhocMeeting,
   endBooking,
   editBooking,
   addResource,
   setMeetingTitle(),
   endBookingOnNavigation,
])
