import classNames from 'classnames';
import filesize from 'filesize';
import React, { useState } from 'react';
import { useDropzone } from 'react-dropzone';
import FontAwesome from 'react-fontawesome';
import { Modal, ModalBody, ModalHeader } from 'reactstrap';
import { Agenda, AgendaItem, Attachment } from '../model';
import { Except, newGuid } from '../utils/misc';
import css from './AgendaEditor.module.scss';
import Button from './Button';
import Toggle from './mobile/Toggle';

interface Props {
   value: Agenda
   files: readonly Attachment[]
   duration: number
   attendeesCanEdit?: boolean
   onChange(value: Agenda, files: Attachment[], attendeesCanEdit: boolean): void
   open: boolean
   onClose(): void
}

type AgendaItemWithAttachment = Except<AgendaItem, 'attachmentFilename'> & {
   file: Attachment | null
}

const Durations = [2, 5, 10, 15, 20, 25, 30]

export default function AgendaEditor({ value, files, duration, attendeesCanEdit, onChange, open, onClose }: Props) {
   const [text, setText] = useState(value.notes)
   const [items, setItems] = useState<AgendaItemWithAttachment[]>(value.items.map(item => ({
      ...item,
      file: files.find(f => f.name === item.attachmentFilename) || null,
   })))
   const [attCanEdit, setAttCanEdit] = useState(attendeesCanEdit || false)

   const [itemBeingEdited, startEdit] = useState<AgendaItemWithAttachment>()
   const [fade, setFade] = useState(true)

   function editItem(item: AgendaItemWithAttachment | null) {
      setFade(false)
      startEdit(item || {
         uniqueId: '',
         subject: '',
         elapsedTime: 0,
         scheduledTime: 15 * 60,
         isCompleted: false,
         isRunning: false,
         file: null,
         webLink: '',
      })
   }

   function onCloseAgendaEditor() {
      setFade(true)
      onClose()
   }

   function stopEdit() {
      startEdit(undefined)
   }

   function ensureUniqueName(item: AgendaItemWithAttachment): void {
      const { file } = item
      if (!(file instanceof File)) { return }

      const names = items.map(i => i.file).map(f => f?.name)

      const index = items.findIndex(i => i.uniqueId === item.uniqueId)
      if (index !== -1) {
         if (items[index].file === file) { return }
         names.splice(index, 1)
      }

      for (let i = 0; ; i++) {
         let name = file.name
         if (i > 0) {
            name = name.replace(/^(.*?)(\..*)?$/, `$1-${i}$2`)
         }

         if (!names.includes(name)) {
            if (name !== file.name) {
               item.file = new File([file], name, { type: file.type, lastModified: file.lastModified })
            }
            break
         }
      }
   }

   function onSave() {
      // attachments that were associated with agenda items
      const agendaFiles = new Set(files.filter(f => value.items.some(a => a.attachmentFilename === f.name)))

      // add new
      const newFiles = Array.from(files)
      for (const item of items) {
         if (!item.file) { continue }
         if (agendaFiles.has(item.file)) {
            agendaFiles.delete(item.file)
         } else {
            newFiles.push(item.file)
         }
      }

      // delete old
      for (const file of agendaFiles) {
         newFiles.splice(newFiles.indexOf(file), 1)
      }

      onChange({
         notes: text,
         items: items.map(({ file, ...item }) => ({
            ...item,
            attachmentFilename: file ? file.name : null,
         }))
      }, newFiles, attCanEdit)

      onCloseAgendaEditor()
   }

   function onSaveItem(item: AgendaItemWithAttachment) {
      const newItems = [...items]
      const index = newItems.findIndex(x => x.uniqueId === item.uniqueId)

      ensureUniqueName(item)

      if (!item.uniqueId) {
         item.uniqueId = newGuid()
      }

      if (index === -1) {
         newItems.push(item)
      } else {
         newItems[index] = item
      }

      setItems(newItems)
      stopEdit()
   }

   function onDeleteItem(id: string) {
      setItems(items => items.filter(x => x.uniqueId !== id))
      stopEdit()
   }

   if (itemBeingEdited) {
      return <ItemEditor item={itemBeingEdited} onCancel={stopEdit} onSave={onSaveItem} onDelete={onDeleteItem} />
   }

   const totalTime = items.reduce((a, c) => a + c.scheduledTime / 60, 0)
   const showIcons = items.some(item => item.webLink || item.file)

   return <Modal isOpen={open} className={css.modal} fade={fade} autoFocus={false}>
      <ModalHeader>
         Meeting agenda
      </ModalHeader>
      <ModalBody className={css.agendaEditor}>
         <label htmlFor='text'>Agenda notes</label>
         <textarea id='text' className={css.text} value={text} onChange={e => setText(e.currentTarget.value)} autoFocus />

         <div className={css.items}>
            {items.map(item => <div key={item.uniqueId} className={css.item} onClick={() => editItem(item)}>
               {showIcons && <div className={css.icon}>
                  {item.file ? <svg viewBox='0 0 32 32'>
                     <path d='M29.363,4.637c-3.514-3.516-9.213-3.516-12.727,0L2.046,19.054c-2.729,2.729-2.729,7.171,0,9.9  c2.729,2.729,7.17,2.729,9.898,0l14.59-14.418c1.953-1.953,1.953-5.118,0-7.071c-1.953-1.952-5.119-1.952-7.07,0L8.496,18.433  c-0.391,0.392-0.391,1.023,0,1.415c0.391,0.391,1.023,0.391,1.414,0L20.879,8.879c1.17-1.169,3.072-1.169,4.242,0  c1.17,1.17,1.17,3.073,0,4.243l-14.59,14.417c-1.953,1.953-5.117,1.953-7.07,0c-1.953-1.952-1.953-5.118,0-7.07L17.908,6.192  c2.734-2.734,7.168-2.734,9.898,0c2.736,2.735,2.736,7.165,0.002,9.899L16.982,26.918c-0.393,0.392-0.393,1.023,0,1.414  c0.391,0.392,1.023,0.392,1.414,0l10.967-10.968C32.879,13.85,32.879,8.151,29.363,4.637z' />
                  </svg> : item.webLink ? <svg viewBox='0 0 96 96'>
                     <path d='M84.523,44.968L73.21,56.281c-0.644,0.645-1.308,1.238-2.002,1.789c-2.644,2.121-5.635,3.586-8.768,4.412  c-2.843,0.777-5.812,1.004-8.732,0.688c-2.716-0.283-5.402-1.033-7.913-2.256c-2.362-1.133-4.575-2.682-6.526-4.633  c-1.951-1.951-3.5-4.164-4.632-6.526c3.126-2.22,7.496-1.923,10.289,0.87c2.871,2.871,6.562,4.426,10.317,4.66  c2.68,0.162,5.409-0.348,7.885-1.535c1.604-0.756,3.104-1.803,4.426-3.125l11.313-11.314c6.251-6.25,6.294-16.334,0.043-22.585  c-6.244-6.244-16.419-6.293-22.67-0.042l-8.697,8.697c-3.289-0.884-6.683-1.223-10.062-1.039c0.552-0.693,1.146-1.358,1.789-2.001  l11.313-11.313c9.369-9.369,24.572-9.369,33.941,0S93.893,35.599,84.523,44.968z M56.239,73.252L44.925,84.566c-9.369,9.369-24.48,9.461-33.856,0.084C1.7,75.281,1.615,59.994,10.984,50.625l11.313-11.314  c0.644-0.644,1.308-1.237,2.001-1.789c2.645-2.122,5.636-3.585,8.769-4.413c2.842-0.778,5.812-1.004,8.733-0.686  c2.715,0.283,5.402,1.033,7.913,2.255c2.361,1.132,4.575,2.681,6.526,4.632c1.951,1.952,3.5,4.165,4.631,6.526  c-3.125,2.221-7.494,1.924-10.288-0.87c-2.871-2.871-6.562-4.426-10.316-4.66c-2.68-0.163-5.409,0.347-7.884,1.535  c-1.605,0.756-3.104,1.803-4.426,3.125L16.641,56.281c-6.25,6.252-6.25,16.377,0,22.627c6.251,6.252,16.377,6.252,22.627,0  l8.697-8.697c3.281,0.877,6.689,1.217,10.062,1.041C57.477,71.945,56.883,72.609,56.239,73.252z' />
                  </svg> : null}
               </div>}
               <div className={css.subject}>{item.subject}</div>
               <div className={css.time}>{(item.scheduledTime / 60).toFixed()}m</div>
            </div>)}
         </div>
         <div className={css.total}>
            <Button className={css.add} onClick={() => editItem(null)}>
               + Add item...
            </Button>
            Allocated <span className={classNames(totalTime > duration * 60 && css.error)}>{totalTime.toFixed()}m</span> out of <span>{duration * 60}m</span>
         </div>

         <label className={css.editScope}>
            <Toggle value={attCanEdit} onChange={setAttCanEdit} /> Attendees can edit agenda
         </label>

         <div className={css.buttons}>
            <Button color='accent' className={css.save} onClick={onSave}>Save</Button>
            <Button className={css.cancel} onClick={onCloseAgendaEditor}>Cancel</Button>
         </div>
      </ModalBody>
   </Modal>
}

type ItemEditorProps = {
   item: AgendaItemWithAttachment
   onSave(item: AgendaItemWithAttachment): void
   onDelete(id: string): void
   onCancel(): void
}

function ItemEditor({ item, onSave, onDelete, onCancel }: ItemEditorProps) {
   const [value, setValue] = useState(item)

   function update(item: Partial<AgendaItemWithAttachment>) {
      setValue(x => ({ ...x, ...item }))
   }

   const { getRootProps, getInputProps } = useDropzone({
      onDrop(files: File[]) {
         update({ file: files[0] || null })
      },
   })

   function onSubmit(e: React.FormEvent) {
      e.preventDefault()
      onSave(value)
   }

   function setTime(min: number) {
      update({ scheduledTime: min * 60 })
   }

   const existing = Boolean(item.uniqueId)
   return <Modal className={css.modal} isOpen={true} fade={false} autoFocus={false}>
      <ModalHeader>
         {existing ? 'Edit agenda item' : 'Add agenda item'}
      </ModalHeader>
      <ModalBody tag='form' className={css.itemEditor} onSubmit={onSubmit}>
         <section>
            <label className={css.subject}>
               Subject
               <input required autoFocus value={value.subject} onChange={e => update({ subject: e.currentTarget.value })} />
            </label>
         </section>
         <section className={css.time}>
            <label>
               Duration (min)
               <input required type='number' value={value.scheduledTime / 60} min={0} onChange={e => setTime(parseFloat(e.currentTarget.value))} />
            </label>
            {Durations.map(dur => <Button type='button' className={css.button} key={dur} color={dur === value.scheduledTime / 60 ? 'accent' : undefined} onClick={() => setTime(dur)}>{dur}</Button>)}
         </section>
         <section className={classNames(value.file && css.disabled)}>
            <label className={css.link}>
               Link
               <input pattern='http://.*|https://.*' placeholder='https://...' disabled={Boolean(value.file)}
                  value={value.file ? 'Link cannot be specified together with attachment' : value.webLink}
                  onChange={e => update({ webLink: e.currentTarget.value })} />
            </label>
         </section>

         <div className={css.or}>Or</div>

         {value.file ? <div className={css.drop}>
            <div className={css.file}>
               <span aria-hidden className='far fa-file' /> {value.file.name}
            </div>
            <div className={css.size}>
               {filesize(value.file.size)}
            </div>
            <Button type='button' color='red' onClick={() => update({ file: null })}>
               Delete attachment
            </Button>
         </div> : <div className={css.drop} {...getRootProps()}>
            <input {...getInputProps()} />
            <Button color='accent' type='button'>
               <FontAwesome name='cloud-upload-alt' />
               Select and upload
            </Button>
            <p>or</p>
            <p>Drag and drop attachment here</p>
         </div>}

         <div className={css.buttons}>
            <Button color='accent'>{existing ? 'Save' : 'Add'}</Button>
            <Button type='button' onClick={onCancel}>Cancel</Button>
            {existing && <Button type='button' color='red' className={css.delete} onClick={() => onDelete(item.uniqueId)}>Delete</Button>}
         </div>
      </ModalBody>
   </Modal>
}
