import React from 'react';
import { MeetingType } from '../../../model';
import { ReactComponent as GoogleIcon } from './google.svg';
import { ReactComponent as JitsiIcon } from './jitsi.svg';
import { ReactComponent as SkypeIcon } from './skype.svg';
import { ReactComponent as TeamsIcon } from './teams.svg';
import { ReactComponent as WebExIcon } from './webex.svg';
import { ReactComponent as ZoomIcon } from './zoom.svg';

export { GoogleIcon, JitsiIcon, SkypeIcon, TeamsIcon, WebExIcon, ZoomIcon };

type SvgProps = React.SVGProps<SVGSVGElement> & { title?: string }

export function MeetingTypeIcon({ type, ...rest }: { type: MeetingType } & SvgProps) {
   return React.createElement(getMeetingTypeIcon(type), rest)
}

function getMeetingTypeIcon(type: MeetingType) {
   switch (type) {
      case 'GoogleMeet': return GoogleIcon
      case 'Jitsi': return JitsiIcon
      case 'SkypeForBusiness': return SkypeIcon
      case 'Teams': return TeamsIcon
      case 'WebEx': return WebExIcon
      case 'Zoom': return ZoomIcon
   }

   const _exhaustive: never = type
}

export function getMeetingTypeTitle(type: MeetingType) {
   switch (type) {
      case 'GoogleMeet': return 'Google'
      case 'Jitsi': return 'IPFX'
      case 'SkypeForBusiness': return 'Skype'
      case 'Teams': return 'MS Teams'
      case 'WebEx': return 'WebEx'
      case 'Zoom': return 'Zoom'
   }

   const _exhaustive: never = type
}
