import moment from 'moment';
import { createSelector } from 'reselect';
import { getType } from 'typesafe-actions';
import { MeetingData } from '../../api';
import { getDayStart } from '../../utils/dateUtils';
import { Actions, actions, StoreState } from '../utils';

interface State {
   [email: string]: undefined | Calendar
}

interface Calendar {
   [day: number]: undefined | false | string[]
}

function addData(state: Calendar = {}, data: Record<string, MeetingData[]>): Calendar {
   const newState: Calendar = {}
   for (const dayStr in data) {
      const day = parseInt(dayStr)
      newState[day] = data[dayStr].map(m => m.id)
   }

   return {
      ...state,
      ...newState,
   }
}

function calendar(state: Calendar = {}, action: Actions): Calendar {
   switch (action.type) {
      case getType(actions.fetchCalendarsRequest): {
         const newState: Calendar = {}
         const { start, end } = action.payload

         for (let i = start; i < end; i = moment(i).add(1, 'd').valueOf()) {
            newState[i] = state[i] || false
         }

         return { ...state, ...newState }
      }

      case getType(actions.removeCalendarDaysBut): {
         const { day } = action.payload
         const data = state[day]
         if (data === undefined) return {}
         return { [day]: data }
      }

      case getType(actions.addMeetingData): {
         const { meeting } = action.payload

         const day = getDayStart(meeting.startTime)
         const cal = state[day]

         if (cal && cal.includes(meeting.id)) { return state }
         return {
            ...state,
            [day]: [...cal || [], meeting.id],
         }
      }

      case getType(actions.replaceMeetingData): {
         const { oldId, meeting } = action.payload
         const day = getDayStart(meeting.startTime)

         const cal = state[day] || []

         return {
            ...state,
            [day]: [...cal.filter(x => x !== oldId), meeting.id]
         }
      }

      case getType(actions.removeMeeting): {
         const id = action.payload
         const newState: Calendar = {}

         for (const day in state) {
            const cal = state[day]
            if (!cal || !cal.includes(id)) { continue }
            newState[day] = cal.filter(x => x !== id)
         }

         return Object.keys(newState).length ? { ...state, ...newState } : state
      }
   }

   return state
}

export default function calendars(state: State = {}, action: Actions): State {
   switch (action.type) {
      case getType(actions.fetchCalendarsRequest): {
         const newState: State = {}
         const { email } = action.payload
         newState[email] = calendar(state[email], action)
         return { ...state, ...newState }
      }

      case getType(actions.fetchCalendarsResponse): {
         const newState: State = {}
         newState[action.payload.email] = addData(state[action.payload.email], action.payload.schedules)
         return { ...state, ...newState }
      }

      case getType(actions.removeCalendars): {
         state = { ...state }
         for (const email of action.payload) {
            delete state[email]
         }
         return state
      }

      case getType(actions.removeCalendarDaysBut): {
         const { email } = action.payload
         const cal = state[email]
         if (!cal) { return state }
         return {
            [email]: calendar(cal, action),
         }
      }

      case getType(actions.addMeetingData): {
         const { email } = action.payload
         return {
            ...state,
            [email]: calendar(state[email], action)
         }
      }

      case getType(actions.replaceMeetingData): {
         const { meeting } = action.payload
         const email = meeting.calendarContext
         return {
            ...state,
            [email]: calendar(state[email], action)
         }
      }

      case getType(actions.removeMeeting): {
         state = { ...state }
         for (const email in state) {
            state[email] = calendar(state[email], action)
         }
         return state
      }
   }

   return state
}

function getRoot(state: StoreState) { return state.calendars }

export function selectCalendar(state: StoreState, email: string) {
   return getRoot(state)[email]
}

export function selectCalendarDay(state: StoreState, email: string, day: number) {
   const cal = selectCalendar(state, email)
   return cal && cal[day]
}

export const selectCalendarLoadedDays = createSelector(
   selectCalendar,
   (cal) => {
      if (!cal) return []
      return Object.keys(cal).map(d => parseInt(d)).filter(d => cal[d] !== undefined).sort()
   }
)

export function shouldLoadCalendar(state: StoreState, email: string, day: number) {
   const cal = selectCalendarDay(state, email, day)
   return cal === undefined
}
