import classNames from 'classnames';
import moment from 'moment';
import React, { useLayoutEffect, useState } from 'react';
import { useSelector } from '../store/utils';
import { timeOfDayInHours } from '../utils/dateUtils';
import { HasChildren } from '../utils/misc';
import { useRems } from '../utils/remUtil';
import ScrollParentProvider from './ScrollParentProvider';
import css from './TimeGrid.module.scss';

export const cellHeight = parseFloat(css.cellHeight);
export const cellWidth = parseFloat(css.cellWidth);
export const headerHeight = parseFloat(css.headerHeight);
export const scrollMargin = 10;

export function hrToRem(hr: number) {
   return cellHeight * hr + 'rem';
}

interface TGProps extends HasChildren {
   className?: string
   headerClassName?: string
   noHeaders?: boolean
   corner?: React.ReactNode
   showCurrentTime: boolean
   autoScroll?: boolean
}

function scrollToTime(toTime: number, element: HTMLElement, pxPerRem: number) {
   element.scrollTop = timeOfDayInHours(toTime) * parseFloat(css.cellHeight) * pxPerRem - scrollMargin
}

export function TimeGrid({ className, headerClassName, noHeaders, corner, children, showCurrentTime, autoScroll }: TGProps) {
   const [scrollParent, setScrollParent] = useState<HTMLElement | null>(null);
   const { pxPerRem } = useRems();
   const time = useSelector(s => s.time)

   useLayoutEffect(() => {
      if (!scrollParent) { return }
      scrollToTime(Date.now(), scrollParent, pxPerRem)
   }, [pxPerRem, scrollParent]);

   useLayoutEffect(() => {
      if (!autoScroll || !scrollParent) { return }
      scrollToTime(time, scrollParent, pxPerRem)
   }, [time, pxPerRem, scrollParent, autoScroll]);

   return <div className={classNames(css.calendarContainer, noHeaders && css.noHeaders, className)}>
      <header className={classNames(css.cornerHeader, headerClassName)}>{corner}</header>
      <div ref={setScrollParent} className={css.scrollContainer}>
         <ScrollParentProvider value={scrollParent}>
            <div className={css.calendar}>
               <div className={classNames(css.column, css.times)}>
                  {Array.from(Array(24)).map((_, h) => <div key={h} className={css.timeCell}>
                     <div>{moment.utc(0).add(h, 'h').format('h A')}</div>
                  </div>)}
                  {showCurrentTime && <CurrentTime time={time} className={css.currentTimeKnob} />}
                  {showCurrentTime && <CurrentTime time={time} className={css.currentTime}>
                     {moment(time).format('LT')}
                  </CurrentTime>}
               </div>
               {children}
               {/* empty column to occupy the remaining space, if any */}
               {!noHeaders && <div className={classNames(css.column, css.filler)}><header className={headerClassName} /></div>}
               {showCurrentTime && <CurrentTime time={time} className={css.currentTimeLine} />}
            </div>
         </ScrollParentProvider>
      </div>
   </div>;
}

interface TGCProps extends HasChildren {
   className?: string
   headerClassName?: string
   header?: React.ReactNode
   single?: boolean
   loading?: boolean
   onHeaderClick?(e: React.MouseEvent): void
   onClick?(hr: number): void
}

export function TimeGridColumn({ className, headerClassName, single, loading, header, onHeaderClick, onClick, children }: TGCProps) {
   const { pxPerRem } = useRems();

   return <div className={classNames(css.column, single && css.single, loading && css.loading, className)}>
      <header className={headerClassName} onClick={onHeaderClick}>{header}</header>
      <div className={classNames(css.items)} onClick={onClick && (e => onClick(e.nativeEvent.offsetY / cellHeight / pxPerRem))}>
         {children}
      </div>
   </div>
}

interface TGIProps extends HasChildren {
   className?: string
   start: number
   finish: number
   onClick?(e: React.MouseEvent): void
}

export function TimeGridItem({ className, start, finish, children, onClick }: TGIProps) {
   return <div onClick={onClick} tabIndex={onClick ? 0 : undefined} role={onClick ? 'button' : undefined} className={classNames(css.item, className)}
      style={{
         top: hrToRem(timeOfDayInHours(start)),
         height: hrToRem(moment(finish).diff(start, 'h', true))
      }}>
      {children}
   </div>
}

interface CurrentTimeProps extends HasChildren {
   time: number;
   className?: string;
}

function CurrentTime({ time, className, children }: CurrentTimeProps) {
   return <div className={className} style={{ top: hrToRem(timeOfDayInHours(time)) }}>
      {children}
   </div>
}
