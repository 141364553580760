import classNames from 'classnames';
import moment from 'moment';
import React from 'react';
import { Extend } from '../../utils/misc';
import css from './NativePickers.module.scss';

type NativeTimePicker = Extend<React.LabelHTMLAttributes<HTMLLabelElement>, {
   value: number | null
   minValue?: number
   onChange: (value: number | null) => void
}>

export function NativeTimePicker({ className, value, minValue, onChange, children, ...props }: NativeTimePicker) {
   function handleChange(e: React.ChangeEvent<HTMLInputElement>) {
      const ms = e.currentTarget.valueAsNumber
      onChange(Number.isNaN(ms) ? null : ms / (1000 * 60 * 60))
   }

   function format(v: number) {
      return moment().startOf('d').add(v, 'h').format('HH:mm')
   }

   return <label className={classNames(css.nativePicker, className)} {...props} onClick={e => e.stopPropagation()}>
      <input type='time' min={minValue === undefined ? undefined : format(minValue)}
         value={value === null ? '' : format(value)} onChange={handleChange} />
      {children}
   </label>
}

type NativeDatePicker = Extend<React.LabelHTMLAttributes<HTMLLabelElement>, {
   value: number | null
   minValue?: number
   onChange: (value: number | null) => void
}>

export function NativeDatePicker({ className, value, minValue, onChange, children, ...props }: NativeDatePicker) {
   function handleChange(e: React.ChangeEvent<HTMLInputElement>) {
      const value = e.currentTarget.valueAsNumber
      onChange(Number.isNaN(value) ? null : value)
   }

   return <label className={classNames(css.nativePicker, className)} {...props} onClick={e => e.stopPropagation()}>
      <input type='date' min={minValue && moment(minValue).format('YYYY-MM-DD')}
         value={value === null ? '' : moment(value).format('YYYY-MM-DD')} onChange={handleChange} />
      {children}
   </label>
}
