import { useEffect } from 'react';
import FontAwesome from 'react-fontawesome';
import { useDispatch } from 'react-redux';
import { Modal, ModalBody } from 'reactstrap';
import { Room } from '../model';
import { selectRooms } from '../store/helpers';
import { actions, useSelector } from '../store/utils';
import { isDefined } from '../utils/misc';
import css from './AddResourcePopup.module.scss';
import { BookingResourceEditor } from './BookingFilter';
import BrandedModalHeader from './BrandedModalHeader';
import Button from './Button';
import ContactPhoto from './ContactPhoto';
import Spinner from './Spinner';
import { useSuggestions } from './Suggestions';

export default function AddResourcePopup() {
   const dispatch = useDispatch()
   const popup = useSelector(s => s.popup)

   function onCancel() {
      if (!popup.isSubmitting) {
         dispatch(actions.closePopup())
      }
   }

   return <Modal isOpen={popup.type === 'addResource'} toggle={onCancel} autoFocus={false}>
      <BrandedModalHeader toggle={onCancel}>Add resource</BrandedModalHeader>
      <AddResourceBody />
   </Modal>
}

function AddResourceBody() {
   const dispatch = useDispatch()
   const booking = useSelector(s => s.booking)
   const popup = useSelector(s => s.popup)
   const meeting = useSelector(s => s.booking?.meetingId && s.meetings[s.booking.meetingId] || null)
   const rooms = useSelector(selectRooms)
   const suggestions = useSuggestions()

   useEffect(() => {
      return () => {
         dispatch(actions.endBooking())
      }
   }, [dispatch])

   function onUpdateMeeting() {
      dispatch(actions.addResourcesToMeeting())
   }

   if (!booking) { return null }

   return <ModalBody className={css.body}>
      <section>
         <h2>Existing resources</h2>
         <ul className={css.resources}>
            {meeting?.participants
               .map(x => rooms.find(r => r.emailAddress === x.emailAddress))
               .filter(isDefined)
               .map(x => <li key={x.emailAddress}>
                  {renderRoom(x)}
               </li>)}
         </ul>
      </section>

      <BookingResourceEditor title='Additional resources' canEdit />

      {meeting?.isExternal && <section className={css.warning}>
         <FontAwesome name='exclamation-triangle' />
         This meeting was booked by an extenral party. <br />
         The additional resources will only be available to you.
      </section>}

      <div className={css.buttons}>
         <Button color='accent' className={css.updateMeeting} disabled={popup.isSubmitting || !suggestions.currentValid || suggestions.loading || !booking.resources.length} onClick={onUpdateMeeting}>
            {popup.isSubmitting ? <Spinner className={css.spinner} /> : null}
            Update booking
         </Button>
         <Button onClick={() => dispatch(actions.closePopup())}>Cancel</Button>
      </div>

      {popup.error && <div className={css.error}>
         {popup.error}
      </div>}
   </ModalBody>
}

function renderRoom(room: Room) {
   return <div className={css.room}>
      <ContactPhoto contact={room} className={css.roomIcon} />
      {room.name || room.emailAddress}
   </div>
}
