import React from 'react';
import { ModalHeader } from 'reactstrap';
import css from './BrandedModalHeader.module.scss';
import { ReactComponent as CloseButton } from './buttons/close.svg';
import Logo from './Logo';

const BrandedModalHeader: React.FC<{ toggle?(): void }> = ({ children, toggle }) => {
   return <ModalHeader className={css.header}>
      {children || <Logo className={css.logo} mono />}
      {toggle && <button className={css.closeButton} onClick={toggle}>
         <CloseButton />
      </button>}
   </ModalHeader>
}

export default BrandedModalHeader
