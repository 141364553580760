import moment from 'moment';
import React, { useCallback, useState } from 'react';
import { useDispatch } from 'react-redux';
import { useParams } from 'react-router';
import { Login, MeetingData, notifyOrganiser, tapVisitorTag, VisitorTapResult, VisitorType, whoAmI } from '../../api';
import { actions } from '../../store/utils';
import { listOf } from '../../utils/react';
import Button from '../Button';
import { ToastHost } from '../Toast';
import { Header } from './Header';
import OtpScreen from './OtpScreen';
import Page from './Page';
import css from './Visit.module.scss';

interface MeetingInfo {
   meetings: MeetingData[]
   notified: boolean
}

export default function Visit() {
   const dispatch = useDispatch()
   const params = useParams<{ id?: string }>()
   const [meetings, setMeetings] = useState<MeetingInfo[]>()
   const [visitorType, setVisitorType] = useState<VisitorType>()

   const api = useCallback(async () => {
      if (!params.id) {
         return (await whoAmI()) || null
      }

      return tapVisitorTag(params.id)
   }, [params.id])

   function openBookit(replace?: boolean) {
      const method = replace ? 'replace' : 'assign'
      window.location[method]('/')
   }

   function onSuccess(result: Login | VisitorTapResult) {
      if ('myself' in result) {
         openBookit(true)
         return
      } else {
         setVisitorType(result.visitorType)

         const meetings = new Map<string, MeetingData[]>()
         for (const meeting of result.meetings) {
            let arr = meetings.get(meeting.iCalUid)
            if (!arr) {
               arr = []
               meetings.set(meeting.iCalUid, arr)
            }

            arr.push(meeting)
         }

         const sorted = Array.from(meetings.values())

         // sort meetings inside each group
         sorted.forEach(ms => ms.sort(cmpMeetings))
         // sort groups
         sorted.sort((ms1, ms2) => cmpMeetings(ms1[0], ms2[0]))

         setMeetings(sorted.map(m => ({ meetings: m, notified: false })))
      }
   }

   async function notify(info: MeetingInfo) {
      try {
         await notifyOrganiser(params.id!, info.meetings[0].id)
         setMeetings(ms => ms?.map(mi => mi === info ? { ...mi, notified: true } : mi))
      } catch (err) {
         dispatch(actions.apiError(err))
      }
   }

   return <Page className={css.page}>
      <Header>Welcome to Book-it</Header>
      <Page.Body>
         {!meetings ? <OtpScreen onSuccess={onSuccess} api={api} tag={params.id}>
            <h2>Please validate your email to proceed</h2>
         </OtpScreen> : <>
            {meetings.length ? <h2>You are invited to the following meeting{meetings.length === 1 ? '' : 's'} today</h2> : <h2>You don't have any meetings today</h2>}
            {meetings.map((mi, i) => <div key={i} className={css.meetings}>
               <div className={css.title}>{mi.meetings[0].subject}</div>
               {mi.meetings[0].organiser && <div className={css.organiser}>{mi.meetings[0].organiser.name}</div>}
               <ul>
                  {mi.meetings.map(m => <li key={m.id}>
                     {listOf([moment(m.startTime).format('LT'), getRoom(m)].filter(x => x), ', ')}
                  </li>)}
               </ul>
               <Button disabled={mi.notified} className={css.notify} onClick={() => notify(mi)}>
                  {mi.notified ? 'Organiser notified' : 'Notify organiser'}
               </Button>
            </div>)}
            {(visitorType === 'OtherOrg' || visitorType === 'SameOrg') &&
               <Button color='accent' className={css.button} onClick={() => openBookit()}>Open Book-it</Button>}
         </>}
      </Page.Body>
      <ToastHost />
   </Page>
}

function getRoom(m: MeetingData) {
   const room = m.meetingRooms.find(r => r.emailAddress === m.calendarContext)
   return room ? room.name : (m.meetingRooms[0]?.name)
}

function cmpMeetings(m1: MeetingData, m2: MeetingData) {
   return m1.startTime - m2.startTime
}
