import classNames from 'classnames';
import { isMobile } from 'is-mobile';
import moment from 'moment';
import React, { useCallback, useEffect, useMemo, useState } from 'react';
import FontAwesome from 'react-fontawesome';
import { useDispatch } from 'react-redux';
import { Modal, ModalBody } from 'reactstrap';
import { Agenda, Attachment, AttendeeType, isRoom, Page, PropertyType, TeamToJoin, TeamWithMembers } from '../model';
import { getIsEditing, getSecondaryUser, isSingleUser, isExternalUser, selectBookingAttendees, selectDefaultResourceFactory, getMyself, isMyselfOrganiser } from '../store/helpers';
import { mustCreateAppointment } from '../store/reducers/booking';
import { actions, useSelector } from '../store/utils';
import { timeOfDayInHours } from '../utils/dateUtils';
import { Extend, isDefined } from '../utils/misc';
import { orList } from '../utils/react';
import AccentButton from './AccentButton';
import AgendaEditor from './AgendaEditor';
import css from './BookingFilter.module.scss';
import BrandedModalHeader from './BrandedModalHeader';
import Button from './Button';
import { ReactComponent as EditButtonIcon } from './buttons/edit.svg';
import { ReactComponent as TeamsIcon } from './buttons/teams.svg';
import Combobox from './Combobox';
import DatePicker from './DatePicker';
import FormField from './FormField';
import { ClockIcon, EditIcon, OnlineMeetingTypeIcon, VideoFxIcon, AttendeesIcon } from './icons';
import { MeetingAndResourceSelector } from './mobile/MeetingTypeSelector';
import { NativeTimePicker } from './mobile/NativePickers';
import Toggle from './mobile/Toggle';
import ParticipantEditor from './ParticipantEditor';
import RecurrenceEditor from './RecurrenceEditor';
import PermissionCombo from './PermissionEditor';
import ResourceFilterDialog from './ResourceFilterDialog';
import { ResourceTypeIcon } from './ResourceIcon';
import { getLocationNames, getResourceFilterSummary } from './RoomFilter';
import Suggestions, { useSuggestions } from './Suggestions';
import TeamsDropdown from './TeamsDropdown';
import AdhocPanel from './AdhocPanel';
import useAdhoc from '../utils/useAdhoc';

export default function BookingFilter() {
   const booking = useSelector(s => s.booking)
   const isEditing = useSelector(getIsEditing)
   const date = useSelector(s => s.selectedDate)
   const singleUser = useSelector(isSingleUser)
   const teamsMode = useSelector(s => s.teams.teamsMode)
   const dispatch = useDispatch()
   const suggestions = useSuggestions()
   const externalUser = useSelector(isExternalUser)

   const [showConfirmation, setShowConfirmation] = useState(false)
   const [title, setTitle] = useState('')
   const [agendaShown, showAgenda] = useState(false)

   const isOrganiser = useSelector(isMyselfOrganiser)

   const allowAttendeeEditing = useSelector(s => s.config.allowAttendeeEditing
      && (isOrganiser || s.booking?.meetingId === undefined)
      && booking)

   const { disableEdit, disableEditCollection, permissions } = usePermissions()

   const adhoc = useAdhoc()
   console.log("Adhoc: ", adhoc)

   const bookingSubject = booking?.subject
   useEffect(() => {
      setTitle(bookingSubject || '')
   }, [bookingSubject])

   function setEndDate(endDate: number) {
      if (!booking) { return }
      const newEnd = moment(endDate).add(timeOfDayInHours(endDateTime), 'h').add(booking.allDay ? 1 : 0, 'd')
      dispatch(actions.setBookingDuration(moment(newEnd).diff(startDateTime, 'h', true)))
   }

   function setEndTime(hr: number | null) {
      const newEnd = moment(endDateTime).startOf('d').add(hr || 0, 'h')
      dispatch(actions.setBookingDuration(moment(newEnd).diff(startDateTime, 'h', true)))
   }

   function onGoToMyCalendar() {
      dispatch(actions.endBooking())
      dispatch(actions.goToPage(Page.calendar))
   }

   function applyChanges() {
      dispatch(actions.setBookingSubject(title))
   }

   function onSearchMore() {
      applyChanges()
      dispatch(actions.goToPage(Page.searchMore))
   }

   function onCreateBooking() {
      if (!booking) { return }
      applyChanges()

      if (booking.full) {
         dispatch(actions.createMeeting())
      } else if (adhoc && adhoc.selectedRoom) {
         dispatch(actions.createAdhocMeeting(adhoc.selectedRoom.emailAddress, booking.duration, booking.adhocOrganisation?.organisationId ?? null))
      }
   }

   function onCancel() {
      if (singleUser) {
         onGoToMyCalendar()
      } else {
         if (booking && !booking.full) {
            dispatch(actions.secondaryLogout())
         } else {
            setShowConfirmation(true)
         }
      }
   }

   function onSetAgenda(body: Agenda, files: Attachment[], attendeesCanEdit: boolean) {
      dispatch(actions.setBookingAgenda(body))
      dispatch(actions.setAttachments(files))
   }

   if (!booking) { return null }

   const startDateTime = moment(date).add(booking.start, 'h').valueOf()
   const endDateTime = moment(startDateTime).add(booking.duration, 'h').valueOf()

   const showCreateToggle = !mustCreateAppointment(booking);
   const durations = !booking.full && adhoc ? adhoc.durations : []

   return <div className={classNames(css.bookingFilter, teamsMode && css.teamsMode)}>
      <header>
         <Button color={teamsMode ? undefined : 'none'} onClick={() => onCancel()}>Cancel</Button>
         <span>{isEditing ? 'Edit booking' : 'New booking'}</span>
      </header>
      <div>
         {!booking.full && adhoc && <AdhocPanel durations={durations} room={adhoc.selectedRoom} />}
         {booking.full && <>
            <FormField icon={<OnlineMeetingTypeIcon />}>
               <MeetingAndResourceSelector disableConferenceType={disableEdit('ConferenceType')} disableResources={disableEdit('Resources')} />
            </FormField>
            <FormField icon={<ClockIcon />}>
               <div className={css.dates}>
                  <DatePicker value={date} onChange={d => dispatch(actions.selectDate(d))} minDate={moment().startOf('d').valueOf()} disabled={disableEdit('Date')}/>
                  {!booking.allDay && <TimePicker data-testid='booking-start-time' value={booking.start} onChange={hr => dispatch(actions.setBookingStart(hr))} disabled={disableEdit('Time')} />}

                  <svg viewBox='-6 -6 32 32'>
                     <path d='M12.846 5.473l.058-.07a.5.5 0 01.638-.057l.069.058 4.271 4.274.03.037.041.074.023.058.013.048L18 10l-.008.089-.023.083-.023.055-.025.043-.037.05-.03.034-4.243 4.242a.5.5 0 01-.765-.638l.058-.069 3.388-3.39L2.5 10.5a.5.5 0 01-.492-.41L2 10a.5.5 0 01.41-.492L2.5 9.5h13.792l-3.388-3.39a.5.5 0 01-.058-.637l.058-.07-.058.07z' />
                  </svg>

                  <DatePicker value={moment(endDateTime).startOf('d').add(booking.allDay ? -1 : 0, 'd').valueOf()} onChange={setEndDate} minDate={date} disabled={disableEdit('Date')} />
                  {!booking.allDay && <TimePicker
                     data-testid='booking-end-time'
                     durationSince={moment(startDateTime).diff(moment(endDateTime).startOf('d'), 'h', true)}
                     value={timeOfDayInHours(endDateTime)}
                     onChange={setEndTime}
                     disabled={disableEdit('Time')}
                  />}

                  <span className={css.duration}>{formatDuration(booking.duration)}</span>

                  <label className={css.toggle} onClick={e => e.stopPropagation()}>
                     <Toggle value={booking.allDay} onChange={v => dispatch(actions.setAllDay(v))} disabled={disableEdit('AllDay')} />
                     All day
                  </label>
               </div>
            </FormField>

            <FormField icon={<FontAwesome name='sync-alt' />}>
               <RecurrenceEditor disabled={disableEdit('Recurrence')}/>
            </FormField>

            <FormField icon={showCreateToggle ? <span aria-hidden className='far fa-calendar' /> : <FontAwesome name='lock' />}>
               {showCreateToggle && <label className={css.toggle} onClick={e => e.stopPropagation()}>
                  <Toggle value={booking.createAppointment} onChange={v => dispatch(actions.setCreateAppointment(v))} />
                  Create calendar appointment
               </label>}
               {booking.createAppointment && <label className={css.toggle} onClick={e => e.stopPropagation()}>
                  <Toggle value={booking.isPrivate} onChange={v => dispatch(actions.setMeetingPrivate(v))} disabled={disableEdit('Private')} />
                  Mark as private
               </label>}
            </FormField>

            {allowAttendeeEditing && <FormField icon={<FontAwesome name='lock' />}>
               <div className={css.permissions}>
                  <div className={css.permissionsLabel}>Attendee editing permission</div>
                  <PermissionCombo/>
               </div>
            </FormField>}

            {booking.createAppointment && <FormField icon={<EditIcon />}>
               <input className={classNames(css.input, css.title)} placeholder='Enter meeting title'
                  data-testid='meeting-title' value={title} onChange={e => setTitle(e.currentTarget.value)} disabled={disableEdit('Title')}/>

               <Button className={css.agenda} onClick={() => showAgenda(true)} disabled={disableEditCollection('Agenda')}>
                  <svg viewBox='0 0 16 16'>
                     <path fillRule='evenodd' d='M14.5 3h-13a.5.5 0 00-.5.5v9a.5.5 0 00.5.5h13a.5.5 0 00.5-.5v-9a.5.5 0 00-.5-.5zm-13-1A1.5 1.5 0 000 3.5v9A1.5 1.5 0 001.5 14h13a1.5 1.5 0 001.5-1.5v-9A1.5 1.5 0 0014.5 2h-13z' />
                     <path d='M7 5.5a.5.5 0 01.5-.5h5a.5.5 0 010 1h-5a.5.5 0 01-.5-.5zm-1.496-.854a.5.5 0 010 .708l-1.5 1.5a.5.5 0 01-.708 0l-.5-.5a.5.5 0 11.708-.708l.146.147 1.146-1.147a.5.5 0 01.708 0zM7 9.5a.5.5 0 01.5-.5h5a.5.5 0 010 1h-5a.5.5 0 01-.5-.5zm-1.496-.854a.5.5 0 010 .708l-1.5 1.5a.5.5 0 01-.708 0l-.5-.5a.5.5 0 01.708-.708l.146.147 1.146-1.147a.5.5 0 01.708 0z' />
                  </svg>
                  Agenda...
               </Button>
            </FormField>}

            <Attendees disabled={disableEditCollection('Attendees')}/>
            {!externalUser && <TeamsToJoin />}
         </>}
      </div>
      <div>
         {booking.full && <BookingResourceEditor title='Requested resources' canEdit={!disableEdit('Resources')} />}

         {!booking.full && adhoc && adhoc.selectedRoom && <section className={css.adhoc}>
            <h2>Adhoc meeting</h2>
            <div>
               Room: <span>{adhoc.selectedRoom.name}</span>
            </div>
            {!adhoc.isDefaultRoom && <span className={css.anotherRoom}>This choosen room was unavailable. A different room has been selected for you.</span>}
         </section>}

         {!disableEdit('Time') && <Suggestions className={css.suggestions} />}

         <section className={css.buttons}>
            <Button color='green' disabled={booking.full
               ? !suggestions.currentValid || suggestions.loading || (!booking.createAppointment && booking.resources.length === 0)
               : durations.length === 0}
               onClick={onCreateBooking}>{isEditing ? 'Update' : 'Create'} booking</Button>
            {!booking.allDay && booking.full && <Button className={css.searchMore} onClick={onSearchMore}>
               <svg viewBox='0 0 83 83'><path d='M53 32c0,-6 -3,-11 -7,-15 -3,-4 -9,-6 -14,-6 -6,0 -11,2 -15,6 -4,4 -6,9 -6,15 0,5 2,11 6,14 4,4 9,7 15,7 5,0 11,-3 14,-7 4,-3 7,-9 7,-14zm4 18l26 25 -8 8 -25 -26c-5,4 -12,6 -18,6 -9,0 -17,-3 -23,-9 -5,-6 -9,-14 -9,-22 0,-9 4,-17 9,-23 6,-5 14,-9 23,-9 8,0 16,4 22,9 6,6 9,14 9,23 0,6 -2,13 -6,18z' /></svg>
               Search for more...
            </Button>}
         </section>
      </div>
      <Modal isOpen={showConfirmation} toggle={() => setShowConfirmation(false)}>
         <BrandedModalHeader />
         <ModalBody className={css.confirmation}>
            <div className={css.confirmationButtons}>
               <AccentButton onClick={onGoToMyCalendar}>Go to my calendar</AccentButton>
               <button className={css.logoutButton} onClick={() => dispatch(actions.secondaryLogout())}>Log out</button>
            </div>
         </ModalBody>
      </Modal>
      <AgendaEditor open={agendaShown} duration={booking.duration} value={booking.agenda}
         files={booking.attachments} attendeesCanEdit={disableEditCollection('Agenda')}
         onChange={onSetAgenda} onClose={() => showAgenda(false)} />
   </div>
}

function usePermissions() {
   const bookingPermissionsSet = useSelector(s => s.booking?.permissions)
   const myself = useSelector(getMyself)
   const isExternal = useSelector(isExternalUser)
   const permissionSets = useSelector(s => s.login.data && s.login.data?.organisation?.permissions)
   const isOrganiser = useSelector(isMyselfOrganiser)


   const permissions = useMemo(() => {
      if (bookingPermissionsSet) {
         for (const type of ['Specific', (isExternal ? 'External' : 'Organisation'), 'All']) {
            let perm = bookingPermissionsSet.permissions.find(p => p.user.type === type)
            if (perm && perm.user.type === 'Specific' && !perm.user.attendees.some(e => myself?.emailAddress.toLowerCase() === e)) {
               perm = undefined
            } 
            if (perm) return perm.properties
         }
      }
      return null
   }, [isExternal, myself?.emailAddress, bookingPermissionsSet])

   const all = useMemo(() => {
         return permissions !== null && permissions.some(p => p.property === 'All')
   }, [permissions])

   const disableEdit = useCallback((propertyType: PropertyType): boolean => {
      if (isOrganiser) return false
      if (all) return false
      if (!permissions) return true
      const canEdit = permissions.some(p => {
         return p.property === propertyType && p.access === 'Update'
      })
      return !canEdit
   }, [isOrganiser, all, permissions])


   return { disableEdit, disableEditCollection: disableEdit, permissions, permissionSets: (permissionSets ? permissionSets : null) }
}

export function useResourceList() {
   const booking = useSelector(s => s.booking)
   const contacts = useSelector(s => s.contacts)
   const loading = useSelector(s => s.suggestions.final.loading)

   if (!booking) { return null }
   const { resources, suggested } = booking

   return {
      loading,
      current: resources.map((filter, index) => {
         const resources = suggested?.selected[index]?.map(email => {
            const room = contacts[email]
            if (!isRoom(room)) { return undefined }

            return {
               room,
               unavailable: !suggested.available[index].includes(email),
            }
         }).filter(isDefined) ?? []

         return {
            filter,
            resources,
            incomplete: !suggested ? false : resources.length < filter.quantity,
         }
      }),
   }
}

interface AttendeesProps {
   disabled?: boolean
}
function Attendees({disabled}: AttendeesProps) {
   const dispatch = useDispatch();
   const [showOptional, setShowOptional] = useState(false)
   const participants = useSelector(selectBookingAttendees)
   const myself = useSelector(getSecondaryUser)
   const existing = useMemo(() => participants.map(p => p.contact.emailAddress), [participants])

   useEffect(() => {
      if (participants.some(p => p.type === AttendeeType.optional)) {
         setShowOptional(true)
      }
   }, [participants])

   function addParticipants(team: TeamWithMembers, type: AttendeeType) {
      for (const m of team.members) {
         dispatch(actions.addParticipant(m.emailAddress, type))
      }
   }

   function renderEditor(type: AttendeeType) {
      return <ParticipantEditor className={css.participantEditor}
         placeholder={disabled ? undefined : (type === AttendeeType.required ? 'Add required attendees' : 'Add optional attendees')}
         contacts={participants.filter(p => p.type === type).map(p => p.contact)}
         exclude={existing} canDelete={c => !disabled && c !== myself}
         onAdd={c => dispatch(actions.addParticipant(c.emailAddress, type))}
         onDel={c => dispatch(actions.removeParticipant(c))} 
         disabled={disabled}
         />
   }

   return <FormField icon={<AttendeesIcon />} iconClassName={css.attendeesIcon} bodyClassName={css.attendeesField}>
      <div className={css.attendeeRow}>
         <div className={css.attendees}>
            {renderEditor(AttendeeType.required)}
            {!showOptional && !disabled && <button className={css.optionalButton} onClick={() => setShowOptional(true)}>
               + Optional
            </button>}
         </div>
         <TeamsDropdown className={css.addTeamsMembers} onSelect={team => addParticipants(team, AttendeeType.required)} right>
            <span>+</span>
            <TeamsIcon />
         </TeamsDropdown>
      </div>
      {showOptional && <div className={css.attendeeRow}>
         <div className={css.attendees}>
            <span>Optional:</span>
            {renderEditor(AttendeeType.optional)}
         </div>
         <TeamsDropdown className={css.addTeamsMembers} onSelect={team => addParticipants(team, AttendeeType.optional)} right>
            <span>+</span>
            <TeamsIcon />
         </TeamsDropdown>
      </div>}
   </FormField>
}

function TeamsToJoin() {
   const dispatch = useDispatch()
   const teams = useSelector(s => s.booking?.teamsToJoin) || []

   function onAdd(team: TeamWithMembers) {
      dispatch(actions.addTeam({
         teamId: team.id,
         teamName: team.displayName,
      }))
   }

   function onDelete(team: TeamToJoin) {
      dispatch(actions.removeTeam(team))
   }

   return <FormField icon={<VideoFxIcon />} bodyClassName={css.teamsToJoin} iconClassName={css.vfxIcon}>
      {teams.map(t => <div key={t.teamId} className={css.teamToJoin} onClick={() => onDelete(t)}>
         {t.teamName}
         <button><FontAwesome name='times' /></button>
      </div>)}
      <TeamsDropdown onSelect={onAdd} className={css.addTeam}>
         + Include team in VideoFX meeting
      </TeamsDropdown>
   </FormField>
}

export function BookingResourceEditor({ title, canEdit }: { title: string, canEdit: boolean }) {
   const dispatch = useDispatch()
   const booking = useSelector(s => s.booking)
   const factory = useSelector(selectDefaultResourceFactory)
   const roomSizesFilter = useSelector(s => s.roomSizesFilter)
   const roomAttributes = useSelector(s => s.roomAttributes)
   const suggestions = useResourceList()

   if (!booking || !suggestions) { return null }
   const { loading, current } = suggestions

   return <>
      <section className={css.resourcesSection}>
         <h2>{title}</h2>

         <div className={css.resources}>
            {current.map((res, i) => {
               return <div key={i} className={css.resource} onClick={() => dispatch(actions.editResource(res.filter, res.filter))}>
                  <ResourceTypeIcon type={res.filter.type} className={css.icon} />
                  <div className={css.summary}>
                     {orList(getLocationNames(res.filter.location))}, {getResourceFilterSummary(res.filter, roomSizesFilter, roomAttributes)}
                  </div>
                  <div>
                     {loading ? <div className={css.loading}>Loading...</div>
                        : res.incomplete && res.resources.length === 0 ? <div className={css.unavailable}>Nothing is available</div>
                           : <ul>
                              {res.resources.map(({ room, unavailable }) => <li key={room.emailAddress} className={classNames({
                                 [css.invalid]: unavailable,
                              })}>
                                 {room.name || room.emailAddress} {room.roomType === 'MeetingRoom' && `(${room.seats} seat${room.seats === 1 ? '' : 's'})`}
                              </li>)}
                              {res.incomplete && <li className={css.unavailable}>Some resources are not available</li>}
                           </ul>}
                  </div>
                  {canEdit && <button>
                     <EditButtonIcon />
                  </button>}
               </div>
            })}
         </div>

         {canEdit && <Button className={css.add} disabled={factory() === null} onClick={() => dispatch(actions.editResource(null, factory()))}>+ Add...</Button>}
      </section>

      {booking.newResource && <ResourceFilterDialog resource={booking.newResource} />}
   </>
}

type TimePickerProps = Extend<React.InputHTMLAttributes<HTMLInputElement>, {
   value: number
   onChange: React.Dispatch<number>
   durationSince?: number
}>

function TimePicker({ value, onChange, durationSince, disabled, ...props }: TimePickerProps) {
   function parse(text: string) {
      if (!text.trim()) return undefined
      const time = moment(text, 'LT');
      return time.isValid() ? timeOfDayInHours(time) : undefined;
   }

   function format(value: number | undefined) {
      return value === undefined ? '' : moment.utc(0).add(Math.round(value * 60), 'm').format('LT')
   }

   function handleChange(newVal: number | undefined, text: string) {
      if (newVal === undefined) {
         newVal = parse(text)
      }

      if (newVal !== undefined) {
         onChange(newVal)
      }
   }

   function tryHightlight(text: string, item: number) {
      const value = parse(text)
      return value !== undefined && item >= value
   }

   function getRenderItem() {
      if (durationSince === undefined) {
         return undefined
      }

      return function renderItem(value: number) {
         return <>{format(value)} <span className={css.duration}>({formatDuration(value - durationSince)})</span></>
      }
   }

   if (isMobile({ tablet: true })) {
      const minValue = durationSince === undefined ? undefined
         : durationSince < 0 ? 0 : durationSince

      return <NativeTimePicker className={css.nativePicker} value={value} minValue={minValue} onChange={v => v !== null && onChange(v)}>
         <div className={classNames(css.input, css.timeInput)}>{format(value)}</div>
      </NativeTimePicker>
   }

   return <Combobox
      items={Array.from(new Array(48)).map((_, x) => x / 2).filter(hr => durationSince === undefined || hr > durationSince)}
      getItemText={format}
      highlightItem={tryHightlight}
      renderItem={getRenderItem()}
      disabled={disabled}
      value={value} onChange={handleChange}>
     
      {(ref, value, onChange) => <input
         ref={ref} className={classNames(css.input, css.timeInput)}
         value={value} onChange={e => onChange(e.currentTarget.value)} {...props}
         disabled={disabled}
          />}
   </Combobox>
}

function formatDuration(durInHr: number) {
   const d = Math.trunc(durInHr / 24)
   const h = Math.trunc(durInHr % 24)
   const m = (durInHr % 1) * 60
   return [d > 0 && `${d}d`, h > 0 && `${h}h`, m >= 1 && `${m.toFixed()}m`].filter(x => x).join(' ')
}
