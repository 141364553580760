import React, { useEffect, useRef, useState } from 'react';
import { useDispatch } from 'react-redux';
import { fetchMeeting } from '../../api';
import { actions, useSelector } from '../../store/utils';
import Spinner from '../Spinner';
import css from './LoadMeeting.module.scss';

interface Props {
   meetingId: string
   onMeetingIdChanged?: React.Dispatch<string>
}

export default function LoadMeeting({ meetingId, onMeetingIdChanged }: Props) {
   const dispatch = useDispatch()
   const exists = useSelector(s => s.meetings[meetingId] !== undefined)
   const [loading, setLoading] = useState(false)
   const [error, setError] = useState<string>()

   const onChangedRef = useRef(onMeetingIdChanged)
   useEffect(() => {
      onChangedRef.current = onMeetingIdChanged
   })

   useEffect(() => {
      if (exists) { return }
      let mounted = true
      load()
      return () => { mounted = false }

      async function load() {
         try {
            setLoading(true)
            const meetingData = await fetchMeeting(meetingId)
            if (!mounted) { return }
            dispatch(actions.replaceMeetingData(meetingData.id, meetingData))
            if (meetingId !== meetingData.id) {
               onChangedRef.current?.(meetingData.id)
            }
         } catch (e) {
            if (mounted) {
               setError((e as Error).message || 'Unknown error')
            }
         } finally {
            if (mounted) {
               setLoading(false)
            }
         }
      }
   }, [dispatch, exists, meetingId])

   if (exists) { return null }

   return <div className={css.loadMeeting}>
      {loading && <Spinner className={css.spinner} />}
      {error && <div className={css.error}>{error}</div>}
   </div>
}
