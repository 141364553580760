import React from 'react';
import { Modal, ModalBody } from 'reactstrap';
import BrandedModalHeader from './BrandedModalHeader';
import { useSelector, actions } from '../store/utils';
import { useDispatch } from 'react-redux';
import css from './StartConfirmationPopup.module.scss';
import AccentButton from './AccentButton';
import { isSingleUser } from '../store/helpers';

export default function StartConfirmationPopup() {
   const popup = useSelector(s => s.popup)
   const myself = useSelector(s => !isSingleUser(s) && s.login.data && s.login.data.myself)
   const dispatch = useDispatch()

   if (popup.type !== 'startMeeting') { return <Modal isOpen={false} /> }

   function close() {
      dispatch(actions.closePopup())
   }

   const forceStart = () => {
      if (!myself) { return }
      dispatch(actions.startMeeting(popup.meetingId, myself))
      close()
   }

   return <Modal isOpen toggle={close}>
      <BrandedModalHeader toggle={close}>Start meeting</BrandedModalHeader>
      <ModalBody>
         The previous meeting is currently running, would you like to close that meeting?
         <div className={css.dialogButtons}>
            <AccentButton onClick={forceStart}>Yes</AccentButton>
            <AccentButton onClick={close} className={css.cancelButton}>Cancel</AccentButton>
         </div>
      </ModalBody>
   </Modal>
}
