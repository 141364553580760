import * as PopperJS from '@popperjs/core';
import classNames from 'classnames';
import React, { useCallback, useEffect, useLayoutEffect, useMemo, useRef, useState } from 'react';
import FontAwesome from 'react-fontawesome';
import css from './Combobox.module.scss';
import Popover, { usePopover } from './Popover';

interface Props<T> {
   className?: string
   inputClassName?: string
   itemClassName?: string
   items: readonly T[] | ((text: string) => readonly T[]) | ((text: string) => Promise<readonly T[]>)
   renderItem?: (item: T, selected: boolean) => React.ReactNode
   children?: (ref: React.Ref<HTMLInputElement>, value: string, onChange: React.Dispatch<string>) => React.ReactNode
   getKey?: (item: T, index: number) => React.Key
   setWidth?: boolean
   caret?: boolean
   highlightItem?: (text: string, item: T) => boolean
   getItemText?: (item: T|undefined) => string
   value: T|undefined
   onChange: (item: T | undefined, text: string) => void
   disabled?: boolean
}

export default function Combobox<T>({
   className, inputClassName, itemClassName,
   items, renderItem, highlightItem, getItemText = String,
   value, onChange, children, disabled,
   getKey = (_, i) => i, setWidth = true, caret = true
}: Props<T>) {
   const containerRef = useRef<HTMLDivElement>(null)
   const menuRef = useRef<HTMLDivElement>(null)
   const [selectedIndex, setSelectedIndex] = useState(0)
   const [curItems, setCurItems] = useState<readonly T[]>([])
   const [text, setText] = useState('')
   const [stale, setStale] = useState(false)
   const selectedRef = useRef<HTMLDivElement>(null)
   const [isOpen, setOpen] = usePopover(containerRef, menuRef)
   const [needsTextUpdate, setNeedsTextUpdate] = useState(false)

   const inputRef = useRef<HTMLInputElement>(null)
   const [inputRefState, setInputRefState] = useState(inputRef.current)

   useEffect(() => {
      if (inputRefState !== inputRef.current) {
         setInputRefState(inputRef.current)
      }
   }, [inputRefState])

   function onKeyDown(e: React.KeyboardEvent) {
      setOpen(true)
      switch (e.key) {
         case 'ArrowUp':
            highlight(-1)
            break

         case 'ArrowDown':
            highlight(+1)
            break

         case 'Enter':
            setOpen(false)
            blur()
            break

         case 'Escape':
            setOpen(false)
            setText(getItemText(value))
            blur()
            break

         default:
            return
      }

      e.preventDefault()

      function highlight(offset: number) {
         const idx = Math.max(0, Math.min(curItems.length - 1, selectedIndex + offset))
         setSelectedIndex(idx)
         setText(getItemText(curItems[idx]))
      }
   }

   useLayoutEffect(() => {
      if (isOpen && selectedRef.current) {
         // scroll on open
         selectedRef.current.scrollIntoView({ block: 'center' })
      }
   }, [isOpen])

   useLayoutEffect(() => {
      if (isOpen && selectedRef.current) {
         selectedRef.current.scrollIntoView({ block: 'nearest' })
      }
   }, [isOpen, selectedIndex])

   useEffect(() => {
      let active = true
      updateItems()
      return () => { active = false }

      async function updateItems() {
         if (typeof items !== 'function') {
            setCurItems(items)
            return
         }

         setStale(true)
         const curItems = await items(text)
         if (active) {
            setCurItems(curItems)
            setStale(false)
         }
      }
   }, [items, text])

   function renderInput() {
      return <input ref={inputRef} className={inputClassName} value={text} onChange={e => setText(e.currentTarget.value)} disabled={disabled}/>
   }

   const onHighlightItem = useCallback((text: string, item: T) => {
      if (highlightItem) {
         return highlightItem(text, item)
      }

      return getItemText(item) === text
   }, [getItemText, highlightItem])

   const lastOnChange = useLastValue(onChange)
   useEffect(() => {
      if (!isOpen && itemSelected.current !== null && itemSelected.current !== text) {
         lastOnChange.current(undefined, text)
         setNeedsTextUpdate(true)
      }
   }, [isOpen, lastOnChange, text])

   useEffect(() => {
      if (needsTextUpdate) {
         setText(getItemText(value))
         setNeedsTextUpdate(false)
      }
   }, [getItemText, needsTextUpdate, value])

   const lastGetItemText = useLastValue(getItemText)
   useEffect(() => {
      const text = lastGetItemText.current(value)
      setText(text)
      itemSelected.current = text
   }, [lastGetItemText, value])

   useEffect(() => {
      const index = curItems.findIndex(item => onHighlightItem(text, item))
      if (index !== -1) {
         setSelectedIndex(index)
      }
   }, [curItems, onHighlightItem, text, isOpen])

   const modifiers = useMemo((): PopperJS.Modifier<any>[] => [{
      name: 'setWidth',
      enabled: setWidth,
      phase: 'write',
      fn({ instance: { state: { elements: { reference, popper } } } }) {
         if (!(reference instanceof HTMLElement)) { return }
         popper.style.minWidth = `${reference.offsetWidth}px`;
      }
   }], [setWidth])

   const itemSelected = useRef<string | null>(null)

   function select(item: T) {
      onChange(item, text)
      itemSelected.current = text
      setOpen(false)
   }

   function onFocus(e: React.SyntheticEvent) {
      if (disabled) return;
      setOpen(true)
      if (inputRef.current && (e.target instanceof Node) && inputRef.current.contains(e.target)) {
         inputRef.current.select()
      }
   }

   return <div ref={containerRef} className={classNames(css.combobox, className)} onKeyDown={onKeyDown} onFocus={onFocus}>
      {children ? children(inputRef, text, setText) : renderInput()}
      {caret && <FontAwesome className={css.caret} name='angle-down' onClick={() => inputRef.current?.focus()} />}
      {isOpen && <Popover ref={menuRef} referenceElement={inputRef.current} modifiers={modifiers}>
         {curItems.map((item, idx) => <div key={getKey(item, idx)} ref={selectedIndex === idx ? selectedRef : undefined}
            onClick={() => select(item)}
            className={classNames(css.item, itemClassName, selectedIndex === idx && css.selected)}>
            {renderItem ? renderItem(item, false) : getItemText(item)}
         </div>)}
      </Popover>}
   </div>
}

function blur() {
   if (document.activeElement instanceof HTMLElement) {
      document.activeElement.blur()
   }
}

function useLastValue<T>(value: T) {
   const ref = useRef(value)
   useEffect(() => {
      ref.current = value
   })

   return ref
}
