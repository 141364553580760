import classNames from 'classnames';
import React, { useState } from 'react';
import { useDispatch } from 'react-redux';
import { Dispatch } from 'redux';
import { searchContacts } from '../api';
import { Contact, isPerson } from '../model';
import { actions } from '../store/utils';
import Autocomplete from './Autocomplete';
import ContactPhoto from './ContactPhoto';
import css from './ParticipantInput.module.scss';

type ParticipantInput = {
   className?: string
   children: (props: React.InputHTMLAttributes<HTMLInputElement> & React.RefAttributes<HTMLInputElement>) => React.ReactNode
   exclude: string[]
   onAddParticipant: (contact: Contact) => void

   value?: string
   onChange?: (value: string) => void
}

const EmailRegex = /^\S+@\S\S+$/;

export async function performContactSearch(name: string, dispatch: Dispatch) {
   const results = await searchContacts(name);

   if (EmailRegex.test(name) && !results.some(r => r.emailAddress.toLowerCase() === name.toLowerCase())) {
      results.push({
         type: 'Person',
         name,
         emailAddress: name,
         jobTitle: null,
         department: null,
         organisation: null,
         isGuest: true
      });
   }

   for (const contact of results) {
      dispatch(actions.updateContact(contact));
   }

   return results
}

export default function ParticipantInput({ className, children, exclude, onAddParticipant, value, onChange }: ParticipantInput) {
   const dispatch = useDispatch()
   const [localValue, setLocalValue] = useState('')

   async function getItems(name: string) {
      const results = await performContactSearch(name, dispatch)
      return results.filter(c => !exclude.includes(c.emailAddress));
   }

   function onItemSelected(value: string, contact: Contact) {
      onAddParticipant(contact)
      setValue('')
   }

   function setValue(newValue: string) {
      if (value === undefined) {
         setLocalValue(newValue)
      }

      if (onChange) {
         onChange(newValue)
      }
   }

   return <Autocomplete
      className={className}
      value={value ?? localValue}
      onChange={(e, v) => setValue(v)}
      renderInput={children}
      renderItem={renderPersonItem}
      getItems={getItems}
      getItemValue={getItemValue}
      shouldHighlight={() => true}
      onSelect={onItemSelected} />;
}

export function renderPersonItem(item: Contact, isHighlighted: boolean) {
   let name = isPerson(item) && item.isGuest
      ? `${item.name} (Guest)`
      : item.name
   console.log("renderPersonItem", item)
   return <div className={classNames(css.item, isHighlighted && css.highlighted)} key={item.emailAddress}>
      <ContactPhoto contact={item} />
      <div className={css.details}>
         <div className={css.name}>{name}</div>
         <div>{isPerson(item) && [item.jobTitle, item.department].filter(x => x).join(', ')}</div>
      </div>
   </div>;
}

function getItemValue(item: Contact) {
   return item.emailAddress;
}
