import { useEffect, useRef } from 'react';

type Callback = () => void;

export default function useInterval(callback: Callback, delay: number | null, additionalDependencies: any[] = []) {
   const savedCallback = useRef<Callback>();

   // Remember the latest callback.
   useEffect(() => {
      savedCallback.current = callback;
   }, [ callback ]);

   // Set up the interval.
   useEffect(() => {
      function tick() {
         savedCallback.current!();
      }

      if (delay !== null) {
         const id = setInterval(tick, delay);
         return () => clearInterval(id);
      }

      return;
   // eslint-disable-next-line react-hooks/exhaustive-deps
   }, [ delay, ...additionalDependencies ]);
}
