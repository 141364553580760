import { useEffect, useRef } from 'react';

export type Callback = () => void;

export default function useAnimationFrame(callback: Callback, enabled = true) {
   const callbackRef = useRef<Callback>();
   const frameRef = useRef<number>();

   useEffect(() => {
      callbackRef.current = callback;
   }, [ callback ]);


   useEffect(() => {
      function loop() {
         frameRef.current = requestAnimationFrame(loop);
         callbackRef.current!();
      };

      if (!enabled) { return }
      frameRef.current = requestAnimationFrame(loop);
      return () => cancelAnimationFrame(frameRef.current!);
   }, [ enabled ]);
}
