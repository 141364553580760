import { combineReducers } from 'redux'
import { getType } from 'typesafe-actions'
import { DisplayBoardWelcome, Recipient } from '../../api'
import { actions, Actions } from '../utils'

function welcome(state: DisplayBoardWelcome | null = null, action: Actions): DisplayBoardWelcome | null {
   switch (action.type) {
      case getType(actions.setDisplayBoardWelcome):
         return action.payload

      case getType(actions.setDisplayBoardOrganiserNotified):
      case getType(actions.timeoutDisplayBoard):
         return null
   }

   return state
}

function organiserNotified(state: Recipient | null = null, action: Actions): Recipient | null {
   switch (action.type) {
      case getType(actions.setDisplayBoardOrganiserNotified):
         return action.payload

      case getType(actions.timeoutDisplayBoard):
         return null
   }

   return state
}

export default combineReducers({
   welcome,
   organiserNotified,
})
