import classNames from 'classnames';
import debounce from 'debounce-promise';
import React, { useEffect, useMemo, useRef, useState } from 'react';
import { useDispatch } from 'react-redux';
import { Attendee, AttendeeType, Contact } from '../../model';
import { getSecondaryUsername, selectBookingAttendees } from '../../store/helpers';
import { actions, useSelector } from '../../store/utils';
import { isTruthy } from '../../utils/misc';
import ContactPhoto, { useIsAttendeeFree } from '../ContactPhoto';
import { performContactSearch } from '../ParticipantInput';
import css from './Attendees.module.scss';
import BottomSheet from './BottomSheet';
import { Header } from './Header';
import { usePageNav } from './navigation';
import SideSheet from './SideSheet';
import TextInput from './TextInput';

type Tags = keyof JSX.IntrinsicElements & keyof HTMLElementTagNameMap

type AttendeeCardProps<T extends Tags = 'div'> = {
   attendee: Attendee
   tag?: T
} & React.HTMLAttributes<HTMLElementTagNameMap[T]>

export function AttendeeCard<T extends Tags = 'div'>({ attendee: { contact }, tag, className, children, ...props }: AttendeeCardProps<T>) {
   const Tag = (tag || 'div') as 'div'

   return <Tag className={classNames(css.attendeeCard, className)} {...props as any}>
      <ContactPhoto contact={contact} className={css.photo} />
      <div className={css.name} title={contact.name}>{contact.name}</div>
      {children}
   </Tag>
}

export function AttendeeStatus({ attendee: { contact, type } }: { attendee: Attendee }) {
   const isFree = useIsAttendeeFree(contact.emailAddress)
   return <div className={css.status}>
      {join(<>, </>, [
         isFree !== null && <span key='free' className={classNames(isFree ? css.free : css.busy)}>
            {isFree ? 'Free' : 'Busy'}
         </span>,
         type === AttendeeType.optional && <span key='type' className={css.type}>Optional</span>,
      ].filter(isTruthy))}
   </div>
}

function join(separator: JSX.Element, elements: JSX.Element[]) {
   if (!elements.length) { return [] }

   const res = [elements[0]]

   for (let i = 1; i < elements.length; i++) {
      res.push(React.cloneElement(separator, { key: 'separator-' + i }))
      res.push(elements[i])
   }

   return res
}

export function AttendeeList({ className, ...props }: React.HTMLAttributes<HTMLDivElement>) {
   return <div className={classNames(css.attendeeList, className)} {...props} />
}

export function AttendeeSelector() {
   const { isOpen, open } = usePageNav()
   const { isOpen: isMenuOpen, open: openMenu, close: closeMenu } = usePageNav()
   const forceFocus = useRef(true)

   const dispatch = useDispatch()
   const attendees = useSelector(selectBookingAttendees)
   const [selected, setSelected] = useState<Attendee>()
   const myself = useSelector(getSecondaryUsername)
   const input = useRef<HTMLInputElement>(null)
   const [value, setValue] = useState('')
   const [results, setResults] = useState<Contact[]>([])
   const exclude = useRef<string[]>([])

   function onSelect(a: Attendee) {
      if (a.contact.emailAddress === myself) { return }
      setSelected(a)
      openMenu()
   }

   function toggleType() {
      if (!selected) { return }
      dispatch(actions.setAttendeeType(
         selected.contact.emailAddress,
         selected.type === AttendeeType.required ? AttendeeType.optional : AttendeeType.required,
      ))
      closeMenu()
   }

   function addAttendee(c: Contact) {
      dispatch(actions.addParticipant(c.emailAddress, AttendeeType.required))
      setValue('')
   }

   function deleteAttendee() {
      if (!selected) { return }
      dispatch(actions.removeParticipant(selected.contact))
      closeMenu()
   }

   function onKeyDown(e: React.KeyboardEvent) {
      if (e.key === 'Backspace' && !value) {
         const last = attendees[attendees.length - 1]
         if (last.contact.emailAddress === myself) { return }
         dispatch(actions.removeParticipant(last.contact))
      }
   }

   const search = useMemo(() => debounce(async (name: string) => await performContactSearch(name, dispatch), 300), [dispatch])

   useEffect(() => {
      exclude.current = attendees.map(a => a.contact.emailAddress)
   }, [attendees])

   useEffect(() => {
      if (!value) {
         setResults([])
         return
      }

      let active = true
      showResults()
      return () => { active = false }

      async function showResults() {
         const res = await search(value)
         if (!active) { return }
         setResults(res.filter(c => !exclude.current.includes(c.emailAddress)))
      }
   }, [search, value])

   useEffect(() => {
      forceFocus.current = !isMenuOpen
      if (!input.current) { return }

      if (!isMenuOpen) {
         input.current.focus()
      } else {
         input.current.blur()
      }
   }, [isMenuOpen])

   function onBlur() {
      setTimeout(() => {
         if (!forceFocus.current || !input.current) { return }
         input.current.focus()
      }, 0)
   }

   return <>
      <button className={css.attendeeSelector} onClick={open}>
         <AttendeeList>
            {attendees.map(a => <AttendeeCard key={a.contact.emailAddress} attendee={a}>
               <AttendeeStatus attendee={a} />
            </AttendeeCard>)}
         </AttendeeList>
      </button>
      <SideSheet open={isOpen}>
         <Header back>
            Attendees
         </Header>
         <SideSheet.Body>
            <AttendeeList className={css.list}>
               {attendees.map(a => <AttendeeCard tag='button' onClick={() => onSelect(a)} key={a.contact.emailAddress} attendee={a}>
                  <AttendeeStatus attendee={a} />
               </AttendeeCard>)}
               <TextInput ref={input} autoFocus className={css.input} value={value} onChange={e => setValue(e.currentTarget.value)}
                  onKeyDown={onKeyDown} onBlur={onBlur} />
            </AttendeeList>
            <div className={css.results}>
               {results.map(c => <button key={c.emailAddress} className={css.result} onClick={() => addAttendee(c)}>
                  <ContactPhoto className={css.photo} contact={c} />
                  <div className={css.name}>{c.name}</div>
                  <div className={css.email}>{c.emailAddress}</div>
               </button>)}
            </div>
            <BottomSheet open={isMenuOpen} onClose={closeMenu}>
               {selected && <>
                  <button onClick={toggleType} className={css.menuButton}>
                     Make {selected.type === AttendeeType.required ? 'optional' : 'required'}
                  </button>
                  <button onClick={deleteAttendee} className={classNames(css.menuButton, css.delete)}>Delete</button>
               </>}
            </BottomSheet>
         </SideSheet.Body>
      </SideSheet>
   </>
}
