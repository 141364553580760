import React, { Children, PropsWithChildren } from 'react';
import css from './DefaultPage.module.scss';
import { Header } from './Header';
import Page from './Page';

interface ExternalLoginHomeProps {
   message?: string
}

export default function DefaultPage({ message, children }: PropsWithChildren<ExternalLoginHomeProps>) {
   return <Page className={css.page}>
      <Header>Welcome to Book-it</Header>
      <Page.Body>
         {message && <p className={css.nessage}>{message}</p>}
         {children}
      </Page.Body>
   </Page>
}
