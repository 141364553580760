import classNames from 'classnames';
import moment from 'moment';
import { useCallback, useRef } from 'react';
import FontAwesome from 'react-fontawesome';
import { useDispatch } from 'react-redux';
import { Suggestion } from '../model';
import { actions, useSelector } from '../store/utils';
import Button from './Button';
import css from './Suggestions.module.scss';

interface Props {
   className?: string
}

export default function Suggestions({ className }: Props) {
   const dispatch = useDispatch()
   const booking = useSelector(s => s.booking)
   const suggestions = useSuggestions()
   const scroller = useRef<HTMLDivElement>(null)

   function onAccept(sug: Suggestion) {
      dispatch(actions.acceptSuggestion(sug))
   }

   const autoFocus = useCallback((button: HTMLButtonElement | null) => {
      if (!button || !scroller.current) { return null }
      const parent = scroller.current
      if (parent.scrollLeft > button.offsetLeft) {
         parent.scrollLeft = button.offsetLeft
      } else if (parent.scrollLeft + parent.offsetWidth < button.offsetLeft + button.offsetWidth) {
         parent.scrollLeft = button.offsetLeft + button.offsetWidth - parent.offsetWidth
      }
   }, [])

   if (!booking?.full || booking.allDay) { return null }

   return <div className={classNames(css.wrapper, className)} ref={scroller}>
      <div className={css.suggestions}>
         {suggestions.all.map(({ suggestion, isCurrent }) =>
            <Button color='none'
                  ref={isCurrent ? autoFocus : undefined}
                  key={suggestion.start} onClick={() => onAccept(suggestion)}
                  className={classNames(css.suggestion, isCurrent && css.active)}>
               <div>{moment(suggestion.start).format('ddd D MMM')}</div>
               <div>{moment(suggestion.start).format('LT')}</div>
               {renderSuggestionStatus(suggestion)}
            </Button>)}
         {suggestions.loading && <div className={css.loading}>
            {suggestions.all.length === 0 && 'Loading suggestions...'}
         </div>}
      </div>
   </div>

   function renderSuggestionStatus(suggestion: Suggestion) {
      if (suggestion.resourcesAvailable) {
         if (suggestion.participantsAvailable) {
            return <div className={css.available}>
               <FontAwesome name='check-circle' />
               Everyone available
            </div>
         } else {
            return <div className={css.unavailable}>
               <FontAwesome name='exclamation-triangle' />
               Some attendees unavailable
            </div>
         }
      } else {
         if (suggestion.participantsAvailable) {
            return <div className={css.unavailable}>
               <FontAwesome name='exclamation-triangle' />
               Some resources unavailable
            </div>
         } else {
            return <div className={css.unavailable}>
               <FontAwesome name='exclamation-triangle' />
               Nothing is available
            </div>
         }
      }
   }
}

export function useSuggestions() {
   const booking = useSelector(s => s.booking)
   const final = useSelector(s => s.suggestions.final)

   return {
      loading: final.loading,
      all: final.suggestions.map(suggestion => ({
         suggestion,
         isCurrent: suggestion === booking?.suggested,
      })),
      currentValid: booking?.suggested?.incomplete.every(x => !x) ?? false,
   }
}
