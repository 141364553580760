import { getLocation, push, replace } from 'connected-react-router';
import { put, select } from 'redux-saga/effects';
import { Page } from '../model';
import { isSingleUser } from '../store/helpers';
import { getHaveSecondaryLogin } from '../store/reducers';
import { StoreState } from '../store/utils';
import { goToPage } from './page';

export function* goHome() {
   const state: StoreState = yield select();
   if (isSingleUser(state)) {
      if (state.booking) {
         yield goToPage(Page.bookingFilter, state, false)
      } else {
         yield goToPage(Page.calendar, state, false)
      }
   } else {
      if (state.booking && getHaveSecondaryLogin(state)) {
         yield goToPage(Page.bookingFilter, state, false)
         return
      }

      const { login: { data } } = state
      const home = data && data.device?.home

      if (home === 'RoomList') {
         yield goToPage(Page.roomList, state, false)
      } else if (home === 'Timeline') {
         yield goToPage(Page.timeline, state, false)
      }
   }
}

export function* goTo(path: string, state: StoreState, usePush: boolean) {
   if (getLocation(state).pathname !== path) {
      yield put(usePush ? push(path) : replace(path));
   }
}
