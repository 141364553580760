import classNames from 'classnames'
import moment from 'moment'
import { useEffect, useMemo } from 'react'
import FontAwesome from 'react-fontawesome'
import { useDispatch } from 'react-redux'
import { Attendee, AttendeeType } from '../../model'
import { getSelectedDateTime, selectBookingAttendees, selectBookingTime } from '../../store/helpers'
import { actions, useSelector } from '../../store/utils'
import { roundDate, roundStartTime, timeOfDayInHours } from '../../utils/dateUtils'
import ContactPhoto, { useIsAttendeeFree } from '../ContactPhoto'
import { ClockIcon } from '../icons'
import BottomSheet from './BottomSheet'
import { CalendarCursorProvider } from './CalendarCursor'
import css from './DateTimeFields.module.scss'
import FormField from './FormField'
import { Header } from './Header'
import { Calendar, DatePicker } from './MyCalendarMobile'
import { NativeDatePicker, NativeTimePicker } from './NativePickers'
import { usePageNav } from './navigation'
import SideSheet from './SideSheet'
import { ToggleWithLabel } from './Toggle'
import ToggleSelector from './ToggleSelector'

export default function DateTimeFields() {
   const dispatch = useDispatch()
   const now = useSelector(s => s.time)
   const date = useSelector(s => s.selectedDate)
   const booking = useSelector(s => s.booking)
   const { open: openCalendar, isOpen: isCalendarOpen } = usePageNav()
   const { open: openTimePicker, isOpen: isTimerPickerOpen, close: closeTimePicker } = usePageNav()

   if (!booking) { return null }

   function fixMinTime(time: number | null) {
      return time ?? roundStartTime(timeOfDayInHours(now))
   }

   const setStartTime = (newStart: number | null) => {
      newStart = fixMinTime(newStart)

      dispatch(actions.setBookingStart(newStart))

      const newDur = booking.start + booking.duration - newStart
      dispatch(actions.setBookingDuration(newDur < 0 ? 0.5 : newDur))
   }

   const setEndTime = (newEnd: number | null) => {
      newEnd = fixMinTime(newEnd)

      if (newEnd < booking.start) {
         setStartTime(newEnd - 0.5)
         dispatch(actions.setBookingDuration(0.5))
      } else {
         dispatch(actions.setBookingDuration(Math.max(5 / 60, newEnd - booking.start)))
      }
   }

   const bookingStart = moment(date).add(booking.start, 'h').valueOf()
   const bookingEnd = moment(bookingStart).add(booking.duration, 'h').add(booking.allDay ? -1 : 0, 'd').valueOf()

   function fixMinDate(date: number | null) {
      const today = moment(now).startOf('d').valueOf()
      return date === null || date < today ? today : date
   }

   const setStartDate = (newStart: number | null) => {
      newStart = fixMinDate(newStart)
      dispatch(actions.selectDate(newStart))
      dispatch(actions.setBookingDuration(Math.max(24, (moment(bookingEnd).diff(newStart, 'd') + 1) * 24)))
   }

   const setEndDate = (newEnd: number | null) => {
      newEnd = fixMinDate(newEnd)

      if (newEnd < bookingStart) {
         dispatch(actions.selectDate(newEnd))
         dispatch(actions.setBookingDuration(24))
      } else {
         dispatch(actions.setBookingDuration((moment(newEnd).diff(date, 'd') + 1) * 24))
      }
   }

   return <>
      <FormField icon={<ClockIcon />}>
         <ToggleWithLabel label={'All day' + (booking.allDay ? ` (${booking.duration / 24}d)` : '')}
            value={booking.allDay} onChange={x => dispatch(actions.setAllDay(x))} />
      </FormField>

      <FormField icon={null} className={css.dateTimeField}>
         {!booking.allDay ? <>
            <NativeDatePicker className={classNames(css.datePicker, css.datePart)} value={date} onChange={v => v && dispatch(actions.selectDate(v))}>
               <span>Date</span>
               {moment(bookingStart).format('ddd, D MMM YYYY')}
            </NativeDatePicker>
            <div className={css.divider} />
            <button className={css.timePart} onClick={openCalendar}>
               <span>Time</span>
               <div>
                  {moment(bookingStart).format('hh:mm a')}
                  <FontAwesome name='caret-right' className={css.arrow} />
                  {moment(bookingEnd).format('hh:mm a')}
               </div>
            </button>
         </> : <>
            <NativeDatePicker className={css.datePicker} value={bookingStart} onChange={setStartDate}>
               <span>Start</span>
               {moment(bookingStart).format('ddd, D MMM YYYY')}
            </NativeDatePicker>
            <div className={css.divider} />
            <NativeDatePicker className={css.datePicker} value={bookingEnd} onChange={setEndDate}>
               <span>End</span>
               {moment(bookingEnd).format('ddd, D MMM YYYY')}
            </NativeDatePicker>
         </>}
      </FormField>

      <SideSheet open={isCalendarOpen}>
         <Header back>
            <div className={css.header}>
               <NativeDatePicker className={css.month} value={date} onChange={v => v !== null && dispatch(actions.selectDate(v))}>
                  {moment(date).format(moment().isSame(date, 'y') ? 'MMMM' : 'MMMM YYYY')}
               </NativeDatePicker>
               <button className={css.clock} onClick={openTimePicker}>
                  <ClockIcon />
               </button>
            </div>
         </Header>
         <DatePicker />
         <DurationPicker openTimePicker={openTimePicker} />
         <CalendarCursorProvider>
            <Calendar />
            <FreeBusyPanel />
         </CalendarCursorProvider>
      </SideSheet>

      <BottomSheet open={isTimerPickerOpen} onClose={closeTimePicker}>
         <FormField icon={<ClockIcon />}>
            <span>Start</span>
            <NativeTimePicker className={css.timePicker} value={booking.start} onChange={setStartTime}>
               {moment().startOf('d').add(booking.start, 'h').format('hh:mm a')}
            </NativeTimePicker>
         </FormField>
         <FormField combine icon={null}>
            <span>End</span>
            <NativeTimePicker className={css.timePicker} value={booking.start + booking.duration} onChange={setEndTime}>
               {moment().startOf('d').add(booking.start + booking.duration, 'h').format('hh:mm a')}
            </NativeTimePicker>
         </FormField>
      </BottomSheet>
   </>
}

function FreeBusyPanel() {
   const attendees = useSelector(selectBookingAttendees)

   return <div className={css.freeBusyPanel}>
      <div className={css.wrapper}>
         {attendees.map(a => <FreeBusy key={a.contact.emailAddress} attendee={a} />)}
      </div>
   </div>
}

function FreeBusy({ attendee: { contact, type } }: { attendee: Attendee }) {
   const isFree = useIsAttendeeFree(contact.emailAddress)

   return <ContactPhoto contact={contact} className={classNames({
      [css.optional]: type === AttendeeType.optional,
      [css.free]: isFree === true,
      [css.busy]: isFree === false,
   })} />
}

function DurationPicker({ openTimePicker }: { openTimePicker: () => void }) {
   const dispatch = useDispatch()
   const booking = useSelector(selectBookingTime)
   if (!booking) { return null }

   return <div className={css.durationPicker}>
      {[15 / 60, 30 / 60, 1, 2, 4].map(hr => <button key={hr}
         className={classNames(booking.duration === hr && css.active)}
         onClick={() => dispatch(actions.setBookingDuration(hr))}>
         {hr < 1 ? `${hr * 60}m` : `${hr}h`}
      </button>)}
      <button onClick={openTimePicker}>...</button>
   </div>
}

const Durations = [0.5, 1, 1.5, 2]

export function SimpleDateTimeFields() {
   const dispatch = useDispatch()
   const start = useSelector(getSelectedDateTime)
   const booking = useSelector(s => s.booking)

   const startMinute = moment(useSelector(s => s.time)).startOf('m').valueOf()
   const wrongTime = useSelector(s => s.booking ? s.booking.start !== timeOfDayInHours(startMinute) : false)

   const durations = useMemo(() => {
      const roundedUpMin = roundDate(startMinute, 'm', 'ceil', 30)
      const durations = Array.from(new Array(6)).map((_, i) => moment(roundedUpMin).add(i * 30, 'm').diff(start ?? startMinute, 'h', true)).filter(h => h >= 10 / 60)
      return durations
   }, [start, startMinute])

   useEffect(() => {
      if (wrongTime) {
         dispatch(actions.setBookingStart(timeOfDayInHours(startMinute)))
      }
   }, [dispatch, startMinute, wrongTime])


   if (start === null || booking === null) { return null }

   return <FormField icon={<ClockIcon />}>
      <div className={css.simple}>
         <div>Start:</div>
         <div>{moment(start).format('LT')}{moment(booking.start).isSame(undefined, 'm') ? ' (now)' : ''}</div>
         <div>End:</div>
         <ToggleSelector value={booking.duration} onChange={hr => dispatch(actions.setBookingDuration(hr))}>

            {durations.map(hr => <ToggleSelector.Button key={hr} value={hr} className={css.durationButton}>
               <div>{hr < 1 ? `${(hr * 60).toFixed()}min` : moment.utc(0).add(hr, 'h').format('H:mm')}</div>
               <div>{moment(start).add(hr, 'h').format('LT')}</div>
            </ToggleSelector.Button>)}
         </ToggleSelector>
      </div>
   </FormField>
}
