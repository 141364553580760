import moment from 'moment';
import { getType } from 'typesafe-actions';
import { AttendeeResource, MeetingData, MeetingException, MeetingExceptions } from '../../api';
import { AgendaItem, Attachment, AttendeeData, AttendeeType, FreeBusyStatus, MeetingType, PermissionSet, Recurrence, ResponseStatus, RoundtripBookingResourcesFilter, TeamToJoin } from '../../model';
import { flatten } from '../../utils/misc';
import { Actions, actions } from '../utils';

export interface Meeting {
   id: string
   startTime: number
   endTime: number
   subject: string
   bodyText: string
   agendaItems: AgendaItem[]
   agendaNotes: string
   organiser?: string
   participants: AttendeeData[]
   types: MeetingType[]
   isPrivate: boolean
   isAdhoc: boolean
   adhocOrganisationId: string | null
   isExternal: boolean
   additional: NonNullable<MeetingData['resourceMeetings']>
   exceptions: MeetingExceptions
   attachments: Attachment[]
   permissions: PermissionSet | null
   filter: RoundtripBookingResourcesFilter[]
   pending: boolean
   iCalUid: string
   recurrenceId: string
   calendarContext: string
   isRecurring: boolean
   showAs: FreeBusyStatus
   allDay: boolean
   recurrence: Recurrence | null
   teamsToJoin: TeamToJoin[]
}

interface Meetings {
   [id: string]: Meeting | undefined
}

function getMeeting({
   id, startTime, endTime, subject, bodyText, agendaItems, agendaNotes, organiser, participants, meetingRooms, onlineMeetingTypes,
   isPrivate, isAdHocMeeting, adhocOrganisationId, isRecurring, isAllDayEvent, recurrence, isExternalOrganisation, resourceMeetings, meetingExceptions,
   attachments, permissions, filter, pending, iCalUid, recurrenceId, calendarContext, showAs, teamsToJoin }: MeetingData): Meeting {

   if (isAllDayEvent) {
      startTime = trimAllDay(startTime)
      endTime = trimAllDay(endTime)
   }

   return {
      id,
      startTime, endTime,
      subject,
      bodyText: bodyText || '',
      agendaItems, agendaNotes,
      organiser: organiser?.emailAddress,
      participants: [
         ...participants.map(p => ({ emailAddress: p.emailAddress, type: getAttendeeType(p.attendeeType), responseStatus: p.responseStatus })),
         ...meetingRooms.map(r => ({ emailAddress: r.emailAddress, type: getAttendeeType(r.attendeeType), responseStatus: r.responseStatus })),
      ],
      types: onlineMeetingTypes,
      isPrivate,
      isAdhoc: isAdHocMeeting,
      adhocOrganisationId,
      isExternal: isExternalOrganisation,
      additional: resourceMeetings || [],
      exceptions: meetingExceptions,
      attachments: attachments ? attachments.map<Attachment>(a => ({ id: a.uniqueId, name: a.name, type: a.contentType, size: a.size })) : [],
      permissions,
      filter,
      pending,
      iCalUid,
      recurrenceId,
      calendarContext,
      isRecurring,
      showAs,
      allDay: isAllDayEvent,
      recurrence,
      teamsToJoin: teamsToJoin,
   }
}

function getAttendeeType(type: AttendeeResource["attendeeType"]): AttendeeType {
   if (type === "Required") return AttendeeType.required
   if (type === "Optional") return AttendeeType.optional
   if (type === "Resource") return AttendeeType.required
   return AttendeeType.required
}

function trimAllDay(time: number) {
   const [y, m, d] = moment.utc(time).toArray()
   return moment([y, m, d]).valueOf()
}

export default function meetings(state: Meetings = {}, action: Actions): Meetings {
   switch (action.type) {
      case getType(actions.fetchCalendarsResponse): {
         const meetings = flatten(Object.values(action.payload.schedules))
         return meetings.map(getMeeting).reduce((s, m) => {
            s[m.id] = m
            return s
         }, { ...state });
      }

      case getType(actions.addMeetingData):
      case getType(actions.updateMeetingData): {
         const { meeting } = action.payload;
         const m = getMeeting(meeting)
         return {
            ...state,
            [m.id]: m,
         }
      }

      case getType(actions.changeMeetingResponseStatus): {

         const { id, responseStatus, myself } = action.payload
         const meeting = state[id]
         if (!meeting) return state

         return {
            ...state,
            [id]: {
               ...meeting,
               participants: meeting.participants.map(p => {
                  if (myself === p.emailAddress) {
                     return {...p, responseStatus: getResponseStatus(responseStatus) }
                  }
                  return p
               })
            },
         }
      }

      case getType(actions.markMeetingPending): {
         const id = action.payload;
         const m = state[id]
         if (!m) { return state }

         return {
            ...state,
            [m.id]: { ...m, pending: true },
         }
      }

      case getType(actions.replaceMeetingData): {
         const { oldId, meeting } = action.payload
         const { [oldId]: _, ...newState } = state
         const m = getMeeting(meeting)
         return {
            ...newState,
            [m.id]: m,
         }
      }

      case getType(actions.removeMeeting): {
         const { [action.payload]: x, ...rest } = state;
         return rest;
      }

      case getType(actions.meetingStarted): {
         const { id, email } = action.payload
         return startLeave(state, id, email, { startTime: Date.now() })
      }

      case getType(actions.meetingLeft): {
         const { id, email } = action.payload
         return startLeave(state, id, email, { endTime: Date.now() })
      }
   }

   return state;
}

function startLeave(state: Meetings, id: string, email: string, exception: Partial<MeetingException>) {
   const meeting = state[id]
   if (!meeting) { return state }
   return {
      ...state,
      [id]: {
         ...meeting,
         exceptions: {
            ...meeting.exceptions,
            [email]: {
               ...meeting.exceptions[email],
               ...exception,
            }
         }
      },
   }
}

export function getById(state: Meetings, id: string) {
   return state[id];
}

function getResponseStatus(status: "Accepted" | "Tentative" | "Declined"): ResponseStatus {
   if (status === "Accepted") return "Accepted"
   if (status === "Tentative") return "Tentative"
   return "Rejected"
}
