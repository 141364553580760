import React, { useCallback, useState } from 'react';
import { Login, whoAmI } from '../../api';
import css from './FastTrack.module.scss';
import { Header } from './Header';
import OtpScreen from './OtpScreen';
import Page from './Page';
import AccentButton from '../AccentButton';

export default function FastTrack() {
   const [login, setLogin] = useState<Login>()

   const api = useCallback(async () => {
      return (await whoAmI()) || null
   }, [])

   function openBookit() {
      window.location.replace('/')
   }

   return <Page className={css.page}>
      <Header>Welcome to Book-it</Header>
      <Page.Body>
         {login === undefined ? <OtpScreen api={api} onSuccess={setLogin}>
            <h2>Please validate your email to proceed</h2>
         </OtpScreen> : <>
            <p>
               Thank you! Your <em>Fast Track</em> ID has now been activated. When you arrive for you meeting look for the <em>Fast Track</em> display.
            </p>

            {!login.isExternal && <AccentButton className={css.button} onClick={openBookit}>Open Book-it</AccentButton>}
         </>}
      </Page.Body>
   </Page>
}
