export function getLocation() {
   return read().location;
}

export function setLocation(location: string) {
   merge({ location })
}

export function resetSettings() {
   write({});
}

type Settings = Partial<{
   location: string;
}>;

const Key = 'bookit-settings';
const Storage = window.localStorage;

function read() : Settings {
   const data = Storage.getItem(Key);
   if (!data) { return {} }
   return JSON.parse(data);
}

function write(settings: Settings) {
   Storage.setItem(Key, JSON.stringify(settings));
}

function merge(settings: Settings) {
   write({ ...read(), ...settings });
}
