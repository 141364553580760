import React, { useState } from 'react';
import { useDispatch } from 'react-redux';
import { Modal, ModalBody, ModalHeader } from 'reactstrap';
import { isMobile } from '../store/helpers';
import { actions, useSelector } from '../store/utils';
import AccentButton from './AccentButton';
import css from './ReconnectPopup.module.scss';
import Spinner from './Spinner';

export default function ReconnectPopup() {
   const reconnecting = useSelector(s => s.reconnecting)
   const dispatch = useDispatch()
   const [clicked, setClicked] = useState(false)
   const mobile = useSelector(isMobile)

   function reload() {
      setClicked(true)
      dispatch(actions.reconnect())
   }

   if (mobile) {
      if (!reconnecting) { return null}

      return <div className={css.mobile}>
         <svg viewBox='0 0 32 32'>
            <path fill='#6264a7' d='M12.3636364,24 L8.00281647,24 C5.79793835,24 4,22.209139 4,20 C4,18.1046097 5.32460991,16.5117359 7.10100919,16.1021544 L7.10100919,16.1021544 C7.03467626,15.7448817 7,15.3764904 7,15 C7,11.6862913 9.68629134,9 13,9 C15.6154416,9 17.8400262,10.6734569 18.6614243,13.0080411 C19.435776,12.3778124 20.4237666,12 21.5,12 C23.8583427,12 25.7929639,13.814166 25.9844379,16.1230721 L25.9844379,16.1230721 C27.7144917,16.5630972 29,18.1320162 29,20 C29,22.2046438 27.207878,24 24.9971835,24 L20.6363636,24 L16.5,17 L12.3636364,24 L12.3636364,24 L12.3636364,24 Z M21.2272727,25 L25.0005601,25 C27.7616745,25 30,22.7558048 30,20 C30,17.9035809 28.7132907,16.1085075 26.8828633,15.3655101 L26.8828633,15.3655101 C26.3600217,12.8722494 24.1486546,11 21.5,11 C20.6371017,11 19.8206159,11.1987158 19.0938083,11.5528817 C17.8911816,9.43144875 15.6127573,8 13,8 C9.13400656,8 6,11.1340066 6,15 C6,15.1381509 6.00400207,15.275367 6.01189661,15.4115388 L6.01189661,15.4115388 C4.23965876,16.1816085 3,17.9491311 3,20 C3,22.7614237 5.23249418,25 7.99943992,25 L11.7727273,25 L10,28 L23,28 L21.2272727,25 L21.2272727,25 L21.2272727,25 Z M16.5,19 L21.25,27 L11.75,27 L16.5,19 L16.5,19 Z M16,21 L16,24 L17,24 L17,21 L16,21 L16,21 Z M16,25 L16,26 L17,26 L17,25 L16,25 L16,25 Z' id='cloud-error' />
         </svg>
         <div>Connection to the server is lost. Trying to reconnect...</div>
      </div>
   }

   return <Modal isOpen={reconnecting}>
      <ModalHeader>Connection to the server is lost. Reconnecting...</ModalHeader>
      <ModalBody className={css.body}>
         <Spinner className={css.svg} />
         <AccentButton disabled={clicked} onClick={reload}>{clicked ? 'Reloading...' : 'Reload now'}</AccentButton>
      </ModalBody>
   </Modal>
}
