import { connect } from 'react-redux';
import { Dispatch } from 'redux';
import LocationPicker from '../components/LocationPicker';
import { actions, ReduxPropsType, StoreState } from '../store/utils';

function mapStateToProps({ locations }: StoreState) {
   return { locations };
}

function mapDispatchToProps(dispatch: Dispatch) {
   return {
      setLocation(location: string) {
         dispatch(actions.pickLocation(location));
      },
   };
}

const withRedux = connect(mapStateToProps, mapDispatchToProps);
export type LocationPickerProps = ReduxPropsType<typeof withRedux>;
export default withRedux(LocationPicker);
