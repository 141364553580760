import React from 'react';
import FontAwesome from 'react-fontawesome';
import { TeamWithMembers } from '../../model';
import { selectTeams } from '../../store/helpers';
import { useSelector } from '../../store/utils';
import { renderMembers } from '../TeamsDropdown';
import FormField from './FormField';
import { Header } from './Header';
import { usePageNav } from './navigation';
import SideSheet from './SideSheet';
import css from './TeamSelector.module.scss';

interface Props {
   title: React.ReactNode
   onAdd: (team: TeamWithMembers) => void
}

export default function TeamSelector({ children, title, onAdd }: React.PropsWithChildren<Props>) {
   const { isOpen, open, close } = usePageNav()
   const teams = useSelector(selectTeams)

   function onPick(team: TeamWithMembers) {
      close()
      onAdd(team)
   }

   return <>
      <FormField icon={null}>
         <button onClick={open} className={css.button}>
            {children}
         </button>
      </FormField>
      <SideSheet open={isOpen}>
         <Header back>
            {title}
         </Header>
         <SideSheet.Body>
            {!teams ? 'Loading...' :
            <ul className={css.teams}>
               {teams.map(team => <li key={team.id}>
                  <button className={css.team} onClick={() => onPick(team)}>
                     <div className={css.name}>{team.displayName}</div>
                     <div className={css.members}>{renderMembers(team)}</div>
                     <FontAwesome name='chevron-right' />
                  </button>
               </li>)}
            </ul>}
         </SideSheet.Body>
      </SideSheet>
   </>
}
