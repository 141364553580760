import React, { useContext, useEffect, useState } from 'react'
import { HasChildren } from './misc'

const defaultValue = 16
const RemContext = React.createContext(defaultValue)

export function useRems() {
   const pxPerRem = useContext(RemContext)
   return { pxPerRem }
}

export function RemProvider({ children }: HasChildren) {
   const [ value, setValue ] = useState(defaultValue)

   useEffect(() => {
      const div = document.createElement('div')
      div.style.width = '1rem'
      document.body.append(div)
      const ratio = div.getBoundingClientRect().width
      div.remove()
      setValue(ratio)
   }, [])

   return <RemContext.Provider value={value}>
      {children}
   </RemContext.Provider>
}
