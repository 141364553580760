import * as teams from '@microsoft/teams-js';
import { organisationLogin } from './api';
import createAzureAdClient from './azure-ad';
import { Domain, LoginType } from './model';

let Initialized = false;

const getContext = (t: typeof teams) => new Promise<teams.Context>(resolve => {
   t.getContext(c => resolve(c));
});

export function initTeams() {
   if (Initialized) { return }
   Initialized = true;

   teams.initialize();
   teams.settings.registerOnSaveHandler(e => {
      teams.settings.setSettings({
         entityId: 'bookit',
         contentUrl: `${window.location.origin}/book#teams`,
      })
      e.notifySuccess();
   });
}

export async function setConfigValid(valid: boolean = true) {
   teams.settings.setValidityState(valid);
}

export async function showAuthPopup() {
   return new Promise<string>((resolve, reject) => {
      teams.authentication.authenticate({
         url: origin + '/teams-auth-popup',
         width: 600,
         height: 535,
         successCallback(token) {
            if (!token) {
               reject(new Error('Token wasn\'t provided'))
            } else {
               resolve(token);
            }
         },
         failureCallback(reason) {
            reject(reason);
         }
      });
   })
}

export async function createApp(appId: string, tenantId: string, popup: boolean) {
   return createAzureAdClient({
      clientId: appId,
      scopes: ['https://graph.microsoft.com/User.ReadBasic.All'],
      authority: `https://login.microsoftonline.com/${tenantId}/`,
      redirectUrl: '/teams-auth-popup-callback',
      async onRedirected(app, response, error) {
         if (!popup) { return }

         if (error) {
            teams.authentication.notifyFailure(String(error))
         } else {
            const token = await app.acquireSilent()

            if (token?.accessToken) {
               teams.authentication.notifySuccess(token.accessToken)
            } else {
               console.error('failed to get the token', token)
            }
         }
      }
   })
}

export async function getTeamsDomain() {
   const upn = await getCurrentTeamsUser()
   if (!upn) { return null }

   const domain = await organisationLogin(upn)
   if (typeof domain === 'string' || domain.loginType !== LoginType.azure) { return null }

   return domain
}

export async function isTeamsUserValid(domain: Domain, email: string) {
   const user = await getCurrentTeamsUser()
   if (user?.toLowerCase() !== email.toLowerCase()) { return false }
   const token = await acquireTeamsTokenSilent(domain)
   console.warn('acquired token', token)
   return Boolean(token)
}

export const notifySuccess = teams.authentication.notifySuccess

export interface Member {
   name: string;
   email: string;
}

export async function getCurrentTeamsUser() {
   const ctx = await getContext(teams);
   return ctx.userPrincipalName
}

export async function getCurrentChat(domain: Domain): Promise<Member[]> {
   const ctx = await getContext(teams)
   const ids = ctx.chatId && parseChatMembers(ctx.chatId)
   if (!ids) { return [] }

   const token = await acquireTeamsTokenSilent(domain)
   const graph = new Graph(token)

   const members = await Promise.all(ids.map(id => graph.getUser(id)))

   return members.map(getMember)
}

export async function acquireTeamsTokenSilent(domain: Domain) {
   if (domain.loginType !== LoginType.azure) { return null }
   const app = await createApp(domain.clientId, domain.tenantId, false)

   const result = await app.acquireSilent()
   if (!result) { return null }

   return result.accessToken
}

function getMember(u: GraphUser): Member {
   return {
      name: u.displayName,
      email: u.userPrincipalName,
   }
}

function parseChatMembers(chatId: string) {
   const res = /^19:(\w{8}(?:-\w{4}){3}-\w{12})_(\w{8}(?:-\w{4}){3}-\w{12})@/.exec(chatId);
   if (!res) { return }
   return [res[1], res[2]];
}

interface GraphUser {
   userPrincipalName: string;
   displayName: string;
}

class Graph {
   private token: string | null

   constructor(token: string | null) {
      this.token = token
   }

   public getUser(id: string): Promise<GraphUser> {
      return get<GraphUser>(this.token, `users/${id}?$select=userPrincipalName,displayName`)
   }
}

async function get<T>(token: string | null, url: string) {
   if (!token) { throw new Error('Not logged in'); }

   const resp = await fetch(`https://graph.microsoft.com/v1.0/${url}`, {
      headers: { 'Authorization': 'bearer ' + token },
   });

   if (!resp.ok) {
      throw new Error(`${url} returned ${resp.status} ${resp.statusText}\n\n${await resp.text()}`)
   }

   return (await resp.json()) as T;
}
