import * as PopperJS from '@popperjs/core';
import classNames from 'classnames';
import React, { useEffect, useMemo, useState } from 'react';
import css from './Popover.module.scss';
import { PortalPopper } from './Popper';

interface Props extends React.HTMLAttributes<HTMLDivElement> {
   referenceElement: HTMLElement | null | undefined
   modifiers?: readonly PopperJS.Modifier<any>[]
   placement?: PopperJS.Placement
   gap?: number
}

export default React.forwardRef<HTMLDivElement, Props>(function Popover({ className, referenceElement, style, children, modifiers, gap = 2, placement = 'bottom-start', ...rest }, ref) {
   const allModifiers = useMemo(() => [
      ...(modifiers || []),
      {
         name: 'offset',
         options: {
            offset: [0, gap],
         },
      }, {
         name: 'flip',
      }
   ], [gap, modifiers])

   return <PortalPopper innerRef={ref} referenceElement={referenceElement || undefined} placement={placement} modifiers={allModifiers}>
      {props => <div className={css.container}>
         <div tabIndex={0} className={classNames(css.popover, props.placement.startsWith('top') && css.top, className)} ref={props.ref} style={{...props.style, ...style}} {...rest}>
            {children}
         </div>
      </div>}
   </PortalPopper>
})

export function usePopover(...childrenRefs: readonly React.RefObject<HTMLElement>[]) {
   const [isOpen, setOpen] = useState(false)

   useEffect(() => {
      document.addEventListener('mousedown', onDocument, true)
      document.addEventListener('focus', onDocument, true)
      return () => {
         document.removeEventListener('mousedown', onDocument, true)
         document.removeEventListener('focus', onDocument, true)
      }

      function onDocument(e: Event) {
         if (!(e.target instanceof Node)) {
            return
         }

         const target = e.target

         if (childrenRefs.some(ref => ref.current?.contains(target))) {
            return
         }

         setOpen(false)
      }
      // eslint-disable-next-line react-hooks/exhaustive-deps
   }, [])

   return [isOpen, setOpen] as const
}
