import { all, put } from 'redux-saga/effects';
import { bookitAzureAdPopup } from '../azure';
import { LoginType } from '../model';
import { actions } from '../store/utils';
import { takeEvery } from '../utils/apiActions';
import { ApiResult } from './api';

const onSecondaryLogin = takeEvery(actions.secondaryExternalLogin, function* (state, { domain, loginHint }) {
   if (domain.loginType !== LoginType.azure) { return }

   const result: ApiResult<typeof bookitAzureAdPopup> = yield bookitAzureAdPopup(domain.clientId, domain.tenantId, loginHint)

   if (result === null) {
      yield put(actions.setPopupSubmitting(false))
   } else if (typeof result === 'string') {
      yield put(actions.setPopupError(result))
   } else if (state.popup.type === 'cancelMeeting') {
      yield put(actions.cancelMeeting(state.popup.meetingId, { token: result.accessToken }))
   } else {
      yield put(actions.secondaryLogin.request({ token: result.accessToken }))
   }
})

export default all([
   onSecondaryLogin,
])
