import { all, call, put } from 'redux-saga/effects';
import * as Api from '../api';
import { actions } from '../store/utils';
import { invokeApiRetry, takeEvery } from '../utils/apiActions';
import { ApiResult } from './api';

const onFetchCalendars = takeEvery(actions.fetchCalendarsRequest, function* (state, { start, end, email }) {
   yield invokeApiRetry(function* () {
      const data: ApiResult<typeof Api.fetchCalendars> = yield call(Api.fetchCalendars, start, end, email)
      yield put(actions.fetchCalendarsResponse(email, data))
   })
})

export default all([
   onFetchCalendars,
])
