import { Action } from 'redux';
import * as effects from 'redux-saga/effects';
import { createAction, getType } from 'typesafe-actions';
import { B, FsaBuilder, NoArgCreator, PayloadAction, PayloadCreator, TypeMeta } from 'typesafe-actions/dist/types';
import * as Api from '../api';
import { actions, StoreState } from '../store/utils';
import { backoff, delay } from './misc';

export type ApiAction<T extends string, P> = FsaBuilder<T, B<P>>;

export interface ApiActionsBuilder<T1 extends string, T2 extends string, P, R> {
   request: ApiAction<T1, P>;
   response: PayloadCreator<T2, R>;
}

export function createApiActions<T1 extends string, T2 extends string, P, R>
   (requestType: T1, successType: T2, call: () => Promise<R>): ApiActionsBuilder<T1, T2, void, R>;

export function createApiActions<T1 extends string, T2 extends string, P, R>
   (requestType: T1, successType: T2, call: (payload: P) => Promise<R>): ApiActionsBuilder<T1, T2, P, R>;

export function createApiActions<T1 extends string, T2 extends string, P, R>(
   requestType: T1, successType: T2) {

   const request = createAction(getBeforeActionType(requestType), resolve => (payload: P) => resolve(payload));
   (request as TypeMeta<T1>).getType = () => requestType;

   return {
      request,
      response: createAction(successType, resolve => (payload: R) => resolve(payload)),
   };
}

function getBeforeActionType(requestType: string) {
   return 'BEFORE_' + requestType;
}

type ActionGuard<P, S = any> = (state: S, payload: P) => boolean;

function* process<T1 extends string, T2 extends string, P, R>(
   action: PayloadAction<T1, P>,
   apiActions: ApiActionsBuilder<T1, T2, P, R>,
   apiCall: (payload: P) => Promise<R>,
   slice?: string | ActionGuard<P>,
   guard?: ActionGuard<P>
) {
   if (slice !== undefined) {
      let state: StoreState;

      if (typeof (slice) === 'string') {
         state = yield effects.select((s: any) => s[slice]);
      } else {
         guard = slice;
         state = yield effects.select();
      }

      if (guard && !guard(state, action.payload)) { return; }
   }

   yield* invokeApi(apiActions, apiCall, action.payload);
}

export function* invokeApi<T1 extends string, T2 extends string, P, R>(
   apiActions: ApiActionsBuilder<T1, T2, P, R>,
   apiCall: (payload: P) => Promise<R>,
   payload: P) {

   yield* putApiRequestAction(apiActions, payload);

   try {
      const result: R = yield effects.call(apiCall, payload);
      yield effects.put(apiActions.response(result));
   } catch (e) {
      if (e instanceof Error) {
         yield* reportApiError(e);
      } else {
         throw e;
      }
   }
}

export function* invokeApiRetry(
   apiCall: () => Iterator<any, any, any>
) {
   let retries = 0
   while (retries <= 10) {
      try {
         yield effects.call(apiCall)
         return
      } catch (e) {

         if (e instanceof Api.HttpError && e.status === 504) {
            const n = backoff(retries++)
            yield delay(n)
            console.log(`Received 504 from server performing retry: ${retries} (${n.toFixed(0)}ms delay)`)
         } else {
            yield reportApiError(e)
            return null
         }
      }
   }
   yield effects.put(actions.reconnect());
   return null
}

export function* putApiRequestAction<T1 extends string, T2 extends string, P, R>(apiActions: ApiActionsBuilder<T1, T2, P, R>, payload: P) {
   yield effects.put({ type: (apiActions.request as TypeMeta<string>).getType!(), payload });
}

export function* reportApiError(e: any) {

   if (e instanceof Api.HttpError && e.status === 401) {
      yield effects.put(actions.reconnect());
   } else if (e instanceof Api.HttpError && e.status === 403) {
      let detail: Api.ApiError = { title: "Forbidden", detail: "Permission denied. Unable to call this API endpoint", code: 'ErrorGeneral', instance: e.apiError.instance, status: e.status }
      const err = new Api.HttpError(e.status, "Forbidden", detail);
      yield effects.put(actions.apiError(err));
   } else if (e instanceof Error) {
      yield effects.put(actions.apiError(e));
   }
}

export function processLatest<T1 extends string, T2 extends string, P extends P2, R, P2>(
   apiActions: ApiActionsBuilder<T1, T2, P, R>,
   apiCall: (payload: P2) => Promise<R>,
   guard?: ActionGuard<P, StoreState>
): effects.ForkEffect;

export function processLatest<T1 extends string, T2 extends string, P extends P2, R, K extends keyof StoreState, P2>(
   apiActions: ApiActionsBuilder<T1, T2, P, R>,
   apiCall: (payload: P2) => Promise<R>,
   slice: K,
   guard: ActionGuard<P, StoreState[K]>
): effects.ForkEffect;

export function processLatest<T1 extends string, T2 extends string, R>(
   apiActions: ApiActionsBuilder<T1, T2, void, R>,
   apiCall: () => Promise<R>,
   guard?: ActionGuard<void, StoreState>
): effects.ForkEffect;

export function processLatest<T1 extends string, T2 extends string, R, K extends keyof StoreState>(
   apiActions: ApiActionsBuilder<T1, T2, void, R>,
   apiCall: () => Promise<R>,
   slice: K,
   guard: ActionGuard<void, StoreState[K]>
): effects.ForkEffect;

export function processLatest<T1 extends string, T2 extends string, P extends P2, R, P2>(
   apiActions: ApiActionsBuilder<T1, T2, P, R>,
   apiCall: (payload: P2) => Promise<R>,
   slice?: string | ActionGuard<P>,
   guard?: ActionGuard<P>
) {
   return effects.takeLatest(getBeforeActionType(getType(apiActions.request)), (a: any) => process(a, apiActions, apiCall, slice, guard));
}

export function processEvery<T1 extends string, T2 extends string, P extends P2, R, P2>(
   apiActions: ApiActionsBuilder<T1, T2, P, R>,
   apiCall: (payload: P2) => Promise<R>,
   guard?: ActionGuard<P, StoreState>
): effects.ForkEffect;

export function processEvery<T1 extends string, T2 extends string, P extends P2, R, K extends keyof StoreState, P2>(
   apiActions: ApiActionsBuilder<T1, T2, P, R>,
   apiCall: (payload: P2) => Promise<R>,
   slice: K,
   guard: ActionGuard<P, StoreState[K]>
): effects.ForkEffect;

export function processEvery<T1 extends string, T2 extends string, R>(
   apiActions: ApiActionsBuilder<T1, T2, void, R>,
   apiCall: () => Promise<R>,
   guard?: ActionGuard<void, StoreState>
): effects.ForkEffect;

export function processEvery<T1 extends string, T2 extends string, R, K extends keyof StoreState>(
   apiActions: ApiActionsBuilder<T1, T2, void, R>,
   apiCall: () => Promise<R>,
   slice: K,
   guard: ActionGuard<void, StoreState[K]>
): effects.ForkEffect;

export function processEvery<T1 extends string, T2 extends string, P extends P2, R, P2>(
   apiActions: ApiActionsBuilder<T1, T2, P, R>,
   apiCall: (payload: P2) => Promise<R>,
   slice?: string | ActionGuard<P>,
   guard?: ActionGuard<P>
) {
   return effects.takeEvery(getBeforeActionType(getType(apiActions.request)), (a: any) => process(a, apiActions, apiCall, slice, guard));
}

export function takeLatest<T extends string, A extends Action<T>>(creator: (...args: any[]) => A, func: (state: StoreState, action: A) => any) {
   return effects.takeLatest(getType(creator), function* (action: A) {
      const state: StoreState = yield effects.select();
      yield* func(state, action);
   });
}

export function takeEvery<T extends string, P>(
   creator: (...args: any[]) => PayloadAction<T, P>,
   func: (state: StoreState, payload: P) => Iterator<any, any, any>,
): effects.ForkEffect;

export function takeEvery<T extends string>(
   creator: NoArgCreator<T>,
   func: (state: StoreState) => Iterator<any, any, any>,
): effects.ForkEffect;

export function takeEvery<T1 extends string, T2 extends string, P extends P2, R, P2>(
   apiActions: ApiActionsBuilder<T1, T2, P, R>,
   func: (state: StoreState, payload: P) => Iterator<any, any, any>,
): effects.ForkEffect;

export function takeEvery(
   creator: ApiActionsBuilder<string, string, any, any> | ((...args: any[]) => any),
   func: (state: StoreState, payload: any) => any
) {
   function* handler(action: { payload: any }) {
      const state: StoreState = yield effects.select();
      yield* func(state, action.payload);
   }

   if ('request' in creator) {
      return effects.takeEvery<any>(getBeforeActionType(getType(creator.request)), handler);
   } else {
      return effects.takeEvery<any>(getType(creator), handler);
   }
}
