import moment from 'moment';
import { buffers, eventChannel } from 'redux-saga';
import { call, fork, join, put, select, take } from 'redux-saga/effects';
import * as Api from '../api';
import pushNotifications, { PushNotification } from '../pushNotifications';
import { selectRoomFilter, selectRooms } from '../store/helpers';
import { getDraftResources } from '../store/reducers/booking';
import { actions, StoreState } from '../store/utils';
import { flatten, isDefined } from '../utils/misc';
import { ApiResult } from './api';
import { runAvailabilitySearch } from './suggestions';
import { goHome } from './utils';
import { getDayStart } from '../utils/dateUtils'

export default function* pushNotificationsSaga() {
   const channel = eventChannel<PushNotification>(emitter => pushNotifications(emitter), buffers.expanding(20))

   for (; ;) {
      const notification: PushNotification = yield take(channel);


      console.log('mgb: Notification', notification)

      if (notification === 'reconnecting') {
         yield put(actions.reconnecting());
         continue;
      }

      if (notification === 'reconnected') {
         yield put(actions.reconnect());
         continue;
      }

      // Update includes new meetings.
      if ('update' in notification) {

         
         console.log('mgb: Notification update')

         const { meeting } = notification.update
         const state: StoreState = yield select()
         const cal = state.calendars[meeting.calendarContext]
         
         if (cal) {
            // find first meeting in this calendar that has the same iCalUid
            const id = Object.keys(cal)
               .map(n => cal[parseInt(n)])
               .map(ids => ids && ids.find(id => state.meetings[id] && state.meetings[id]!.iCalUid === meeting.iCalUid))
               .filter(x => x)[0]
            
           
            const days = Object.keys(cal).map(x => parseInt(x)).filter(key => cal[key] !== undefined)

            if (id || doesAffectCachedDay(meeting, days)) {
               yield put(actions.replaceMeetingData(meeting.id, meeting))
            } 

         }

         if (meeting.organiser && !state.contacts[meeting.organiser.emailAddress]) {
            yield put(actions.fetchContacts.request([meeting.organiser.emailAddress]))
         }
      }

      if ('delete' in notification) {
         const { escapedId, id } = notification.delete
         yield put(actions.removeMeeting(escapedId))

         const state: StoreState = yield select()
         const instances = Object.values(state.meetings).filter(isDefined).filter(m => m.recurrenceId === id)
         for (const instance of instances) {
            yield put(actions.removeMeeting(instance.id))
         }
      }

      if ('resourceAvailabilityChange' in notification) {
         const state: StoreState = yield select()

         if (!state.booking) continue

         const resources = getDraftResources(state.booking)
         const allRooms = selectRooms(state)
         const filterRoom = selectRoomFilter(state)

         const match = resources.some(res =>
            allRooms.some(r => filterRoom(r, res) && r.emailAddress === notification.resourceAvailabilityChange.emailAddress)
         )
         if (match) {
            yield runAvailabilitySearch('silent')
         }
      }

      if ('reccurrence' in notification) {
         const { email, id } = notification.reccurrence
         yield processRecurrenceUpdate(email, id)
      }

      if ('' in notification) {

      }

      if ('locationTag' in notification) {
         const { userEmail, token } = notification.locationTag
         const result: ApiResult<typeof Api.secondaryLogin> = yield call(Api.secondaryLogin, { userName: userEmail, token })

         if (typeof result === 'string') {
            yield put(actions.setPopupError(result))
         } else {
            yield put(actions.secondaryLogin.response(result))
            yield goHome()
            yield put(actions.setSplash(`Welcome,\n${result.contact.name}`))
         }
      }

      if ('visitorTag' in notification) {
         yield put(actions.setDisplayBoardWelcome(notification.visitorTag))
      }

      if ('organiserNotified' in notification) {
         yield put(actions.setDisplayBoardOrganiserNotified(notification.organiserNotified))
      }

      if ('reload' in notification) {
         const { organisationId } = notification.reload
         const state: StoreState = yield select()
         if (state.login.data && state.login.data.organisation && state.login.data.organisation.organisationId === organisationId ||
            state.secondaryLogin && state.secondaryLogin.organisation.organisationId === organisationId) {
            window.location.reload()
         }
      }
   }
}

function doesAffectCachedDay(meeting: Api.MeetingData, days: number[]) {
   
   const meetingDayStart = getDayStart(meeting.startTime)
   const meetingDayEnd = getDayStart(meeting.endTime)
   const today = getDayStart(moment())

   let meetingDays=[]

   // Convert the range into individual dates
   for(let dt = new Date(meetingDayStart); dt <= new Date(meetingDayEnd); dt.setDate(dt.getDate()+1)){
      meetingDays.push(new Date(dt));
      if (dt.getTime() === today) {
         return true
      }
  }

   // Are any of the meeting days on the cache?
   for(let i=0; i < days.length; i++){
      if (meetingDays.some(meetingDay => meetingDay.getTime() === days[i])) {
         return true;
      }
   }

   return false
}


function* processRecurrenceUpdate(email: string, recId: string): Iterator<any, any, any> {

   const state: StoreState = yield select()
   const cal = state.calendars[email]
   
   

   if (cal) {
      const days = Object.keys(cal).map(x => parseInt(x)).filter(key => cal[key] !== undefined)

      const forks = []
      for (const range of mergeDays(days)) {
         forks.push(yield fork(Api.fetchRecurrenceInstances, email, recId, range.start, range.end))
      }

      const meetings: ApiResult<typeof Api.fetchRecurrenceInstances> = flatten(yield join(forks))

      for (const m of Object.values(state.meetings)) {
         if (m?.recurrenceId === recId && m.calendarContext === email) {
            yield put(actions.removeMeeting(m.id))
         }
      }

      for (const m of meetings) {
         yield put(actions.addMeetingData(email, m))
      }
   }
}

function* mergeDays(days: number[]) {
   if (days.length === 0) { return }
   days.sort((a, b) => a - b)

   let start = days[0]
   for (let i = 1; i < days.length; i++) {
      if (Math.round(moment(days[i]).diff(days[i - 1], 'd', true)) > 1) {
         yield { start, end: moment(days[i - 1]).endOf('d').valueOf() }
         start = days[i]
      }
   }

   yield { start, end: moment(days[days.length - 1]).endOf('d').valueOf() }
}
