import { all, call } from 'redux-saga/effects';
import { reconnect, subscribe } from '../pushNotifications';
import { actions, StoreState } from '../store/utils';
import { takeEvery } from '../utils/apiActions';

const subscribeOnLogin = takeEvery(actions.login.response, onSubscribe)
const subscribeOnWhoAmI = takeEvery(actions.whoAmI.response, onSubscribe)
const subscribeOnSecondaryLogin = takeEvery(actions.secondaryLogin.response, onSubscribe)
const subscribeOnSecondaryLogout = takeEvery(actions.secondaryLogout, onSubscribe)

function* onSubscribe(state: StoreState) {
   const { login: { data } } = state
   if (!data) { return }

   yield reconnect()

   try {
      if (data.device?.home === 'DisplayBoard') {
         yield call(subscribe, ...state.localRooms)
      } else {
         yield call(subscribe, data.myself)
      }

      if (state.secondaryLogin) {
         yield call(subscribe, state.secondaryLogin.emailAddress)
      }
   } catch (e) {
      console.warn('Subscription failed', e)
   }
}

export default all([
   subscribeOnLogin,
   subscribeOnWhoAmI,
   subscribeOnSecondaryLogin,
   subscribeOnSecondaryLogout,
])
