import classNames from 'classnames';
import React, { useRef, useState } from 'react';
import { Contact, TeamWithMembers } from '../model';
import { selectTeams } from '../store/helpers';
import { useSelector } from '../store/utils';
import Autocomplete from './Autocomplete';
import ContactPhoto from './ContactPhoto';
import css from './TeamsDropdown.module.scss';

type TeamsDropdown = {
   className?: string
   right?: boolean
   onSelect: (team: TeamWithMembers) => void
}

export default function TeamsDropdown({ className, onSelect, right, children }: React.PropsWithChildren<TeamsDropdown>) {
   const teams = useSelector(selectTeams)
   const [isOpen, setOpen] = useState(false)
   const [value, setValue] = useState('')

   const canOpen = useRef(true)
   const openTimeout = useRef<number>()

   function renderTeam(team: TeamWithMembers, isHighlighted: boolean, styles?: React.CSSProperties) {
      return <div key={team.id} className={classNames(css.team, isHighlighted && css.active)} style={styles}>
         <div className={css.title}>
            {team.displayName}
         </div>
         <div className={css.members}>
            {renderMembers(team)}
         </div>
      </div>
   }

   function handleSelect(team: TeamWithMembers) {
      setOpen(false)
      onSelect(team)
   }

   function onBlur() {
      window.clearTimeout(openTimeout.current)
      canOpen.current = false
      setOpen(false)
      openTimeout.current = window.setTimeout(() => canOpen.current = true, 200)
   }

   const menuClass = classNames(css.autoComplete, right && css.right)

   return <div className={classNames(css.teamsDropdown, className)} onBlur={() => teams === null && onBlur()}>
      <button className={css.toggle} onClick={() => canOpen.current && setOpen(!isOpen)}>
         {children}
      </button>
      {isOpen && (teams === null ? <div className={menuClass}>Loading...</div> : <Autocomplete
         className={menuClass}
         getItems={text => teams.filter(t => filterTitle(t.displayName, text))}
         value={value}
         onChange={(_, v) => setValue(v)}
         getItemValue={t => t.id}
         renderItem={renderTeam}
         onSelect={(_, t) => handleSelect(t)}
         onBlur={onBlur}
         renderInput={props => <input {...props} placeholder='Type to search...' autoFocus />}
         menuClassName={css.menu}
      />)}
   </div>
}

export function renderMembers(team: TeamWithMembers) {
   if (team.members.length <= 3) {
      return team.members.map(renderMember)
   }

   const rest = team.members.filter((_, i) => i >= 2).map(m => m.name)

   return <>
      {team.members.filter((_, i) => i < 2).map(renderMember)}
      <div className={classNames(css.member, css.moreMembers)} title={rest.join(', ')}>
         +{rest.length}
      </div>
   </>
}

function renderMember(contact: Contact) {
   return <ContactPhoto className={css.member} key={contact.emailAddress} contact={contact} />
}

function filterTitle(title: string, input: string) {
   if (!input) { return true }
   const inputs = input.toLowerCase().split(' ')
   return inputs.every(inp => title.split(' ').some(t => t.toLowerCase().startsWith(inp)))
}
