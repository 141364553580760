import classNames from 'classnames';
import moment from 'moment';
import React, { useEffect, useState } from 'react';
import { useDispatch } from 'react-redux';
import { getPhotoUrl } from '../api';
import { Contact, isRoom } from '../model';
import { selectBookingTime } from '../store/helpers';
import { selectSchedule, shouldLoadSchedule } from '../store/reducers/schedules';
import { actions, useSelector } from '../store/utils';
import css from './ContactPhoto.module.scss';
import { useCurrentBookingTime } from './mobile/CalendarCursor';
import { ResourceIcon } from './ResourceIcon';

interface ContactPhotoProps {
   contact: Contact;
   className?: string;
   title?: string;
   onClick?(): void;
}

export default function ContactPhoto({ contact, onClick, className }: ContactPhotoProps) {
   const [loaded, setLoaded] = useState<boolean>()

   if (isRoom(contact)) {
      return <ResourceIcon className={classNames(css.icon, className)} onClick={onClick} resource={contact} />
   }

   return <>
      {!loaded && <div className={classNames(css.error, className)} title={contact.name}>
         <svg viewBox='0 0 30 30' style={getColors(contact.emailAddress)}>
            <text x='50%' y='55%' dominantBaseline='middle' textAnchor='middle'>{getInitials(contact.name) || '?'}</text>
         </svg>
      </div>}
      {loaded !== false && <img style={loaded ? undefined : { position: 'absolute', opacity: 0 }}
         className={classNames(css.photo, className)} src={getPhotoUrl(contact.emailAddress)}
         title={contact.name} onError={() => setLoaded(false)} onLoad={() => setLoaded(true)} />}
   </>
}

function getInitials(name: string) {
   return (/(.)[^ ]+ ?(?:\(.*?\) ?)?(.)?/.exec(name) || []).slice(1).join('').toUpperCase()
}

function getColors(email: string): React.CSSProperties {
   const hue = hash(email) % 360
   return {
      background: `hsl(${hue}, 50%, 90%)`,
      color: `hsl(${hue}, 80%, 25%)`,
   }
}

function hash(str: string) {
   let hash = 0
   for (let i = 0; i < str.length; i++) {
      const chr = str.charCodeAt(i)
      hash = (((hash << 5) - hash) + chr)|0
   }
   return hash
}

export function useIsAttendeeFree(email: string) {
   const dispatch = useDispatch()
   const curBooking = useCurrentBookingTime()
   const fallbackBooking = useSelector(selectBookingTime)
   const date = useSelector(s => s.selectedDate)
   const schedule = useSelector(s => selectSchedule(s, email, date))
   const shouldLoad = useSelector(s => shouldLoadSchedule(s, email, date))

   useEffect(() => {
      if (shouldLoad) {
         dispatch(actions.fetchScheduleRequest(date, email))
      }
   }, [date, dispatch, email, shouldLoad])

   const booking = curBooking || fallbackBooking
   if (!schedule || !booking) { return null }

   const start = moment(date).add(booking.start, 'h').valueOf()
   const end = moment(start).add(booking.duration, 'h').valueOf()

   return !schedule.periods.some(p => start < p.end && p.start < end)
}
