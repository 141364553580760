import React from 'react';
import { useDispatch } from 'react-redux';
import { TeamWithMembers } from '../../model';
import { selectTeams } from '../../store/helpers';
import { actions, useSelector } from '../../store/utils';
import { listOf } from '../../utils/react';
import { VideoFxIcon } from '../icons';
import { renderMembers } from '../TeamsDropdown';
import FormField from './FormField';
import { Header } from './Header';
import { usePageNav } from './navigation';
import SideSheet from './SideSheet';
import css from './VideoFxTeams.module.scss';

export default function VideoFxTeams() {
   const dispatch = useDispatch()
   const { isOpen, open } = usePageNav()
   const teams = useSelector(selectTeams)
   const selectedTeams = useSelector(s => s.booking ? s.booking.teamsToJoin : [])

   function onToggle(team: TeamWithMembers) {
      const tt = { teamId: team.id, teamName: team.displayName }

      if (selectedTeams.some(t => t.teamId === team.id)) {
         dispatch(actions.removeTeam(tt))
      } else {
         dispatch(actions.addTeam(tt))
      }
   }

   return <>
      <FormField icon={<VideoFxIcon />}>
         <button onClick={open} className={css.button}>
            {selectedTeams.length === 0
               ? <div className={css.placeholder}>Choose VideoFX teams...</div>
               : listOf(selectedTeams.map(t => t.teamName).sort(), ', ')}
         </button>
      </FormField>
      <SideSheet open={isOpen}>
         <Header back>
            Choose VideoFX teams
         </Header>
         <SideSheet.Body>
            {!teams ? 'Loading...' :
            <ul className={css.teams}>
               {teams.map(team => <li key={team.id}>
                  <label className={css.team}>
                     <input type='checkbox' checked={selectedTeams.some(t => t.teamId === team.id)} onChange={() => onToggle(team)} />
                     <div className={css.name}>{team.displayName}</div>
                     <div className={css.members}>{renderMembers(team)}</div>
                  </label>
               </li>)}
            </ul>}
         </SideSheet.Body>
      </SideSheet>
   </>
}
