import bind from 'bind-decorator';
import classNames from 'classnames';
import React, { useCallback } from 'react';
import { useDispatch } from 'react-redux';
import { BookingProps } from '../containers/Booking';
import Schedule from '../containers/Schedule';
import TimelinePopup from '../containers/TimelinePopup';
import * as model from '../model';
import { getSecondaryUser } from '../store/helpers';
import { actions, useSelector } from '../store/utils';
import css from './Booking.module.scss';
import { ReactComponent as AddIcon } from './buttons/add.svg';
import { ReactComponent as EditIcon } from './buttons/edit.svg';
import SheduleDatePicker from './ScheduleDatePicker';

function getInitialState() {
   return {
      selected: undefined as model.Contact | undefined,
      location: [] as model.LocationFilter,
   }
}

export interface OwnProps {
   roomListMode: boolean;
}

type Props = BookingProps & OwnProps;
type State = ReturnType<typeof getInitialState>;

export default class Booking extends React.PureComponent<Props, State> {
   public readonly state = getInitialState();

   public componentDidMount() {
      this.selectFirstRoom();
   }

   public render() {
      const { date, roomListMode, booking, teamsMode, loginDisabled } = this.props;

      if (!roomListMode && !booking) { return null }

      return <div className={classNames(css.booking, teamsMode && css.teamsMode)}>
         {this.renderHeader()}
         <div className={css.scheduleContainer}>
            <div>
               <Schedule rooms={this.getFilteredRooms()} roomListMode={roomListMode} onClickSegment={loginDisabled ? undefined : this.onClickSegment}
                  onLocationSelected={location => this.setState({ location })}
                  onContactClick={this.onContactClick} highlight={this.state.selected?.emailAddress} />
            </div>
         </div>
         <TimelinePopup email={this.state.selected?.emailAddress} onClose={this.onCloseSchedule} time={date} />
      </div>;
   }

   public componentDidUpdate() {
      this.selectFirstRoom();
   }

   private selectFirstRoom() {
      if (this.props.roomListMode && this.state.selected === undefined) {
         const rooms = this.getFilteredRooms()
         if (rooms?.length) {
            this.setState({ selected: rooms[0] })
         }
      }
   }

   @bind
   private renderHeader() {
      const { date, setDate, roomListMode, goBack, startBooking, isEditing, loginDisabled } = this.props;

      return <div className={css.header}>
         <button className={css.backButton} onClick={() => goBack(roomListMode)}>
            Back
         </button>
         <SheduleDatePicker className={css.date} value={date} onChange={setDate} />
         {!roomListMode && <button className={css.mainButton} onClick={this.props.book}>{isEditing ? 'Save' : 'Book'}</button>}
         {roomListMode && !loginDisabled && <>
            <div tabIndex={0} className={css.headerButton} onClick={() => {
               startBooking(this.state.selected)
               console.log("New clicked", this.state.selected)
            }}>
               <AddIcon />
               New
            </div>
            <EditBookingButton />
         </>}
      </div>
   }

   private getFilteredRooms() {
      const { filterRoom, allRooms, participants, roomListMode } = this.props;
      const rooms = allRooms.filter(r => filterRoom(r, this.state.location))
      return roomListMode ? rooms : rooms.filter(room => !participants.includes(room));
   }

   @bind
   private onClickSegment(c: model.Contact, hr: number) {
      const { roomListMode, startBooking } = this.props;
      if (!roomListMode) { return };
      startBooking(c, hr);
   }

   @bind
   private onContactClick(c: model.Contact) {
      this.setState({ selected: c });
   }

   @bind
   private onCloseSchedule() {
      this.setState({ selected: undefined });
   }
}

function EditBookingButton() {
   const myself = useSelector(getSecondaryUser)
   const dispatch = useDispatch()

   const onClick = useCallback(() => {
      if (myself) {
         dispatch(actions.goToPage(model.Page.calendar))
      } else {
         dispatch(actions.openPopup('myCalendar'))
      }
   }, [ myself, dispatch ])

   return <div className={css.headerButton} tabIndex={0} role='button' onClick={onClick}>
      <EditIcon />
      Edit Booking
   </div>
}
