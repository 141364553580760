import moment from 'moment';
import { useEffect } from 'react';
import { useDispatch } from 'react-redux';
import { MeetingData } from '../../api';
import { actions, useSelector } from '../../store/utils';
import { getDayStart } from '../../utils/dateUtils';
import useInterval from '../../utils/useInterval';
import FloorMap from './FloorMap';
import css from './index.module.scss';
import { ReactComponent as Logo } from './logo.svg';
import RoomList from './RoomList';

export default function DisplayBoard() {
   const dispatch = useDispatch()
   const login = useSelector(s => s.login.data)
   const time = useSelector(s => s.time)
   const rooms = useSelector(s => s.localRooms)
   const date = getDayStart(time)
   const { welcome, organiserNotified } = useSelector(s => s.displayBoard)

   useEffect(() => {
      document.documentElement.style.fontSize = '2vmin'
      return () => { document.documentElement.style.fontSize = '' }
   }, [])

   useEffect(() => {
      for (let r of rooms) {
         dispatch(actions.fetchCalendarsRequest(date, moment(date).endOf('d').valueOf(), r))
      }
   }, [ date, dispatch, rooms ])

   useDispayBoardTimeout()

   const device = login && login.device
   if (!device) { return null }

   function renderContent() {
      if (organiserNotified) {
         return <div className={css.notification}>
            <div className={css.name}><em>{organiserNotified.name}</em> has been notified.</div>
         </div>
      } else if (welcome) {
         return <div className={css.notification}>
            <div className={css.name}>Welcome, <em>{welcome.person.name}</em>!</div>
            {renderMeetings(welcome.meetings)}
         </div>
      } else {
         return <RoomList className={css.rooms} />
      }
   }

   return <div className={css.DisplayBoard}>
      <h1>{device.name}</h1>
      <div className={css.time}>{moment(time).format('LT')}</div>
      <div className={css.date}>{moment(time).format('dddd, LL')}</div>

      <div className={css.content}>
         {renderContent()}

         <FloorMap className={css.map} />
      </div>

      <Logo className={css.logo} />
   </div>
}

function useDispayBoardTimeout() {
   const dispatch = useDispatch()
   const { welcome, organiserNotified } = useSelector(s => s.displayBoard)

   function getTimeout() {
      if (organiserNotified) {
         return 5 * 1000
      } else if (welcome) {
         return 20 * 1000
      } else {
         return null
      }
   }

   useInterval(() => {
      dispatch(actions.timeoutDisplayBoard())
   }, getTimeout())
}

function renderMeetings(meetings: MeetingData[]) {
   if (!meetings.length) {
      return <div>You don't have any meetings today.</div>
   }

   const meeting = meetings[0]
   const room = meeting.meetingRooms[0]
   return <div>
      Your meeting '<em>{meeting.subject}</em>' is at <em>{moment(meeting.startTime).format('LT')}</em>{room ? <> in <em>{room.name}</em></> : null}.
   </div>
}
