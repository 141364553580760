import moment from 'moment';
import { getType } from 'typesafe-actions';
import { Suggestion } from '../../model';
import { actions, Actions, StoreState } from '../utils';

// false - waiting for request
// null - loading (waiting for response)

interface Suggestions {
   suggestions: Suggestion[]
   loading: boolean
}

interface SuggestionsReducer {
   draft: Suggestions
   final: Suggestions

   /** whether there is a resource filter being edited */
   hasDraft: boolean
}

const empty: Suggestions = { suggestions: [], loading: false }

export default function suggestions(state: SuggestionsReducer = { draft: empty, final: empty, hasDraft: false }, action: Actions): SuggestionsReducer {
   switch (action.type) {
      case getType(actions.rollbackResource):
         return { ...state, draft: empty, hasDraft: false }

      case getType(actions.commitResource):
      case getType(actions.removeResource):
         return { draft: empty, final: state.draft, hasDraft: false }

      case getType(actions.fetchSuggestionRequesting): {
         return {
            ...state,
            draft: state.hasDraft ? { ...state.draft, loading: true } : state.draft,
            final: state.hasDraft ? state.final : { ...state.final, loading: true },
         }
      }

      case getType(actions.fetchSuggestionResponse): {
         return {
            ...state,
            draft: state.hasDraft ? { suggestions: action.payload, loading: false } : empty,
            final: !state.hasDraft || state.final.loading ? { suggestions: action.payload, loading: false } : state.final,
         }
      }

      case getType(actions.editResource): {
         return {
            ...state,
            hasDraft: true,
            draft: action.payload.newResource === action.payload.oldResource ? state.final : empty,
         }
      }
   }

   return state
}

export function selectCurrentDraftSuggestion(state: StoreState) {
   const { booking, selectedDate, suggestions: { draft: { suggestions } } } = state
   if (!booking) { return null }

   const start = moment(selectedDate).add(booking.start, 'h').valueOf()
   return suggestions.find(s => s.start === start) ?? null
}
