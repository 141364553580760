import { AuthError, BrowserCacheLocation, BrowserAuthErrorMessage } from '@azure/msal-browser';
import createAzureAdClient from './azure-ad';

export function createBookitAzureAdClient(appId: string, tenantId: string, loginHint?: string) {
   return createAzureAdClient({
      clientId: appId,
      scopes: ['https://graph.microsoft.com/User.ReadBasic.All'],
      redirectUrl: '/blank',
      authority: `https://login.microsoftonline.com/${tenantId}/`,
      forceLogin: true,
      loginHint,
      cacheLocation: BrowserCacheLocation.SessionStorage,
   })
}

export async function bookitAzureAdPopup(clientId: string, tenantId: string, loginHint?: string) {
   const app = createBookitAzureAdClient(clientId, tenantId, loginHint)

   try {
      sessionStorage.clear()
      return await app.acquirePopup()
   } catch (e) {
      const err: AuthError = e

      if (err.errorCode === BrowserAuthErrorMessage.userCancelledError.code) {
         return null
      } else {
         return err.message
      }
   }
}
