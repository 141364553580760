import moment from 'moment';
import { getType } from 'typesafe-actions';
import { SharedOrganisationData } from '../../api';
import { Agenda, Attachment, AttendeeData, AttendeeType, BookingResourcesFilter, BookingTime, MeetingType, Nearby, PermissionSet, Recurrence, RoomType, Suggestion, TeamToJoin } from '../../model';
import { timeOfDayInHours } from '../../utils/dateUtils';
import { keysOf } from '../../utils/misc';
import { actions, Actions } from '../utils';

type Booking = BookingTime & {
   allDay: boolean
   participants: AttendeeData[]
   meetingId?: string

   suggested: Suggestion | null
   suggestionWarning?: string
   addResourcesMode: boolean

   originalRoom?: string

   subject: string
   isPrivate: boolean
   createAppointment: boolean
   types: MeetingType[]
   permissions: PermissionSet | null

   resources: BookingResourcesFilter[]
   oldResource: BookingResourcesFilter | null
   newResource: BookingResourcesFilter | null

   agenda: Agenda
   attachments: Attachment[]

   isRecurring: boolean
   recurrence: Recurrence | null

   full: boolean
   nearby?: Nearby

   teamsToJoin: TeamToJoin[]
   adhocOrganisation: SharedOrganisationData | null
}

function createBooking(): Booking {
   return {
      start: 0,
      allDay: false,
      duration: .5,
      participants: [],

      suggested: null,
      addResourcesMode: false,

      subject: '',
      agenda: { notes: '', items: [] },
      types: ['Teams'],
      isPrivate: false,
      createAppointment: true,
      permissions: null,

      resources: [],
      oldResource: null,
      newResource: null,

      attachments: [],

      isRecurring: false,
      recurrence: null,
      full: false,

      teamsToJoin: [],
      adhocOrganisation: null
   }
}

export default function booking(state: Booking | null = null, action: Actions): Booking | null {
   switch (action.type) {
      case getType(actions.startBooking): {
         const { participants, meeting, room, start: hr, nearby, pinnedRoom, full } = action.payload

         const result = state ? { ...state } : createBooking()

         if (participants) {
            result.participants = [...new Set([...result.participants, ...participants])]
         }

         if (pinnedRoom) {
            result.resources = [{
               type: 'MeetingRoom',
               quantity: 1,
               location: [null],
               sizeIndex: -1,
               attributes: [],
               extraBefore: 0,
               extraAfter: 0,
               pinned: [pinnedRoom]
            }]
         }

         if (full) {
            result.full = full
         }

         if (meeting) {
            result.meetingId = meeting.id
            result.subject = meeting.subject
            // result.permissions = meeting.permisssions
            result.agenda = { notes: meeting.agendaNotes, items: meeting.agendaItems }
            result.types = meeting.types
            result.isPrivate = meeting.isPrivate
            result.resources = meeting.filter
            result.isRecurring = meeting.isRecurring
            result.allDay = meeting.allDay
            result.attachments = meeting.attachments
            result.teamsToJoin = meeting.teamsToJoin
            result.full = true
         }

         result.originalRoom = room
         result.start = hr
         result.nearby = nearby

         if (nearby) {
            result.types = []
         }

         result.createAppointment = mustCreateAppointment(result)
         return result
      }

      case getType(actions.startAddResource): {
         const { id, startTime, endTime, allDay } = action.payload
         const start = timeOfDayInHours(startTime)
         return {
            ...createBooking(),
            full: true,
            meetingId: id,
            start,
            duration: moment(endTime).diff(startTime, 'h', true),
            allDay,
            addResourcesMode: true,
         }
      }

      case getType(actions.endBooking):
         return null

      case getType(actions.addParticipant): {
         const { participants, ...rest } = state || createBooking()
         if (participants.some(p => p.emailAddress === action.payload.emailAddress)) { return state }
         return {
            ...rest,
            createAppointment: true,
            participants: [...participants, action.payload],
         }
      }

      case getType(actions.removeParticipant): {
         const { participants, ...rest } = state || createBooking()
         return {
            ...rest,
            participants: participants.filter(p => p.emailAddress !== action.payload.emailAddress),
         }
      }

      case getType(actions.setAttendeeType): {
         const { attendee, type } = action.payload
         if (!state) { return state }

         const index = state.participants.findIndex(p => p.emailAddress === attendee)
         if (index === -1 || state.participants[index].type === type) { return state }

         return {
            ...state,
            participants: [
               ...state.participants.slice(0, index),
               {
                  emailAddress: attendee,
                  type
               },
               ...state.participants.slice(index + 1),
            ],
         }
      }

      case getType(actions.acceptSuggestion): {
         if (!state) { return state }

         const { suggestion, warning } = action.payload
         const start = suggestion ? timeOfDayInHours(suggestion.start) : state.start

         return {
            ...state,
            start,
            suggested: suggestion,
            suggestionWarning: warning,
         }
      }

      case getType(actions.setBookingStart):
         if (!state) { return state }
         return {
            ...state,
            start: action.payload,
         }

      case getType(actions.setBookingDuration):
         return reduce(state, 'duration', action.payload <= 0 ? 0.5 : action.payload)

      case getType(actions.setBookingSubject):
         return reduce(state, 'subject', action.payload)

      case getType(actions.setPermissions):
         return reduce(state, 'permissions', action.payload)

      case getType(actions.setAdhocOrganisation):
            return reduce(state, 'adhocOrganisation', action.payload)

      case getType(actions.setBookingAgenda):
         return reduce(state, 'agenda', action.payload)

      case getType(actions.setMeetingTypes): {
         return reduce(state, 'types', [...action.payload])
      }

      case getType(actions.setMeetingPrivate): {
         state = reduce(state, 'isPrivate', action.payload)
         if (state.isPrivate) {
            state.createAppointment = true
         }
         return state
      }

      case getType(actions.setMeetingFull): {
         return reduce(state, 'full', action.payload)
      }

      case getType(actions.setCreateAppointment): {
         if (!state) { return state }
         state = { ...state, createAppointment: action.payload || mustCreateAppointment(state) }
         if (!state.createAppointment) {
            state.types = []
            state.isPrivate = false
         }
         return state
      }

      case getType(actions.setAttachments):
         return reduce(state, 'attachments', action.payload)

      case getType(actions.setAllDay): {
         const newState = reduce(state, 'allDay', action.payload)
         newState.duration = action.payload ? 24 : 0.5
         return newState
      }

      case getType(actions.setRecurrence): {
         if (!state) { return state }
         return reduce(state, 'recurrence', action.payload)
      }

      case getType(actions.editResource): {
         if (!state) { return state }
         const { oldResource, newResource } = action.payload

         return {
            ...state,
            oldResource,
            newResource,
         }
      }

      case getType(actions.updateResource): {
         if (!state?.newResource) { return state }

         const value = action.payload;
         const key = keysOf(value)[0]
         if (state.newResource[key] === value[key]) { return state }

         const newResource = {
            ...state.newResource,
            ...action.payload,
            pinned:
               key === 'quantity' ? state.newResource.pinned :
               key === 'pinned' ? value.pinned :
               [],
         }

         if (newResource.quantity < newResource.pinned.length) {
            newResource.quantity = newResource.pinned.length
         }

         return { ...state, newResource }
      }

      case getType(actions.removeResource): {
         if (!state?.oldResource) { return state }

         return reduce({ ...state, newResource: null, oldResource: null }, 'resources', state.resources.filter(r => r !== state!.oldResource))
      }

      case getType(actions.commitResource): {
         if (!state) { return state }
         const { oldResource, newResource } = state

         if (oldResource === null && newResource === null) {
            return state
         }

         return reduce({ ...state, newResource: null, oldResource: null }, 'resources', getDraftResources(state))
      }

      case getType(actions.rollbackResource): {
         if (!state) { return state }

         return {
            ...state,
            oldResource: null,
            newResource: null,
         }
      }

      case getType(actions.removeNearby): {
         state = reduce(state, 'nearby', undefined)
         if (action.payload) {
            state = reduce(state, 'resources', [action.payload])
         }
         return state
      }

      case getType(actions.addTeam): {
         if (!state) { return state }
         return reduce(state, 'teamsToJoin', [...state.teamsToJoin.filter(t => t.teamId !== action.payload.teamId), action.payload])
      }

      case getType(actions.removeTeam): {
         if (!state) { return state }
         return reduce(state, 'teamsToJoin', state.teamsToJoin.filter(t => t.teamId !== action.payload.teamId))
      }

      case getType(actions.secondaryLogin.response): {
         if (!state) { return state }
         return {
            ...state,
            full: true,
            participants: [{ emailAddress: action.payload.contact.emailAddress, type: AttendeeType.required }],
         }
      }
   }

   return state
}

function reduce<K extends keyof Booking>(state: Booking | null, key: K, value: Booking[K]) {
   const prevMust = state ? mustCreateAppointment(state) : false

   state = { ...(state || createBooking()), [key]: value }

   const must = mustCreateAppointment(state)

   if (must || prevMust !== must) {
      state.createAppointment = must
   }

   return state
}

export function mustCreateAppointment(booking: Booking) {
   const { resources, participants, meetingId, nearby } = booking
   return Boolean(meetingId) || participants.length > 1 || !(resources.length === 1 && resources[0].type !== 'MeetingRoom' && resources[0].quantity === 1 || nearby )
}

export function getExtraTime(extra: number, booking: Booking, type: RoomType) {
   return booking.createAppointment && !booking.allDay && (type === 'Locker' || type === 'ParkingSpace') ? extra : 0
}

export function getDraftResources({ newResource, oldResource, resources }: Booking) {
   if (oldResource === null) {
      return newResource === null ? resources : [...resources, newResource]
   } else if (newResource === null) {
      return resources.filter(r => r !== oldResource)
   } else {
      return resources.map(r => r === oldResource ? newResource : r)
   }
}
