import { getType } from 'typesafe-actions';
import { PopupParams } from '../../model';
import { actions, Actions } from '../utils';

type Popup = {
   isSubmitting: boolean;
   error?: string;
} & (PopupParams | { type: undefined })

export default function popup(state: Popup = { isSubmitting: false, type: undefined }, action: Actions): Popup {
   switch (action.type) {
      case getType(actions.openPopup):
         const { params, isSubmitting } = action.payload
         return { ...params, isSubmitting };

      case getType(actions.closePopup):
      case getType(actions.secondaryLogin.response):
         return { type: undefined, isSubmitting: false };

      case getType(actions.setPopupSubmitting):
         return { ...state, isSubmitting: action.payload, error: undefined };

      case getType(actions.secondaryExternalLogin):
         return { ...state, isSubmitting: true, error: undefined };

      case getType(actions.setPopupError):
         return { ...state, isSubmitting: false, error: action.payload  };
   }

   return state;
}
