import moment from 'moment';
import React from 'react';
import css from './TimeAndDate.module.scss';

interface Props {
   time: number;
}

export default function TimeAndDate({ time }: Props) {
   const date = moment(time);

   return <div className={css.timeAndDate}>
      <div className={css.time}>{date.format('LT')}</div>
      <div className={css.date}>{date.format('dddd, LL')}</div>
   </div>
}
