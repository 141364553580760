import classNames from 'classnames';
import React from 'react';
import { CSSTransition } from 'react-transition-group';
import Page from './Page';
import css from './SideSheet.module.scss';

interface Props {
   open: boolean
}

export default function SideSheet({ open, className, ...props }: Props & React.HTMLAttributes<HTMLDivElement>) {
   return <CSSTransition in={open} unmountOnExit timeout={150} classNames={{ enter: css.enter, enterActive: css.enterActive, exit: css.exit, exitActive: css.exitActive }}>
      <Page className={classNames(css.sideSheet, className)} {...props} />
   </CSSTransition>
}

SideSheet.Body = Page.Body
