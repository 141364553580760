import classNames from 'classnames';
import React from 'react';
import css from './Spinner.module.scss';

interface SpinnerProps {
   className?: string;
}

export default function Spinner({ className }: SpinnerProps) {
   return (
      <svg className={classNames(css.spinner, className)} viewBox="0 0 512 512" preserveAspectRatio="xMidYMid meet">
         <path d="M256,233a23,23,0,1,0,23,23A23,23,0,0,0,256,233Zm0,170a23,23,0,1,0,23,23A23,23,0,0,0,256,403ZM256,0C114.62,0,0,114.62,0,256S114.62,512,256,512,512,397.38,512,256,397.38,0,256,0Zm0,465C140.57,465,47,371.43,47,256S140.57,47,256,47s209,93.57,209,209S371.43,465,256,465ZM426,233a23,23,0,1,0,23,23A23,23,0,0,0,426,233ZM256,63a23,23,0,1,0,23,23A23,23,0,0,0,256,63ZM86,233a23,23,0,1,0,23,23A23,23,0,0,0,86,233Z" />
         <g className={css.hand}>
            <rect x="233" y="119" width="46" height="100" />
            {/* "transform-origin: center" doesn't work properly in Chrome 61 (electron) - works relative to the element, not the whole SVG, */}
            {/* so have to add this invisible element to make the center of the group match the center of the SVG */}
            <rect x="0" y="0" width="512" height="512" className={css.invisible} />
         </g>
      </svg>
   );
}
