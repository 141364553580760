import React, { useMemo, useState } from "react"
import FontAwesome from "react-fontawesome"
import Combobox from './Combobox';
import { Contact, isPerson } from '../model';
import css from './LoginAutoCompleteInput.module.scss';
import { searchContacts } from '../api';
import debounce from 'debounce-promise';
import ContactPhoto from "./ContactPhoto";


interface LoginAutoCompleteInputProps {
   onChange: (item: Contact | undefined) => void
}

const EmailRegex = /^\S+@\S\S+$/;

async function contactSearch(searchTerm: string): Promise<Contact[]> {
   let contacts = await searchContacts(searchTerm)
   let items = contacts
      .filter(c => isPerson(c) && !c.isGuest)
   if (EmailRegex.test(searchTerm) && !items.some(i => i.emailAddress.toLowerCase() === searchTerm.toLowerCase())) {
      items.push({
         name: searchTerm,
         emailAddress: searchTerm,
         type: 'Person',
         isGuest: true,
         jobTitle: "Login using this email address",
         department: null,
         organisation: null
      })
   }
   return items
}


export function LoginAutoCompleteInput({ onChange }: LoginAutoCompleteInputProps) {
   const [contact, setContact] = useState<Contact>()
   const search = useMemo(() => debounce(contactSearch, 300), [])
   //const tabletOrg = useState

   function handleChange(item: Contact | undefined, text: string) {

      if (item === undefined && EmailRegex.test(text)) {
         item = {
            name: text,
            emailAddress: text,
            type: 'Person',
            isGuest: true,
            jobTitle: "Login using this email address",
            department: null,
            organisation: null
         }
      }
      setContact(item)
      onChange(item)
   }

   return <div className={css.loginAutoCompleteInput}>
      <div className={css.icon}>
         <FontAwesome name='envelope' />
      </div>

      <Combobox
         className={css.comboBox}
         caret={false}
         items={text => search(text)}
         renderItem={v => <LoginInputItem item={v} tabletOrg='IPFX' />}
         getItemText={i => i === undefined ? '' : i.emailAddress}
         onChange={handleChange}
         value={contact}
      >
         {(inputRef, text, setText) => <input autoFocus required data-testid='login-email' ref={inputRef} className={css.input} type='email' value={text} onChange={e => setText(e.currentTarget.value)} />}
      </Combobox>
   </div>
}

interface LoginInputItemProps {
   item: Contact
   tabletOrg: string
}

function LoginInputItem({ item, tabletOrg}: LoginInputItemProps) {
   let name = tabletOrg === item.organisation  || item.organisation === null
         ? item.name
         : `${item.name} (${item.organisation})`
   return <div className={css.item} key={item.emailAddress}>
      <ContactPhoto contact={item} />
      <div className={css.details}>
         <div className={css.name}>{name}</div>
         {isPerson(item) ? <div>{item.jobTitle}</div> : null}
      </div>
   </div>
}
