import { routerMiddleware } from 'connected-react-router';
import { History } from 'history';
import { applyMiddleware, createStore, DeepPartial, Middleware } from 'redux';
import { createLogger } from 'redux-logger';
import createSagaMiddleware from 'redux-saga';
import { getType } from 'typesafe-actions';
import { reloadOnFatalError } from '..';
import bridge from '../javascriptBridge';
import rootSaga from '../sagas';
import createRootReducer from './reducers';
import { Actions, actions, StoreState } from './utils';

const skipLog = [actions.updateTime, actions.lastActivity].map<string>(a => getType(a));

const loggerMiddleware = createLogger({
   predicate: (getState, action: Actions) => {
      if (skipLog.includes(action.type)) {
         return false;
      }

      return true;
   }
});

const debugMiddleware: Middleware = store => next => action => {
   action = next(action)
   const log = (window as any).reduxLog
   if (log && !skipLog.includes(action.type)) {
      log.push({
         time: Date.now(),
         action,
         state: store.getState(),
      })
   }
   return action
}

function getAndroidLogMiddleware(): Middleware {
   const jsbridge = bridge

   if (!jsbridge || true) {
      return store => next => action => next(action)
   }

   return store => next => action => {
      action = next(action)

      if (!skipLog.includes(action.type)) {
         jsbridge.log(JSON.stringify({
            time: Date.now(),
            action,
            state: store.getState(),
         }))
      }
      return action
   }
}

const sagaMiddleware = createSagaMiddleware({
   onError(e, ei) {
      console.error('Unhandled saga error', e, ei.sagaStack)
      reloadOnFatalError()
   }
});

export function configureStore(preloadedState: DeepPartial<StoreState>, history: History, runSagas: boolean) {
   const store = createStore(
      createRootReducer(history),
      preloadedState,
      applyMiddleware(
         sagaMiddleware,
         loggerMiddleware,
         routerMiddleware(history),
         debugMiddleware,
         getAndroidLogMiddleware(),
      )
   );

   if (runSagas) {
      sagaMiddleware.run(rootSaga);
   }

   return store;
}
