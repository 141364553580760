import classNames from 'classnames';
import moment from 'moment';
import React, { useCallback, useEffect, useLayoutEffect, useMemo, useRef, useState } from 'react';
import { useDispatch } from 'react-redux';
import { AttendeeType, Meeting } from '../../model';
import { getSecondaryUser, getSelectedDate, isExternalUser } from '../../store/helpers';
import { makeMeetingsSelector } from '../../store/reducers';
import { actions, useSelector } from '../../store/utils';
import { roundStartTime } from '../../utils/dateUtils';
import { ReactComponent as AddIcon } from '../buttons/add.svg';
import ContactPhoto from '../ContactPhoto';
import { useLoadCalendar } from '../MyCalendar';
import { TimeGrid, TimeGridColumn, TimeGridItem } from '../TimeGrid';
import { CalendarCursor, useCurrentBookingTime } from './CalendarCursor';
import FloatingActionButton from './FloatingActionButton';
import { Header } from './Header';
import css from './MyCalendarMobile.module.scss';
import { NativeDatePicker } from './NativePickers';
import { routerActions } from 'connected-react-router';
import DefaultPage from './DefaultPage';

export default function MyCalendarMobile() {
   const dispatch = useDispatch()
   const myself = useSelector(getSecondaryUser)
   const date = useSelector(getSelectedDate)
   const externalUser = useSelector(isExternalUser)

   function onNew() {
      if (!myself) { return }
      dispatch(actions.startBooking({
         participants: [{ emailAddress: myself.emailAddress, type: AttendeeType.required }],
      }))
   }

   function onMeetingClick({ id }: Meeting) {
      dispatch(routerActions.push(`/meeting/${encodeURIComponent(id)}/add-resources`))
   }

   if (externalUser) {
      return <DefaultPage message="Thank you for using Book-It. To use Book-It again please follow a meeting link that you find in your Outlook meeting invite." />
   }

   return <div className={css.myCalendar}>
      <Header>
         <div className={css.header}>
            {myself && <ContactPhoto contact={myself} />}
            <NativeDatePicker className={css.month} value={date} onChange={v => v !== null && dispatch(actions.selectDate(v))}>
               {moment(date).format(moment().isSame(date, 'y') ? 'MMMM' : 'MMMM YYYY')}
            </NativeDatePicker>
         </div>
      </Header>
      <DatePicker />
      <Calendar onMeetingClick={onMeetingClick} />
      <FloatingActionButton onClick={onNew}>
         <AddIcon />
      </FloatingActionButton>
   </div>
}

export function DatePicker() {
   const dispatch = useDispatch()
   const date = useSelector(getSelectedDate)
   const curWeekRef = useRef<HTMLDivElement>(null)
   const [week, setWeek] = useState(moment(date).startOf('w').valueOf())
   const scrollEndTimer = useRef<number>()
   const touchDown = useRef(false)

   useEffect(() => {
      setWeek(moment(date).startOf('w').valueOf())
   }, [date])

   useLayoutEffect(() => {
      if (!curWeekRef.current) { return }
      curWeekRef.current.scrollIntoView()
   }, [week])

   function pickDate(date: number) {
      dispatch(actions.selectDate(date))
   }

   function onScrollEnd() {
      if (touchDown.current) {
         onScroll()
         return
      }

      const { current: curWeek } = curWeekRef
      if (!curWeek || !curWeek.parentElement) { return }

      const pos = Math.round(curWeek.parentElement.scrollLeft / curWeek.offsetWidth)

      curWeek.parentElement.scrollTo({ left: pos * curWeek.offsetWidth, behavior: 'smooth' })

      setTimeout(() => {
         if (pos < 1) {
            setWeek(moment(week).add(-1, 'w').valueOf())
         } else if (pos > 1) {
            setWeek(moment(week).add(1, 'w').valueOf())
         }
      }, 300)
   }

   function onScroll() {
      window.clearTimeout(scrollEndTimer.current)
      scrollEndTimer.current = window.setTimeout(onScrollEnd, 50)
   }

   function onTouchEnd() {
      touchDown.current = false
      onScroll()
   }

   function renderWeek(week: number, ref?: React.Ref<HTMLDivElement>) {
      const w = moment(week)
      return <div ref={ref} className={css.week}>
         {Array.from(new Array(7)).map((_, d) => w.add(d, 'd')).map(d =>
            <button key={d.valueOf()} onClick={() => pickDate(d.valueOf())} className={classNames({
               [css.day]: true,
               [css.active]: d.valueOf() === date,
               [css.today]: d.isSame(moment(), 'd'),
            })}>
               <div className={classNames({
                  [css.weekDay]: true,
                  [css.weekend]: d.day() === 0 || d.day() === 6,
               })}>{d.format('dd')}</div>
               <div className={classNames(css.bottom, !d.isSame(date, 'M') && css.nonCurrent)}>
                  {!d.isSame(date, 'M') && (d.startOf('M').isSame(d) || d.endOf('M').isSame(d, 'd')) && <div className={css.month}>{d.format('MMM')}</div>}
                  <div className={css.dayOfMonth}>{d.format('D')}</div>
                  {!d.isSame(date, 'y') && (d.startOf('y').isSame(d) || d.endOf('y').isSame(d, 'd')) && <div className={css.year}>{d.format('YYYY')}</div>}
               </div>
            </button>
         )}
      </div>
   }

   return <div className={css.datePicker} onTouchStart={() => touchDown.current = true} onTouchEnd={onTouchEnd} onScroll={onScroll}>
      {renderWeek(moment(week).add(-1, 'w').valueOf())}
      {renderWeek(week, curWeekRef)}
      {renderWeek(moment(week).add(1, 'w').valueOf())}
   </div>
}

type CalendarProps = {
   onMeetingClick?: (m: Meeting) => void
}

export function Calendar({ onMeetingClick }: CalendarProps) {
   const date = moment(useSelector(getSelectedDate)).startOf('d')
   useLoadCalendar(date.valueOf(), 1)
   const showTime = date.isSame(moment(), 'd')

   return <TimeGrid noHeaders showCurrentTime={showTime} className={css.timeGrid}>
      <Column day={date.valueOf()} onClick={onMeetingClick} />
   </TimeGrid>
}

function Column({ day, onClick }: { day: number, onClick?: (m: Meeting) => void }) {
   const myself = useSelector(getSecondaryUser)
   const selector = useMemo(() => makeMeetingsSelector(() => myself?.emailAddress, () => day), [myself, day])
   const meetings = useSelector(s => selector(s, null))
   const dispatch = useDispatch()
   const hasBooking = useSelector(s => s.booking !== null)
   const ctx = useCurrentBookingTime()

   const onStartBooking = useCallback((hr: number) => {
      if (hasBooking) { return }
      dispatch(actions.selectDate(day))
      dispatch(actions.startBooking({
         start: roundStartTime(hr, 'round'),
         participants: [{ emailAddress: myself!.emailAddress, type: AttendeeType.required }],
      }))
   }, [hasBooking, dispatch, day, myself])

   function onMeetingClick(e: React.MouseEvent, m: Meeting) {
      e.stopPropagation()
      onClick!(m)
   }

   return <TimeGridColumn className={classNames(css.column, !meetings && css.loading)} onClick={onStartBooking} header={<>
      <div>{moment(day).format('dddd')}</div>
      <div>{moment(day).format('DD MMM')}</div>
   </>} loading={!meetings} headerClassName={css.columnHeader}>
      {meetings?.map(m => <TimeGridItem key={m.id}
         start={moment.max(moment(m.startTime), moment(day)).valueOf()}
         finish={moment.min(moment(m.endTime), moment(day).endOf('d')).valueOf()}
         className={classNames(css.meeting, onClick && css.clickable)} onClick={e => onClick && onMeetingClick(e, m)}>
         {m.subject}
      </TimeGridItem>)}
      {ctx && <CalendarCursor />}
   </TimeGridColumn>
}
