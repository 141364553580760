import { combineReducers } from 'redux';
import { getType } from 'typesafe-actions';
import { Team } from '../../model';
import { actions, Actions } from '../utils';

function teams(state: Team[] | null = null, action: Actions): Team[] | null {
   if (action.type === getType(actions.setTeams)) {
      return action.payload
   } else if (action.type === getType(actions.secondaryLogout)) {
      return null
   }

   return state;
}

function currentTeam(state: string[] = [], action: Actions): string[] {
   if (action.type === getType(actions.setCurrentTeamsChat)) {
      return action.payload;
   } else if (action.type === getType(actions.secondaryLogout)) {
      return []
   }

   return state;
}

function teamsMode(state: boolean = false, action: Actions) : boolean {
   switch (action.type) {
      case getType(actions.login.response):
      case getType(actions.whoAmI.response):
         return action.payload ? action.payload.teams : state;
   }

   return state;
}

export default combineReducers({
   teams,
   currentTeam,
   teamsMode,
})
