import { all, call, put, select, takeEvery as takeAny } from 'redux-saga/effects';
import { getType } from 'typesafe-actions';
import { fetchMyTeams, findPerson } from '../api';
import { AttendeeType, getGuest, Person } from '../model';
import { getMyself, selectDomain } from '../store/helpers';
import { actions, StoreState } from '../store/utils';
import { getCurrentChat, Member } from '../teams';
import { takeEvery } from '../utils/apiActions';
import { PromiseReturnType } from '../utils/misc';

const loadCurrentChat = takeEvery(actions.loggedIn, function* ({ teams: { teamsMode }, booking }) {
   if (!teamsMode) { return }

   const domain = selectDomain(yield select())
   if (!domain) { return }

   const currentTeam: PromiseReturnType<ReturnType<typeof getCurrentChat>> = yield getCurrentChat(domain);

   const loadingContacts = new Map<string, Promise<Person>>();
   for (const member of currentTeam) {
      if (!loadingContacts.has(member.email)) {
         loadingContacts.set(member.email, getPerson(member));
      }
   }

   const promise = Promise.all(loadingContacts.values())
   const persons: PromiseReturnType<typeof promise> = yield promise;

   // emails with proper casing
   const emailsMap = new Map<string, string>([...loadingContacts.keys()].map((k, i) => {
      const pair: [ string, string ] = [ k, persons[i].emailAddress ];
      return pair;
   }))

   const emails = fixCase(currentTeam.map(m => m.email), emailsMap)

   yield put(actions.setCurrentTeamsChat(emails))
   yield all(persons.map(p => put(actions.updateContact(p))));

   if (booking) {
      yield all(emails.map(p => put(actions.addParticipant(p, AttendeeType.required))))
   }
})

const loadTeam = takeAny([getType(actions.login.response), getType(actions.whoAmI.response), getType(actions.secondaryLogin.response)], function* () {
   // clear the prevous ones
   yield put(actions.setTeams(null))

   const state: StoreState = yield select()
   if (!getMyself(state)) { return }

   const teams: PromiseReturnType<ReturnType<typeof fetchMyTeams>> = yield call(fetchMyTeams)
   yield put(actions.setTeams(teams))

   const people = Array.from(new Set(teams.flatMap(t => t.members.map(m => m.email))))
   yield put(actions.fetchContacts.request(people.filter(x => !state.contacts[x])))
})

async function getPerson(m: Member): Promise<Person> {
   const person = await findPerson(m.email);
   return person ?? getGuest(m.email, m.name);
}

function fixCase(emails: string[], map: Map<string, string>) {
   return emails.map(e => map.get(e) || e);
}

export default all([
   loadCurrentChat,
   loadTeam,
]);
