import classNames from 'classnames';
import moment from 'moment';
import React, { useEffect } from 'react';
import { useDispatch } from 'react-redux';
import { Contact, Meeting, Period } from '../model';
import { selectSchedule, shouldLoadSchedule } from '../store/reducers/schedules';
import { actions, useSelector } from '../store/utils';
import { timeOfDayInHours } from '../utils/dateUtils';
import { hoursToPx, segmentSpacing, segmentWidth } from './Schedule';
import css from './ScheduleLine.module.scss';

type Props = {
   contact: Contact
   date: number
   highlight: boolean
   freePeriods: Period[] | null
   onClickSegment?(c: Contact, hr: number): void
   otherProps: {}
}

export default function ScheduleLine({ contact, date, onClickSegment, highlight, freePeriods, otherProps }: Props) {
   const dispatch = useDispatch()
   const schedule = useSelector(s => selectSchedule(s, contact.emailAddress, date))
   const shouldLoad = useSelector(s => shouldLoadSchedule(s, contact.emailAddress, date))

   useEffect(() => {
      if (shouldLoad) {
         dispatch(actions.fetchScheduleRequest(date, contact.emailAddress))
      }
   }, [contact.emailAddress, date, dispatch, shouldLoad])

   return <div className={classNames(css.line, !schedule && css.loading, highlight && css.highlight)} {...otherProps}>
      {schedule && renderWorkingHours(schedule.workingHours, date)}
      {schedule && [...getSegments(schedule.periods, date, (freePeriods || [])[Symbol.iterator](), h => onClickSegment?.(contact, h))]}
   </div>
}

function renderWorkingHours(workingHours: Period[], date: number) {
   if (workingHours.length === 0) {
      return <div className={css.workingHours} style={{ left: 0, width: hoursToPx(24) }} />
   }

   if (workingHours.length === 2) {
      return <div className={css.workingHours} style={{
         left: workingHoursToPx(workingHours[0].end),
         width: workingHoursToPx(workingHours[1].start) - workingHoursToPx(workingHours[0].end)
      }} />
   }

   const wh = workingHours[0]

   return <>
      <div className={css.workingHours} style={{ left: 0, width: workingHoursToPx(wh.start) }} />
      <div className={css.workingHours} style={{ left: workingHoursToPx(wh.end), width: hoursToPx(24) - workingHoursToPx(wh.end) }} />
   </>
}

function workingHoursToPx(date: number) {
   const hours = timeOfDayInHours(date)
   return hoursToPx(hours)
}

function* getSegments(schedule: Period[], date: number, freePeriods: IterableIterator<Period>, onClick: (hr: number) => void) {
   const precisionInMinutes = 15
   const day = moment(date).startOf('d')
   const periods = getUnitedPeriods(schedule)

   let finish = day;
   let skipCount = 0;
   let even = false
   let period = periods.next()
   let freePeriod: IteratorResult<Period> | null = null

   for (const hours of getDayHours(precisionInMinutes)) {
      even = !even
      if (skipCount > 0) {
         skipCount--;
         continue;
      }

      const segmentStart = day.add(hours, 'h');
      const segmentFinish = segmentStart.add(precisionInMinutes, 'm');

      // search for next periods
      period = findNextPeriod(period, periods, finish)
      freePeriod = freePeriod === null ? freePeriods.next() : findNextPeriod(freePeriod, freePeriods, segmentStart)

      if (period.done || segmentFinish.isSameOrBefore(period.value.start)) {
         const free = !freePeriod.done && segmentFinish.isAfter(freePeriod.value.start)
         yield <div key={hours} className={classNames(css.segment, even ? css.even : css.odd, free && css.free)} onClick={() => onClick?.(hours)} />;
      } else {
         finish = moment(period.value.end);
         const style: React.CSSProperties = {};
         const length = Math.ceil(finish.diff(segmentStart, 'm') / precisionInMinutes);
         const evenOdd = even ? 1 : 0

         if (length > 1) {
            const gaps = Math.floor((length - evenOdd) / 2)
            style.minWidth = segmentWidth / 2 * length + 2 * segmentSpacing * gaps;
         }
         skipCount = length - 1;

         yield <div key={hours} style={style} onClick={() => onClick(hours)}
            className={classNames(css.segment, css.busy, even && css.even, evenOdd !== (length % 2) && css.odd)} />;
      }
   }
}

function findNextPeriod(period: IteratorResult<Period>, periods: IterableIterator<Period>, finish: moment.MomentInput): IteratorResult<Period> {
   for (; ;) {
      if (period.done) {
         break;
      }

      if (moment(period.value.end).isAfter(finish)) {
         break // found
      }

      period = periods.next()
   }

   return period
}

export function* getDayHours(precisionInMinutes: number = 60) {
   for (let i = 0; i < 24 / precisionInMinutes * 60; i++) {
      yield i * precisionInMinutes / 60;
   }
}

type MeetingTime = Pick<Meeting, 'startTime' | 'endTime'>

/**
 * Unions meetings' periods
 * @param periods should be sorted by `start`
 */
export function* getUnitedPeriods(periods: Iterable<Period>) {
   let prev: Period | undefined

   for (const period of periods) {
      if (!prev) {
         prev = period
         continue
      }

      if (period.start > prev.end) {
         yield prev
         prev = period
         continue
      }

      if (period.end > prev.end) {
         prev = {
            start: prev.start,
            end: period.end,
         }
      }
   }

   if (prev) {
      yield prev
   }
}
