import classNames from 'classnames';
import React from 'react';
import css from './Logo.module.scss';

export default function Logo(props: { className?: string, mono?: boolean }) {
   const accent = props.mono ? undefined : css.accent;
   return <svg viewBox="0 0 323.03 68.64" className={classNames(css.logo, props.className)}>
      <path d="M23.07,56.08,8.13,56C3,56-.53,52,.3,47.2L6.88,8.83C7.71,4,12.57.14,17.71.18L70,.53c5.14,0,8.66,4,7.83,8.78L73.62,33.73l-3.73,0L74.08,9.29A4.49,4.49,0,0,0,69.38,4L17.11,3.67a6.65,6.65,0,0,0-6.5,5.19L4,47.23a4.49,4.49,0,0,0,4.7,5.27l14.93.1Z"/>
      <path d="M53.25,10.89a1.49,1.49,0,0,1,1.56,1.76,2.21,2.21,0,0,1-2.16,1.73,1.5,1.5,0,0,1-1.57-1.76,2.24,2.24,0,0,1,2.17-1.73m.3-1.74a4.44,4.44,0,0,0-4.34,3.46,3,3,0,0,0,3.14,3.51,4.42,4.42,0,0,0,4.33-3.46,3,3,0,0,0-3.13-3.51Z"/>
      <path d="M42.05,10.82a1.48,1.48,0,0,1,1.56,1.75,2.21,2.21,0,0,1-2.16,1.74,1.5,1.5,0,0,1-1.57-1.76,2.23,2.23,0,0,1,2.17-1.73m.3-1.75A4.45,4.45,0,0,0,38,12.54a3,3,0,0,0,3.14,3.51h0a4.44,4.44,0,0,0,4.33-3.46,3,3,0,0,0-3.13-3.52Z"/>
      <path d="M64.45,11A1.48,1.48,0,0,1,66,12.72a2.21,2.21,0,0,1-2.16,1.74,1.5,1.5,0,0,1-1.57-1.76A2.23,2.23,0,0,1,64.45,11m.3-1.75a4.44,4.44,0,0,0-4.34,3.47,3,3,0,0,0,3.14,3.51,4.44,4.44,0,0,0,4.33-3.46,3,3,0,0,0-3.13-3.52Z"/>
      <path d="M17.85,21.13a1.5,1.5,0,0,1,1.57,1.76,2.23,2.23,0,0,1-2.17,1.73,1.49,1.49,0,0,1-1.56-1.76,2.21,2.21,0,0,1,2.16-1.73m.3-1.74a4.42,4.42,0,0,0-4.33,3.46A3,3,0,0,0,17,26.36a4.42,4.42,0,0,0,4.34-3.46,3,3,0,0,0-3.14-3.51Z"/>
      <path d="M29.05,21.21A1.49,1.49,0,0,1,30.62,23a2.23,2.23,0,0,1-2.17,1.73,1.48,1.48,0,0,1-1.56-1.75,2.21,2.21,0,0,1,2.16-1.73m.3-1.75A4.44,4.44,0,0,0,25,22.92a3,3,0,0,0,3.13,3.52A4.44,4.44,0,0,0,32.49,23a3,3,0,0,0-3.14-3.52Z"/>
      <path d="M40.25,21.28A1.5,1.5,0,0,1,41.82,23a2.23,2.23,0,0,1-2.17,1.73A1.49,1.49,0,0,1,38.09,23a2.21,2.21,0,0,1,2.16-1.73m.3-1.74A4.42,4.42,0,0,0,36.22,23a3,3,0,0,0,3.13,3.51h0a4.43,4.43,0,0,0,4.34-3.46,3,3,0,0,0-3.14-3.51Z"/>
      <path d="M51.45,21.36A1.49,1.49,0,0,1,53,23.11a2.23,2.23,0,0,1-2.17,1.73,1.48,1.48,0,0,1-1.56-1.75,2.21,2.21,0,0,1,2.16-1.73m.3-1.75a4.44,4.44,0,0,0-4.33,3.47,3,3,0,0,0,3.13,3.51,4.44,4.44,0,0,0,4.33-3.46,3,3,0,0,0-3.13-3.52Z"/>
      <path d="M62.65,21.43a1.5,1.5,0,0,1,1.57,1.76,2.23,2.23,0,0,1-2.17,1.73,1.5,1.5,0,0,1-1.57-1.76,2.24,2.24,0,0,1,2.17-1.73m.3-1.74a4.42,4.42,0,0,0-4.33,3.46,3,3,0,0,0,3.13,3.51,4.42,4.42,0,0,0,4.33-3.46A3,3,0,0,0,63,19.69Z"/>
      <path d="M16.06,31.59a1.49,1.49,0,0,1,1.56,1.76,2.21,2.21,0,0,1-2.16,1.73,1.49,1.49,0,0,1-1.57-1.75,2.23,2.23,0,0,1,2.17-1.74m.3-1.74A4.44,4.44,0,0,0,12,33.31a3,3,0,0,0,3.14,3.52,4.44,4.44,0,0,0,4.33-3.47,3,3,0,0,0-3.13-3.51Z"/>
      <path d="M27.26,31.67a1.49,1.49,0,0,1,1.56,1.76,2.22,2.22,0,0,1-2.16,1.73,1.5,1.5,0,0,1-1.57-1.76,2.23,2.23,0,0,1,2.17-1.73m.3-1.74a4.42,4.42,0,0,0-4.34,3.46,3,3,0,0,0,3.14,3.51,4.43,4.43,0,0,0,4.33-3.46,3,3,0,0,0-3.13-3.51Z"/>
      <path d="M14.26,42.06a1.49,1.49,0,0,1,1.57,1.75,2.23,2.23,0,0,1-2.17,1.74,1.5,1.5,0,0,1-1.57-1.76,2.23,2.23,0,0,1,2.17-1.73m.3-1.75a4.44,4.44,0,0,0-4.33,3.47,3,3,0,0,0,3.13,3.51,4.44,4.44,0,0,0,4.33-3.46,3,3,0,0,0-3.13-3.52Z"/>
      <path d="M51.22,33.58c9.27.06,15.59,7.15,14.11,15.81S55.1,65,45.84,65s-15.6-7.16-14.11-15.81S42,33.51,51.22,33.58m.6-3.49c-11.34-.08-22,8.45-23.82,19s5.9,19.25,17.24,19.33,22-8.45,23.82-19-5.9-19.24-17.24-19.32Z"/>
      <path className={accent} d="M43.3,58a1.65,1.65,0,0,1-1.23-.52l-4.71-5.27a1.72,1.72,0,0,1,.43-2.47,2,2,0,0,1,2.64,0l3.59,4,13.63-10.9a2,2,0,0,1,2.64,0,1.7,1.7,0,0,1-.42,2.46L44.71,57.47A2.31,2.31,0,0,1,43.3,58Z"/>
      <path d="M82.94.18H98.1c8.53,0,13.55,4.47,13.55,11.4,0,11.81-7.73,14.84-8.93,15.24.8.24,7.42,2.47,7.42,10.84,0,11.41-7.26,18.35-19.86,18.35H73.13Zm22.81,38.28c0-4.78-2.23-9.25-10-9.25H82l-4.06,23h12.6C102,51.94,105.75,45.48,105.75,38.46ZM94.35,25.38c9.41,0,13-6.7,13-13,0-5-2.79-8.37-10.37-8.37H86.37L82.62,25.38Z"/>
      <path d="M154.07,34.63c0,12-9.65,22.33-21.53,22.33-9.49,0-16.11-6.3-16.11-15.87,0-12,9.41-22.41,21.45-22.41C147.45,18.68,154.07,25.06,154.07,34.63Zm-4.3,0c0-6.7-4-11.88-11.89-11.88-10.21,0-17.14,8.85-17.14,18.34,0,6.7,4,11.81,11.8,11.81C142.83,52.9,149.77,44.12,149.77,34.63Z"/>
      <path d="M196.49,34.63c0,12-9.65,22.33-21.53,22.33-9.49,0-16.11-6.3-16.11-15.87,0-12,9.41-22.41,21.45-22.41C189.87,18.68,196.49,25.06,196.49,34.63Zm-4.3,0c0-6.7-4-11.88-11.89-11.88-10.2,0-17.14,8.85-17.14,18.34,0,6.7,4,11.81,11.8,11.81C185.25,52.9,192.19,44.12,192.19,34.63Z"/>
      <path d="M208.61,37.82,205.42,56h-4.15L211.09.18h4.15l-6.39,36.13,20.89-16.67h6.06l-22,17.63L231.26,56H225.6Z"/>
      <path className={accent} d="M275.14,18.5h9.47l-6.55,37.44h-9.48ZM281.61.18a6.2,6.2,0,0,1,6.24,6.24,6.24,6.24,0,1,1-12.48,0A6.2,6.2,0,0,1,281.61.18Z"/>
      <path className={accent} d="M294.13,18.58h8.13l2-11.13h9.47l-2,11.13H323l-1.5,8.53H310.24l-2.77,15.48c-.79,4.58,1.5,5.53,4,5.53a8.83,8.83,0,0,0,4.11-1.34l2.68,7.89A19.39,19.39,0,0,1,309.05,57c-7.18,0-12.55-5.68-11.13-13.66l2.84-16.19h-8.13Z"/>
      <rect className={accent} x="238.14" y="31.23" width="21.58" height="9.08"/>
   </svg>;
}
