import classNames from 'classnames';
import moment from 'moment';
import React, { useState } from 'react';
import FontAwesome from 'react-fontawesome';
import { useDispatch } from 'react-redux';
import { DropdownItem, DropdownMenu, DropdownToggle, Modal, ModalBody, UncontrolledDropdown } from 'reactstrap';
import { Contact, MeetingType, ResponseStatus } from '../model';
import { getSecondaryUser } from '../store/helpers';
import { Contacts } from '../store/reducers/contacts';
import { Meeting } from '../store/reducers/meetings';
import { actions, useSelector } from '../store/utils';
import BrandedModalHeader from './BrandedModalHeader';
import Button from './Button';
import { ReactComponent as AddIcon } from './buttons/add.svg';
import ContactPhoto from './ContactPhoto';
import css from './MeetingInformation.module.scss';
import Toggle from './mobile/Toggle';

export function MeetingInformation() {
   const popup = useSelector(s => s.popup)
   const contacts = useSelector(s => s.contacts)
   const myself = useSelector(getSecondaryUser)
   const meeting = useSelector(s => popup.type === 'meetingInformation' && s.meetings[popup.meetingId])
   const dispatch = useDispatch()

   const [changeMyStatus, setChangeMyStatus] = useState<MyStatus>()

   const organiser = useSelector(s => meeting && meeting.organiser && s.contacts[meeting.organiser])

   const myStatus = getMyStatus(myself ? myself : false, meeting ? meeting : false)

   const isOrganiser = Boolean(meeting && meeting.organiser && myself && meeting.organiser === myself.emailAddress)
   const description = getDescription(meeting ? meeting : false)

   function onCancel() {
      if (!popup.isSubmitting) {
         dispatch(actions.closePopup())
      }
   }

   function handleEdit() {
      if (!meeting) return
      dispatch(actions.closePopup())
      dispatch(actions.editBooking(meeting.id, undefined))
   }

   function handleDelete() {
      if (!meeting || !myself) return
      dispatch(actions.closePopup())
      dispatch(actions.cancelMeeting(meeting.id, { userName: myself.emailAddress, password: '' }))
   }

   function handleDiscard() {
      setChangeMyStatus(undefined)
   }

   function handleChangeStatus(comment: string, emailOrganiser: boolean) {
      if (!meeting || changeMyStatus === undefined || changeMyStatus === "Unknown" || changeMyStatus === "None" || !myself) return
      dispatch(actions.changeMeetingResponseStatus(meeting.id, myself.emailAddress, comment, emailOrganiser, changeMyStatus))
      setChangeMyStatus(undefined)
   }

   function handleAddResource() {
      if (popup.type !== 'meetingInformation') { return }
      dispatch(actions.openPopupWithParams({ type: 'addResource', meetingId: popup.meetingId }))
   }

   if (popup.type !== 'meetingInformation' || !meeting || !myself) {
      return <Modal isOpen={false} />
   }

   return <Modal isOpen toggle={onCancel} autoFocus={false}>
      <BrandedModalHeader toggle={onCancel}>
         Meeting Information
      </BrandedModalHeader>
      <ModalBody className={css.body}>
         <div className={css.titleRow}>
            <span className={css.headerColumn}><FontAwesome name="circle" className={css.titleIcon} /></span>
            <span className={classNames(css.meetingTitle, css.wrap)}>{meeting.subject}</span>
         </div>

         <div className={classNames(css.row)}>
            <span className={css.headerColumn}><FontAwesome name='clock' className={css.icon} /></span>
            <span>{getMeetingRange(meeting)}</span>
         </div>

         {meeting.types.length > 0 && <div className={classNames(css.row, css.locationInfo)}>
            <span className={css.headerColumn}><FontAwesome name='map-marker-alt' className={css.icon} /></span>
            <span>{getMeetingType(meeting.types[0])}</span>
         </div>}

         <hr />

         {organiser && <div className={classNames(css.row)}>
            <div className={css.headerColumn}>
               <ContactPhoto contact={organiser} />
            </div>
            <div className={css.attendeeSummary}>
               <span>{getMeetingOrganiser(organiser, myself.emailAddress)}</span>
               <div className={css.attendeeInfo}>{getAttendeeInfo(myself, organiser, meeting, contacts)}</div>
            </div>
            {moment(meeting.endTime).isAfter() && <Button className={css.addResource} onClick={handleAddResource}>
               <AddIcon />
               Resource
            </Button>}
         </div>}

         {description && <>
            <hr />
            <div className={classNames(css.row)}>
               <span className={css.headerColumn}><FontAwesome name='align-left' className={css.descIcon} /></span>
               <span className={classNames(css.wrap, css.description)}>{description}</span>
            </div>
         </>}

         <div className={css.actionBar}>
            {changeMyStatus && !isOrganiser && <ChangeStatus to={changeMyStatus} onSend={handleChangeStatus} onDiscard={handleDiscard} />}
            {isOrganiser && <div className={css.actionBarButtons}>
               <Button onClick={() => handleEdit()}><FontAwesome name='pen' className={css.icon} />Edit</Button>
               <Button onClick={() => handleDelete()}><FontAwesome name='trash-alt' className={css.icon} />Delete</Button>
            </div>}
            {!changeMyStatus && myStatus === "None" && !isOrganiser && <div className={css.actionBarButtons}>
               <Button onClick={() => setChangeMyStatus("Accepted")}>{getStatusIcon("Accepted", css.icon)}Yes</Button>
               <Button onClick={() => setChangeMyStatus("Tentative")}>{getStatusIcon("Tentative", css.icon)}Maybe</Button>
               <Button onClick={() => setChangeMyStatus("Declined")}>{getStatusIcon("Declined", css.icon)}No</Button>
            </div>}
            {!changeMyStatus && !isOrganiser && myStatus !== "None" && <UncontrolledDropdown>
               <DropdownToggle caret className={css.acceptButton}>{getStatusIcon(myStatus, css.statusButtonIcon)}{getStatusText(myStatus)}</DropdownToggle>
               <DropdownMenu>
                  <DropdownItem onClick={() => setChangeMyStatus("Accepted")}>{getStatusIcon("Accepted", css.dropdownIcon)}Yes</DropdownItem>
                  <DropdownItem onClick={() => setChangeMyStatus("Tentative")}>{getStatusIcon("Tentative", css.dropdownIcon)}Maybe</DropdownItem>
                  <DropdownItem onClick={() => setChangeMyStatus("Declined")}>{getStatusIcon("Declined", css.dropdownIcon)}No</DropdownItem>
               </DropdownMenu>
            </UncontrolledDropdown>}
         </div>

      </ModalBody>
   </Modal>
}

function ChangeStatus({ to, onSend, onDiscard }: { to: MyStatus, onSend: (comment: string, messageOrganiser: boolean) => void, onDiscard: () => void }) {

   const [comment, setComment] = useState('')
   const [emailOrganiser, setEmailOrganiser] = useState(true)


   function onChange(e: React.ChangeEvent<HTMLTextAreaElement>) {
      setComment(e.currentTarget.value)
   }

   return (<div className={css.changeName}>
      <div className={css.emailOrganiser}>
         <label className={css.emailOrganiserLabel}>
            Email organiser
            <Toggle className={css.emailOrganiserToggle} onChange={(value) => setEmailOrganiser(value)} value={emailOrganiser} />
         </label>
      </div>
      <div className={css.title}>
         <FontAwesome name='calendar-check' className={css.icon} />
         <div>{getStatusText(to)}</div>
      </div>
      <textarea className={css.edit} onChange={onChange} />
      <div className={css.actionButtons}>
         <Button color='accent' onClick={() => onSend(comment, emailOrganiser)}>Send</Button>
         <Button onClick={() => onDiscard()}>Discard</Button>
      </div>
   </div>)
}


type MyStatus = ReturnType<typeof getMyStatus>

function getStatusIcon(status: MyStatus, className: string): React.ReactNode {
   if (status === "Accepted") return <FontAwesome name='check' className={className} />
   if (status === "Tentative") return <FontAwesome name='question' className={className} />
   if (status === "Declined") return <FontAwesome name='times' className={className} />

   return null
}

function getStatusText(status: MyStatus): string {
   switch (status) {
      case "Accepted": return "Yes, I'll attend"
      case "Declined": return "No, I won't attend"
      case "Tentative": return "I might attend"
   }
   return "Unknown"
}

function getMyStatus(myself: Contact | false, m: Meeting | false): "Accepted" | "Tentative" | "Declined" | "None" | "Unknown" {
   if (!m || !myself) return "Unknown"

   const me = m.participants.find(p => p.emailAddress === myself.emailAddress)
   if (me === undefined || me.responseStatus === undefined) {
      return "Unknown"
   }

   switch (me.responseStatus) {
      case "Unknown": return "Unknown"
      case "Accepted": return "Accepted"
      case "Rejected": return "Declined"
      case "Tentative": return "Tentative"
      case "None": return "None"
      case "NoResponse": return "None"
   }

   return "Unknown"

}

function getAttendeeInfo(myself: Contact, organiser: Contact, m: Meeting, contacts: Contacts): string {
   const attendees = m.participants.filter(p => p.emailAddress.toLowerCase() !== organiser.emailAddress.toLowerCase())

   if (attendees.length === 1) {
      const attendee = contacts[attendees[0].emailAddress]
      const who = attendees[0].emailAddress === myself.emailAddress
         ? "You"
         : (attendee ? attendee.name : attendees[0].emailAddress)

      return `${who} ${getAcceptedText(attendees[0].responseStatus)}`
   }
   const accepted = attendees.filter(p => p.responseStatus && p.responseStatus === "Accepted").length
   const declined = attendees.filter(p => p.responseStatus && p.responseStatus === "Rejected").length
   const tentative = attendees.filter(p => p.responseStatus && p.responseStatus === "Tentative").length
   const noReply = attendees.length - accepted - declined - tentative

   let res = ""
   if (accepted > 0) {
      res = res + `Accepted ${accepted}`
   }
   if (declined > 0) {
      if (res.length > 0) res = res + ", "
      res = res + `Declined ${declined}`
   }
   if (tentative > 0) {
      if (res.length > 0) res = res + ", "
      res = res + `Tentative ${tentative}`
   }
   if (noReply > 0) {
      if (res.length > 0) res = res + ", "
      res = res + `Didn't respond ${noReply}`
   }
   return res
}

function getAcceptedText(status?: ResponseStatus) {
   if (status === "Accepted") return "accepted"
   if (status === "Rejected") return "declined"
   if (status === "NoResponse" || status === "ResponseRequired" || status === "None") return "didn't respond"
   return "didn't respond (*)"
}

export function getMeetingRange(m: {startTime: number, endTime: number}): string {
   const from = moment(m.startTime)
   const to = moment(m.endTime)
   return from.format('ddd') + ' ' + from.format('L') + ' ' + from.format('LT') + ' - ' + to.format('LT')
}

export function getMeetingOrganiser(organiser: Contact, myself: string): string {
   if (organiser.emailAddress === myself) {
      return "You're the organiser"
   }
   return `${organiser.name} invited you.`
}

function getMeetingType(t: MeetingType): string {
   switch (t) {
      case 'SkypeForBusiness': return "Skype for Business Meeting"
      case 'Teams': return "Microsoft Teams Meeting"
      case 'Jitsi': return "Jitsi Meeting"
      case 'Zoom': return "Zoom Meeting"
      case 'WebEx': return "WebEx Meeting"
      case 'GoogleMeet': return "Google Meet Meeting"
   }
   return t
}

function getDescription(m: Meeting|false): string | undefined {
   if (!m || !m.bodyText) return undefined

   let pos = 0

   function getLine(desc: string) {
      const newPos = desc.indexOf("\r\n", pos)
      const line = (newPos !== -1) ? desc.substr(pos, newPos - pos) : desc.substr(pos)
      //console.log(`newPos: ${newPos}, line: ${line}`)
      if (line.startsWith("___________________")) return undefined
      pos = newPos !== -1 ? newPos + 4 : newPos
      return line
   }

   let res = ""
   while (res.length < 150) {
      const newLine = getLine(m.bodyText)
      //console.log(`res: [${res}], newLine: [${newLine}, pos: ${pos}], charAtPos: ${m.bodyText.charCodeAt(pos)}, charAtPos+1: ${m.bodyText.charCodeAt(pos+1)}`)
      if (newLine === undefined) break
      res += " "
      res += newLine
      if (pos === -1) break
   }

   if (res.length >= 150) {
      return res = res + "..."
   }

   return res
}
