import React from 'react';
import { BookingResourcesFilter, getRoomTypeName, LocationFilter, RoomAttribute } from '../model';
import { useSelector } from '../store/utils';
import { toggleArrayItem } from '../utils/misc';
import { UncontrolledToggleButton } from './mobile/ToggleSelector';

type Location = string | null | undefined

type RoomLocationsProps = {
   value: LocationFilter
   onChange: React.Dispatch<LocationFilter>
}

export function RoomLocations({ value, onChange }: RoomLocationsProps) {
   const hasLocalRooms = useSelector(s => s.localRooms).length > 0
   const locations = useSelector(s => s.locations)

   function renderLocation(loc: Location, key?: string) {
      return <UncontrolledToggleButton key={key} active={isLocationSelected(loc, value) || false} onClick={() => onToggleLocation(loc)}>
         {getLocationName(loc)}
      </UncontrolledToggleButton>
   }

   function onToggleLocation(location: Location) {
      const locations = toggleLocation(location, value)
      onChange(locations)
   }

   return <>
      {renderLocation(undefined)}
      {hasLocalRooms && renderLocation(null)}
      {locations?.map(loc => renderLocation(loc, loc))}
   </>
}

export function isLocationSelected(loc: Location, locations: LocationFilter): boolean | undefined {
   return loc === undefined ? locations.length === 0 : locations.includes(loc)
}

export function toggleLocation(location: Location, locations: LocationFilter) {
   return location === undefined ? [] : toggleArrayItem(locations, location)
}

export function getLocationNames(location: LocationFilter) {
   if (location.length === 0) {
      return [getLocationName()]
   }

   return location.map(getLocationName)
}

export function getLocationName(location?: string | null) {
   return location === undefined ? 'Anywhere' :
      location === null ? 'Local Rooms' :
      location
}

function* sizeFiltersGenerator(roomSizesFilter: readonly number[]) {
   yield `< ${roomSizesFilter[0]}`

   for (let i = 1; i < roomSizesFilter.length; i++) {
      const a = roomSizesFilter[i - 1]
      const b = roomSizesFilter[i] - 1

      yield a === b ? `${a}` : `${a} – ${b}`
   }

   yield `${roomSizesFilter[roomSizesFilter.length - 1]}+`
}

export function getSizeFilters(roomSizesFilter: readonly number[]) {
   return [...sizeFiltersGenerator(roomSizesFilter)]
}

export function getAttribute(id: number, attributes: RoomAttribute[]): RoomAttribute | undefined {
   return attributes.filter(a => a.id === id)[0]
}

export function getResourceFilterSummary(res: BookingResourcesFilter, roomSizesFilter: readonly number[], roomAttributes: null | readonly RoomAttribute[]) {
   return <>
      {res.quantity} {getRoomTypeName(res.type, res.quantity)}
      {res.type === 'MeetingRoom' && res.sizeIndex !== -1 && ' size ' + getSizeFilters(roomSizesFilter)[res.sizeIndex]}
      {res.type === 'MeetingRoom' && res.attributes.map(id => {
         if (!roomAttributes) { return null }
         const attr = roomAttributes.find(a => a.id === id)
         if (!attr) { return null }
         return <img key={id} src={attr.url} alt={attr.name} title={attr.name} />
      })}
      {(res.type === 'Locker' || res.type === 'ParkingSpace') && `, pre ${-res.extraBefore}m post ${res.extraAfter}m`}
   </>
}
