import classNames from 'classnames';
import React, { useCallback, useEffect, useMemo, useState } from 'react';
import FontAwesome from 'react-fontawesome';
import { useDispatch } from 'react-redux';
import { Modal, ModalBody } from 'reactstrap';
import { AllRoomTypes, BookingResourcesFilter, getRoomTypeName, Room, RoomAttribute } from '../model';
import { getDraftResourceSuggestion, selectLocationRoomFilter, selectRoomFilter, selectRooms, selectRoomSizeFilter } from '../store/helpers';
import { actions, useSelector } from '../store/utils';
import { toggleArrayItem } from '../utils/misc';
import BrandedModalHeader from './BrandedModalHeader';
import Button from './Button';
import FormField from './FormField';
import { ClockIcon, ResourceTypeIcon as FormResourceTypeIcon, RoomSizeIcon, TackIcon } from './icons';
import IntInput from './IntInput';
import ToggleSelector, { UncontrolledToggleButton } from './mobile/ToggleSelector';
import css from './ResourceFilterDialog.module.scss';
import { ResourceTypeIcon } from './ResourceIcon';
import { getSizeFilters, RoomLocations } from './RoomFilter';
import Spinner from './Spinner';

function useRoomSizes() {
   const allSizes = useSelector(s => s.roomSizesFilter)
   return useMemo(() => getSizeFilters(allSizes).map((name, index) => ({ name, index })), [allSizes])
}

type Props = {
   resource: BookingResourcesFilter
}

type UpdateFilter = <T extends keyof BookingResourcesFilter>(value: Pick<BookingResourcesFilter, T>) => void

export default function ResourceFilterDialog({ resource }: Props) {
   const dispatch = useDispatch()
   const oldResource = useSelector(s => s.booking?.oldResource ?? null)

   const filter = useSelector(selectRoomFilter)

   const allRooms = useSelector(selectRooms)
   const locationFilter = useSelector(selectLocationRoomFilter)

   const allSizes = useRoomSizes()
   const sizeFilter = useSelector(selectRoomSizeFilter)

   const allAttributes = useSelector(s => s.roomAttributes || [])

   const update = useCallback<UpdateFilter>((value) => {
      dispatch(actions.updateResource(value))
   }, [dispatch])

   function onSave() {
      dispatch(actions.commitResource())
   }

   function onRemove() {
      dispatch(actions.removeResource())
   }

   const roomsInSelectedLocation = useMemo(() => allRooms.filter(r => locationFilter(r, resource.location)), [allRooms, locationFilter, resource.location])

   // filter types
   const availableTypes = useMemo(() => AllRoomTypes.filter(type => roomsInSelectedLocation.some(r => r.roomType === type)), [roomsInSelectedLocation])
   useEffect(() => {
      if (!availableTypes.includes(resource.type)) {
         update({ type: availableTypes[0] })
      }
   }, [resource.type, availableTypes, update])

   // filter sizes
   const availableSizes = useMemo(() => allSizes.filter(x => roomsInSelectedLocation.some(r => sizeFilter(r, x.index))), [allSizes, roomsInSelectedLocation, sizeFilter])
   useEffect(() => {
      if (resource.sizeIndex !== -1 && !availableSizes.some(s => s.index === resource.sizeIndex)) {
         update({ sizeIndex: -1 })
      }
   }, [availableSizes, resource.sizeIndex, update])

   // filter attributes
   const availableAttrs = useMemo(() => allAttributes.filter(a => roomsInSelectedLocation.filter(r => sizeFilter(r, resource.sizeIndex)).some(r => r.attributes.includes(a.id))), [allAttributes, resource.sizeIndex, roomsInSelectedLocation, sizeFilter])
   useEffect(() => {
      if (resource.attributes.some(a => !isAvail(a))) {
         update({ attributes: resource.attributes.filter(isAvail) })
      }

      function isAvail(attrId: number) {
         return availableAttrs.some(a => a.id === attrId)
      }
   }, [availableAttrs, resource.attributes, update])

   // filter quantity
   const maxQuantity = useMemo(() => !resource ? 0 : allRooms.filter(r => filter(r, resource)).length, [allRooms, filter, resource])
   useEffect(() => {
      if (maxQuantity < resource.quantity && maxQuantity !== 0) {
         update({ quantity: maxQuantity })
      }
   }, [maxQuantity, resource.quantity, update])

   return <Modal isOpen className={css.modal}>
      <BrandedModalHeader>
         Booking resource
      </BrandedModalHeader>
      <ModalBody className={css.resourceDialog}>
         <div className={css.filter}>
            <FormField icon={<FontAwesome name='map-marker-alt' />} bodyClassName={css.multi} iconClassName={css.locationIcon}>
               <RoomLocations value={resource.location} onChange={location => update(({ location }))} />
            </FormField>

            <hr />

            <FormField icon={<FormResourceTypeIcon />}>
               <ToggleSelector value={resource.type} onChange={type => update({ type })}>
                  {availableTypes.map(type => <ToggleSelector.Button key={type} value={type} className={css.toggleWithIcon}>
                     <ResourceTypeIcon type={type} />
                     {getRoomTypeName(type)}
                  </ToggleSelector.Button>)}
               </ToggleSelector>
            </FormField>

            <hr />

            <FormField icon={<FontAwesome name='hashtag' />} bodyClassName={css.quantity}>
               <button aria-hidden />
               <Button color='none' onClick={() => update({ quantity: Math.max(1, resource.quantity - 1) })}>
                  &minus;
               </Button>
               <div className={css.count}>{resource.quantity}</div>
               <Button color='none' onClick={() => update({ quantity: Math.min(maxQuantity, resource.quantity + 1) })}>
                  +
               </Button>
            </FormField>

            <hr />

            {resource.type === 'MeetingRoom' && <FormField icon={<RoomSizeIcon />}>
               <span className={css.label}>Size:</span>
               <ToggleSelector value={resource.sizeIndex} onChange={sizeIndex => update({ sizeIndex })}>
                  <ToggleSelector.Button className={css.size} value={-1}>Any</ToggleSelector.Button>
                  {availableSizes.map(x => <ToggleSelector.Button key={x.index} className={css.size} value={x.index}>{x.name}</ToggleSelector.Button>)}
               </ToggleSelector>
            </FormField>}

            <hr />

            {resource.type === 'MeetingRoom' && availableAttrs.length > 0 && <FormField icon={<FontAwesome name='cogs' />} bodyClassName={css.multi} iconClassName={css.attrIcon}>
               {availableAttrs.map(attr => <RoomAttributeButton key={attr.id} attr={attr} selected={resource.attributes} onSelected={attributes => update({ attributes })} />)}
            </FormField>}

            <hr />

            {(resource.type === 'Locker' || resource.type === 'ParkingSpace') && <FormField icon={<ClockIcon />}>
               <ExtraTimeEditor value={resource} onChange={update} />
            </FormField>}

            <hr />

            <div className={css.dialogButtons}>
               <Button color='accent' onClick={() => onSave()}>{oldResource ? 'Save' : 'Add'}</Button>
               <Button onClick={() => dispatch(actions.rollbackResource())} className={css.cancel}>Cancel</Button>
               {oldResource && <Button color='red' onClick={onRemove}>Remove</Button>}
            </div>
         </div>
         <div className={css.wrapper}>
            <SuggestedResources resource={resource} />
         </div>
      </ModalBody>
   </Modal>
}

interface SuggestionsProps {
   className?: string
   resource: BookingResourcesFilter
}

export function SuggestedResources({ className, resource }: SuggestionsProps) {
   const suggestions = useFilterSuggestions(resource)

   if (!suggestions) {
      return <Spinner className={css.spinner} />
   }

   const { show: showMore, setShow: setShowMore, isLoading, selected, available, togglePin } = suggestions

   const renderRoom = (room: Room, pinned: boolean, unavailable: boolean) => {
      return <li key={room.emailAddress} onClick={() => togglePin(room)}>
         <button className={classNames(css.pinResource, unavailable && css.unavailable)}>
            <span>{room.name || room.emailAddress} {room.roomType === 'MeetingRoom' && `(${room.seats} seat${room.seats === 1 ? '' : 's'})`}</span>
            {showMore && <TackIcon className={classNames(!pinned && css.outline)} />}
         </button>
      </li>
   }

   return <div className={classNames(css.suggestions, className)}>
      <h2>Selected resources</h2>
      <ul>
         {selected.map(x => renderRoom(x.room, x.pinned, x.unavailable))}
      </ul>
      {!showMore && <Button className={css.moreButton} onClick={() => setShowMore(true)}>More options</Button>}
      {showMore && <>
         <hr />
         <h2>Alternative resources</h2>
         <ul>
            {available.map(x => renderRoom(x, false, false))}
         </ul>
      </>}
      {isLoading && <div className={css.loading}>Loading...</div>}
   </div>
}

function useFilterSuggestions(resource: BookingResourcesFilter) {
   const dispatch = useDispatch()
   const [show, setShow] = useState(false)
   const selected = useSelector(s => getDraftResourceSuggestion(s, 'selected'))
   const available = useSelector(s => getDraftResourceSuggestion(s, 'available'))
   const isLoading = useSelector(s => s.suggestions.draft.loading)

   useEffect(() => {
      if (resource.pinned.length) {
         setShow(true)
      }
   }, [resource.pinned.length])

   if (!selected || !available) { return null }

   function togglePin(room: Room) {
      if (!show) { return }
      if (resource.pinned.includes(room.emailAddress)) {
         dispatch(actions.updateResource({ pinned: resource.pinned.filter(x => x !== room.emailAddress) }))
      } else {
         dispatch(actions.updateResource({ pinned: [...resource.pinned, room.emailAddress] }))
      }
   }

   return {
      show,
      setShow,
      isLoading,
      selected: selected.map(room => ({
         room,
         pinned: resource.pinned.includes(room.emailAddress),
         unavailable: !available.includes(room),
      })),
      available: available.filter(a => !selected.includes(a)),
      togglePin,
   }
}

type RoomAttributeButtonProps = {
   attr: RoomAttribute
   selected: number[]
   onSelected: React.Dispatch<number[]>
}

function RoomAttributeButton({ attr, selected, onSelected }: RoomAttributeButtonProps) {
   function toggleAttribute() {
      onSelected(toggleArrayItem(selected, attr.id))
   }

   return <UncontrolledToggleButton title={attr.name}
      className={css.toggleWithIcon}
      active={selected.includes(attr.id)}
      onClick={toggleAttribute}>
      <img src={attr.url} alt={attr.name} title={attr.name} />
      {attr.name}
   </UncontrolledToggleButton>
}

type ExtraTimeProps = {
   value: BookingResourcesFilter
   onChange: UpdateFilter
}

function ExtraTimeEditor({ value, onChange }: ExtraTimeProps) {
   return <>
      <label className={css.extra} onClick={e => e.stopPropagation()}>
         Before (minutes)
         <IntInput value={-value.extraBefore} onChange={v => onChange({ extraBefore: -v })} />
      </label>
      <label className={css.extra} onClick={e => e.stopPropagation()}>
         After (minutes)
         <IntInput value={value.extraAfter} onChange={v => onChange({ extraAfter: v })} />
      </label>
   </>
}
