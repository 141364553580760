import classNames from 'classnames';
import moment from 'moment';
import React, { CSSProperties, HTMLProps, useContext, useEffect, useLayoutEffect, useMemo, useReducer, useRef, useState } from 'react';
import ReactDOM, { findDOMNode } from 'react-dom';
import FontAwesome from 'react-fontawesome';
import { useDispatch } from 'react-redux';
import { BookingTime } from '../../model';
import { selectBookingTime } from '../../store/helpers';
import { actions, useSelector } from '../../store/utils';
import { roundStartTime, TimeRounding } from '../../utils/dateUtils';
import DragDrop from '../../utils/DragDrop';
import { Extend, useRequiredContext } from '../../utils/misc';
import { useRems } from '../../utils/remUtil';
import useAnimationFrame from '../../utils/useAnimationFrame';
import { useScrollParent } from '../ScrollParentProvider';
import { cellHeight, hrToRem, scrollMargin as calendarScrollMargin } from '../TimeGrid';
import css from './CalendarCursor.module.scss';

const minDuration = 15 / 60

export function CalendarCursor() {
   const dispatch = useDispatch()
   const { start, setStart, duration, setDuration } = useRequiredContext(CursorContext)
   const { pxPerRem } = useRems()
   const booking = useSelector(s => s.booking)
   const curBookingTime = useCurrentBookingTime()

   function setBookingStart(hr: number) {
      dispatch(actions.setBookingStart(Math.max(0, Math.min(24, hr))))
   }

   function setBookingDuration(hr: number) {
      dispatch(actions.setBookingDuration(Math.max(0, hr)))
   }

   function onMoveWhole(offset: Coords, { start, duration }: BookingTime) {
      setStart(Math.max(0, Math.min(24 - duration, start + offsetToHr(offset.y))))
   }

   function onDoneWhole() {
      setBookingStart(roundStartTime(start!, 'round', TimeRounding.mobile))
      setStart(undefined)
   }

   function onMoveStart(offset: Coords, booking: BookingTime) {
      const { start, duration } = booking
      const newStart = start + offsetToHr(offset.y)

      if (newStart < 0) { return }
      if (newStart > start + duration - minDuration) { return }

      onMoveWhole(offset, booking)
      onMoveDuration({ x: 0, y: -offset.y }, booking)
   }

   function onDoneStart() {
      onDoneWhole()
      onDoneDuration()
   }

   function onMoveDuration(offset: Coords, { start, duration }: BookingTime) {
      setDuration(Math.min(24 - start, Math.max(duration + offsetToHr(offset.y), minDuration)))
   }

   function onDoneDuration() {
      setBookingDuration(roundStartTime(duration!, 'round', TimeRounding.mobile))
      setDuration(undefined)
   }

   function offsetToHr(px: number) {
      return px / (cellHeight * pxPerRem)
   }

   function hrToOffset(hr: number) {
      return hr * cellHeight * pxPerRem
   }

   if (!booking || !curBookingTime) { return null }
   const { start: curStart, duration: curDuration } = curBookingTime

   const getStyle = (): CSSProperties => {
      const bookingStart = start ?? booking.start
      const bookingDuration = duration ?? booking.duration

      return {
         transform: `translateY(${hrToOffset(bookingStart)}px)`,
         height: hrToRem(bookingDuration),
      }
   }

   const mode =
      curDuration < 0.5 ? css.overflow :
      curDuration < 1 ? css.compact :
      null

   return <Knob className={classNames(css.selection, mode)} style={getStyle()} value={booking}
      scrollMargin={calendarScrollMargin} onChange={onMoveWhole} onDone={onDoneWhole}>

      <div className={css.text}>
         {moment().startOf('d').add(curStart, 'h').format('LT')}
         <FontAwesome name='caret-right' className={css.arrow} />
         {moment().startOf('d').add(curStart, 'h').add(curDuration, 'h').format('LT')}
         <span className={css.duration}>{formatDuration(curDuration)}</span>
      </div>

      {/* <div className={css.knobTop} /> */}
      <Knob className={css.knobTop} scrollMargin={calendarScrollMargin} value={booking} onChange={onMoveStart} onDone={onDoneStart} />
      <Knob className={css.knobBottom} scrollMargin={calendarScrollMargin} value={booking} onChange={onMoveDuration} onDone={onDoneDuration} />
   </Knob>
}

function formatDuration(hr: number) {
   if (hr < 1) {
      return `${(hr * 60).toFixed()}m`;
   }

   if (hr === Math.floor(hr)) {
      return `${hr}h`;
   }

   return moment().startOf('d').add(hr, 'h').format('H[h] mm[m]');
}

interface ScrollAnimationContext {
   speed?: number
   value: number
   scrollTop: number
}

type ScrollAnimationContextAction =
   { type: 'init', scrollTop?: number | null } |
   { type: 'setSpeed', speed: number } |
   { type: 'animate', diff: number } |
   { type: 'stop' }

function scrollAnimationContextReducer(state: ScrollAnimationContext, action: ScrollAnimationContextAction): ScrollAnimationContext {
   switch (action.type) {
      case 'init':
         return { value: 0, scrollTop: action.scrollTop || 0 }

      case 'setSpeed':
         return { ...state, speed: action.speed }

      case 'animate':
         return { ...state, value: state.value + action.diff }

      case 'stop':
         return { ...state, speed: undefined }
   }

   return state
}

interface KnobProps<T> {
   value: T
   scrollMargin?: number
   onChange(value: Coords, initial: T): void
   onDone(): void
}

function Knob<T>({ value, onChange, onDone, scrollMargin = 10, ...props }: Extend<HTMLProps<HTMLDivElement>, KnobProps<T>>) {
   const ref = useRef<HTMLDivElement>(null)
   const start = useRef<T>(value)
   const lastOffset = useRef<Coords>()
   const [scrollAnimationContext, updateScrollAnimationContext] = useReducer(scrollAnimationContextReducer, { value: 0, scrollTop: 0 })
   const scrollHeight = useRef<number>(0)
   const scrollParent = useScrollParent()

   useEffect(() => {
      scrollHeight.current = scrollParent?.scrollHeight || 0
   }, [scrollParent])

   useDragDrop(ref, {
      onStarted() {
         start.current = value
         lastOffset.current = undefined
         updateScrollAnimationContext({ type: 'init', scrollTop: scrollParent?.scrollTop })
      },
      onMoved(offset) {
         lastOffset.current = offset
         callOnChange()

         if (!scrollParent || !ref.current) { return }
         const parentScrollBottom = scrollParent.scrollTop + scrollParent.clientHeight
         const top = getOffset()
         let diff = top - scrollParent.scrollTop - scrollMargin

         if (diff < 0 && scrollParent.scrollTop > 0) {
            updateScrollAnimationContext({ type: 'setSpeed', speed: diff })
         } else {
            diff = top + ref.current.offsetHeight - parentScrollBottom + scrollMargin

            if (diff > 0 && parentScrollBottom < scrollHeight.current) {
               updateScrollAnimationContext({ type: 'setSpeed', speed: diff })
            } else {
               updateScrollAnimationContext({ type: 'stop' })
            }
         }
      },
      onDone() {
         updateScrollAnimationContext({ type: 'stop' })
         if (lastOffset.current) {
            onDone()
         }
      },
   })

   useAnimationFrame(() => {
      if (!(scrollAnimationContext.speed !== undefined && !!scrollParent)) {
         console.error('UAF', scrollAnimationContext.speed)
         return
      }

      const diff = Math.ceil(scrollAnimationContext.speed)

      if (scrollParent.scrollTop === 0 && diff < 0
         || scrollParent.scrollTop + scrollParent.clientHeight >= scrollHeight.current && diff > 0) {
         updateScrollAnimationContext({ type: 'stop' })
         return
      }


      batchUpdates(() => {
         updateScrollAnimationContext({ type: 'animate', diff })
         callOnChange()
      })

   }, scrollAnimationContext.speed !== undefined && !!scrollParent)

   useLayoutEffect(() => {
      if (scrollParent && scrollAnimationContext.speed !== undefined) {
         scrollParent.scrollTop = scrollAnimationContext.scrollTop + scrollAnimationContext.value
      }
   }, [scrollAnimationContext, scrollParent])

   function callOnChange() {
      const { x, y } = lastOffset.current!
      onChange({ x, y: y + scrollAnimationContext.value }, start.current)
   }

   function getOffset() {
      if (!ref.current || !scrollParent) { return 0 }
      const me = ref.current.getBoundingClientRect()
      const parent = scrollParent.getBoundingClientRect()
      return me.top + scrollParent.scrollTop - parent.top
   }

   return <div ref={ref} {...props} />
}

interface Coords { x: number, y: number }

function useDragDrop<T extends Element | React.Component>(ref: React.RefObject<T>, callbacks: { onStarted?: () => void, onMoved?: (offset: Coords) => void, onDone?: (delta: Coords) => void } = {}) {
   const startPos = useRef<Coords>()
   const offset = useRef<Coords>({ x: 0, y: 0 })
   const dragRef = useRef<DragDrop>()

   const callbacksRef = useRef(callbacks)

   function onStop() {
      if (dragRef.current) {
         dragRef.current.stop()
         dragRef.current = undefined
      }
   }

   useEffect(() => {
      callbacksRef.current = callbacks
   }, [callbacks])

   useEffect(() => {
      const node = findDOMNode(ref.current)
      if (!(node instanceof HTMLElement)) { return }

      function start(e: Event, data: { pageX: number, pageY: number }) {
         e.preventDefault()
         e.stopPropagation()
         onStart({ x: data.pageX, y: data.pageY })
      }

      function onStart(pos: Coords) {
         document.addEventListener('click', preventClick, true)

         const drag = new DragDrop((x, y) => {
            const start = startPos.current
            if (!start) { return }

            offset.current = {
               x: x - start.x,
               y: y - start.y,
            }

            batchUpdates(callbacksRef.current.onMoved, f => f(offset.current))
         }, (x, y) => {
            offset.current = { x: 0, y: 0 }

            batchUpdates(callbacksRef.current.onDone, f => f({ x, y }))
            stop()
         })

         dragRef.current = drag
         startPos.current = pos
         drag.start()

         batchUpdates(callbacksRef.current.onStarted)
      }

      function stop() {
         setTimeout(() => document.removeEventListener('click', preventClick, true), 1)
         onStop()
      }

      const preventClick = (e: MouseEvent) => e.stopPropagation()
      const onMouseDown = (e: MouseEvent) => start(e, e)
      const onTouchStart = (e: TouchEvent) => start(e, e.touches[0])

      node.addEventListener('mousedown', onMouseDown, false)
      node.addEventListener('touchstart', onTouchStart, false)

      return () => {
         node.removeEventListener('mousedown', onMouseDown, false)
         node.removeEventListener('touchstart', onTouchStart, false)
         stop()
      }
   }, [ref])
}

function batchUpdates<F extends (...args: any[]) => any>(func: F | undefined, call: (func: F) => any = f => f()) {
   ReactDOM.unstable_batchedUpdates(() => {
      if (func) { call(func) }
   })
}

type CursorContext = {
   start: number | undefined
   setStart: (start: number | undefined) => void
   duration: number | undefined
   setDuration: (duration: number | undefined) => void
}

const CursorContext = React.createContext<CursorContext | null>(null)
CursorContext.displayName = 'CalendarCursorProvider'

export function CalendarCursorProvider({ children }: React.PropsWithChildren<{}>) {
   const [start, setStart] = useState<number>()
   const [duration, setDuration] = useState<number>()
   const value = useMemo(() => ({ start, setStart, duration, setDuration }), [duration, start])

   return <CursorContext.Provider value={value}>
      {children}
   </CursorContext.Provider>
}

export function useCurrentBookingTime() {
   const ctx = useContext(CursorContext)
   const booking = useSelector(selectBookingTime)

   if (!booking || !ctx) { return }
   const { start, duration } = ctx

   return {
      start: start === undefined ? booking.start : roundStartTime(start, 'round', TimeRounding.mobile),
      duration: duration === undefined ? booking.duration : roundStartTime(duration, 'round', TimeRounding.mobile),
   }
}
