import React, { useEffect, useMemo, useState } from "react"
import { useDispatch } from "react-redux"
import { useParams } from "react-router-dom"
import { fetchMeetingByMeetingLinkId, HttpError, MeetingData } from "../api"
import { actions, useSelector } from "../store/utils"
import Spinner from "./Spinner"
import MeetingView from "./MeetingView"
import css from './MeetingLink.module.scss';
import Login from './Login';
import LoginMobile from './mobile/Login';
import DefaultPage from './mobile/DefaultPage';
import FontAwesome from "react-fontawesome"

export default function MeetingLink() {

   const dispatch = useDispatch()
   const params = useParams<{ id: string }>()
   const meetingLinkId = decodeURIComponent(params.id)
   console.log(`MeetingLink: ${meetingLinkId}`)
   // https://localhost:44355/meeting/link/skuaykzm
   const mobile = useSelector(s => s.mobile)
   const allowExternalAttendeeEditing = useSelector(s => s.config.allowExternalAttendeeEditing)

   const afterLogin = useMemo(() => {
      if (document.location.search) {
         return new URLSearchParams(document.location.search).get("afterLogin") !== null
      }
      return false
   }, [])


   const [loading, setLoading] = useState(true)
   const [error, setError] = useState<string>()
   const [notFoundError, setNotFoundError] = useState<string>()
   const [meetingData, setMeetingData] = useState<MeetingData | null>(null)

   const { initialized, data } = useSelector(s => s.login)

   useEffect(() => {
      dispatch(actions.whoAmI.request())
   }, [dispatch])

   useEffect(() => {
      let mounted = true
      if (!initialized || (data === undefined)) return
      load()
      return () => { mounted = false }

      async function load() {
         try {
            console.log("Starting fetchMeetingByMeetingLinkId")
            try {
               const meetingLink = await fetchMeetingByMeetingLinkId(meetingLinkId)
               if (!mounted) { return }
               if (meetingLink === null) {
                  setError("Meeting link is expired or missing")
                  return
               }
               setMeetingData(meetingLink.meetingData)
               if (meetingLink.externalData) {
                  // we updated the cookie
                  dispatch(actions.whoAmI.response(meetingLink.externalData))

               }
            } catch (e) {
               if (e instanceof HttpError && e.apiError.code === 'ErrorMeetingNotFound') {
                  setNotFoundError("The meeting for this link has been deleted by the organiser.")
               } else if (e instanceof HttpError && e.apiError.code === 'ErrorMeetingLinkNotFound') {
                  setNotFoundError("The meeting link has expired or has been disabled by the organiser.")

               } else {
                  throw e
               }
            }
         } catch (e) {
            if (mounted) {
               setError((e as Error).message || 'Unknown error')
            }
         } finally {
            if (mounted) {
               setLoading(false)
            }
         }
      }
   }, [data, dispatch, initialized, meetingLinkId])

   useEffect(() => {
      if (data && meetingData !== null) {
         if (!data.isExternal || allowExternalAttendeeEditing) {
            dispatch(actions.editBooking(undefined, meetingData))
         }
      }
   }, [allowExternalAttendeeEditing, data, dispatch, meetingData])

   if (initialized && data === undefined) {
      return mobile 
         ? <LoginMobile meetingLinkId={meetingLinkId} /> 
         : <Login meetingLinkId={meetingLinkId} />
   }

   if (error) {
      return <DefaultPage><div className={css.error}>{error}</div></DefaultPage>
   }
   if (notFoundError) {
      return <DefaultPage><div className={css.notFound}>
         <FontAwesome name='calendar-times' className={css.icon} />
         <p>{notFoundError}</p>
      </div>
      </DefaultPage >
   }
   return <div className={css.meetingLink}>
      {loading && <Spinner className={css.spinner} />}
      {data && data.isExternal && !allowExternalAttendeeEditing && meetingData && <MeetingView meetingData={meetingData} />}
   </div >
}

