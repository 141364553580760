import classNames from 'classnames';
import React from 'react';
import { CSSTransition } from 'react-transition-group';
import css from './BottomSheet.module.scss';

interface Props {
   className?: string
   open: boolean
   onClose: () => void
}

export default function BottomSheet({ className, open, onClose, children }: React.PropsWithChildren<Props>) {
   return <CSSTransition in={open} unmountOnExit timeout={150} classNames={{ enter: css.enter, enterActive: css.enterActive, exit: css.exit, exitActive: css.exitActive }}>
      <div className={css.shade} onClick={e => { onClose(); e.stopPropagation(); e.preventDefault() }}>
         <div className={classNames(css.sheet, className)} onClick={e => e.stopPropagation()}>
            {children}
         </div>
      </div>
   </CSSTransition>
}
