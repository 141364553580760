import React, { useLayoutEffect, useState } from 'react';
import { useDispatch } from 'react-redux';
import { Modal, ModalBody } from 'reactstrap';
import { isMobile } from '../store/helpers';
import { actions, useSelector } from '../store/utils';
import AccentButton from './AccentButton';
import css from './BookingProgressPopup.module.scss';
import BrandedModalHeader from './BrandedModalHeader';
import Spinner from './Spinner';

export default function BookingProgressPopup() {
   const popup = useSelector(s => s.popup)
   const dispatch = useDispatch()
   const [showCurtain, setShowCurtain] = useState(false)
   const mobile = useSelector(isMobile)

   useLayoutEffect(() => {
      if (popup.type === 'progress' && !popup.canCancel && !popup.noDelay && !popup.error) {
         setShowCurtain(true)

         const timeout = window.setTimeout(() => {
            setShowCurtain(false)
         }, 2000)

         return () => {
            window.clearTimeout(timeout)
            setShowCurtain(false)
         }
      }
   }, [popup])

   function onClose() {
      if (popup.isSubmitting) { return }
      dispatch(actions.closePopup())
   }

   function onCancel() {
      if (!popup.isSubmitting) { return }
      dispatch(actions.closePopup())
   }

   if (popup.type !== 'progress') {
      return <Modal isOpen={false} />
   }

   if (showCurtain) {
      return <>
         <Modal isOpen={false} />
         <div className={css.curtain} />
      </>
   }

   if (mobile) {
      return <div className={css.mobile}>
         <div>{popup.message}</div>
         {popup.isSubmitting && <Spinner />}
         <div className={css.error}>{popup.error}</div>
         {!popup.isSubmitting && <AccentButton onClick={onClose}>Close</AccentButton>}
      </div>
   }

   return <Modal isOpen toggle={onClose}>
      <BrandedModalHeader>{popup.message}</BrandedModalHeader>
      <ModalBody className={css.body}>
         {popup.isSubmitting && <Spinner className={css.svg} />}
         <div className={css.error}>{popup.error}</div>
         {popup.isSubmitting && popup.canCancel && <AccentButton onClick={onCancel}>Cancel</AccentButton>}
         {!popup.isSubmitting && <AccentButton onClick={onClose}>Close</AccentButton>}
      </ModalBody>
   </Modal>
}
