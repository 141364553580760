import { routerActions } from 'connected-react-router';
import { connect } from 'react-redux';
import { Dispatch } from 'redux';
import Booking, { OwnProps } from '../components/Booking';
import { AttendeeType, Contact, Page } from '../model';
import { getIsEditing, getSelectedDate, isLoginDisabled, isSingleUser, selectBookingParticipants, selectBookingTime, selectLocationRoomFilter, selectRooms } from '../store/helpers';
import { actions, ReduxPropsType, StoreState } from '../store/utils';

function mapStateToProps(state: StoreState) {
   const { time } = state;

   return {
      time,
      singleUser: isSingleUser(state),
      date: getSelectedDate(state),
      booking: selectBookingTime(state),
      allRooms: selectRooms(state),
      filterRoom: selectLocationRoomFilter(state),
      participants: selectBookingParticipants(state),
      isEditing: getIsEditing(state),
      teamsMode: state.teams.teamsMode,
      loginDisabled: isLoginDisabled(state),
   }
}

function mapDispatchToProps(dispatch: Dispatch) {
   return {
      dispatch,
      book() {
         dispatch(actions.createMeeting());
      },
      setDate(date: number) {
         dispatch(actions.selectDate(date));
      },
      startBooking(c?: Contact, hr?: number) {
         dispatch(startBooking(c, hr))
      },
      goBack(roomListMode:boolean) {
         roomListMode ? dispatch(routerActions.goBack()) : dispatch(actions.goToPage(Page.bookingFilter));
      },
   }
}

const withRedux = connect(mapStateToProps, mapDispatchToProps, ({ time, booking, singleUser, ...props1 }, { dispatch, ...props2 }, ownProps: OwnProps) => { return {
   ...props1,
   ...props2,
   ...ownProps,
   time,
   booking,
}});

function startBooking(c?: Contact, hr?: number) {
   return actions.startBooking({ start: hr, pinnedRoom: c?.emailAddress, full: hr !== undefined });
}


export type BookingProps = ReduxPropsType<typeof withRedux>;
export default withRedux(Booking);
