import * as signalR from '@aspnet/signalr';
import classNames from 'classnames';
import React, { useEffect, useMemo, useRef, useState } from 'react';
import { bearerLogin, fetchStaticConfig, generateOtp } from '../../api';
import { useSelector } from '../../store/utils';
import { newGuid } from '../../utils/misc';
import AccentButton from '../AccentButton';
import Spinner from '../Spinner';
import FormField from './FormField';
import css from './OtpScreen.module.scss';

type Props<T> = {
   tag?: string
   api: () => Promise<T | null>
   onSuccess: (result: T) => void
}

const errorMessage = { text: 'Sorry, something went wrong', error: true }

export function useOtp<T>(api: () => Promise<T | null>,  onSuccess: (result: T) => void) {

   const [data, setData] = useState<null | T>()
   const configRequest = useMemo(fetchStaticConfig, [])
   const [nonce, setNonce] = useState(newGuid)
   const [message, setMessage] = useState<{ text: React.ReactNode, error: boolean }>()
   const [submitting, setSubmitting] = useState<boolean>()

   const onSuccessRef = useRef(onSuccess)
   useEffect(() => {
      onSuccessRef.current = onSuccess
   })

   useEffect(() => {
      let active = true
      let cleanup = () => {}

      load()

      return () => {
         active = false
         cleanup()
      }

      async function load() {
         try {
            let data = await api()
            if (!active) { return }
            setData(data)

            if (!data) {
               const config = await configRequest
               if (!active) { return }

               const connection = new signalR.HubConnectionBuilder()
                  .withUrl(`${config.mrsUrl}/otp_notifications?nonce=${nonce}`)
                  .configureLogging(process.env.NODE_ENV === 'development' ? signalR.LogLevel.Error : signalR.LogLevel.None)
                  .build()

               cleanup = () => connection.stop()

               connection.on('login', async (token: string) => {
                  try {
                     connection.stop()

                     const login = await bearerLogin(token)
                     if (!active) { return }
                     if (!login) { throw new Error('Login failed') }

                     data = await api()
                     if (!active) { return }

                     if (data) {
                        setData(data)
                     } else {
                        throw new Error('Fetching data failed')
                     }
                  } catch {
                     if (!active) { return }
                     setMessage(errorMessage)
                  }
               })

               connection.onclose(err => {
                  if (err && active) {
                     setMessage(errorMessage)
                  }
               })

               await connection.start()
            }
         } catch (err) {
            console.error(err)
            if (!active) { return }
            setMessage(errorMessage)
            setData(null)
         }
      }
   }, [api, configRequest, nonce])

   useEffect(() => {
      if (data) {
         onSuccessRef.current(data)
      }
   }, [data])

   async function sendOtpRequest(email: string, tag?: string, meetingId?: string) {
      if (message?.error) {
         setMessage(undefined)
         setData(undefined)
         setNonce(newGuid())
         return
      }

      try {
         setSubmitting(true)

         const config = await configRequest
         await generateOtp(config.mrsUrl, email, nonce, tag, meetingId, meetingId ? "MeetingLink" : undefined)

         setMessage({ text: <>
            Email sent. Please follow the email verification link. Please keep this page open until you click the link.
         </>, error: false })
      } catch {
         setMessage(errorMessage)
      } finally {
         setSubmitting(false)
      }
   }

   return {data, message, submitting, sendOtpRequest}
}

export default function OtpScreen<T>({ tag, api, onSuccess, children }: React.PropsWithChildren<Props<T>>) {
   const [email, setEmail] = useState('')

   const {data, message, submitting, sendOtpRequest} = useOtp(api, onSuccess)

   async function onSubmit(e: React.FormEvent) {
      e.preventDefault()
      sendOtpRequest(email, tag)
   }

   if (data === undefined) {
      return <Spinner className={css.spinner} />
   }

   if (data !== null) {
      return null
   }

   const hasError = message?.error

   return <form className={css.form} onSubmit={onSubmit}>
      {!hasError && children}
      {!hasError && <FormField icon={<span aria-hidden className='far fa-envelope' />}>
         <input type='email' required autoFocus className={css.input} placeholder='Enter your email' value={email} onChange={e => setEmail(e.currentTarget.value)} />
      </FormField>}
      {message && <p className={classNames(css.message, message.error && css.error)}>{message.text}</p>}
      {message && !hasError && <p className={css.waiting}>
         <Spinner />
         Waiting for the confirmation...
      </p>}
      <AccentButton className={css.button} disabled={submitting}>
         {submitting && <Spinner />}
         {message ?
            message.error ? 'Retry' : 'Resend confirmation email'
            : 'Send confirmation email'}
      </AccentButton>
   </form>
}
