import classNames from "classnames"
import FontAwesome from "react-fontawesome";
import { MeetingData } from "../api"
import { getMeetingRange } from "./MeetingInformation";
import css from './MeetingView.module.scss';

interface MeetingViewProps {
   meetingData: MeetingData
}

export default function MeetingView({ meetingData }: MeetingViewProps) {
   return <div className={css.meetingView}>
      <div className={css.titleRow}>
         <span className={classNames(css.title, css.wrap)}>{meetingData.subject}</span>
      </div>

      <div className={classNames(css.row)}>
            <span className={css.headerColumn}><FontAwesome name='clock' className={css.icon} /></span>
            <span>{getMeetingRange(meetingData)}</span>
         </div>

      <div className={classNames(css.row)}>
            <div className={css.headerColumn}>
               <FontAwesome name='user' className={css.icon} />
            </div>
            <div className={css.attendeeSummary}>
               <span>{meetingData.organiser?.name ?? meetingData?.organiser?.emailAddress}</span>
            </div>
      </div>
   </div>
}
