import moment from 'moment';
import { all, call, delay, fork, join, put, race, select, take } from 'redux-saga/effects';
import { getType } from 'typesafe-actions';
import { fetchVersion } from '../api';
import { getHostingContext } from '../hosted';
import { AttendeeType, isRoom } from '../model';
import { getSecondaryUsername, isSingleUser, selectBookingTime } from '../store/helpers';
import { getHaveSecondaryLogin } from '../store/reducers';
import { selectSchedule } from '../store/reducers/schedules';
import { actions, StoreState } from '../store/utils';
import { initTeams } from '../teams';
import { roundStartTime, timeOfDayInHours } from '../utils/dateUtils';
import api, { ApiResult } from './api';
import azure from './azure';
import booking from './booking';
import calendars from './calendars';
import contacts from './contacts';
import hosted from './hosted';
import page from './page';
import pushNotifications from './pushNotifications';
import rooms from './rooms';
import schedules from './schedules';
import settings from './settings';
import suggestions from './suggestions';
import teams from './teams';
import time from './time';
import subscription from './subscription';
import { goHome } from './utils';
import { push } from 'connected-react-router';
import { isAppUnloaded } from '..';

function* init(): Iterator<any, any, any> {
   const timeUpdated = yield fork(function* () { yield take(getType(actions.updateTime)) });
   yield fork(showVersion)

   yield take(getType(actions.initApp));

   const hostingCtx: ApiResult<typeof getHostingContext> = yield call(getHostingContext)

   if (!hostingCtx) {
      yield initTeamsWhenNeeded()
      yield put(actions.whoAmI.request())
   } else {
      yield fork(hosted, hostingCtx)
   }

   for (; ;) {
      const login = yield select((s: StoreState) => s.login.data);
      if (login) { break }

      yield race({
         whoAmI: take(getType(actions.whoAmI.response)),
         login: take(getType(actions.login.response)),
      });
   }


   yield initTeamsWhenNeeded();

   yield fork(pushNotifications)
   // yield take(getType(actions.pushConnected))

   yield goHome();

   yield put(actions.loggedIn());

   // wait for the time to update
   yield join(timeUpdated);

   const t: number = yield select((s: StoreState) => s.time);
   yield put(actions.selectDate(t));
}

function* onAfterLoginAction() {
   yield take(getType(actions.loggedIn))
   const state: StoreState = yield select()
   const myself = getSecondaryUsername(state)
   const { afterLoginAction, contacts } = state

   if (!afterLoginAction || !myself) { return }

   if (afterLoginAction.type === 'nearby') {
      const { email, roomType, start } = afterLoginAction
      const room = contacts[email]
      if (!isRoom(room)) { return }

      const startTime = roundStartTime(timeOfDayInHours(start ? start : Date.now()), 'ceil', 1 / 60)

      yield put(actions.startBooking({
         start: startTime,
         participants: [{ emailAddress: myself, type: AttendeeType.required }],
         nearby: { email, roomType },
      }))
      return
   }

   if (afterLoginAction.type === 'new') {
      yield put(actions.startBooking({
         start: roundStartTime(timeOfDayInHours(Date.now()), 'ceil'),
         participants: [{ emailAddress: myself, type: AttendeeType.required }],
      }))
      yield put(actions.setBookingDuration(15 / 60))

      let schedule: ReturnType<typeof selectSchedule>

      for (; ;) {
         const state: StoreState = yield select()
         schedule = selectSchedule(state, myself, state.selectedDate)
         if (schedule) { break }
         yield put(actions.fetchScheduleRequest(state.selectedDate, myself))
         yield take(getType(actions.fetchSchedulesResponse))
      }

      const state: StoreState = yield select()
      const booking = selectBookingTime(state)
      if (!booking) { return }
      let moved = false

      let start = moment(state.selectedDate).add(booking.start, 'h').valueOf()

      // find first available time for the given duration
      for (const period of schedule.periods) {
         if (period.end < start) { continue }

         // don't go into tomorrow
         if (moment(period.end).isAfter(moment(start).endOf('d'))) {
            break
         }

         if (period.start < start) {
            start = period.end
            moved = true
            continue
         }

         const end = moment(start).add(booking.duration, 'h').valueOf()

         if (end <= period.start) {
            break
         }

         start = period.end
         moved = true
      }

      if (moved) {
         yield put(actions.setBookingStart(timeOfDayInHours(start)))
      }

      return
   }

   if (afterLoginAction.type === 'addResources') {
      yield put(push(`/meeting/${encodeURIComponent(afterLoginAction.meetingId)}/add-resources`))
      return
   }

   const _exhaustive: never = afterLoginAction
}

function* initTeamsWhenNeeded() {
   const state: StoreState = yield select();
   if (state.teams.teamsMode) {
      yield initTeams();
   }
}

function* resetScreen() {
   yield take(getType(actions.loggedIn));

   if (isSingleUser(yield select())) {
      return;
   }

   const timeoutInSeconds = 60;
   for (; ;) {
      const { timeout } = yield race({
         activity: take(getType(actions.lastActivity)),
         timeout: delay(timeoutInSeconds * 1000),
      })

      if (timeout) {
         const state: StoreState = yield select()

         if (state.popup.isSubmitting || state.reconnecting) {
            continue
         }

         if (getHaveSecondaryLogin(state)) {
            yield put(actions.openPopup('autoLogout'))
         } else {
            if (state.popup.type || state.booking) {
               yield put(actions.endBooking(true));
               yield put(actions.selectDate());
            }

            yield goHome();
         }
      }
   }
}

function* reconnect() {
   if (isAppUnloaded()) {
      console.warn('not reloading since app is being unloaded')
      return
   }

   yield take(getType(actions.reconnect));
   console.log("Reloading:", window.location.href)
   window.location.reload();
}

function* showVersion() {
   const version: ApiResult<typeof fetchVersion> = yield call(fetchVersion)
   if (version) { console.warn('running version', version) }
   yield put(actions.setVersion(version))
}

export default function* rootSaga() {
   yield all([
      init(),
      time,
      api,
      booking,
      contacts,
      rooms,
      settings,
      teams,
      resetScreen(),
      reconnect(),
      schedules,
      calendars,
      suggestions,
      page,
      azure,
      onAfterLoginAction(),
      subscription,
   ]);
}
