import classNames from 'classnames';
import React from 'react';
import { isMobile } from '../store/helpers';
import { useSelector } from '../store/utils';
import css from './Version.module.scss';

export default function Version() {
   const version = useSelector(s => s.version)
   const mobile = useSelector(isMobile)
   if (!version || mobile) {
      return null
   }

   return <div className={classNames(css.Version)}>
      v{version}
   </div>
}
