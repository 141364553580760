import { useEffect } from 'react';
import { createPromiseSource, PromiseSource } from '../utils/promise';
import { url } from 'inspector';

export default function WebexLoginCallback() {
   useEffect(() => {
      onCallback()
   }, [])

   return null
}

async function onCallback() {

   console.log('WebEx onCallBack')

   if (!isWebExCallback()) {

      const authUrl = reconstituteAuthUrl()

      // First time: call the OAuth authority to get the authentication token
      if (authUrl){
         console.log('WebEx: Redirect', authUrl)
         window.location.href = authUrl
      } else {
         console.log('WebEx: Redirect: No authurl')
         throw new Error('Missing authUrl for Webex authentication.')
      }

   } else {

      console.log('WebEx: Return from redirect.', document.location)

      // we're returning from the login
      const params = new URLSearchParams(document.location.search.substring(1))
      const token = params.get('code') || undefined

      console.log('WebEx: Return from redirect. Params: ', params)
      console.log('WebEx: Return from redirect. Token: ', token)

      // now tell the parent window
      const msg: WebexLoginMessage = {
         type: 'WEBEX_LOGIN',
         token,
      }

      window.opener!.postMessage(msg, window.location.origin)
   }
}

// The Auth uri is encoded into a query parameter named "auth", this
// has to be decoded and partially re-encoded and the additional
// "state" parameter added.
function reconstituteAuthUrl() : string | any {

   let urlParams = new URLSearchParams(window.location.search)
   const authUrl = decodeURIComponent(urlParams.get('auth')!)

   //console.log('reconstituteAuthUrl: authUrl', authUrl)

   const root = authUrl.split('?')[0]
   urlParams = new URLSearchParams(authUrl.split('?')[1])

   const clientId = urlParams.get('client_id')
   const responseType = urlParams.get('response_type')
   const redirectUri = urlParams.get('redirect_uri')
   const scopes = urlParams.get('scope')
   const state = 'WebExAuth'

   //console.log('reconstituteAuthUrl:urlParams', urlParams);
   //console.log('reconstituteAuthUrl:clientId', clientId);
   //console.log('reconstituteAuthUrl:responseType', responseType);
   //console.log('reconstituteAuthUrl:redirectUri', redirectUri);
   //console.log('reconstituteAuthUrl:scopes', scopes);
   //console.log('reconstituteAuthUrl:state', state);
   //console.log('reconstituteAuthUrl:root', root);

   if (clientId && responseType && redirectUri && scopes) {
      const url = `${root}?client_id=${clientId}&response_type=${responseType}&redirect_uri=${encodeURIComponent(redirectUri)}&scope=${encodeURIComponent(scopes)}&state=${state}`
      console.log('reconstituteAuthUrl', url)
      return url
   } else {
      return null
   }
}

export function isWebExCallback() {
   console.log('WebEx: isWebExCallback', document.location.search.substring(1))
   return document.location.search.substring(1).includes('WebExAuth')
}

let registered = false
let promise: PromiseSource<string> | undefined
let popup: Window | null = null

export async function webexLogin(webExAuthUrl: string | undefined, timeoutMs: number = 120000): Promise<string> {

   console.log('webexLogin:', webExAuthUrl);


   if (promise) {
      throw new Error('Another webex login attempt is in progress')
   }

   if (!webExAuthUrl) {
      throw new Error('Missing webex Auth url for login')
   }

   promise = createPromiseSource()
   const p = promise

   console.log('webexLogin: 1');

   if (!registered) {
      registered = true
      window.addEventListener('message', onMessage)
   }

   console.log('webexLogin: 2');

   try {
      openPopup(webExAuthUrl)
   } catch (e) {
      reject(e)
   }

   console.log('webexLogin: 3');

   setTimeout(() => {
      reject(new Error('Timeout waiting for WebEx login'))
   }, timeoutMs)

   function reject(error: Error) {
      console.log('webexLogin reject',error);
      if (p !== promise) { return }
      promise = undefined
      closePopup()
      p.reject(error)
   }

   console.log('webexLogin: 4');

   return promise.promise
}

setInterval(() => {
   if (popup && promise && popup.closed) {
      console.log('WebExLogin: setInterval trigger')
      const { reject } = promise
      promise = undefined
      popup = null
      reject(new Error('WebEx login cancelled'))
   }
}, 50)

function closePopup() {

   console.log('WebExLogin: closePopup')
   if (popup) {
      popup.close()
      popup = null
   }
}

function openPopup(webExAuthUrl: string) {

   console.log('openPopup 1:', webExAuthUrl);

   const w = 500
   const h = 600

   // Fixes dual-screen position                             Most browsers      Firefox
   const dualScreenLeft = window.screenLeft !== undefined ? window.screenLeft : window.screenX
   const dualScreenTop = window.screenTop !== undefined ? window.screenTop : window.screenY

   const width = window.innerWidth || document.documentElement.clientWidth || window.screen.width
   const height = window.innerHeight || document.documentElement.clientHeight || window.screen.height

   const systemZoom = width / window.screen.availWidth
   const left = (width - w) / 2 / systemZoom + dualScreenLeft
   const top = (height - h) / 2 / systemZoom + dualScreenTop

   popup = window.open(`./webex-login-callback?auth=${encodeURIComponent(webExAuthUrl)}`, undefined,
   // popup = window.open(`./webex-login-callback`, undefined,
      `scrollbars=yes,
      menubar=0,
      toolbar=0,
      width=${w / systemZoom},
      height=${h / systemZoom},
      top=${top},
      left=${left}`
   )

   console.log('openPopup 2:', webExAuthUrl);

   if (!popup) {
      throw new Error('Failed to open the popup')
   } else {
      if (popup.focus) {
         popup.focus()
      }
   }

   console.log('openPopup 3:', webExAuthUrl);

}

type WebexLoginMessage = {
   type: 'WEBEX_LOGIN'
   token?: string
   error?: any
}

function onMessage(ev: MessageEvent) {

   console.log('webex: onMessage', ev.data);


   const msg: WebexLoginMessage | undefined = ev.data
   if (!msg || msg.type !== 'WEBEX_LOGIN') { return }

   if (!promise) {
      console.warn('Received an unexpected WEBEX_LOGIN message', msg)
      return
   }

   const { resolve, reject } = promise
   promise = undefined

   closePopup()

   if (msg.error || !msg.token) {
      console.log('webex: onMessage reject');
      reject(msg.error || new Error('No token returned.'))
   } else {
      console.log('webex: onMessage resolve');
      resolve(msg.token)
   }



}
