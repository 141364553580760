import React, { useCallback, useContext, useMemo, useState } from 'react';
import ReactDOM from 'react-dom';
import { Popper, PopperProps } from 'react-popper';

interface ContextValue {
   node: HTMLElement | null
   setNode(node: HTMLElement | null): void
}

const Context = React.createContext<ContextValue | null>(null)

export function PopperProvider({ children }: React.PropsWithChildren<{}>) {
   const [ node, setNode ] = useState<HTMLElement | null>(null)
   const value = useMemo(() => ({ node, setNode }), [ node ])

   return <Context.Provider value={value}>
      {children}
   </Context.Provider>
}

export function PopperHost({ tag = 'div', ...props } : React.DetailedHTMLProps<React.HTMLAttributes<HTMLElement>, HTMLElement> & { tag?: string }) {
   const ctx = useContext(Context)

   const ref = useCallback((node: HTMLElement | null) => {
      if (ctx) {
         ctx.setNode(node)
      }
   }, [ ctx ])

   return React.createElement(tag, {
      ...props,
      ref,
   })
}

export function PortalPopper(props: PopperProps<any>) {
   const ctx = useContext(Context)
   return ctx?.node ? ReactDOM.createPortal(<Popper {...props} />, ctx.node) : null
}
