import { connect } from 'react-redux';
import { Dispatch } from 'redux';
import Schedule from '../components/Schedule';
import { AttendeeType, Contact, isRoom } from '../model';
import { getHaveSuggestions, getSecondaryUser, getSelectedDate, selectBookingAttendees, selectSuggestedBookingParticipants } from '../store/helpers';
import { actions, ReduxPropsType, StoreState } from '../store/utils';

function mapStateToProps(state: StoreState) {
   const { booking, time, locations } = state;
   const suggested: Contact[] = selectSuggestedBookingParticipants(state)

   return {
      time,
      date: getSelectedDate(state),
      booking,
      attendees: selectBookingAttendees(state),
      suggested,
      haveSuggestions: getHaveSuggestions(state),
      locations,
      freePeriods: [],
      myself: getSecondaryUser(state),
   }
}

function mapDispatchToProps(dispatch: Dispatch) {
   return {
      dispatch,
      onAdd(c: Contact) { dispatch(actions.addParticipant(c.emailAddress, AttendeeType.required)) },
      onRemove(p: Contact) { dispatch(actions.removeParticipant(p)) },
   }
}

const withRedux = connect(mapStateToProps, mapDispatchToProps, ({ booking, ...props }, { dispatch, ...callbacks }, ownProps) => ({
   ...props,
   ...callbacks,
   ...ownProps,
   booking,

   onAcceptSuggested: (c: Contact) => {
      if (!booking || !isRoom(c)) { return }
      dispatch(actions.addParticipant(c.emailAddress, AttendeeType.required))
   },
}));
export type ScheduleProps = ReduxPropsType<typeof withRedux>;
export default withRedux(Schedule);
