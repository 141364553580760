import classNames from 'classnames';
import React from 'react';
import css from './FormField.module.scss';

type Props = {
   icon?: React.ReactNode
   bodyClassName?: string
   iconClassName?: string
}

export default function FormField({ icon, bodyClassName, iconClassName, children }: React.PropsWithChildren<Props>) {
   return <label className={css.formField}>
      <div className={classNames(css.icon, iconClassName)}>{icon}</div>
      <div className={classNames(css.body, bodyClassName)} onClick={e => e.preventDefault()}>
         {children}
      </div>
   </label>
}
