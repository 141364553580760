import classNames from 'classnames';
import React from 'react';
import FontAwesome from 'react-fontawesome';
import css from './Input.module.scss';

interface Props {
   icon?: string;
   inputRef?: React.LegacyRef<HTMLInputElement>;
   tag?: string;
}

export default function Input({ className, icon, inputRef, tag, ...props }: Props & React.InputHTMLAttributes<HTMLInputElement>) {
   const { type, placeholder } = props;
   const isCheckbox = type === 'checkbox';

   const input = React.createElement(tag || 'input', {
      ...props,
      className: classNames(isCheckbox && css.addon),
      ref: inputRef
   });

   return <div className={classNames(css.input, icon && css.withIcon, className)}>
      {icon !== undefined && <div className={css.icon}>
         <FontAwesome name={icon} />
      </div>}

      {isCheckbox ? <label>{placeholder}{input}</label> : input}
   </div>
}


