import React, { useContext } from 'react';

type Context = HTMLElement | null

const Context = React.createContext<Context>(null)

export default Context.Provider

export function useScrollParent() {
   const ctx = useContext(Context)
   return ctx
}
