import moment from "moment"
import { useEffect, useMemo, useRef, useState } from "react"
import { useDispatch } from "react-redux"
import { Contact, Meeting, MeetingExtras } from "../model";
import { makeMeetingsSelector } from '../store/reducers';
import { actions, useSelector } from '../store/utils';
import { roundDate, timeOfDayInHours } from "./dateUtils";
import { getAdhocRoom, getCurrentRoom } from '../store/helpers';
import { fetchSchedules } from "../api";
import { isDefined } from "./misc";

export default function useAdhoc() {
   const dispatch = useDispatch()
   const defaultRoom = useSelector(getAdhocRoom)
   const currentRoom = useSelector(getCurrentRoom)

   const otherRooms = useSelector(s => (s.login.data && s.login.data.device && s.login.data.device.rooms))
   const startMinute = moment(useSelector(s => s.time)).startOf('m').valueOf()
   const contacts = useSelector(s => s.contacts)

   const date = moment(startMinute).startOf('d').valueOf()
   const selector = useMemo(() => makeMeetingsSelector<null>(_ => defaultRoom?.emailAddress ?? undefined, _ => date), [defaultRoom, date])
   const meetings = useSelector(s => selector(s, null))
   const isAdhoc = useSelector(s => s.config.canBookAdhoc && !s.booking?.full)
   const duration = useSelector(s => s.booking?.duration)

   const wrongTime = useSelector(s => s.booking ? isAdhoc && s.booking.start !== timeOfDayInHours(startMinute) : false)
   const wrongDate = useSelector(s => s.booking ? isAdhoc && s.selectedDate !== date : false)

   const [selectedRoom, setSelectedRoom] = useState<Contact | undefined>(defaultRoom)
   const [durations, setDurations] = useState<number[]>([])


   useEffect(() => {
      function getCurrentRoom(start: moment.Moment, m: number, meetings: (Meeting & MeetingExtras)[]) {
         let maxDuration = 0
         if (!meetings || meetings.some(m => start.isSameOrAfter(m.startTime) && start.isBefore(m.endTime))) {
            maxDuration = 0
         } else {
            maxDuration = -start.diff(meetings.map(m => m.startTime).filter(t => start.isSameOrBefore(t))[0] || start.add(1, 'd').startOf('d'), 'h', true)
         }
         return { durations: Array.from(new Array(6)).map((_, i) => moment(m).add(i * 30, 'm').diff(start, 'h', true)).filter(h => h >= 10 / 60).filter(x => x <= maxDuration), max: maxDuration }
      }

      async function getOtherRoom(start: moment.Moment, m: number, email: string) {
         const startDay = moment(start).startOf('d')
         const schedule = await fetchSchedules([startDay.valueOf()], [email])
         const busy = schedule[email]?.[startDay.valueOf()]
         let maxDuration = 0
         if (busy.periods.some(p => start.isSameOrAfter(p.start) && start.isBefore(p.end))) {
            maxDuration = 0
         } else {
            maxDuration = -start.diff(busy.periods.map(m => m.start).filter(t => start.isSameOrBefore(t))[0] || start.add(1, 'd').startOf('d'), 'h', true)
         }
         return { durations: Array.from(new Array(6)).map((_, i) => moment(m).add(i * 30, 'm').diff(start, 'h', true)).filter(h => h >= 10 / 60).filter(x => x <= maxDuration), max: maxDuration }
      }

      async function selectRoom() {
         if (!defaultRoom || !currentRoom) {
            console.log("No default or current room defined")
            return
         }

         const m = roundDate(startMinute, 'm', 'ceil', 30)

         let search = [defaultRoom]
         if (otherRooms) {
            const rest = otherRooms.filter(r => r.emailAddress !== defaultRoom.emailAddress).map(e => contacts[e.emailAddress]).filter(isDefined)
            search = [...search, ...rest]
         }


         for (const room of search) {
            let dur = room.emailAddress !== currentRoom.emailAddress
               ? await getOtherRoom(moment(startMinute), m, room.emailAddress)
               : meetings ? await getCurrentRoom(moment(startMinute), m, meetings) : null
            console.log(`Check ${room.emailAddress} found:`, dur)

            if (dur !== null && dur.durations.length > 0) {
               setDurations(dur.durations)
               setSelectedRoom(room)
               console.log(`Selected adhoc room ${room.emailAddress}`)
               return
            }
         }

         setDurations([])
         setSelectedRoom(undefined)
         console.log("No adhoc room available")
      }
      selectRoom()
   }, [contacts, currentRoom, defaultRoom, meetings, otherRooms, startMinute])


   useEffect(() => {
      if (wrongTime) {
         dispatch(actions.setBookingStart(timeOfDayInHours(startMinute)))
      }
   }, [dispatch, startMinute, wrongTime])

   useEffect(() => {
      if (wrongDate) {
         dispatch(actions.selectDate(startMinute))
      }
   }, [dispatch, startMinute, wrongDate])


   const firstRun = useRef(true)

   useEffect(() => {
      if (duration === undefined || durations.length === 0 || !isAdhoc) return
      let match = durations[0]

      if (firstRun.current) {
         dispatch(actions.setBookingDuration(match))
         firstRun.current = false
         return
      }

      for (const dur of durations) {
         if (Math.abs(dur - duration) < Math.abs(match - duration)) {
            match = dur
         }
      }
      if (match !== duration) {
         dispatch(actions.setBookingDuration(match))
      }
   }, [isAdhoc, dispatch, duration, durations])

   if (!isAdhoc) { return false }
   // if (!meetings) { return null }


   return {
      selectedRoom,
      durations,
      isDefaultRoom: selectedRoom?.emailAddress === defaultRoom?.emailAddress
   }
}
