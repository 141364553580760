import React, { useCallback, useEffect, useState } from 'react';
import { useDispatch } from 'react-redux';
import { FormGroup, Modal, ModalBody } from 'reactstrap';
import { actions, useSelector } from '../store/utils';
import useInterval from '../utils/useInterval';
import AccentButton from './AccentButton';
import css from './AutoLogoutPopup.module.scss';
import BrandedModalHeader from './BrandedModalHeader';

const Timeout = 10

export default function AutoLogoutPopup() {
   const dispatch = useDispatch()
   const [ time, setTime ] = useState(Timeout)
   const { type } = useSelector(s => s.popup)

   const isOpen = type === 'autoLogout'

   useEffect(() => {
      if (!isOpen) {
         setTime(Timeout)
      }
   }, [ isOpen ])

   useInterval(() => {
      if (time <= 0) {
         logOut()
      } else {
         setTime(time - 1)
      }
   }, isOpen ? 1000 : null)

   const logOut = useCallback(() => {
      dispatch(actions.secondaryLogout())
      dispatch(actions.endBooking(true))
   }, [ dispatch ])

   return <Modal isOpen={isOpen}>
      <BrandedModalHeader />
      <ModalBody className={css.body}>
         <FormGroup>
            <h1>Your session is about to expire</h1>
            <div>You will be logged out in <span className={css.em}>{time}</span> seconds.</div>
            <div>Do you want to stay logged in?</div>
         </FormGroup>
         <FormGroup>
            <AccentButton onClick={() => dispatch(actions.closePopup())}>Yes, keep me logged in</AccentButton>
            <AccentButton onClick={logOut} className={css.logoutButton}>No, sign me out</AccentButton>
         </FormGroup>
      </ModalBody>
   </Modal>;
}
