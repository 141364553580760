import React from 'react';
import { RoomType, Room } from '../model';
import { CarParkIcon, HotDeskIcon, LockerIcon, RoomIcon } from './icons';
import classNames from 'classnames';
import css from './ResourceIcon.module.scss';
import { Extend, Except } from '../utils/misc';

type ResourceIconProps = Extend<Except<React.HTMLAttributes<HTMLDivElement>, 'children'>, {
   resource: Room
}>

export function ResourceIcon({ resource, className, ...props }: ResourceIconProps) {
   return <div className={classNames(css.resourceIcon, className)} {...props}>
      <div>
         <ResourceTypeIcon type={resource.roomType} />
         {resource.roomType === 'MeetingRoom' && <svg viewBox='0 0 24 24'>
            <text x='50%' y='55%' dominantBaseline='middle' textAnchor='middle'>
               {resource.seats}
            </text>
         </svg>}
      </div>
   </div>
}

interface ResourceTypeIconProps extends React.SVGProps<SVGSVGElement> {
   type: RoomType
   title?: string
}

export function ResourceTypeIcon({ type, ...props }: React.PropsWithChildren<ResourceTypeIconProps>) {
   return React.createElement(getRoomTypeIcon(type), props)
}

export function getRoomTypeIcon(type: RoomType) {
   switch (type) {
      case 'MeetingRoom': return RoomIcon
      case 'HotDesk': return HotDeskIcon
      case 'Locker': return LockerIcon
      case 'ParkingSpace': return CarParkIcon
   }

   const _exhaustive: never = type
}
