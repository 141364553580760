import classNames from 'classnames';
import css from './MeetingLinkInfo.module.scss';

interface MeetingLinkInfoProps {
   className?: string
}

export default function MeetingLinkInfo({className}: MeetingLinkInfoProps) {
   return <div className={classNames(css.meetingLinkInfo, className)}>
      <p>Hi welcome. You are seeing this screen as it is the first time you have used this solution or you have cleared your browser cookies. As a security measure we just need to validate it is really you.</p>
      <p>When you enter your email address you will get a confirmation email sent back to you alone and when you click on that your account will be activated and you can automatically proceed.</p>
      <p>This is a once off process (as long as you use the same device and don’t clear your cookies).</p>
   </div>
}
