import { createSelector } from 'reselect';
import { Meeting } from '../../model';
import { getMeetings } from '../../store/reducers';
import { StoreState } from '../../store/utils';
import { getDayStart } from '../../utils/dateUtils';
import sortBy from '../../utils/sortBy';

export const selectRoomsAndMeetings = createSelector(
   (s: StoreState) => s.login.data,
   s => s.contacts,
   s => s.meetings,
   s => s.calendars,
   s => s.time,
   (login, contacts, meetingsById, calendars, time): RoomData[] => {
      if (!login || !login.device) { return [] }
      const date = getDayStart(time)

      return login.device.rooms.map(room => ({
         email: room.emailAddress,
         icon: room.directionIcon,
         name: getName(room.emailAddress),
         status: getStatus(room.emailAddress),
      })).sort(sortBy(x => x.email === login.myself ? 0 : 1))

      function getStatus(email: string): RoomStatus | null {
         const cal = calendars[email]
         const ids = cal && cal[date]
         if (!ids) { return null }
         const meetings = getMeetings(ids, meetingsById, email, contacts)

         const current = meetings.find(m => m.startTime <= time && time < m.endTime)
         if (current) {
            return {
               type: 'busy',
               till: current.endTime,
               meeting: current,
            }
         }

         const next = meetings.find(m => m.startTime > time)
         return {
            type: 'free',
            till: next ? next.startTime : null,
         }
      }

      function getName(email: string) {
         const contact = contacts[email]
         return contact?.name || email
      }
   }
)

export interface RoomData {
   email: string
   icon: string | null
   name: string
   status: RoomStatus | null
}

type RoomStatus = {
   type: 'busy'
   till: number
   meeting: Meeting
} | {
   type: 'free'
   till: number | null
}
