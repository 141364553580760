import classNames from 'classnames';
import React, { useEffect, useState } from 'react';
import { useDispatch } from 'react-redux';
import { Button, Modal, ModalBody } from 'reactstrap';
import { organisationLogin } from '../api';
import { Contact, Domain, LoginType } from '../model';
import { getLoginData, getSecondaryUser, isLoginDisabled } from '../store/helpers';
import { actions, useSelector } from '../store/utils';
import AccentButton from './AccentButton';
import BrandedModalHeader from './BrandedModalHeader';
import { LoginAutoCompleteInput } from './LoginAutoCompleteInput';
import css from './LoginPopup.module.scss';
import Input from './mobile/Input';

export default function LoginPopup() {
   const [email, setEmail] = useState('');
   const [password, setPassword] = useState('');

   const loginDisabled = useSelector(isLoginDisabled)
   const [domain, setDomain] = useState<Domain>()

   const dispatch = useDispatch()

   const { isSubmitting, error, ...params } = useSelector(s => s.popup)
   const myself = useSelector(getSecondaryUser)
   const allowSecondaryLoginAutoComplete = useSelector(s => s.config.allowSecondaryLoginAutoComplete)
   const meetings = useSelector(s => s.meetings)
   const loginData = useSelector(getLoginData)

   const type = params.type
   const isOpen = type === 'newBooking' || type === 'myCalendar' || type === 'cancelMeeting' || type === 'secondaryLogin' || type === 'editMeeting'

   const meeting = params.type === 'cancelMeeting' && meetings[params.meetingId]
   const noLogin = meeting && (myself && meeting.organiser === myself.emailAddress || meeting.isAdhoc) && (type === 'cancelMeeting')
   const organiser = noLogin ? undefined
      : params.type === 'editMeeting' ? params.organiser
         : meeting ? meeting.organiser
            : undefined

   useEffect(() => {
      load()

      async function load() {
         if (!organiser) { return }

         setDomain(undefined)
         const domain = await organisationLogin(organiser);
         if (typeof domain === 'string') {
            dispatch(actions.setPopupError(domain))
         } else {
            setDomain(domain)
         }
      }
   }, [dispatch, organiser])

   useEffect(() => {
      if (!isOpen) { return }

      if (organiser) {
         setEmail(organiser)
         setPassword('')
      }
   }, [organiser, isOpen])

   function onSubmit(e: React.FormEvent) {
      e.preventDefault()

      if (domain && domain.loginType !== LoginType.bookIt && !noLogin) {
         if (domain.loginType !== LoginType.none) {
            dispatch(actions.secondaryExternalLogin(domain, email))
         }
         return
      }

      if (params.type === 'cancelMeeting') {
         dispatch(actions.cancelMeeting(params.meetingId, { userName: myself ? myself.emailAddress : email, password }))
      } else {
         dispatch(actions.secondaryLogin.request({ userName: email, password: password || '' }))
      }
   }

   function onCancel() {
      if (!isSubmitting) {
         dispatch(actions.closePopup())
      }
   }

   if (!isOpen) { return null }

   return <Modal isOpen autoFocus={false}>
      <BrandedModalHeader toggle={onCancel}>
         {type === 'newBooking' ? 'New booking' : undefined}
      </BrandedModalHeader>
      <ModalBody className={css.body}>
         <h1>{
            type === 'newBooking' || type === 'secondaryLogin' ? (loginDisabled ? null : 'Log In') :
               type === 'cancelMeeting' ? (meeting && meeting.isRecurring ? 'Delete meeting occurrence' : 'Delete meeting') :
                  null
         }</h1>
         {renderBody(allowSecondaryLoginAutoComplete)}
      </ModalBody>
   </Modal>

   function renderBody(allowSecondaryLoginAutoComplete: boolean) {
      if (type === 'editMeeting' && myself || type === 'cancelMeeting' && meeting && myself && meeting.organiser !== myself.emailAddress) {
         return <>
            <div className={css.message}>
               You need to log out first and log in as the organiser in order to proceed.
            </div>
            <div className={css.buttons}>
               <AccentButton className={css.loginButton} disabled={isSubmitting} data-testid='login-logout' onClick={() => dispatch(actions.secondaryLogout())}>
                  Logout
               </AccentButton>
               <Button outline onClick={onCancel}>Cancel</Button>
            </div>
         </>
      }

      if (type === 'cancelMeeting') {
         return <form onSubmit={onSubmit}>
            {email && <div className={css.email}>Logging in as <span className={css.em}>{email}</span></div>}

            {!noLogin && domain?.loginType === LoginType.bookIt &&
               <Input autoFocus required icon='lock' type='password' value={password} onChange={e => setPassword(e.currentTarget.value)} data-testid='login-password' />}

            <div className={css.buttons}>
               <AccentButton className={classNames(css.loginButton, css.deleteButton)}
                  disabled={isSubmitting || (domain?.loginType === LoginType.none && !noLogin)} data-testid='login-login'>
                  Delete
               </AccentButton>
            </div>
            <div className={css.error}>{error}</div>
         </form>
      }

      return !loginDisabled && renderTwoStepLogin(allowSecondaryLoginAutoComplete)
   }

   function renderTwoStepLogin(allowSecondaryLoginAutoComplete: boolean) {
      if (!domain) {
         return <form onSubmit={onEmail}>
            {allowSecondaryLoginAutoComplete
               ? <LoginAutoCompleteInput onChange={i => {
                  setEmail(i ? i.emailAddress : '')
               }} />
               : <Input type='email' icon='envelope' data-testid='login-email' autoFocus required value={email} onChange={e => setEmail(e.currentTarget.value)} />}
            <div className={css.buttons}>
               <AccentButton size='lg' disabled={isSubmitting} data-testid='login-next'>Next</AccentButton>
            </div>
            {error && <div className={css.error}>{error}</div>}
         </form>
      } else {
         return <form onSubmit={onSubmit}>
            <div className={css.email}>Logging in as <span className={css.em}>{email}</span></div>

            {domain.loginType === LoginType.bookIt &&
               <Input type='password' icon='lock' autoFocus required value={password} onChange={e => setPassword(e.currentTarget.value)} data-testid='login-password' />}

            <div className={css.buttons}>
               {!organiser && <Button size='lg' disabled={isSubmitting} type='button' onClick={onBack}>Back</Button>}
               {<AccentButton autoFocus size='lg' disabled={isSubmitting} data-testid='login-login'>Log in</AccentButton>}
            </div>
            {error && <div className={css.error}>{error}</div>}
         </form>
      }
   }


   async function onEmail(e: React.FormEvent) {
      e.preventDefault()
      dispatch(actions.setPopupSubmitting(true))

      const emailDomain = email.split('@').pop()
      if (emailDomain !== undefined) {
         const knownDomain = loginData.get(emailDomain)

         if (knownDomain && knownDomain.loginType !== LoginType.bookIt && !noLogin) {
            setDomain(knownDomain)
            if (knownDomain.loginType !== LoginType.none) {
               dispatch(actions.secondaryExternalLogin(knownDomain, email))
               return
            }
         }
      }

      const domain = await organisationLogin(email)
      if (typeof domain === 'string') {
         dispatch(actions.setPopupError(domain))
      } else if (domain.loginType === LoginType.none || domain.loginType === LoginType.email) {
         dispatch(actions.setPopupError('Login is not allowed'))
      } else {
         dispatch(actions.setPopupSubmitting(false))
         setDomain(domain)
      }
   }

   function onBack() {
      setDomain(undefined)
   }
}
