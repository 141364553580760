import classNames from 'classnames';
import React from 'react';
import { Button, ButtonProps } from 'reactstrap';
import css from './AccentButton.module.scss';

export default class AccentButton extends React.PureComponent<ButtonProps & { ref?: never, color?: never }> {
   public render() {
      const { className, ...props } = this.props;
      return <Button className={classNames(css.accentButton, className)} {...props} />
   }
}
