import classNames from "classnames";
import React, { ReactNode } from "react";
import css from './OtpStatus.module.scss';
import Spinner from "./Spinner";

interface OtpStatusProps {
   error?: ReactNode
   otpComplete: boolean
   otpStarted: boolean
}

export default function OtpStatus({otpStarted, otpComplete, error}: OtpStatusProps) {
   return <div className={css.otpStatus}>
      {error && <p className={classNames(css.message, css.error)}>{error}</p>}
      {otpStarted && !otpComplete && !error && <p className={css.message}>Please follow the instructions in the email that has been sent to your inbox. It is safe to close this tab at anytime.</p>}
      {otpStarted && otpComplete && !error && <p className={css.message}>Now that you have complete the login process you can close this tab.</p>}
      {otpStarted && !error && !otpComplete && <p className={css.waiting}>
         <Spinner className={css.spinner} />
         Waiting you to follow the link in your email...
      </p>}
   </div>
}
