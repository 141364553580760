import bind from 'bind-decorator';
import React from 'react';
import { Button } from 'reactstrap';
import { TeamsLoginProps } from '../containers/TeamsLogin';
import css from './TeamsLogin.module.scss';

interface State {
   error?: string;
}

export default class TeamsLogin extends React.PureComponent<TeamsLoginProps, State> {
   public readonly state: State = {};

   public render() {
      const { login } = this.props;
      const { error } = this.state;
      return <div className={css.loginTeams}>
         <Button color='primary' onClick={this.onLogin} disabled={login.loading}>Log in with MS Teams</Button>
         <div className={css.error}>{error}</div>
      </div>;
   }

   public async componentDidMount() {
      await this.props.onInit();
   }

   @bind
   private async onLogin() {
      this.setState({ error: undefined });
      this.setState({
         error: await this.props.onLogin(),
      })
   }
}
