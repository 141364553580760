import moment from 'moment';
import { all, debounce, put, select } from 'redux-saga/effects';
import { getType } from 'typesafe-actions';
import { AvailabilitySearchItem, searchAvailable } from '../api';
import { AttendeeData, isRoom, Nearby, Recurrence } from '../model';
import { getSecondaryUser, getSelectedDateTime, selectRecurrence, selectRoomFilter, selectRooms } from '../store/helpers';
import { getDraftResources, getExtraTime } from '../store/reducers/booking';
import { actions, Actions, StoreState } from '../store/utils';
import { reportApiError, takeEvery } from '../utils/apiActions';
import { ApiResult } from './api';

const areArgsSame = createArgsComparer()
const areTimelinesSame = createArgsComparer()
const isStartSame = createArgsComparer()

const onBookingChanged = debounce(50, (x: Actions) => x.type !== getType(actions.updateTime), function*() {
   yield runAvailabilitySearch(false)
})

type UpdateType = 'skip' | 'silent' | 'normal'
let CallId = 0

function getUpdateType(myself: string, selectedDate: number, booking: NonNullable<StoreState['booking']>, recurrence: Recurrence | null): UpdateType {
   const { participants, duration, meetingId, allDay, createAppointment } = booking
   const resources = getDraftResources(booking)
   const timelinesSame = areTimelinesSame(participants.map(p => p.emailAddress))
   const argsSame = areArgsSame(myself, resources, participants, selectedDate, duration, meetingId, allDay, recurrence, createAppointment)

   return timelinesSame && argsSame
      ? 'skip'
      : argsSame ? 'silent' : 'normal'
}

export function* runAvailabilitySearch(force: boolean|'silent') {
   const state: StoreState = yield select()

   const { booking, selectedDate, suggestions } = state
   const recurrence = selectRecurrence(state)
   const myself = getSecondaryUser(state)

   if (!booking || !myself) { return }

   const { participants, duration, allDay, nearby } = booking
   if (nearby) { return }

   const resources = getDraftResources(booking)
   const allRooms = selectRooms(state)
   const start = moment(selectedDate).add(booking.start, 'h').valueOf()

   let updateType = force ? (force === 'silent' ? 'silent' : 'normal') : getUpdateType(myself.emailAddress, selectedDate, booking, recurrence)

   const startIsSame = isStartSame(start)
   if (updateType === 'skip') {
      if (!startIsSame && !suggestions.final.suggestions.some(s => s.start === start)) {
         updateType = 'normal'
      }
   }

   if (updateType === 'skip') { return }

   const filterRoom = selectRoomFilter(state)
   const searchPool = resources.map<AvailabilitySearchItem>(res => ({
      before: getExtraTime(res.extraBefore, booking, res.type),
      after: getExtraTime(res.extraAfter, booking, res.type),
      count: Math.max(0, res.quantity - res.pinned.length),
      emails: allRooms
         .filter(r =>
            // (!pinned.includes(r.emailAddress) /* || res.pinned.includes(r.emailAddress) */) &&
            filterRoom(r, res) &&
            !participants.some(p => p.emailAddress === r.emailAddress))
         .map(r => r.emailAddress),
      pinned: res.pinned,
   }))

   if (updateType !== 'silent') {
      yield put(actions.fetchSuggestionRequesting())
   }

   let res: ApiResult<typeof searchAvailable>
   try {
      const callId = ++CallId
      res = yield searchAvailable(myself.emailAddress, searchPool,
         booking.createAppointment ? participants.map(p => p.emailAddress) : [],
         start, duration,
         booking.addResourcesMode ? undefined : booking.meetingId,
         allDay, recurrence,
         booking.addResourcesMode ? 1 : 3)
      if (callId !== CallId) { return }
   } catch (e) {
      yield reportApiError(e)
      return
   }

   yield put(actions.fetchSuggestionResponse(res))

   yield put(actions.acceptSuggestion(res.find(sug => sug.start === start) ?? null))
}

const onBookingEnded = takeEvery(actions.endBooking, function* (): IterableIterator<void> {
   areArgsSame.reset()
   isStartSame.reset()
})

function createArgsComparer() {
   let prevArgs: any[] | undefined

   function reset() {
      prevArgs = undefined
   }

   return Object.assign(function compareArgs(...args: any[]): boolean {
      const localPrevArgs = prevArgs
      prevArgs = args

      if (!localPrevArgs) {
         return false
      }

      if (localPrevArgs.length !== args.length) { throw new Error('Args don\'t match') }

      return arraysEqual(args, localPrevArgs)
   }, { reset })
}

/** Compares arrays shallowly, except when an item is also an array, in which case compares that item shallowly as well. */
function arraysEqual(arr1: any[], arr2: any[]) {
   if (arr1.length !== arr2.length) { return false }

   for (let i = 0; i < arr1.length; i++) {
      const a = arr1[i]
      const b = arr2[i]

      if (Object.is(a, b)) { continue }

      if (Array.isArray(a) && Array.isArray(b)) {
         if (a.length !== b.length) { return false }
         for (let j = 0; j < a.length; j++) {
            if (!Object.is(a[j], b[j])) { return false }
         }
         continue
      }

      return false
   }

   return true
}

const acceptOnTimeChange = takeEvery(actions.setBookingStart, function*(state) {
   if (!state.booking) { return }
   const time = getSelectedDateTime(state)
   if (time !== null && time !== state.booking.suggested?.start) {
      const suggestion = state.suggestions.final.suggestions.find(s => s.start === time)
      if (suggestion) {
         yield put(actions.acceptSuggestion(suggestion))
      }
   }
})

export default all([
   onBookingChanged,
   onBookingEnded,
   acceptOnTimeChange,
])
