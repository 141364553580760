import bind from 'bind-decorator';
import classNames from 'classnames';
import debounce from 'debounce-promise';
import moment from 'moment';
import React from 'react';
import { Flipped, Flipper } from 'react-flip-toolkit';
import ScrollbarSize, { Measurements } from 'react-scrollbar-size';
import { DropdownItem, DropdownMenu, DropdownToggle, UncontrolledDropdown } from 'reactstrap';
import ScheduleLine from '../components/ScheduleLine';
import BookingCursor from '../containers/BookingCursor';
import { ScheduleProps } from '../containers/Schedule';
import { AttendeeType, Contact, LocationFilter } from '../model';
import { timeOfDayInHours } from '../utils/dateUtils';
import { flatten } from '../utils/misc';
import AddIcon from './buttons/add.png';
import RemoveIcon from './buttons/remove.png';
import ContactPhoto from './ContactPhoto';
import { getLocationName, getLocationNames, isLocationSelected, toggleLocation } from './RoomFilter';
import css from './Schedule.module.scss';
import { getDayHours } from './ScheduleLine';

type Props = ScheduleProps & {
   rooms: Contact[] | null;
   roomListMode: boolean;
   highlight?: string;
   onContactClick?(c: Contact): void;
   onClickSegment?(c: Contact, hr: number): void;
   onLocationSelected?: (location: LocationFilter) => void
}

interface State {
   scrollbarHeight: number;
   shadowLeft: boolean;
   shadowRight: boolean;
   additionalExpanded: boolean;
   location: LocationFilter
}

export default class Schedule extends React.PureComponent<Props, State> {
   public readonly state: State = { scrollbarHeight: 0, shadowLeft: false, shadowRight: true, additionalExpanded: false, location: [] };
   private readonly scrollerRef = React.createRef<HTMLDivElement>();
   private lastScroll = 0;

   private throttledScrollTo = debounce(this.scrollTo, 100);

   public render() {
      const { rooms, roomListMode, haveSuggestions, onAcceptSuggested } = this.props;
      const { scrollbarHeight, shadowLeft, shadowRight, additionalExpanded } = this.state;

      const suggested = roomListMode ? [] : this.props.suggested
      const attendees = roomListMode ? [] : this.props.attendees

      const suggestionsLoading = haveSuggestions === null
      const extraRooms = (rooms || []).filter(r => !suggested.includes(r))

      const panel0Key = '--panel0--'
      const panel1Key = '--panel1--'
      const panel2Key = '--panel2--'
      const loadingKey = '--loading suggestions--'
      const flipKey = flatten([attendees.sort((x, y) => x.type - y.type).map(a => a.contact), suggested, extraRooms]).map(c => c.emailAddress).join()

      const requiredAttendees = attendees.filter(a => a.type === AttendeeType.required)
      const optionalAttendees = attendees.filter(a => a.type === AttendeeType.optional)

      return <div className={css.schedule}>
         <Flipper className={css.infos} flipKey={flipKey}>
            <div className={classNames(css.times, css.corner)}>
               {roomListMode ? this.renderLocationPicker() : <span>Required attendees</span>}
            </div>
            {requiredAttendees.map(p => this.renderInfo(p.contact, false))}
            {!roomListMode && optionalAttendees.length > 0 && <Panel flipId={panel0Key} className={css.optionalAttendeesPanel}>
               Optional attendees
            </Panel>}
            {!roomListMode && optionalAttendees.map(p => this.renderInfo(p.contact, false))}
            {!roomListMode && <Panel flipId={panel1Key} className={css.suggestedResourcesPanel}>
               Auto-selected resources
            </Panel>}
            {!roomListMode && suggestionsLoading ? <Panel flipId={loadingKey} className={css.loading} /> : suggested.map(r => this.renderInfo(r, true, onAcceptSuggested))}
            {!roomListMode && <Panel flipId={panel2Key} className={css.additionalResourcesPanel}>
               <ExpanderButton expanded={additionalExpanded} onToggle={ex => this.setState({ additionalExpanded: ex })}>
                  Select additional resources
               </ExpanderButton>
               {additionalExpanded && <>from {this.renderLocationPicker()}</>}
            </Panel>}
            {(additionalExpanded || roomListMode) && extraRooms.map(r => this.renderInfo(r, true))}
         </Flipper>
         <ScrollbarSize onLoad={this.fixScrollbar} onChange={this.fixScrollbar} />
         <Flipper className={classNames(css.scrollWrapper, shadowLeft && css.shadowLeft, shadowRight && css.shadowRight)} flipKey={flipKey}>
            <div className={classNames(css.scroller)} ref={this.scrollerRef}
               style={{ marginBottom: -scrollbarHeight }}
               onScroll={this.onScroll} onWheel={this.onWheel}>
               <div className={css.times}>
                  {[...getDayHours()].map(h => h % 1 === 0 ? <span key={h}>{moment({ h }).format('LT')}</span> : null)}
               </div>
               <div className={css.lines} style={{ gridTemplateRows: this.getTemplateRows() }}>
                  {requiredAttendees.map(a => this.renderLine(a.contact, false))}
                  {!roomListMode && optionalAttendees.length > 0 && <Flipped flipId={panel0Key}><div /></Flipped>}
                  {!roomListMode && optionalAttendees.map(a => this.renderLine(a.contact, false))}
                  {!roomListMode && <Flipped flipId={panel1Key}><div /></Flipped>}
                  {!roomListMode && suggestionsLoading ? <Flipped flipId={loadingKey}><div /></Flipped> : suggested.map(c => this.renderLine(c, false))}
                  {!roomListMode && <Flipped flipId={panel2Key}><div /></Flipped>}
                  {(additionalExpanded || roomListMode) && extraRooms.map(c => this.renderLine(c, true))}
               </div>
               {this.renderCurrentTime()}
               {this.renderBooking()}
            </div>
         </Flipper>
      </div>;
   }

   public componentDidMount() {
      if (this.props.booking) {
         this.scrollToBooking();
      } else {
         this.scrollToCurrent();
      }

      this.updateLocationFiler()
   }

   public componentDidUpdate({ booking: prevBooking, time: prevTime, roomListMode: prevRoomListMode }: Props) {
      const { booking, time, roomListMode } = this.props;

      if ((time !== prevTime || !prevRoomListMode) && roomListMode) {
         this.scrollToCurrent(!prevRoomListMode);
      }

      if (!roomListMode && (prevRoomListMode
         || !prevBooking
         || !booking
         || prevBooking.duration !== booking.duration
         || prevBooking.start !== booking.start)) {
         this.scrollToBooking();
      }

      if (prevRoomListMode !== roomListMode) {
         this.updateLocationFiler()
      }
   }

   private updateLocationFiler() {
      if (this.props.roomListMode) {
         this.setLocation([null])
      }
   }

   private setLocation(location: LocationFilter) {
      this.setState({ location })
      this.props.onLocationSelected?.(location)
   }

   private renderLocationPicker() {
      const { locations } = this.props
      const { location } = this.state

      return <UncontrolledDropdown className={css.locationPicker}>
         <DropdownToggle caret tag='div' className={css.locationPickerToggle}>
            <span>{getLocationNames(location).join(', ')}</span>
         </DropdownToggle>
         <DropdownMenu right>
            {locations ? [undefined, null, ...locations].map((loc, ind) =>
               <DropdownItem key={ind} toggle={false} className={classNames(css.location, isLocationSelected(loc, location) && css.selected)}
                  onClick={() => this.setLocation(toggleLocation(loc, location))}>
                  {getLocationName(loc)}
               </DropdownItem>) : []}
         </DropdownMenu>
      </UncontrolledDropdown>
   }

   @bind
   private renderInfo(c: Contact, add: boolean, onClick?: (c: Contact) => void) {
      const { onContactClick, onAdd, onRemove, roomListMode, highlight, myself } = this.props;
      const onAddRemove = add ? onAdd : onRemove;

      return <Flipped key={c.emailAddress} flipId={c.emailAddress}>
         <div className={classNames(css.info, c.emailAddress === highlight && css.highlight)}>
            {!roomListMode && (c === myself
               ? <div className={css.addRemoveButtonPlaceholder} />
               : <button className={css.addRemoveButton} onClick={() => (onClick || onAddRemove)(c)}>
                  <img src={add ? AddIcon : RemoveIcon} />
               </button>)}
            <ContactPhoto className={classNames(onContactClick && css.clickable)} contact={c} onClick={() => onContactClick?.(c)} />
            <div className={classNames(css.name, onContactClick && css.clickable)} onClick={() => onContactClick?.(c)}>
               {c.name}
            </div>
         </div>
      </Flipped>
   }

   @bind
   private renderLine(c: Contact, extra: boolean) {
      const { onClickSegment, date, highlight, freePeriods } = this.props;
      return <Flipped key={c.emailAddress} flipId={c.emailAddress}>
         {flipProps => <ScheduleLine contact={c} date={date} freePeriods={extra ? null : freePeriods}
            onClickSegment={onClickSegment} highlight={c.emailAddress === highlight} otherProps={flipProps} />}
      </Flipped>
   }

   private getTemplateRows() {
      const { roomListMode, attendees, suggested } = this.props;

      if (roomListMode) {
         return;
      }

      function repeat(count: number) {
         return count ? `repeat(${count}, 1fr)` : null;
      }

      const optionalAttendees = attendees.filter(a => a.type === AttendeeType.optional).length

      const template = [
         repeat(attendees.filter(a => a.type === AttendeeType.required).length),
         !roomListMode && optionalAttendees && css.roomSeparatorHeight,
         !roomListMode && optionalAttendees && repeat(optionalAttendees),
         !roomListMode && css.roomSeparatorHeight,
         repeat(suggested.length),
         css.panelHeight,
      ]

      return template.filter(x => x).join(' ');
   }

   private renderCurrentTime() {
      if (!this.isDateToday()) {
         return null;
      }
      return <div className={css.currentTime} style={{ left: this.getCurrentTimeInPx() }} />;
   }

   private isDateToday() {
      const { date, time } = this.props;
      return moment(time).isSame(date, 'day');
   }

   private renderBooking() {
      const { roomListMode } = this.props;
      return roomListMode ? null : <BookingCursor className={css.booking} />;
   }

   private scrollToCurrent(force: boolean = false) {
      if ((force || Date.now() - this.lastScroll > 5000) && this.isDateToday()) {
         this.scrollTo(this.getCurrentTimeInPx());
      }
   }

   private scrollToBooking() {
      const { booking } = this.props;
      if (!booking) { return }

      const f = 0.1; // 10% from the edge, if it doesn't fit
      const start = hoursToPx(booking.start);
      const width = hoursToPx(booking.duration);
      const scrollerWidth = this.getScrollerWidth();

      const to = width <= scrollerWidth
         ? start + width / 2
         : start + scrollerWidth * (0.5 - f);

      this.throttledScrollTo(to);
   }

   private getScrollerWidth() {
      const { current: scroller } = this.scrollerRef;
      return !scroller ? 0 : scroller.offsetWidth;
   }

   @bind
   private scrollTo(to: number) {
      const { current: scroller } = this.scrollerRef;
      if (!scroller) { return; }
      const x = to - scroller.offsetWidth / 2;
      if (scroller.scrollTo) {
         scroller.scrollTo(horizontal(x));
      } else {
         scroller.scrollLeft = x;
      }
   }

   @bind
   private fixScrollbar({ scrollbarHeight }: Measurements) {
      this.setState({ scrollbarHeight });
   }

   @bind
   private onScroll(e: React.UIEvent<HTMLDivElement>) {
      const { scrollLeft, scrollWidth, offsetWidth } = e.currentTarget;
      this.lastScroll = Date.now();
      this.setState({
         shadowLeft: scrollLeft > 0,
         shadowRight: scrollLeft + offsetWidth < scrollWidth,
      })
   }

   @bind
   private onWheel(e: React.WheelEvent<HTMLDivElement>) {
      e.preventDefault();
      let k = 1;
      if (e.deltaMode === 1) {
         k = hoursToPx(1);
      }
      e.currentTarget.scrollLeft += e.deltaY * k;
   }

   private getCurrentTimeInPx() {
      const { time } = this.props;
      return hoursToPx(timeOfDayInHours(time));
   }
}

function horizontal(x: number) {
   return {
      left: x,
      top: 0,
      behavior: 'smooth' as 'smooth',
   }
}

function Panel({ className, flipId, children }: React.PropsWithChildren<{ className: string, flipId: string }>) {
   return <div className={css.panelWrapper}>
      <Flipped flipId={flipId}>
         <div className={className}>
            {children ? <div>{children}</div> : null}
         </div>
      </Flipped>
   </div>
}

function ExpanderButton({ expanded, onToggle, children }: React.PropsWithChildren<{ expanded: boolean, onToggle(expanded: boolean): void }>) {
   return <div onClick={() => onToggle(!expanded)} className={classNames(css.expanderButton, expanded && css.expanded)}>
      <svg viewBox='0 0 512 512'>
         <path d='M298.3,256L298.3,256L298.3,256L131.1,81.9c-4.2-4.3-4.1-11.4,0.2-15.8l29.9-30.6c4.3-4.4,11.3-4.5,15.5-0.2l204.2,212.7  c2.2,2.2,3.2,5.2,3,8.1c0.1,3-0.9,5.9-3,8.1L176.7,476.8c-4.2,4.3-11.2,4.2-15.5-0.2L131.3,446c-4.3-4.4-4.4-11.5-0.2-15.8  L298.3,256z' />
      </svg>
      {children}
   </div>
}

export const segmentWidth = parseFloat(css.segmentWidth);
export const segmentSpacing = parseFloat(css.segmentSpacing);
const segmentHours = parseFloat(css.segmentHours);

export function hoursToPx(h: number) {
   return (segmentWidth + segmentSpacing * 2) * h / segmentHours;
}

export function pxToHours(px: number) {
   return px * segmentHours / (segmentWidth + segmentSpacing * 2);
}
