import { LocationChangeAction, LOCATION_CHANGE } from 'connected-react-router';
import { buffers, eventChannel } from 'redux-saga';
import { call, fork, put, select, take } from 'redux-saga/effects';
import { bearerLogin } from '../api';
import { HostingContext, LaunchAction, onLaunchAction } from '../hosted';
import { Page } from '../model';
import { actions, StoreState } from '../store/utils';
import { ApiResult } from './api';
import { getPageUrl } from './page';

export default function* hosted(context: HostingContext) {
   const token: ApiResult<typeof context.getToken> = yield call(context.getToken)
   const res: ApiResult<typeof bearerLogin> = yield call(bearerLogin, token)

   if (!res) {
      alert('Authentication failed')
      window.location.reload()
      return
   }

   yield fork(goToHomeTab, context)

   yield put(actions.login.response(res))

   if (context.action) {
      yield onAction(context.action)
   }

   // yield fork(processActions)
}

function* goToHomeTab(context: HostingContext) {
   console.log("goToHomeTab() started")
   for (; ;) {
      const state: StoreState = yield select()
      const nav: LocationChangeAction = yield take(LOCATION_CHANGE)
      console.log("nav", nav)
      console.log("state.router.location.pathname", state.router.location.pathname)
      if (nav.payload.location.pathname === getPageUrl(Page.calendar, true) && state.router.location.pathname !== '/') {
         context.goHome()
      }
   }
}

function* processActions() {
   const channel = eventChannel<LaunchAction>(emitter => onLaunchAction(emitter), buffers.expanding(20))

   for (; ;) {
      const action: LaunchAction = yield take(channel);
      yield onAction(action)
   }
}

function* onAction(action: LaunchAction) {
   switch (action.type) {
      case 'calendar':
         yield put(actions.goToPage(Page.calendar))
         break;

      case 'editMeeting':
         yield put(actions.editBooking(action.meetingId, undefined))
         break;
   }
}
