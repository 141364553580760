import { connect } from 'react-redux';
import { Dispatch } from 'redux';
import TimelinePopup, { OwnProps } from '../components/TimelinePopup';
import { Contact } from '../model';
import { getContact, isLoginDisabled, makeRoomAttributesSelector, selectSlots } from '../store/helpers';
import { actions, ReduxPropsType, StoreState } from '../store/utils';
import { timeOfDayInHours } from '../utils/dateUtils';

function makeMapStateToProps() {
   const selectAttributes = makeRoomAttributesSelector<string | undefined>((s, p) => p);

   return (state: StoreState, props: OwnProps) => {
      return {
         slots: selectSlots(state, props),
         contact: props.email === undefined ? undefined : getContact(state, props.email),
         attributes: selectAttributes(state, props.email),
         loginDisabled: isLoginDisabled(state),
      };
   };
}

function mapDispatchToProps(dispatch: Dispatch) {
   return {
      dispatch,
   }
}

type StateProps = ReturnType<ReturnType<typeof makeMapStateToProps>>

const withRedux = connect(makeMapStateToProps, mapDispatchToProps, ({ loginDisabled, ...props }: StateProps, { dispatch }, ownProps: OwnProps) => ({
   ...props,
   ...ownProps,
   startBooking(time: number, c: Contact) {
      console.log(`TimelinePopup: startBooking: ${time}, loginDisabled: ${loginDisabled}`, c)
      if (!loginDisabled) {
         return dispatch(actions.startBooking({ start: timeOfDayInHours(time), pinnedRoom: c.emailAddress, full: true }));
      }
   },
}));
export type TimelinePopupProps = ReduxPropsType<typeof withRedux>;
export default withRedux(TimelinePopup);
