import React, { useEffect, useRef } from 'react';
import { useDispatch } from 'react-redux';
import { actions, useSelector } from '../../store/utils';
import css from './DescriptionEditor.module.scss';
import { Header } from './Header';
import { usePageNav } from './navigation';
import SideSheet from './SideSheet';

export default function DescriptionEditor() {
   const { isOpen, open } = usePageNav()

   const dispatch = useDispatch()
   const agenda = useSelector(s => s.booking?.agenda)
   const description = agenda?.notes
   const ref = useRef<HTMLTextAreaElement>(null)

   function onChange(e: React.ChangeEvent<HTMLTextAreaElement>) {
      if (!agenda) { return }
      dispatch(actions.setBookingAgenda({ ...agenda, notes: e.currentTarget.value }))
   }

   useEffect(() => {
      if (!ref.current) { return }
      ref.current.style.height = ref.current.scrollHeight + 'px'
   }, [description])

   return <>
      <button className={css.descriptionEditor} onClick={open}>
         {description || <span>Description</span>}
      </button>
      <SideSheet open={isOpen}>
         <Header back>Description</Header>
         <SideSheet.Body className={css.background}>
            <div className={css.wrapper}>
               <div className={css.border}>
                  <textarea autoFocus ref={ref} className={css.editor} value={description || ''} onChange={onChange} />
               </div>
            </div>
         </SideSheet.Body>
      </SideSheet>
   </>
}
