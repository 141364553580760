import { connectRouter } from 'connected-react-router';
import { History } from 'history';
import moment from 'moment';
import { combineReducers } from 'redux';
import { createSelector } from 'reselect';
import { getType } from 'typesafe-actions';
import { AfterLoginAction, Domains, isRoom, Meeting, MeetingExtras, MeetingType, RoomAttribute } from '../../model';
import { getDayStart } from '../../utils/dateUtils';
import { flatten, isDefined, isTruthy } from '../../utils/misc';
import sortBy from '../../utils/sortBy';
import { actions, Actions, StoreState } from '../utils';
import booking from './booking';
import calendars, { selectCalendar, selectCalendarDay } from './calendars';
import config from './config';
import contacts from './contacts';
import displayBoard from './displayBoard';
import login from './login';
import meetings, * as fromMeetings from './meetings';
import popup from './popup';
import schedules from './schedules';
import secondaryLogin from './secondaryLogin';
import suggestions from './suggestions';
import teams from './teams';
import toasts from './toasts';

function localRooms(emails: string[] = [], action: Actions): string[] {
   switch (action.type) {
      case getType(actions.login.response):
      case getType(actions.whoAmI.response):
         if (!action.payload) { return [] }

         const { myself, device } = action.payload
         const rooms = device?.rooms.map(r => r.emailAddress) || []

         if (isRoom(myself)) {
            const email = myself.emailAddress
            return [email].concat(rooms.filter(r => r !== email))
         }

         return rooms;
   }
   return emails;
}

function meetingTypes(meetingTypes: MeetingType[] = [], action: Actions): MeetingType[] {
   switch (action.type) {
      case getType(actions.login.response):
      case getType(actions.whoAmI.response):
      case getType(actions.secondaryLogin.response):
      case getType(actions.restoreLogin):
         if (!action.payload) { return [] }
         return action.payload.organisation?.onlineMeetingTypes ?? [];
   }
   return meetingTypes;
}

function location(state: string | null = null, action: Actions): string | null {
   if (action.type === getType(actions.pickLocation)) {
      return action.payload;
   }

   return state;
}

function locations(state: string[] | null = null, action: Actions) {
   switch (action.type) {
      case getType(actions.whoAmI.response):
      case getType(actions.secondaryLogin.response):
      case getType(actions.restoreLogin):
      case getType(actions.login.response): {
         if (action.payload) {
            if (action.payload.organisation) {
               const { rooms } = action.payload.organisation
               const locations = new Set(rooms.map(r => r.location))
               return Array.from(locations)
            } else {
               return []
            }
         }
      }
   }

   return state;
}

function roomAttributes(state: RoomAttribute[] | null = null, action: Actions) {
   switch (action.type) {
      case getType(actions.whoAmI.response):
      case getType(actions.secondaryLogin.response):
      case getType(actions.restoreLogin):
      case getType(actions.login.response): {
         if (action.payload) {
            if (action.payload.organisation) {
               const { rooms, attributes } = action.payload.organisation
               const roomAttrs = new Set(flatten(rooms.map(r => r.attributes)))
               return attributes.filter(a => roomAttrs.has(a.id))
            }
            return []
         }
      }
   }

   return state;
}

function domains(state: Domains = {}, action: Actions) {
   switch (action.type) {
      case getType(actions.whoAmI.response):
      case getType(actions.secondaryLogin.response):
      case getType(actions.restoreLogin):
      case getType(actions.login.response): {
         if (action.payload?.organisation) {
            return action.payload.organisation.domains
         } else {
            return {}
         }
      }
   }

   return state;
}

function roomSizesFilter(state = [4, 9], action: Actions): number[] {
   switch (action.type) {
      case getType(actions.whoAmI.response):
      case getType(actions.login.response): {
         if (action.payload) {
            const { roomSizesFilter } = action.payload.staticData
            return roomSizesFilter
         }
      }
   }
   return state;
}

function selectedDate(t: number = 0, action: Actions) {
   switch (action.type) {
      case getType(actions.selectDate):
         return action.payload

      case getType(actions.acceptSuggestion):
         if (action.payload.suggestion) {
            return moment(action.payload.suggestion.start).startOf('d').valueOf()
         }
         break

      case getType(actions.startAddResource):
         return getDayStart(action.payload.startTime)
   }

   return t;
}

function time(state: number = 0, action: Actions) {
   if (action.type === getType(actions.updateTime)) {
      return action.payload;
   }
   return state;
}

function serverTimeOffset(state: number = 0, action: Actions) {
   if (action.type === getType(actions.setServerTimeOffset)) {
      return action.payload;
   }
   return state;
}

function lastActivity(state: number = 0, action: Actions) {
   if (action.type === getType(actions.lastActivity)) {
      return action.payload;
   }

   return state;
}

function mobile(state: boolean = false) {
   return state;
}

function appInited(state: boolean = false, action: Actions) {
   if (action.type === getType(actions.loggedIn)) {
      return true
   }

   return state
}

function splash(state: string | null = null, action: Actions) {
   if (action.type === getType(actions.setSplash)) {
      return action.payload
   }

   return state
}

function reconnecting(state: boolean = false, action: Actions) {
   if (action.type === getType(actions.reconnecting)) {
      return action.payload
   }

   return state
}

function version(state: string | null = null, action: Actions): string | null {
   if (action.type === getType(actions.setVersion)) {
      const version = action.payload
      return version ?? null
   }

   return state
}

function afterLoginAction(state: AfterLoginAction = null): AfterLoginAction {
   return state
}

export default function createRootReducer(history: History) {
   return combineReducers({
      router: connectRouter(history),
      login,
      meetings,
      contacts,
      time,
      serverTimeOffset,
      selectedDate,
      booking,
      roomAttributes,
      location,
      locations,
      localRooms,
      roomSizesFilter,
      popup,
      teams,
      lastActivity,
      mobile,
      secondaryLogin,
      appInited,
      suggestions,
      config,
      domains,
      splash,
      toasts,
      reconnecting,
      version,
      schedules,
      calendars,
      meetingTypes,
      afterLoginAction,
      displayBoard,
   });
}

export function getMeetingsByEmail(state: StoreState, email: string, date: moment.MomentInput) {
   const cal = selectCalendarDay(state, email, moment(date).startOf('d').valueOf());
   if (!cal) { return };
   return cal.map(id => fromMeetings.getById(state.meetings, id));
}

export const makeMeetingsSelector = <P>(
   getEmail: (state: StoreState, props: P) => string | undefined,
   getDate: (state: StoreState, props: P) => number,
) => createSelector(
   (state: StoreState, props: P) => {
      const email = getEmail(state, props)
      return email && selectCalendar(state, email)
   },
   (state: StoreState, props: P) => {
      const email = getEmail(state, props);
      if (!email) return false
      const cal = selectCalendarDay(state, email, getDate(state, props))
      return Boolean(cal)
   },
   (state: StoreState) => state.meetings,
   (state: StoreState) => state.contacts,
   (state: StoreState, props: P) => getEmail(state, props),
   (state: StoreState, props: P) => getDate(state, props),
   (cal, isLoaded, allMeetings, allContacts, email, day) => {
      if (email === undefined) { return }
      if (!cal || !isLoaded) { return };

      const dayEnd = moment(day).endOf('d').valueOf()
      const meetings = flatten(Object.keys(cal).map(x => cal[parseInt(x)]).filter(isTruthy))

      return getMeetings(meetings, allMeetings, email, allContacts)
         .filter(m => m.startTime <= dayEnd && day < m.endTime) // check if meeting intersects the day
   }
)

export function getMeetings(ids: string[], allMeetings: StoreState['meetings'], email: string, allContacts: StoreState['contacts']) {
   return ids
      .map(id => fromMeetings.getById(allMeetings, id))
      .filter(isDefined)
      .map(m => getMeeting(m, email, allContacts))
      .sort(sortBy(x => x.startTime))
}

export function getMeeting(m: fromMeetings.Meeting, email: string, allContacts: StoreState['contacts']): Meeting & MeetingExtras {
   const started = round(m.exceptions[email]?.startTime || null)
   const ended = round(m.exceptions[email]?.endTime || null)
   return {
      ...m,
      startTime: started || round(m.startTime),
      endTime: ended || round(m.endTime),
      organiser: m.organiser ? allContacts[m.organiser] : undefined,
      participants: m.participants.map(p => allContacts[p.emailAddress]).filter(isDefined),
      started,
      ended,
   }

   function round(time: number): number;
   function round(time: null): null;
   function round(time: number | null): number | null;
   function round(time: number | null) {
      if (time === null) { return null }
      return moment(time).startOf('m').valueOf()
   }
}

const emptyArray: never[] = []
export const getBooking = (s: StoreState) => s.booking || undefined;
export const getBookingParticipants = (s: StoreState) => s.booking ? s.booking.participants : emptyArray

export function getHaveSecondaryLogin(s: StoreState) {
   return s.secondaryLogin !== false
}
