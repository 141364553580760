import { getType } from 'typesafe-actions';
import * as api from '../../api';
import { Contact } from '../../model';
import { actions, Actions } from '../utils';

interface Login {
   /** is request in flight? */
   loading: boolean;

   /** do we have a response from the server? */
   initialized: boolean;

   /**
    * - `undefined` when the user is not logged in or the status is unknown (check `initialized` prop),
    * - `false` when the log in attempt failed,
    * - valid user data otherwise
    */
   data?: false | {
      myself: string;
      delegations: api.DelegationData | false
      singleUser: boolean;
      needLogin: boolean;
      isExternal: boolean;
      device?: NonNullable<api.Login['device']>;
      contact: Contact;
      organisation: api.Organisation | null
      domain: string
   }
}

export default function login(state: Login = { loading: false, initialized: false }, action: Actions): Login {
   switch (action.type) {
      case getType(actions.whoAmI.request):
      case getType(actions.login.request):
         return { ...state, loading: true, data: undefined};
      case getType(actions.whoAmI.response):
      case getType(actions.login.response): {
         if (!action.payload) {
            return { ...state, initialized: true, loading: false, data: action.payload };
         }

         const { myself, delegations, device, singleUser, needLogin, isExternal, organisation, domain } = action.payload;

         return {
            ...state,
            initialized: true,
            loading: false,
            data: {
               myself: myself.emailAddress,
               delegations: delegations || false,
               device: device || undefined,
               singleUser,
               isExternal,
               needLogin,
               contact: myself,
               organisation,
               domain,
            }
         };
      }
   }
   return state;
}
