import classNames from 'classnames';
import React, { useState } from 'react';
import { Modal, ModalBody } from 'reactstrap';
import { TimelinePopupProps } from '../containers/TimelinePopup';
import { isRoom } from '../model';
import { TimelineSlot } from '../store/helpers';
import BrandedModalHeader from './BrandedModalHeader';
import css from './TimelinePopup.module.scss';

export interface OwnProps {
   email?: string;
   time: number;
   onClose(): void;
}

type Props = OwnProps & TimelinePopupProps;

export default function TimelinePopup({ slots, contact, attributes, startBooking, onClose }: Props) {
   const [ planOpen, openPlan ] = useState(false)

   const planUrl = isRoom(contact) && contact.hasFloorPlan
      && `/api/rooms/plan/${encodeURIComponent(contact.emailAddress)}` || undefined;

   if (!contact) { return null }

   function onSlotClick(s: TimelineSlot) {
      if (!s.isFree) { return }
      startBooking(s.start.valueOf(), contact!)
      onClose()
   }

   return <div className={css.timielinePopup}>
      {isRoom(contact) && <div tabIndex={0} className={css.plan} onClick={() => planUrl && openPlan(true)}>
         {planUrl ? <img src={planUrl} /> : <div>no plan</div>}
      </div>}
      <div className={css.details}>
         <div className={css.header}>
            <header>{contact.name || contact.emailAddress}</header>
            <div className={css.attributes}>
               {attributes.map(attr => <img key={attr.id} src={attr.url} alt={attr.name} title={attr.name} />)}
            </div>
         </div>
         <div className={css.slots}>
            {slots.map((s, i) => <div tabIndex={0} key={i} className={classNames(css.slot, !s.isFree && css.busy)} onClick={() => onSlotClick(s)}>
               <div>{s.start.format('LT')}</div>
               <div className={css.slotBox}>
                  <div className={css.title}>{s.isFree ? 'Available' : s.title || 'Busy'}</div>
                  {s.isFree ? <BookIcon /> : <UserIcon />}
                  <div className={css.organiser}>{s.isFree ? 'Book Now' : s.organiser}</div>
                  <div className={css.time}>{formatDuration(s)}</div>
               </div>
            </div>)}
         </div>
         <Modal isOpen={planOpen} toggle={() => openPlan(false)} className={css.modal}>
            <BrandedModalHeader toggle={() => openPlan(false)}>
               {contact.name || contact.emailAddress}
            </BrandedModalHeader>
            <ModalBody>
               <img src={planUrl} />
            </ModalBody>
         </Modal>
      </div>
   </div>;
}

function formatDuration({ start, finish }: TimelineSlot) {
   if (start.isSame(start.startOf('d')) ||
      finish.isSame(finish.startOf('d'))) {
      return ''
   }

   const hours = finish.diff(start, 'h')
   const mins = finish.diff(start, 'm') - hours * 60

   let str = ''
   if (hours === 1) {
      str += '1Hr '
   } else if (hours > 1) {
      str += `${hours}Hrs `
   }

   if (mins === 0) {
      return str
   }

   if (mins < 10) {
      str += '0'
   }

   return str + `${mins}Min`
}

function UserIcon() {
   return <svg viewBox="0 0 42 42">
      <path d="M21.53,22.21c3.95,0,7.14-3.89,7.14-8.68s-1.05-8.67-7.14-8.67-7.14,3.88-7.14,8.67S17.59,22.21,21.53,22.21Z" />
      <path d="M35,35.1c-.14-8.34-1.23-10.72-9.57-12.23a5,5,0,0,1-3.91,1.5,5,5,0,0,1-3.91-1.5c-8.25,1.49-9.41,3.83-9.56,12,0,.67,0,.7,0,.62v.88s2,4,13.49,4,13.49-4,13.49-4v-.65A4.62,4.62,0,0,1,35,35.1Z" />
   </svg>
}

function BookIcon() {
   return <svg viewBox="0 0 42 42">
      <path d="M24.57,9.22l6.91,7L14,33.77l-6.91-7ZM36.73,7.55l-3.08-3.1a3.05,3.05,0,0,0-4.33,0l-2.95,3,6.91,7,3.45-3.47A2.38,2.38,0,0,0,36.73,7.55Zm-33.29,29a.8.8,0,0,0,.95,1l7.71-1.88L5.19,28.7Z" />
   </svg>
}
