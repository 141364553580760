import bind from 'bind-decorator';
import classNames from 'classnames';
import moment from 'moment';
import React from 'react';
import { TimelineItemProps } from '../containers/TimelineItem';
import { Meeting, MeetingExtras } from '../model';
import { Except, Extend } from '../utils/misc';
import { ReactComponent as EditIcon } from './buttons/edit.svg';
import { PrivateIcon } from './icons';
import { MeetingTypeIcon } from './icons/MeetingTypes';
import { RecurrenceIcon } from './icons';
import PieButton, { PieButtonProps } from './PieButton';
import css from './TimelineItem.module.scss';
import { useSelector } from '../store/utils';

export interface Props {
   meeting: Meeting & MeetingExtras
   currentTime: number
   isExpanded: boolean
   maxDuration: number
   onExpand(): void
   innerRef?: React.Ref<HTMLDivElement>
   needsForceStart: boolean
}

interface State {
   showPeriods: boolean
}

export default class TimelineItem extends React.PureComponent<Props & TimelineItemProps, State> {
   public readonly state: State = { showPeriods: false };

   public render() {
      const { meeting, currentTime, isExpanded, maxDuration, onExpand, onEdit, onDelete, onStart, onLeave, onExtend, maxEarlyStartMinutes, innerRef, loginDisabled, adhocOrganisationName } = this.props;
      const { showPeriods } = this.state

      const start = moment(meeting.startTime);
      const finish = moment(meeting.endTime);

      const isPast = (meeting.ended || !meeting.started) && finish.isSameOrBefore(currentTime);
      const isCurrent = !isPast && start.isSameOrBefore(currentTime);
      const isStarted = meeting.started && !isPast && moment(meeting.started).isSameOrBefore(currentTime)
      const isAdhoc = meeting.isAdhoc

      const canStart = (maxEarlyStartMinutes < 0 || start.diff(currentTime, 'm', true) <= maxEarlyStartMinutes) && finish.isAfter(currentTime)

      const isPending = meeting.pending || meeting.showAs === 'Tentative'


      // console.log('meeting timelineitem', meeting)

      return <div className={classNames(css.timelineItem, isCurrent && css.current, isStarted && css.started, isPending && css.pending)}
            onClick={this.onClick} ref={innerRef} data-testid={isPending ? 'pending-timeline-item' : 'timeline-item'}>
         <div className={css.info} onClick={onExpand} tabIndex={0}>
            <div className={css.time}>
               {meeting.allDay ? 'All day' : <><span data-testid='start-time'>{start.format('LT')}</span> &ndash; {finish.format('LT')}</>}
            </div>
            <div className={css.subject}>
               {meeting.showAs === 'Tentative'
                  ? 'Awaiting approval'
                  : meeting.isPrivate ? 'Private meeting' : meeting.subject}
            </div>
            {!meeting.isAdhoc && meeting.organiser && <div className={css.organizer}>
               {meeting.organiser.name}
            </div>}
            {meeting.isAdhoc && adhocOrganisationName && <div className={css.organizer}>
               {adhocOrganisationName}
            </div>}
            <div className={css.types}>
               {meeting.isPrivate && <PrivateIcon data-testid="private-icon" />}
               {meeting.isRecurring && <RecurrenceIcon data-testid="recurrence-icon" />}
               {meeting.types.map(type => <MeetingTypeIcon key={type} type={type} data-testid={`${type.toLowerCase()}-icon`} />)}
            </div>
         </div>
         {isExpanded && !meeting.ended && !isPending && <div className={css.buttons}>
            {!meeting.started && canStart && <div tabIndex={0} onClick={() => onStart(meeting.id)}>
               <svg viewBox="0 0 3336 3352"><path d="M635 2039l271 270c154,-146 280,-289 426,-434 140,-138 183,-189 338,-313 144,-114 276,-5 31,242 -72,72 -142,137 -214,217 -64,70 -419,390 -439,429l254 257c107,-33 730,-498 864,-612l460 -430c166,-151 394,-485 506,-677 64,-108 132,-278 169,-426 23,-95 69,-457 -9,-515 -46,-35 -180,-48 -248,-47 -91,1 -173,16 -258,38 -280,75 -555,237 -778,404 -572,427 -1002,984 -1373,1597zm800 672c23,109 48,178 58,298 10,109 3,239 15,343l244 -186c78,-65 166,-140 230,-210 135,-148 312,-306 312,-598 0,-70 -16,-207 -48,-254 -224,203 -523,442 -811,607zm-1435 -870c323,4 335,2 636,71 40,-91 218,-337 283,-423 52,-69 106,-133 159,-198l164 -189c-74,-60 -340,-52 -439,-23 -214,64 -438,313 -579,468 -44,49 -201,236 -224,294zm735 779c142,-36 975,-867 1018,-1017 -102,17 -477,395 -553,470 -64,62 -457,459 -465,547z" /></svg>
               Start
            </div>}
            {meeting.started && <div tabIndex={0} onClick={() => onLeave(meeting.id)}>
               <svg viewBox="0 0 420 420"><path d="M192 104l-85 -35 156 0 0 36 32 0 0 -36c0,-19 -16,-34 -34,-34l-193 0c-18,0 -33,15 -33,34l0 247c0,20 11,27 28,33l129 53 0 -52 69 0c18,0 34,-15 34,-34l0 -36 -32 0 0 35 -71 0 0 -211zm35 72l105 0 0 -52 70 69 -70 69 0 -52 -105 0 0 -34z" /></svg>
               Leave
            </div>}
            {maxDuration > 0 && !meeting.allDay && <div className={classNames(showPeriods && css.toggled)} tabIndex={0} onClick={() => this.setState({ showPeriods: !showPeriods })}>
               <svg viewBox="0 0 335.47 364.01"><path d="M280.65,117v23.45A127.12,127.12,0,0,1,166.84,324.32c-70.11,0-127.16-57-127.16-127.15,0-65.12,49.21-118.95,112.38-126.3a20.55,20.55,0,0,1,19.88-15.45H219V38.69a166.3,166.3,0,0,0-52.19-8.37C74.85,30.32,0,105.17,0,197.17S74.85,364,166.84,364s166.84-74.85,166.84-166.84A165.88,165.88,0,0,0,313.15,117Z M85.66,145.41a15.56,15.56,0,0,0,0,21.92l74.08,74.08v0h0l0,0,65.82-21.21a15.5,15.5,0,0,0-9.51-29.51l-47.83,15.42-60.7-60.7A15.56,15.56,0,0,0,85.66,145.41Z M322.51,66.19H269.28V13A13,13,0,0,0,256.32,0H243.71a13,13,0,0,0-13,13V66.19H177.52a13,13,0,0,0-13,13V91.76a13,13,0,0,0,13,12.95h53.24V158a13,13,0,0,0,13,13h12.61a13,13,0,0,0,13-13V104.71h53.23a13,13,0,0,0,13-12.95V79.14A13,13,0,0,0,322.51,66.19Z" /></svg>
               Extend
            </div>}
            {!meeting.isExternal && !loginDisabled && !isAdhoc && <div tabIndex={0} onClick={() => onEdit(meeting.id)}>
               <EditIcon />
               Edit
            </div>}
            {!meeting.isExternal && (!loginDisabled || isAdhoc) && <div tabIndex={0} onClick={() => onDelete(meeting.id)}>
               <svg viewBox="-1 -1 50 50"><path d="M12 38c0 2.21 1.79 4 4 4h16c2.21 0 4-1.79 4-4v-24h-24v24zm26-30h-7l-2-2h-10l-2 2h-7v4h28v-4z" /></svg>
               Delete
            </div>}
            {showPeriods && <div className={css.periods}>
               <DurationButtons maxDuration={maxDuration} onClick={hr => onExtend(meeting.id, hr)} />
            </div>}
         </div>}
      </div>
   }

   public componentDidUpdate() {
      if (!this.props.isExpanded && this.state.showPeriods) {
         this.setState({ showPeriods: false })
      }
   }

   @bind
   private onClick(e: React.SyntheticEvent) {
      e.stopPropagation();
   }
}

export function DurationButtons({ maxDuration, onClick, ...props }: Extend<Except<PieButtonProps, 'value'>, { maxDuration: number, onClick(duration: number): void }>) {
   if (maxDuration === 0) {
      return <>Room currently unavailable</>
   }
   return <>{
      [ ...new Set([ 0.25, 0.5, 0.75, 1, 1.5, maxDuration ]) ].filter(x => x <= maxDuration).sort().map(dur =>
         <PieButton key={dur} value={dur} {...props} onClick={() => onClick(dur)}>
            {moment.utc(0).add(dur, 'h').format('HH:mm')}
         </PieButton>)
   }</>
}
