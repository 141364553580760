import { bind } from 'bind-decorator';

export type DragDropCallback = (x: number, y: number) => void;

export default class DragDrop {
   private isActive = false;
   private lastX: number = 0;
   private lastY: number = 0;

   constructor(private onMove: DragDropCallback, private onDone: DragDropCallback) { }

   public start() {
      if (this.isActive) { return }
      document.addEventListener('mousemove', this.onMouseMove, false);
      document.addEventListener('mouseup', this.onMouseUp, false);
      document.addEventListener('touchmove', this.onTouchMove, false);
      document.addEventListener('touchend', this.onTouchEnd, false);
      this.isActive = true;
   }

   public stop() {
      if (!this.isActive) { return }
      document.removeEventListener('mousemove', this.onMouseMove, false);
      document.removeEventListener('mouseup', this.onMouseUp, false);
      document.removeEventListener('touchmove', this.onTouchMove, false);
      document.removeEventListener('touchend', this.onTouchEnd, false);
      this.isActive = false;
   }

   @bind
   private onMouseMove(e: MouseEvent) {
      e.preventDefault();
      this.onMove(e.pageX, e.pageY);
   }

   @bind
   private onTouchMove(e: TouchEvent) {
      e.preventDefault();
      this.onMove(this.lastX = e.touches[0].pageX, this.lastY = e.touches[0].pageY);
   }

   @bind
   private onMouseUp(e: MouseEvent) {
      this.stop();
      this.onDone(e.pageX, e.pageY);
   }

   @bind
   private onTouchEnd(e: TouchEvent) {
      this.stop();
      this.onDone(this.lastX, this.lastY);
   }
}
