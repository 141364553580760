import classNames from 'classnames';
import React, { useCallback, useEffect, useState } from 'react';
import FontAwesome from 'react-fontawesome';
import { useDispatch } from 'react-redux';
import { Button, FormGroup, Input, Modal, ModalBody, ModalHeader } from 'reactstrap';
import { organisationLogin, whoAmI, Login as ApiLogin } from '../api';
import { bookitAzureAdPopup } from '../azure';
import { Domain, LoginType } from '../model';
import { actions, useSelector } from '../store/utils';
import AccentButton from './AccentButton';
import { IconInputGroup } from './IconInputGroup';
import css from './Login.module.scss';
import Logo from './Logo';
import MeetingLinkInfo from './MeetingLinkInfo';
import { useOtp } from './mobile/OtpScreen';
import PinInput from './PinInput';
import Spinner from './Spinner';
import OtpStatus from './OtpStatus'

type Mode = 'pin' | 'two-step'

interface LoginProps {
   meetingLinkId?: string
}

export default function Login({meetingLinkId}: LoginProps) {
   const disablePinLogin = meetingLinkId !== undefined
   const { loading, data } = useSelector(s => s.login)
   const [mode, setMode] = useState<Mode>(disablePinLogin ? 'two-step' : 'pin')
   const [hideError, setHideError] = useState(false)


   useEffect(() => {
      setHideError(false)
   }, [loading])

   function renderModeSwitch(m: Mode, icon: string) {
      return <button className={classNames(m === mode && css.active)} onClick={() => {
         setHideError(true)
         setMode(m)
      }}>
         <FontAwesome name={icon} />
      </button>
   }

   return <Modal isOpen className={css.login} autoFocus={false}>
      <ModalHeader>
         <Logo mono className={css.logo} />
      </ModalHeader>
      <ModalBody className={css.body}>
         {!disablePinLogin && <div className={css.modeButtons}>
            {renderModeSwitch('pin', 'key')}
            {renderModeSwitch('two-step', 'user')}
         </div>}
         {mode === 'pin' ? <PinLogin /> : <TwoStepLogin meetingLinkId={meetingLinkId}/>}
         {!hideError && data === false && <div className={css.error}>
            {mode === 'pin' ? 'Invalid pin' : 'Invalid username or password'}
         </div>}
         {loading && <div className={css.loading} />}
      </ModalBody>
   </Modal>
}

function PinLogin() {
   const dispatch = useDispatch()

   function onPin(pin: string) {
      dispatch(actions.login.request({ pin }));
   }

   return <>
      <label>Enter login PIN</label>
      <PinInput onComplete={onPin} />
   </>
}
interface TwoStepLoginProps {
   meetingLinkId?: string
}

export function TwoStepLogin({meetingLinkId}: TwoStepLoginProps) {
   const dispatch = useDispatch()
   const [domain, setDomain] = useState<Domain>()
   const [loading, setLoading] = useState(false)
   const [error, setError] = useState<string>()
   const [email, setEmail] = useState('')
   const [password, setPassword] = useState('')
   const [otpComplete, setOtpComplete] = useState(false)

   const api = useCallback(async () => {
      return (await whoAmI()) || null
   }, [])

   function onSuccess(data: ApiLogin) {
      setOtpComplete(true)
   }

   const { data, message, submitting, sendOtpRequest } = useOtp(api, onSuccess)

   function renderInput<T extends React.ReactElement<React.InputHTMLAttributes<HTMLInputElement>>>(
      icon: string, input: T,
   ) {
      return <FormGroup>
         <IconInputGroup icon={icon}>
            {input}
         </IconInputGroup>
      </FormGroup>
   }


   async function onEmail(e: React.FormEvent) {
      e.preventDefault()
      setLoading(true)
      setError(undefined)

      const domain = await organisationLogin(email)
      setLoading(false)

      if (typeof domain === 'string') {
         setError(domain)
      } else if (domain.loginType === LoginType.none) {
         setError('Login is not allowed')
      } else if (domain.loginType === LoginType.email) {
         sendOtpRequest(email, undefined, meetingLinkId)
         setDomain(domain)
      } else {
         setDomain(domain)
      }
   }

   async function onLogin(e: React.FormEvent) {
      e.preventDefault()
      if (!domain) { return }

      if (domain.loginType === LoginType.bookIt) {
         dispatch(actions.login.request({ userName: email, password }))
         return
      }

      if (domain.loginType === LoginType.azure) {
         setLoading(true)

         const resp = await bookitAzureAdPopup(domain.clientId, domain.tenantId, email)
         if (typeof resp === 'string') {
            setError(resp)
         } else if (resp !== null) {
            dispatch(actions.login.request({ token: resp.accessToken, teams: false }))
         }

         setLoading(false)
      }
   }

   function onBack() {
      setError(undefined)
      setDomain(undefined)
   }

   if (!domain) {
      return <form onSubmit={onEmail}>
         {meetingLinkId && <MeetingLinkInfo/>}
         {renderInput('envelope', <Input type='email' autoFocus required value={email} onChange={e => setEmail(e.currentTarget.value)} />)}
         <div className={css.buttons}>
            <AccentButton size='lg' disabled={loading}>
               {loading ? <Spinner className={css.spinner} /> : null}
               Next
            </AccentButton>
         </div>
         {error && <div className={css.error}>{error}</div>}
      </form>
   } else {

      const hasError = message?.error

      return <form onSubmit={onLogin}>
         <div>Logging in as <span className={css.email}>{email}</span></div>

         {domain.loginType === LoginType.bookIt &&
            renderInput('lock', <Input type='password' autoFocus required value={password} onChange={e => setPassword(e.currentTarget.value)} />)}

         {domain.loginType === LoginType.email && <OtpStatus otpStarted={message !== undefined} otpComplete={otpComplete} error={message?.error ? message.text : undefined}/>}

         {!otpComplete && <div className={css.buttons}>
            <Button size='lg' disabled={loading} type='button' onClick={onBack}>Back</Button>
            {domain.loginType !== LoginType.email && <AccentButton autoFocus size='lg' disabled={loading}>Log in</AccentButton>}
         </div>}
         {error && <div className={css.error}>{error}</div>}
      </form>
   }
}


