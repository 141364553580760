import { all, put } from 'redux-saga/effects';
import { actions } from '../store/utils';
import { takeLatest } from '../utils/apiActions';

const onLogin = takeLatest(actions.loggedIn, function* ({ time, localRooms, login }) {
   if (login.data && login.data.device?.home === 'DisplayBoard') {
      return
   }

   for (const email of localRooms) {
      yield put(actions.fetchScheduleRequest(time, email));
   }

});


export default all([
   onLogin,
]);
