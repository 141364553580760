import classNames from 'classnames';
import React, { useCallback, useLayoutEffect, useRef, useState } from 'react';
import FontAwesome from 'react-fontawesome';
import { Contact } from '../model';
import { updateRef } from '../utils/misc';
import ContactPhoto, { useIsAttendeeFree } from './ContactPhoto';
import css from './ParticipantEditor.module.scss';
import ParticipantInput from './ParticipantInput';

interface Props {
   className?: string
   contacts: Contact[]
   exclude: string[]
   onAdd: (contact: Contact) => void
   onDel: (contact: Contact) => void
   canDelete: (contact: Contact) => boolean
   placeholder: string | undefined
   disabled?: boolean
}

export default function ParticipantEditor({ className, contacts, exclude, onAdd, onDel, canDelete, placeholder, disabled }: Props) {
   const inputRef = useRef<HTMLInputElement | null>(null)
   const [value, setValue] = useState('')

   function withFocus<T extends any[]>(fn: (...args: T) => void) {
      return (...args: T) => {
         fn(...args)
         focus()
      }
   }

   const excludeList = exclude.join(',')
   useLayoutEffect(() => {
      // reset the value to reset the suggestions on exclusion change
      setValue('')
   }, [excludeList])

   const mergeRef = useCallback((input: HTMLInputElement | null, ref?: React.Ref<HTMLInputElement>) => {
      inputRef.current = input
      updateRef(ref, input)
   }, [])

   function onKeyDown(e: React.KeyboardEvent<HTMLInputElement>) {
      if ((e.nativeEvent.code || e.key) === 'Backspace' && e.currentTarget.value === '' && contacts.length) {
         e.preventDefault()
         const contact = contacts[contacts.length - 1]
         if (canDelete(contact)) {
            setValue(contact.name)
            onDel(contact)
         }
      }
   }

   function focus() {
      inputRef.current?.focus()
   }

   return <div className={classNames(css.participantEditor, className)} onClick={focus}>
      {contacts.map(contact => <Attendee key={contact.emailAddress} contact={contact} canDelete={canDelete} onDelete={c => withFocus(onDel)(c)} />)}

      <ParticipantInput className={css.input} exclude={exclude} onAddParticipant={withFocus(onAdd)} value={value} onChange={setValue}>
         {({ ref, onKeyDown: kd, ...props }) =>
            disabled ? null : <input ref={input => mergeRef(input, ref)} {...props} placeholder={placeholder} onKeyDown={mergeHandlers(onKeyDown, kd)} />}
      </ParticipantInput>
   </div>
}

function Attendee({ contact, canDelete, onDelete }: { contact: Contact, canDelete: (contact: Contact) => boolean, onDelete: (contact: Contact) => void}) {
   const isFree = useIsAttendeeFree(contact.emailAddress)
   return <div className={css.item}>
      <ContactPhoto contact={contact} className={classNames(css.photo, {
      [css.free]: isFree === true,
      [css.busy]: isFree === false,
   })} />
      <div className={css.name} title={contact.name}>{contact.name}</div>
      {canDelete(contact) && <button className={css.remove} onClick={() => onDelete(contact)}>
         <FontAwesome name='times' />
      </button>}
   </div>
}

function mergeHandlers<T extends any[]>(...fns: Array<((...args: T) => void) | undefined>) {
   return (...args: T) => {
      for (const fn of fns) {
         if (fn) {
            fn(...args)
         }
      }
   }
}
