import { Location } from 'history';
import { useCallback, useMemo } from 'react';
import { useHistory, useLocation } from 'react-router-dom';

let LastId = 0
type State = undefined | Record<string, true | undefined>

export function usePageNav() {
   const history = useHistory<State>()
   const id = useMemo(() => (LastId++).toString(), [])

   const location = useLocation<State>()
   const isOpen = getOpen(location, id)

   const open = useCallback(() => {
      if (!getOpen(history.location, id)) {
         history.push({ state: { ...(history.location.state || {}), [id]: true } })
      }
   }, [history, id])

   const close = useCallback(() => {
      if (getOpen(history.location, id)) {
         history.goBack()
      }
   }, [history, id])

   return { isOpen, open, close }
}

function getOpen(loc: Location<State>, id: string) {
   return Boolean(loc.state?.[id])
}
