import React from 'react';
import css from './Toast.module.scss';
import { useSelector, actions } from '../store/utils';
import { ApiError } from '../api';
import useInterval from '../utils/useInterval';
import { useDispatch } from 'react-redux';
import { ReactComponent as CloseIcon } from './buttons/close.svg'

interface Props {
   id: string
   error: ApiError | string
}

export default function Toast({ id, error }: Props) {
   const dispatch = useDispatch()

   useInterval(close, 10000)

   function close() {
      dispatch(actions.closeErrorToast(id))
   }

   return <div className={css.Toast} onClick={close}>
      <button>
         <CloseIcon />
      </button>
      <div className={css.title}>{typeof error === 'string' ? error : error.title}</div>
      <div className={css.detail}>{typeof error === 'string' ? '' : error.detail}</div>
   </div>
}

export function ToastHost() {
   const toasts = useSelector(s => s.toasts)

   return <div className={css.ToastHost}>
      {Object.entries(toasts).map(([ id, error ]) => <Toast key={id} id={id} error={error} />)}
   </div>
}
