import classNames from 'classnames';
import moment from 'moment';
import React from 'react';
import { Meeting } from '../../model';
import { useSelector } from '../../store/utils';
import ContactPhoto from '../ContactPhoto';
import css from './RoomLine.module.scss';
import { RoomData } from './selectors';

interface Props {
   room: RoomData
}

export default function RoomLine({ room }: Props) {
   const time = useSelector(s => s.time)

   return <div className={classNames(css.RoomLine, room.status === null ? css.loading : room.status.type === 'busy' && css.busy)}>
      <div className={css.roomName}>{room.name}</div>
      {room.status && <div className={css.till}>
         {room.status.type === 'free'
            ? formatAvailability(room.status.till, time)
            : `Booked till ${moment(room.status.till).format('LT')}`}
      </div>}
      <div className={css.location} dangerouslySetInnerHTML={room.icon ? { __html: room.icon } : undefined} />
      {room.status?.type === 'busy' && renderMeeting(room.status.meeting)}
   </div>
}

function renderMeeting({ organiser, subject, isPrivate }: Meeting) {
   return <>
      <div className={css.subject}>{subject}</div>
      {organiser && <div className={css.organiser}>{organiser.name || organiser.emailAddress}</div>}
      {organiser && <ContactPhoto className={css.photo} contact={organiser} />}
      <div className={css.types}>
         {isPrivate && <svg viewBox='0 0 1792 1792'><path d='M640 768h512v-192q0-106-75-181t-181-75-181 75-75 181v192zm832 96v576q0 40-28 68t-68 28h-960q-40 0-68-28t-28-68v-576q0-40 28-68t68-28h32v-192q0-184 132-316t316-132 316 132 132 316v192h32q40 0 68 28t28 68z'/></svg>}
      </div>
   </>
}

function formatAvailability(till: number | null, now: number) {
   if (till === null) { return 'Available whole day' }

   const dur = Math.ceil((till - now) / 1000 / 60)
   const hr = Math.floor(dur / 60)
   const min = dur - hr * 60

   return `Available ${hr > 0 ? hr === 1 ? '1hr ' : `${hr}hrs ` : ''}${min > 0 ? `${min}min` : ''}`
}
