import React, { useDebugValue, useMemo } from 'react';
import { useDispatch } from 'react-redux';
import { getRoomTypeName, MeetingType, RoomType, AllRoomTypes } from '../../model';
import { getMeetingTypes, selectDefaultResourceFactory, selectRooms } from '../../store/helpers';
import { actions, useSelector } from '../../store/utils';
import { getMeetingTypeTitle, MeetingTypeIcon } from '../icons/MeetingTypes';
import { ResourceTypeIcon } from '../ResourceIcon';
import css from './MeetingTypeSelector.module.scss';
import { SingleSelect } from './Selectors';
import ToggleSelector, { UncontrolledToggleButton } from './ToggleSelector';

type MeetingTypes = MeetingType | 'Normal'

export function MeetingTypeToggleSelector() {
   const [type, setType] = useMeetingType()
   const allMeetingTypes = useAllMeetingTypes()

   return <ToggleSelector className={css.meetingTypeSelector} value={type} onChange={setType}>
      {allMeetingTypes.map(type => <ToggleSelector.Button key={type} value={type} className={css.meetingTypeButton}>
         {renderMeetingType(type)}
      </ToggleSelector.Button>)}
   </ToggleSelector>
}

interface Props {
   disableConferenceType: boolean
   disableResources: boolean
}

export function MeetingAndResourceSelector({ disableConferenceType, disableResources }: Props) {
   const dispatch = useDispatch()
   const [type, setType] = useMeetingType()
   const allMeetingTypes = useAllMeetingTypes()
   const resources = useSelector(s => s.booking ? s.booking.resources : [])
   const allRooms = useSelector(selectRooms)
   const factory = useSelector(selectDefaultResourceFactory)

   function toggle(type: RoomType) {
      let found = false

      for (const res of resources) {
         if (res.type !== type) { continue }
         found = true


         dispatch(actions.editResource(res, res))
         dispatch(actions.removeResource())
      }

      if (!found) {
         const filter = factory()
         if (filter) {
            dispatch(actions.editResource(null, {
               ...filter,
               type,
            }))
            dispatch(actions.commitResource())
         }
      }
   }

   function setMeetingType(newType: MeetingTypes) {
      setType(newType)
   }

   return <ToggleSelector className={css.meetingTypeSelector} value={type} onChange={setMeetingType}>
      {allMeetingTypes.map(type => <ToggleSelector.Button key={type} value={type} className={css.meetingTypeButton} disabled={disableConferenceType}>
         {renderMeetingType(type)}
      </ToggleSelector.Button>)}

      <div className={css.spacer} />

      {!disableResources && AllRoomTypes.filter(t => allRooms.some(r => r.roomType === t)).map(type => <UncontrolledToggleButton key={type} active={resources.some(r => r.type === type)}
         className={css.roomTypeButton} onClick={() => toggle(type)}>
         <ResourceTypeIcon className={css.meetingTypeIcon} type={type} />
         {getRoomTypeName(type)}
      </UncontrolledToggleButton>)}
   </ToggleSelector>
}

export function MeetingTypeSingleSelector() {
   const [type, setType] = useMeetingType()
   const allMeetingTypes = useAllMeetingTypes()

   return <SingleSelect label='Meeting Type' value={type} onChange={setType} items={allMeetingTypes}
      renderItem={type => <div className={css.meetingType}>{renderMeetingType(type)}</div>} />
}

function useMeetingType() {
   const dispatch = useDispatch()
   const booking = useSelector(s => s.booking)

   const value = booking?.types[0] || 'Normal'

   function setMeetingType(type: MeetingTypes) {
      dispatch(actions.setMeetingTypes(type === 'Normal' ? [] : [type]))
   }

   return [value, setMeetingType] as const
}

function useAllMeetingTypes() {
   const meetingTypes = useSelector(getMeetingTypes)
   const allMeetingTypes: MeetingTypes[] = useMemo(() => meetingTypes ? ['Normal', ...meetingTypes] : [], [meetingTypes])
   return allMeetingTypes
}

function renderMeetingType(type: MeetingTypes) {
   return <>
      {type === 'Normal'
         ? <svg className={css.meetingTypeIcon} viewBox='0 0 24 24'>
            <path d='M20 3h-1V1h-2v2H7V1H5v2H4c-1.1 0-2 .9-2 2v16c0 1.1.9 2 2 2h16c1.1 0 2-.9 2-2V5c0-1.1-.9-2-2-2zm0 18H4V10h16v11zm0-13H4V5h16v3z' />
         </svg>
         : <MeetingTypeIcon className={css.meetingTypeIcon} type={type} />}
      {type === 'Normal' ? 'Normal' : getMeetingTypeTitle(type)}
   </>
}

