import React, { useEffect } from "react"
import { useDispatch } from "react-redux"
import { actions, useSelector } from '../store/utils';
import { getSelectedDateTime, getSharedOrganisations, isLoginDisabled } from '../store/helpers';
import ToggleSelector from './mobile/ToggleSelector';
import FormField from './FormField';
import { ClockIcon, AttendeesIcon } from './icons';
import moment from "moment";
import css from './AdhocPanel.module.scss';
import { Contact } from "../model";

export default function AdhocPanel({ durations, room }: { durations: number[], room: Contact | undefined }) {
   const dispatch = useDispatch()
   const booking = useSelector(s => s.booking)
   const bookingTime = useSelector(getSelectedDateTime)
   const sharedOrganisations = useSelector(getSharedOrganisations)
   const loginDisabled = useSelector(isLoginDisabled)

   useEffect(() => {
      if (sharedOrganisations.length > 0) {
         dispatch(actions.setAdhocOrganisation(sharedOrganisations[0]))
      }
   }, [dispatch, sharedOrganisations])

   if (!booking || bookingTime === null) { return null }

   function onFull() {
      dispatch(actions.openPopup('secondaryLogin'))
   }

   console.log("Durationns", durations)

   if (!room) {
      return <div>
        No rooms are available. 
        {!loginDisabled && <span><button className={css.login} onClick={() => onFull()}>Log in</button> for more options.</span>}
      </div>
   }

   return <>
      {sharedOrganisations.length > 1 && <FormField icon={<AttendeesIcon />}>
         <ToggleSelector value={booking.adhocOrganisation} onChange={so => { if (so) { dispatch(actions.setAdhocOrganisation(so)) }}}>
            {sharedOrganisations
               .map(so => <ToggleSelector.Button key={so.organisationId} value={so} className={css.organisationButton}>
               <img className={css.organisationIcon} src={so.iconUrl ?? undefined} />
               <span className={css.name}>{so.name}</span>
            </ToggleSelector.Button>)}
         </ToggleSelector>
      </FormField>}

      <FormField icon={<ClockIcon />}>
         <div className={css.timeHeader}>Start:</div>
         <ToggleSelector value={false} onChange={v => v && onFull()}>
            <ToggleSelector.Button value={false} className={css.timeButton}>
               <div>{moment(bookingTime).format('LT')}</div>
               <div>Now</div>
            </ToggleSelector.Button>
            {!loginDisabled && <ToggleSelector.Button value={true} className={css.timeButton}>
               <div>&nbsp;</div>
               <div>Future</div>
            </ToggleSelector.Button>}
         </ToggleSelector>
      </FormField>

      <FormField>
         <div className={css.timeHeader}>End:</div>
         <ToggleSelector value={booking.duration} onChange={hr => dispatch(actions.setBookingDuration(hr))}>
            {durations.map(hr => <ToggleSelector.Button key={hr} value={hr} className={css.timeButton}>
               <div>{hr < 1 ? `${(hr * 60).toFixed()}min` : moment.utc(0).add(hr, 'h').format('H:mm')}</div>
               <div>{moment(bookingTime).add(hr, 'h').format('LT')}</div>
            </ToggleSelector.Button>)}
         </ToggleSelector>
      </FormField>

      {!loginDisabled && <div className={css.moreOptions}>
         <button className={css.login} onClick={() => onFull()}>Log in</button> for more options
      </div>}
   </>
}

