import React from 'react';
import * as teams from '../teams';

export default class TeamsConfig extends React.PureComponent {
   public async componentDidMount() {
      await teams.initTeams();
      await teams.setConfigValid();
   }

   public render() {
      return 'Book-it is ready to go! Please click "Save" button below.';
   }
}
