import FontAwesome from 'react-fontawesome'
import { useDispatch } from 'react-redux'
import { getRoomTypeName } from '../../model'
import { selectDefaultResourceFactory, selectNearbyFilterRoom, selectSuggested } from '../../store/helpers'
import { actions, useSelector } from '../../store/utils'
import { ResourceTypeIcon } from '../ResourceIcon'
import FormField from './FormField'
import { Header } from './Header'
import { usePageNav } from './navigation'
import css from './NearbyFilterEditor.module.scss'
import SideSheet from './SideSheet'

export default function NearbyFilterEditor() {
   const dispatch = useDispatch()
   const { isOpen, open, close } = usePageNav()
   const nearby = useSelector(s => s.booking?.nearby)
   const room = useSelector(selectNearbyFilterRoom)
   const factory = useSelector(selectDefaultResourceFactory)
   const booking = useSelector(s => s.booking)
   const suggested = useSelector(selectSuggested)

   if (!nearby) { return null }

   const name = room ? room.name : nearby.email

   function onDelete() {
      dispatch(actions.removeNearby(factory()))
      close()
   }

   if (!booking) { return null }

   return <>
      <FormField icon={<ResourceTypeIcon type={nearby.roomType} />}>
         <button className={css.nearbyFilterEditor} onClick={open}>
            {getRoomTypeName(nearby.roomType)} near {name}
            {suggested === null ? <div className={css.loading}>Loading...</div>
            : <ul>
               {suggested[0]?.map(room => <li key={room.emailAddress}>
                  {room.name || room.emailAddress} {room.roomType === 'MeetingRoom' && `(${room.seats} seat${room.seats === 1 ? '' : 's'})`}
               </li>)}
            </ul>}
            <div>
               {booking.suggestionWarning && <div className={css.suggestionWarning}>
                  <FontAwesome name='exclamation-triangle' /> {booking.suggestionWarning}
               </div>}
            </div>
         </button>
      </FormField>

      <SideSheet open={isOpen}>
         <Header back>Nearby search</Header>

         <FormField>
            <div className={css.summary}>
               Searching for a {getRoomTypeName(nearby.roomType)} near {name}
            </div>
         </FormField>

         <button className={css.delete} onClick={onDelete}>
            Remove this filter
         </button>
      </SideSheet>
   </>
}
