import { getType } from 'typesafe-actions';
import { Contact, isPerson, isRoom } from '../../model';
import { toObject } from '../../utils/misc';
import { actions, Actions } from '../utils';

export interface Contacts {
   [email: string]: undefined | Contact;
}

function filterContacts(state: Contacts, condition: (contact: Contact) => boolean): Contacts {
   const result: Contacts = {};
   for (const email in state) {
      if (!state.hasOwnProperty(email)) { continue }

      const contact = state[email]!;
      if (condition(contact)) {
         result[email] = contact;
      }
   }
   return result;
}

export default function contacts(state: Contacts = {}, action: Actions): Contacts {
   switch (action.type) {
      case getType(actions.updateContact): {
         const contact = action.payload;
         return { ...state, [contact.emailAddress]: contact };
      }

      case getType(actions.whoAmI.response):
      case getType(actions.login.response): {
         if (!action.payload) { return state };

         state = { ...state }
         const { secondary } = action.payload

         // take rooms from secondary if we have one
         for (const room of secondary ? secondary.organisation.rooms : action.payload.organisation?.rooms ?? []) {
            state[room.emailAddress] = room
         }

         const contact = action.payload.myself;
         state[contact.emailAddress] = contact;

         if (secondary) {
            state[secondary.contact.emailAddress] = secondary.contact
         }

         return state
      }

      case getType(actions.fetchContacts.response): {
         state = { ...state };
         for (const c of action.payload) {
            if (!state[c.emailAddress]) {
               state[c.emailAddress] = c;
            }
         }
         return state;
      }

      case getType(actions.cleanupPersons):
         return filterContacts(state, c => isRoom(c) || action.payload.includes(c.emailAddress));

      case getType(actions.restoreLogin): 
      case getType(actions.secondaryLogin.response): {
         const contact = action.payload.contact;
         return {
            ...toObject(Object.values(state).filter(isPerson), x => x.emailAddress, x => x),
            ...toObject(action.payload.organisation.rooms, x => x.emailAddress, x => x),
            [contact.emailAddress]: contact,
         }
      }
   }
   return state;
}
