import { goBack } from 'connected-react-router';
import { all, call, delay, put } from 'redux-saga/effects';
import * as Api from '../api';
import bridge from '../javascriptBridge';
import { AttendeeType, Page } from '../model';
import { isMobile } from '../store/helpers';
import { actions } from '../store/utils';
import { isTeamsUserValid } from '../teams';
import { takeEvery } from '../utils/apiActions';
import { backoff, PromiseReturnType } from '../utils/misc';
import { goHome } from './utils';

export type ApiResult<T extends (...args: any[]) => any> = ReturnType<T> extends Promise<infer R> ? R : never;

const extendMeeting = takeEvery(actions.extendMeeting, function* (state, { id, hr }) {
   try {
      yield put(actions.openPopupWithParams({ type: 'progress', message: 'Extending the meeting...' }, true))
      const meeting: ApiResult<typeof Api.extendMeeting> = yield call(Api.extendMeeting, id, hr);
      yield put(actions.updateMeetingData(meeting));
      yield put(actions.closePopup())
      yield put(actions.setSplash('Extension succeeded'))
   } catch (e) {
      yield put(actions.setPopupError(Api.getErrorMessage(e)))
   }
})

const cancelMeeting = takeEvery(actions.cancelMeeting, function* (state, { id, data }) {
   try {
      yield put(actions.setPopupSubmitting());
      yield call(Api.cancelMeeting, id, data);
      yield put(actions.markMeetingPending(id));
      yield put(actions.closePopup());

      if (state.booking?.meetingId === id) {
         yield put(actions.endBooking());

         if (isMobile(state)) {
            yield put(goBack())
         } else {
            yield goHome()
         }
      }
   } catch (e) {
      yield put(actions.setPopupError(Api.getErrorMessage(e)));
   }
})


const changeMeetingResponseStatus = takeEvery(actions.changeMeetingResponseStatus, function* (state, { id, comment, sendResponse, responseStatus }) {
   try {
      yield call(Api.changeMeetingResponseStatus, id, comment, sendResponse, responseStatus);
   } catch (e) {
      console.log("Failed to change meeting response status", e)
   }
})

const startMeeting = takeEvery(actions.startMeeting, function* (state, { id, email }) {
   try {
      yield put(actions.openPopupWithParams({ type: 'progress', message: 'Starting the meeting...' }, true))
      yield call(Api.startMeeting, id)
      yield put(actions.closePopup())
      yield put(actions.meetingStarted(id, email))
   } catch (e) {
      yield put(actions.setPopupError(Api.getErrorMessage(e)))
   }
})

const leaveMeeting = takeEvery(actions.leaveMeeting, function* (state, { id, email }) {
   try {
      yield put(actions.openPopupWithParams({ type: 'progress', message: 'Leaving the meeting...' }, true))
      yield call(Api.leaveMeeting, id)
      yield put(actions.closePopup())
      yield put(actions.meetingLeft(id, email))
   } catch (e) {
      yield put(actions.setPopupError(Api.getErrorMessage(e)))
   }
})

const onWhoAmI = takeEvery(actions.whoAmI, function* (state) {
   const { login } = state

   if (login.loading || login.data) { return }

   for (let retry = 0; ; retry++) {
      try {
      
         let result: ApiResult<typeof Api.whoAmI> = yield call(Api.whoAmI)

         console.log('cookie', document.cookie);

         if (result === undefined && haveCookie()) {
            yield put(actions.reconnecting())
            yield delay(backoff(retry))
            continue;
         }

         if (result?.teams) {
            const domain =  result.organisation ? result.organisation.domains[result.domain] : undefined
            if (!domain || !(yield call(isTeamsUserValid, domain, result.myself.emailAddress))) {
               result = undefined
            }
         }

         if (result && state.teams.teamsMode && !result.teams) {
            result = undefined
         }      

         yield put(actions.reconnecting(false))
         yield put(actions.whoAmI.response(result))         

         break
      } catch (e) {
         console.error(e)
         yield put(actions.reconnecting())
         yield delay(backoff(retry))
      }
   }
})

function haveCookie() : boolean {
   if (!document.cookie) {
      return false;
   } else {
      return true;
   }
}

const secondaryLogin = takeEvery(actions.secondaryLogin.request, function* (state, payload) {
   yield put(actions.setPopupSubmitting())
   const result: ApiResult<typeof Api.secondaryLogin> = yield call(Api.secondaryLogin, payload)
   if (typeof result === 'string') {
      yield put(actions.setPopupError(result))
   } else {
      yield put(actions.secondaryLogin.response(result))
      if (state.popup.type === 'cancelMeeting') { return }

      switch (state.popup.type) {
         case 'myCalendar':
            yield put(actions.goToPage(Page.calendar))
            break;

         case 'newBooking':
         case 'editMeeting':
            break;

         default:
            yield put(actions.setSplash(`Welcome,\n${result.contact.name}`))
      }

      if (result && state.booking && !state.booking.meetingId) {
         yield put(actions.addParticipant(result.contact.emailAddress, AttendeeType.required))
      }
   }
})

const secondaryLogout = takeEvery(actions.secondaryLogout, function* (state) {
   if (!state.login.data) return
   if (!state.login.data.organisation) return

   const { contact, organisation } = state.login.data
   yield put(actions.restoreLogin(contact, organisation))

   if (state.popup.type === 'cancelMeeting' || state.popup.type === 'editMeeting') {
      yield call(Api.secondaryLogout)
      return
   }

   yield put(actions.closePopup())
   yield goHome()
   yield call(Api.secondaryLogout)
   yield put(actions.endBooking())
})

const login = takeEvery(actions.login.request, function* (state, payload) {
   const result = yield call(Api.login, payload)
   if (result && 'pin' in payload && bridge?.pinLogin) {
      bridge.pinLogin(payload.pin)
   }
   yield put(actions.login.response(result))
})

const delegatedLogin = takeEvery(actions.delegatedLogin, function* (state, payload) {
   const result = yield call(Api.delegatedLogin, payload)
   yield put(actions.login.response(result))
})

const logout = takeEvery(actions.logout, function* () {
   yield call(Api.logout)
   window.location.reload()
})

const addResourcesToMeeting = takeEvery(actions.addResourcesToMeeting, function* (state) {
   const booking = state.booking
   if (!booking || !booking.meetingId) { return }

   try {
      yield put(actions.setPopupSubmitting())
      const meeting: PromiseReturnType<ReturnType<typeof Api.addResourcesToMeeting>> = yield call(Api.addResourcesToMeeting, booking)
      yield put(actions.updateMeetingData(meeting))

      if (isMobile(state)) {
         yield put(actions.endBooking())
         yield put(goBack())
      } else {
         yield put(actions.closePopup())
         yield put(actions.setSplash('Booking succeeded'))
      }
   } catch (e) {
      yield put(actions.setPopupError(Api.getErrorMessage(e)));
   }
})

export default all([
   onWhoAmI,
   login,
   delegatedLogin,
   cancelMeeting,
   extendMeeting,
   secondaryLogin,
   secondaryLogout,
   logout,
   startMeeting,
   leaveMeeting,
   changeMeetingResponseStatus,
   addResourcesToMeeting,
])
