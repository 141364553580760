import moment from 'moment';

export function timeOfDayInHours(time: moment.MomentInput) {
   return moment(time).diff(getDayStart(time), 'h', true);
}

export function getDayStart(date: moment.MomentInput) {
   return moment(date).startOf('d').valueOf();
}

export class TimeRounding {
   public static readonly mobile = 15 / 60;
   public static readonly desktop = 15 / 60;
}

export function roundStartTime(hr: number, method: 'floor' | 'round' | 'ceil' = 'ceil', roundToHr = TimeRounding.desktop) {
   // 6:00:59 -> 6:00:00
   // 6:01:00 -> 6:15:00
   hr = Math.trunc(hr * 60) / 60
   const res = Math[method](hr / roundToHr) * roundToHr;
   return isNaN(res) ? 0 : res;
}

const methodMap: Record<moment.unitOfTime.Base, 'year' | 'month' | 'week' | 'day' | 'hour' | 'minute' | 'second' | 'millisecond'> = {
   d: 'day',
   day: 'day',
   days: 'day',
   h: 'hour',
   hour: 'hour',
   hours: 'hour',
   m: 'minute',
   M: 'month',
   millisecond: 'millisecond',
   milliseconds: 'millisecond',
   minute: 'minute',
   minutes: 'minute',
   month: 'month',
   months: 'month',
   ms: 'millisecond',
   s: 'second',
   second: 'second',
   seconds: 'second',
   w: 'week',
   week: 'week',
   weeks: 'week',
   y: 'year',
   year: 'year',
   years: 'year',
}

export function roundDate(date: moment.MomentInput, unit: moment.unitOfTime.Base, method: 'trunc' | 'ceil' | 'floor' | 'round', value = 1) {
   const start = moment(date).startOf(unit)
   const diff = start[methodMap[unit]]() - start.diff(date, unit, true)
   const rounded = Math[method](diff / value) * value
   return start[methodMap[unit]](rounded).valueOf()
}

// round to the nearest second
export function truncHr(hr: number) {
   const ms = 60 * 60;
   return Math.round(hr * ms) / ms;
}

