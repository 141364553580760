import moment from 'moment';
import { useDispatch } from 'react-redux';
import { DropdownItem, DropdownMenu, DropdownToggle, UncontrolledDropdown } from 'reactstrap';
import { AllRecurrenceDaysOfWeek, Recurrence, RecurrenceDayOfWeek } from '../model';
import { actions, useSelector } from '../store/utils';
import { keysOf } from '../utils/misc';
import DatePicker from './DatePicker';
import css from './RecurrenceEditor.module.scss';

export enum RecurrencePattern {
   none = 'Do not repeat',
   weekdays = 'Every weekday (Mon – Fri)',
   daily = 'Daily',
   weekly = 'Weekly',
   monthly = 'Monthly',
   custom = 'Custom',
}

const Weekdays: RecurrenceDayOfWeek[] = AllRecurrenceDaysOfWeek.slice(1, 6)

interface RecurrenceEditorProps {
   disabled?: boolean
}

export default function RecurrenceEditor({disabled}: RecurrenceEditorProps) {
   const { pattern, patterns, endDate, setEndDate, setPattern } = useRecurrence()
   const editing = useSelector(s => Boolean(s.booking?.meetingId))
   const isRecurring = useSelector(s => Boolean(s.booking?.isRecurring))

   if (editing && isRecurring) {
      return <div className={css.recurrenceEditor}>
         Recurring instance
      </div>
   }

   return <div className={css.recurrenceEditor}>
      <UncontrolledDropdown>
         <DropdownToggle className={css.button} caret tag='button' disabled={disabled}>{pattern}</DropdownToggle>
         <DropdownMenu disabled={disabled}>
            {patterns.map(p => <DropdownItem key={p} onClick={() => setPattern(p)}>{p}</DropdownItem>)}
         </DropdownMenu>
      </UncontrolledDropdown>

      {pattern !== RecurrencePattern.none && <>
         <div className={css.until}>Until</div>
         <DatePicker disabled={disabled} allowEmpty value={endDate} onChange={setEndDate} emptyStr='forever' />
      </>}
   </div>
}

export function useRecurrence() {
   const dispatch = useDispatch()
   const booking = useSelector(s => s.booking)
   const pattern = getRecurrencePattern(booking?.recurrence ?? null)

   const recurrence = booking?.recurrence
   const endDate = recurrence?.range.type === 'endDate' ? moment(recurrence.range.endDate).valueOf() : null

   return {
      pattern,
      endDate,

      patterns: keysOf(RecurrencePattern)
         .map(k => RecurrencePattern[k])
         .filter(p => p !== RecurrencePattern.custom || p === pattern),

      setPattern(pattern: RecurrencePattern) {
         const rec = getRecurrence(pattern)
         dispatch(actions.setRecurrence(rec && updateEndDate(rec, endDate)))
      },

      setEndDate(endDate: number | null) {
         if (!recurrence) { return }
         dispatch(actions.setRecurrence(updateEndDate(recurrence, endDate)))
      }
   }
}

function updateEndDate(rec: Recurrence, endDate: number | null): Recurrence {
   if (endDate !== null) {
      return {
         ...rec,
         range: {
            ...rec.range,
            type: 'endDate',
            endDate: new Date(endDate).toISOString(),
         }
      }
   } else {
      return {
         ...rec,
         range: {
            startDate: rec.range.startDate,
            type: 'noEnd',
         }
      }
   }
}

function getRecurrencePattern(recurrence: Recurrence | null): RecurrencePattern {
   if (recurrence === null) {
      return RecurrencePattern.none
   }

   const { pattern } = recurrence

   if (pattern.type === 'daily' && pattern.interval === 1) {
      return RecurrencePattern.daily
   }

   if (pattern.type === 'weekly' && pattern.interval === 1) {
      if (pattern.daysOfWeek.length === Weekdays.length && pattern.daysOfWeek.every(d => Weekdays.includes(d))) {
         return RecurrencePattern.weekdays
      } else if (pattern.daysOfWeek.length === 1) {
         return RecurrencePattern.weekly
      }
   }

   if (pattern.type === 'absoluteMonthly' && pattern.interval === 1) {
      return RecurrencePattern.monthly
   }

   return RecurrencePattern.custom
}

function getRecurrence(pattern: RecurrencePattern): Recurrence | null {
   if (pattern === RecurrencePattern.weekdays) {
      return rec({
         type: 'weekly',
         interval: 1,
         daysOfWeek: Weekdays,
      })
   }

   if (pattern === RecurrencePattern.daily) {
      return rec({
         type: 'daily',
         interval: 1,
      })
   }

   if (pattern === RecurrencePattern.weekly) {
      return rec({
         type: 'weekly',
         interval: 1,
         daysOfWeek: ['monday'], // will be replaced in selectRecurrence
      })
   }

   if (pattern === RecurrencePattern.monthly) {
      return rec({
         type: 'absoluteMonthly',
         interval: 1,
         dayOfMonth: 0,
      })
   }

   return null

   function rec(pattern: Recurrence['pattern']): Recurrence {
      return {
         pattern,
         range: {
            type: 'noEnd',
            startDate: '',  // will be replaced in selectRecurrence
         }
      }
   }
}
