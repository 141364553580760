import { useCallback, useEffect, useLayoutEffect, useRef, useState } from 'react'
import { useSelector } from 'react-redux'
import Pager from './Pager'
import RoomLine from './RoomLine'
import { selectRoomsAndMeetings } from './selectors'

interface Props {
   className?: string
}

export default function RoomList({ className }: Props) {
   const data = useSelector(selectRoomsAndMeetings)

   const resizePage = useCallback(() => {
      const roomDiv = roomsRef.current
      if (!roomDiv) { return }
      if (roomDiv.childElementCount < 2) { return }

      const height = roomDiv.getBoundingClientRect().height
      const roomHeight = roomDiv.children[1].getBoundingClientRect().top - roomDiv.children[0].getBoundingClientRect().top

      const size = Math.max(1, Math.floor(height / roomHeight))
      const count = Math.ceil(data.length / size)

      setPages(pages => size !== pages.size || count !== pages.count ? { size, count } : pages)
   }, [ data ])

   useEffect(() => {
      window.addEventListener('resize', resizePage)
      return () => window.removeEventListener('resize', resizePage)
   }, [ resizePage ])

   // resize whenever the list of rooms changes
   useLayoutEffect(() => {
      resizePage()
   }, [ resizePage ])

   const roomsRef = useRef<HTMLDivElement | null>(null)
   const [ pages, setPages ] = useState({ size: 999, count: 1 })

   const measurer = <>
      <RoomLine room={{ email: 'xx', icon: null, name: 'xx', status: { type: 'free', till: null } }} />
      <RoomLine room={{ email: 'xx', icon: null, name: 'xx', status: { type: 'free', till: null } }} />
   </>

   return <Pager className={className} pages={pages.count} delayMs={5000} measurer={{ ref: roomsRef, children: measurer }}>
      {page => getPage(data, page, pages.size).map(room => <RoomLine key={room.email} room={room} />)}
   </Pager>
}

function getPage<T>(data: T[], page: number, pageSize: number) {
   return data.filter((_, i) => i >= pageSize * page && i < pageSize * (page + 1))
}
