import React from 'react';
import FontAwesome from 'react-fontawesome';
import { InputGroup, InputGroupAddon, InputGroupText } from 'reactstrap';

interface IconInputGroupProps {
   icon: string;
   children?: React.ReactNode;
}

export function IconInputGroup({ icon, children }: IconInputGroupProps) {
   return <InputGroup>
      <InputGroupAddon addonType='prepend'>
         <InputGroupText>
            <FontAwesome name={icon} />
         </InputGroupText>
      </InputGroupAddon>
      {children}
   </InputGroup>;
}
