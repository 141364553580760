import { Page } from '../model';
import { isMobile } from '../store/helpers';
import { actions, StoreState } from '../store/utils';
import { takeEvery } from '../utils/apiActions';
import { goTo } from './utils';

const onGoToPage = takeEvery(actions.goToPage, function* (state, page) {
   yield goToPage(page.page, state, page.usePush)
})

export function* goToPage(page: Page, state: StoreState, usePush?: boolean) {
   if (usePush === undefined) { usePush = isMobile(state) }
   yield goTo(getPageUrl(page, isMobile(state)), state, usePush)
}

export function getPageUrl(page: Page, mobile: boolean): string {
   switch (page) {
      case Page.timeline:
         return '/'
      case Page.bookingFilter:
         return mobile ? '/book/meeting' : '/book/filter'
      case Page.calendar:
         return mobile ? '/mobile/calendar' : '/calendar'
      case Page.roomList:
         return '/rooms'
      case Page.searchMore:
         return '/book/calendar'
      default:
         throw new Error('Unkown page: ' + Page[page])
   }
}

export default onGoToPage
