import { useEffect } from 'react'
import FontAwesome from 'react-fontawesome'
import { useDispatch } from 'react-redux'
import { matchPath, Prompt, useParams } from 'react-router'
import { Room } from '../../model'
import { selectRooms } from '../../store/helpers'
import { actions, useSelector } from '../../store/utils'
import { isDefined } from '../../utils/misc'
import { ResourceIcon } from '../ResourceIcon'
import css from './AddResources.module.scss'
import FormField from './FormField'
import { Header } from './Header'
import LoadMeeting from './LoadMeeting'
import Page from './Page'
import ResourceFilterEditor from './ResourceFilterEditor'

export default function AddResources() {
   const dispatch = useDispatch()
   const params = useParams<{ id: string }>()
   const meetingId = decodeURIComponent(params.id)
   const meeting = useSelector(s => s.meetings[meetingId])
   const rooms = useSelector(selectRooms)
   const suggestions = useSelector(s => s.suggestions.final.suggestions)
   const hasResources = useSelector(s => s.booking && s.booking.resources.length > 0 || false)
   const popupOpen = useSelector(s => s.popup.type !== undefined)
   const hasBooking = useSelector(s => Boolean(s.booking))

   function updateMeeting() {
      dispatch(actions.openPopupWithParams({ type: 'progress', message: 'Updating the meeting...', noDelay: true }, true))
      dispatch(actions.addResourcesToMeeting())
   }

   const existing = meeting?.participants
      .map(x => rooms.find(r => r.emailAddress === x.emailAddress))
      .filter(isDefined) || []

   useEffect(() => {
      if (meeting && !hasBooking) {
         dispatch(actions.startAddResource(meeting))
      }
   }, [dispatch, hasBooking, meeting])

   useEffect(() => {
      return () => {
         dispatch(actions.endBooking())
      }
   }, [dispatch])

   return <Page>
      <Prompt message={(location) => popupOpen ? false : !matchPath(location.pathname, '/meeting/:id/add-resources') && hasResources ? 'Discard changes?' : true} />
      <Header close={true} save={hasResources && suggestions && suggestions.length > 0 ? updateMeeting : undefined}>
         Add resources
      </Header>
      <Page.Body className={css.addResources}>
         <LoadMeeting meetingId={meetingId} />
         {meeting && <>
            <FormField className={css.title} icon={<FontAwesome name='circle' />}>
               <div>{meeting.subject}</div>
            </FormField>
            {meeting.isExternal && <FormField className={css.warning} icon={<FontAwesome name='exclamation-triangle' />}>
               <div>This meeting was booked by an extenral party. The additional resources will only be available to you.</div>
            </FormField>}

            <header>Existing resources</header>
            {existing.length === 0 && renderResult('No resources')}
            {existing.map(renderRoom)}

            <header>Additional resources</header>
            <ResourceFilterEditor />
         </>}
      </Page.Body>
   </Page>
}

function renderRoom(room: Room) {
   return <FormField key={room.emailAddress} icon={<ResourceIcon resource={room} className={css.roomIcon} />}>
      <div>{room.name || room.emailAddress}</div>
   </FormField>
}

function renderResult(text: string) {
   return <FormField icon={null}>
      <div>{text}</div>
   </FormField>
}
