import React, { useEffect, useState } from 'react'
import { Extend } from '../utils/misc'

type InputProps = Extend<React.InputHTMLAttributes<HTMLInputElement>, {
   value: number
   onChange: React.Dispatch<number>
}>

export default function Input({ value, onChange, onFocus, onBlur, ...rest }: InputProps) {
   const [text, setText] = useState('')

   useEffect(() => {
      setText(String(value))
   }, [value])

   function handleFocus(e: React.FocusEvent<HTMLInputElement>) {
      onFocus?.(e)
      e.currentTarget.select()
   }

   function hadnleBlur(e: React.FocusEvent<HTMLInputElement>) {
      onBlur?.(e)

      const value = parseInt(text)
      if (!Number.isNaN(value)) {
         onChange(value)
      }
   }

   return <input type='number' {...rest} value={text} onChange={e => setText(e.currentTarget.value)} onFocus={handleFocus} onBlur={hadnleBlur} />
}
