import React from 'react'

export function listOf(items: React.ReactNode[], separator: React.ReactNode, lastSeparator = separator) {
   if (items.length === 0) {
      return null
   }

   if (items.length === 1) {
      return items[0]
   }

   if (items.length === 2) {
      return <>{items[0]}{lastSeparator}{items[1]}</>
   }
   return <>
      {items.slice(0, items.length - 2).map(x => [x, separator])}
      {items[items.length - 2]}
      {lastSeparator}
      {items[items.length - 1]}
   </>
}

export function orList(items: React.ReactNode[]) {
   return listOf(items, ', ', ' or ')
}
