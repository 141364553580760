
import { useState } from 'react';
import { DropdownItem, DropdownMenu, DropdownToggle, Modal, ModalBody, ModalHeader, UncontrolledDropdown } from 'reactstrap';
import css from './PermissionEditor.module.scss';
import { actions, useSelector } from '../store/utils';
import { PermissionSet, PropertyType, UserType } from '../model';
import { useDispatch } from 'react-redux';
import { keysOf, toObject } from '../utils/misc';
import Button from './Button';


const customPlaceholder = {
   permissionSetId: "be89d3cc-0a51-4a54-b2a2-0d9cf2d7eed5",
   name: "Custom",
   permissions: []
}

export default function PermissionCombo() {
   const { permissions, permissionSets, setPermissionSet } = usePermissionEditor()
   const [open, setOpen] = useState(false)

   if (!permissionSets) return null

   const onPermissionChange = (p: PermissionSet) => {
      if (p.permissionSetId === "Custom" || p.permissionSetId === customPlaceholder.permissionSetId) {
         setOpen(true)
         return
      }
      setPermissionSet(p)
   }

   const onSave = (permission: PermissionSet) => {
      setPermissionSet(permission)
   }

   return <>
      <div className={css.permissionCombo}>
         <UncontrolledDropdown>
            <DropdownToggle className={css.button} caret tag='button'>{permissions?.name ?? "None"}</DropdownToggle>
            <DropdownMenu>
               {[...permissionSets, customPlaceholder].map(p => <DropdownItem key={p.permissionSetId} onClick={() => onPermissionChange(p)}>{p.name}</DropdownItem>)}
            </DropdownMenu>
         </UncontrolledDropdown>
      </div>
      {permissions && <PermissionEditor key={permissions.permissionSetId} open={open} setOpen={setOpen} sourceSet={permissions} onSave={onSave} />}
   </>
}


interface PropertyItem {
   property: PropertyType
   name: string
}
const properties: PropertyItem[] = [
   { property: 'ConferenceType', name: 'Conference Type' },
   { property: 'Date', name: 'Start/End Date' },
   { property: 'Time', name: 'Start/End Time' },
   { property: 'AllDay', name: 'All Day Event' },
   { property: 'Recurrence', name: 'Recurrence' },
   { property: 'Private', name: 'Is Private' },
   { property: 'Title', name: 'Title' },
   { property: 'Attendees', name: 'Attendees' },
   { property: 'Resources', name: 'Resources' },
   { property: 'Agenda', name: 'Agenda' }
]

interface PermissionEditorProps {
   sourceSet: PermissionSet
   open: boolean
   setOpen: (o: boolean) => void
   onSave: (permission: PermissionSet) => void
}

function PermissionEditor({ sourceSet, open, setOpen, onSave }: PermissionEditorProps) {

   const onCancel = () => {
      setOpen(false)
   }


   const [activeUserIndex, setActiveUserIndex] = useState(0)

   const [permissions, setPermissions] = useState(sourceSet.permissions.map(userPermission => ({
      user: userPermission.user,
      properties: toObject(properties, p => p.property, p => userPermission.properties.some(prop => prop.property === p.property) || (userPermission.properties.length === 1 && userPermission.properties[0].property === 'All'))
   })))

   console.log("permissions", permissions)
   console.log("sourceSet.permissions", sourceSet.permissions)

   const handleSave = () => {
      setOpen(false)
      const permissionSet: PermissionSet = {
         name: "Custom",
         permissionSetId: "Custom",
         permissions: permissions.map(item => ({
            user: item.user,
            properties: keysOf(item.properties).filter(key => item.properties[key]).map(x => ({property: x, access: 'Update'}))
         }))
      }

      for (let perm of permissionSet.permissions) {
         if (properties.every(p => perm.properties.some(prop => prop.property === p.property))) {
            perm.properties = [ {
               property: 'All',
               access: 'Update'
            }]
         }
      }

      onSave(permissionSet)
   }

   const onToggle = (p: PropertyItem) => {
      setPermissions(state => state.map((s, i) => activeUserIndex !== i ? s : {
            ...s,
            properties:  {
               ...s.properties,
               [p.property]: !state[activeUserIndex].properties[p.property]
            }
         }))
   }

   const onUserChange = (user: UserType) => {
      setActiveUserIndex(permissions.findIndex(p => p.user.type === user.type))
   }

   if (permissions.length < 1) return null

   return <Modal isOpen={open} className={css.modal} autoFocus={false}>
      <ModalHeader>
         {sourceSet.permissionSetId !== 'Custom' ? `Custom Permissions (based on ${sourceSet.name})` : 'Custom Permissions'}
      </ModalHeader>
      <ModalBody className={css.permissionEditor}>
         <div className={css.userCombo}>
            <UncontrolledDropdown>
               <DropdownToggle className={css.button} caret tag='button'>{permissions[activeUserIndex].user.type}</DropdownToggle>
               <DropdownMenu>
                  {permissions.map(p => <DropdownItem key={p.user.type} onClick={() => onUserChange(p.user)}>{p.user.type}</DropdownItem>)}
               </DropdownMenu>
            </UncontrolledDropdown>
         </div>

         <ul >
            {properties.map(property => <li key={property.property} className={css.item}>
               <label className={css.property}>
                  <input type='checkbox' checked={permissions[activeUserIndex].properties[property.property]} onChange={() => onToggle(property)} />
                  <div className={css.name}>{property.name}</div>
               </label>
            </li>)}
         </ul>

         <div className={css.buttons}>
            <Button color='accent' className={css.save} onClick={handleSave}>Save</Button>
            <Button className={css.cancel} onClick={onCancel}>Cancel</Button>
         </div>
      </ModalBody>
   </Modal >
}

export function usePermissionEditor() {
   const dispatch = useDispatch()

   const permissions = useSelector(s => s.booking?.permissions)
   const permissionSets = useSelector(s => s.login.data && s.login.data?.organisation?.permissions)

   return {
      permissions: permissions ? permissions : null,
      permissionSets,
      setPermissionSet(p: PermissionSet) {
         dispatch(actions.setPermissions(p))
      }
   }
}

