import bind from 'bind-decorator';
import classNames from 'classnames';
import React from 'react';
import css from './PieButton.module.scss';

export interface PieButtonProps {
   className?: string;
   value: number;
   onClick?: (button: PieButton) => void;
   component?: React.ReactType;

   valueClassName?: string;
   value2ClassName?: string;
   backgroundClassName?: string;
};

export default class PieButton extends React.PureComponent<PieButtonProps> {
   public render() {
      const { className, valueClassName, value2ClassName, backgroundClassName, children, value, component = 'button' } = this.props;

      const size = 16;
      const stroke = 4;
      const r = size - stroke / 2;
      const circ = 2 * Math.PI * r;

      const v1 = Math.min(value, 2)
      const v2 = v1 - Math.min(value, 1)

      return React.createElement(component, {
         className: classNames(css.pieButton, className),
         onClick: this.onClick,
      }, [
         <svg key='svg' viewBox={`0 0 ${size * 2} ${size * 2}`}>
            {value > 1 && <circle className={classNames(css.value2, value2ClassName)} r={r} cx={size} cy={size} strokeWidth={stroke} strokeDasharray={circ} strokeDashoffset={circ * (1 - v2)} />}
            <circle className={classNames(css.value, valueClassName)} r={r} cx={size} cy={size} strokeWidth={stroke} strokeDasharray={circ} strokeDashoffset={circ * (1 - v1)} />
            {value < 1 && <circle className={classNames(css.background, backgroundClassName)} r={r} cx={size} cy={size} strokeWidth={stroke} strokeDasharray={circ} strokeDashoffset={-circ * v1} />}
         </svg>,
         <div key='title'>{children}</div>,
      ])
   }

   @bind
   private onClick() {
      const { onClick } = this.props;
      if (onClick) {
         onClick(this);
      }
   }
}
