import classNames from 'classnames';
import React, { useCallback, useEffect, useState } from 'react';
import FontAwesome from 'react-fontawesome';
import { useDispatch } from 'react-redux';
import { organisationLogin, whoAmI, Login as ApiLogin } from '../../api';
import { bookitAzureAdPopup } from '../../azure';
import { Domain, LoginType } from '../../model';
import { actions, useSelector } from '../../store/utils';
import Logo from '../Logo';
import MeetingLinkInfo from '../MeetingLinkInfo';
import OtpStatus from '../OtpStatus';
import Spinner from '../Spinner';
import FormField from './FormField';
import { Header } from './Header';
import css from './Login.module.scss';
import { useOtp } from './OtpScreen';
import TextInput from './TextInput';

interface LoginProps {
   meetingLinkId?: string
}

export default function Login({ meetingLinkId }: LoginProps) {
   const dispatch = useDispatch()
   const [email, setEmail] = useState('')
   const [password, setPassword] = useState('')
   const [domain, setDomain] = useState<Domain>()
   const [loading, setLoading] = useState(false)
   const [error, setError] = useState<string>()
   const loginLoading = useSelector(s => s.login.loading)
   const [expectSuccess, setExpectSuccess] = useState(false)
   const [otpComplete, setOtpComplete] = useState(false)

   const api = useCallback(async () => {
      return (await whoAmI()) || null
   }, [])

   function onSuccess(data: ApiLogin) {
      setOtpComplete(true)
   }

   const { data, message, submitting, sendOtpRequest } = useOtp(api, onSuccess)


   useEffect(() => {
      setLoading(loginLoading)

      if (expectSuccess && !loginLoading) {
         setError('Invalid username or password')
      }
   }, [expectSuccess, loginLoading])

   function onNext(e?: React.FormEvent) {
      e?.preventDefault()

      if (domain) {
         onLogin()
      } else {
         onEmail()
      }
   }

   async function onEmail() {
      setLoading(true)
      setError(undefined)

      const domain = await organisationLogin(email)

      if (typeof domain === 'string') {
         setError(domain)
      } else if (domain.loginType === LoginType.none) {
         setError('Login is not allowed')
      } else if (domain.loginType === LoginType.email) {
         sendOtpRequest(email, undefined, meetingLinkId)
         setDomain(domain)
      } else {
         setDomain(domain)
      }

      setLoading(false)
   }

   const onLogin = useCallback(async () => {
      if (!domain) { return }

      setError(undefined)

      if (domain.loginType === LoginType.bookIt) {
         dispatch(actions.login.request({ userName: email, password }))
         setExpectSuccess(true)
         return
      }

      if (domain.loginType === LoginType.azure) {
         setLoading(true)

         const resp = await bookitAzureAdPopup(domain.clientId, domain.tenantId, email)
         if (typeof resp === 'string') {
            setError(resp)
         } else if (resp !== null) {
            dispatch(actions.login.request({ token: resp.accessToken, teams: false }))
         }

         setLoading(false)
      }
   }, [dispatch, domain, email, password])

   useEffect(() => {
      if (domain?.loginType === LoginType.azure) {
         onLogin()
      }
   }, [domain, onLogin])

   function onBack() {
      if (loading) { return }

      setDomain(undefined)
      setError(undefined)
      setExpectSuccess(false)
   }

   const inputPassword = domain?.loginType === LoginType.bookIt
   const hasError = message?.error
   const otpStarted = message !== undefined


   return <form onSubmit={onNext}>
      <Header back={domain ? onBack : undefined} next={domain ? undefined : onNext} save={domain ? onNext : undefined}>
         <Logo className={css.logo} />
      </Header>
      {!otpStarted && meetingLinkId && <MeetingLinkInfo className={css.meetingLinkInfo} />}
      <FormField icon={<FontAwesome name={inputPassword ? 'lock' : 'envelope'} />}>
         <div className={css.row}>
            {inputPassword
               ? <TextInput className={css.input} disabled={loading || otpStarted} placeholder='password' type='password' value={password} onChange={e => setPassword(e.currentTarget.value)} />
               : <TextInput className={css.input} disabled={loading} placeholder='your.name@company.com' type='email' value={email} onChange={e => setEmail(e.currentTarget.value)} />}

            {loading && <Spinner className={css.spinner} />}
         </div>
      </FormField>
      {domain && domain.loginType === LoginType.email &&
         <FormField >
               <OtpStatus otpStarted={otpStarted} otpComplete={otpComplete} error={message?.error ? message.text : undefined}/>
         </FormField>}
      <FormField icon={null} combine>
         {error && <span className={css.error}>{error}</span>}
      </FormField>
   </form>
}
