import { useEffect } from 'react';
import * as teams from '../teams';

interface Props {
   callback: true | undefined;
}

export default function TeamsLoginPopup({ callback }: Props) {
   useEffect(() => {
      onLoad();

      async function onLoad() {
         teams.initTeams()

         const domain = await teams.getTeamsDomain()
         if (!domain) { return }

         const app = await teams.createApp(domain.clientId, domain.tenantId, true)
         if (callback) { return }

         const token = await app.acquireSilent()
         if (token?.accessToken) {
            teams.notifySuccess(token.accessToken)
         } else {
            app.acquireRedirect()
         }
      }
   }, [callback])

   return null;
}
