import classNames from 'classnames'
import { useLayoutEffect, useMemo, useRef } from 'react'
import { isRoom } from '../../model'
import { useSelector } from '../../store/utils'
import css from './FloorMap.module.scss'
import { selectRoomsAndMeetings } from './selectors'

interface Props {
   className?: string
}

export default function FloorMap({ className }: Props) {
   const mapRef = useRef<HTMLDivElement>(null)
   const data = useSelector(selectRoomsAndMeetings)
   const login = useSelector(s => s.login.data)

   const welcome = useSelector(state => {
      const { displayBoard: { welcome }, contacts } = state
      if (!welcome) { return null }
      return welcome.meetings[0]?.meetingRooms.map(a => contacts[a.emailAddress]).filter(isRoom) ?? []
   })

   const rooms = useMemo(() => {
      if (!welcome) { return data }
      if (!welcome.length) { return [] }

      return data.map(room => ({
         email: room.email,
         status: welcome.some(r => r.emailAddress === room.email) ? {
            type: 'flash' as const,
         } : null,
      }))
   }, [data, welcome])

   useLayoutEffect(() => {
      if (!mapRef.current) { return }
      for (const room of rooms) {
         const node = mapRef.current.querySelector(`#map-${room.email.toLowerCase().replace(/([.@])/g, '\\$1')}`) as SVGElement
         if (!node) { continue }

         node.setAttribute('class', classNames(room.status ? css[room.status.type] : ''))
      }
   }, [rooms])

   const device = login && login.device
   if (!device) { return null }

   const floorPlan = device.floorPlans.find(p => welcome ? welcome.some(r => r.floor === p.floor) : p.floor === null)
   if (!floorPlan) { return null }

   return <div className={classNames(css.FloorMap, welcome && css.welcome, className)}>
      <h2 className={css.header}>Location Map</h2>
      <div className={css.map} ref={mapRef} dangerouslySetInnerHTML={{ __html: floorPlan.plan }} />
   </div>
}
