import moment from 'moment';
import { getType } from 'typesafe-actions';
import { SchedulesDataItem } from '../../model';
import { Actions, actions, StoreState } from '../utils';

type State = Record<string, undefined | SchedulesDataItem & {
   loading: boolean
}>

export default function schedules(state: State = {}, action: Actions): State {
   switch (action.type) {
      case getType(actions.fetchScheduleRequested): {
         const { email, day } = action.payload
         let data = state[getKey(email, day)]

         if (data) {
            data = { ...data, loading: true }
         } else {
            data = { periods: [], workingHours: [], loading: true }
         }

         return {
            ...state,
            [getKey(email, day)]: data
         }
      }

      case getType(actions.fetchSchedulesResponse): {
         const newState: State = {}
         for (const email in action.payload) {
            const days = action.payload[email]
            for (const dayStr in days) {
               const day = parseInt(dayStr)
               newState[getKey(email, day)] = {
                  ...days[dayStr],
                  loading: false,
               }
            }
         }

         return {
            ...state,
            ...newState,
         }
      }

      case getType(actions.endBooking):
      case getType(actions.addMeetingData):
      case getType(actions.replaceMeetingData):
      case getType(actions.removeMeeting): {
         return {}
      }
   }
   return state
}

function getKey(email: string, day: number) {
   return `${email}->${moment(day).format('YYYY.MM.DD')}`
}

function getRoot(state: StoreState) { return state.schedules }

function getSchedule(state: StoreState, email: string, day: number) {
   return getRoot(state)[getKey(email, day)]
}

export function shouldLoadSchedule(state: StoreState, email: string, day: number) {
   const data = getSchedule(state, email, day);
   return data === undefined
}

export function shouldReloadSchedule(state: StoreState, email: string, day: number) {
   const data = getSchedule(state, email, day);
   return data && !data.loading
}

export function selectSchedule(state: StoreState, email: string, day: number): SchedulesDataItem | undefined {
   const schedule = getSchedule(state, email, day)
   return schedule && (schedule.loading ? undefined : schedule)
}
